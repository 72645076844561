import React, { useEffect, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Button, Modal, Icon } from 'semantic-ui-react';
import AvatarEditor from 'react-avatar-editor';
import "./style.less";

const ImageCropModal = ({ onClickSave, loading = false, setModalOpen, open, setImageEditor, borderRadius = 0, setImageName = (imgName: string) => {} }) => {
    const [newImage, setNewImage] = useState();
    const [rotationAngle, setRotationAngle] = useState<number>(0);

    const onDrop = useCallback(acceptedFiles => {
        setNewImage(acceptedFiles[0]);
        setImageName(acceptedFiles[0].name);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/jpeg, image/png',
        preventDropOnDocument: true,
        onDrop: onDrop,
    });

    useEffect(() => {
        setNewImage('');
        setImageName('');
        setRotationAngle(0);
    }, [open]);

    const rotateImage = degrees => {
        const newRotationAngle = rotationAngle + degrees;
        setRotationAngle(newRotationAngle);
    };

    return (
        <Modal
            open={open}
            onClose={() => {
                setNewImage('');
                setImageName('');
                setRotationAngle(0);
                setModalOpen(false);
            }}
            size="small"
        >
            <Modal.Header>Select a Photo</Modal.Header>
            <Modal.Content>
                <div {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    {newImage ? '' : <p>Drag and Drop a photo here, or click into this form to upload a photo.</p>}
                </div>
                {newImage ? (
                    <div className="upload-image-container">
                        <AvatarEditor
                            ref={setImageEditor}
                            width={250}
                            height={250}
                            image={newImage}
                            disableHiDPIScaling={true}
                            rotate={rotationAngle}
                            borderRadius={borderRadius}
                        />
                        <div>
                            <div className="mt-8 mb-8">
                                <Button
                                    className="tiny basic"
                                    onClick={() => {
                                        rotateImage(-90);
                                    }}
                                >
                                    <Icon name="undo alternate" />
                                    Rotate left
                                </Button>
                                <Button
                                    className="tiny basic ml-8"
                                    onClick={() => {
                                        rotateImage(90);
                                    }}
                                >
                                    <Icon name="redo alternate" />
                                    Rotate right
                                </Button>
                            </div>
                            <Button className="primary small basic" onClick={onClickSave}>
                                {loading ? (
                                    <>
                                        <span className="ui active loader"></span> <span>Uploading...</span>
                                    </>
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </Modal.Content>
        </Modal>
    );
};

export default ImageCropModal;
