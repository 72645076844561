import { SocialProfileType } from '../neighbors/types/Registrant';
import * as Lambda from './LambdaApi';

const getFamilyList = async (residentId) => {
    const apiPath = `/residents/${residentId}/relatives`;
    const relativeData = await Lambda.Get(apiPath, {}, 'lambda');
    return relativeData;
};

const getA4hContacts = async (facilityId, residentId) => {
    const apiPath = `/s2l/${facilityId}/fetchA4hContacts`
    const body = {
       Filter:{
        _id: residentId,
       },
    }
    const a4hContactData = await Lambda.Post(apiPath, body, 'lambda')
    return a4hContactData
}

const getRelativeDetail = async (residentId, relativeId) => {
    const apiPath = `/residents/${residentId}/relatives/${relativeId}`;
    const relativeData = await Lambda.Get(apiPath, {}, 'lambda');
    return relativeData;
};

const updateRelative = async (residentId, relativeId, payload) => {
    const apiPath = `/residents/${residentId}/relatives/${relativeId}`;
    const relativeData = await Lambda.Put(apiPath, { ...payload }, 'lambda');
    return relativeData;
};

const updateRelativeImage = async (residentId, relativeId, imageURL) => {
    const apiPath = `/residents/${residentId}/relatives/${relativeId}`;
    const relativeData = await Lambda.Put(apiPath, { Image: imageURL }, 'lambda');
    return relativeData;
};

const updateCanListenToStories = async (residentId, relativeId, value) => {
    const apiPath = `/residents/${residentId}/relatives/${relativeId}`;

    const relativeData = await Lambda.Put(
        apiPath,
        {
            canListenToStories: value,
        },
        'lambda',
    );

    return relativeData;
};

const updateAnnouncementAlerts = async (residentId, relativeId, value) => {
    const apiPath = `/residents/${residentId}/relatives/${relativeId}`;

    const relativeData = await Lambda.Put(
        apiPath,
        {
            announcementAlerts: value,
        },
        'lambda',
    );

    return relativeData;
};

const updateCanRecieveRequests = async (residentId, relativeId, value) => {
    const apiPath = `/residents/${residentId}/relatives/${relativeId}`;

    const relativeData = await Lambda.Put(
        apiPath,
        {
            canRecieveRequests: value,
        },
        'lambda',
    );

    return relativeData;
};
const updateCanSeeFeed = async (residentId, relativeId, value) => {
    const apiPath = `/residents/${residentId}/relatives/${relativeId}`;

    const relativeData = await Lambda.Put(
        apiPath,
        {
            canSeeFeed: value,
        },
        'lambda',
    );

    return relativeData;
};
const updateManager = async (residentId, relativeId, value) => {
    const apiPath = `/residents/${residentId}/relatives/${relativeId}`;

    const relativeData = await Lambda.Put(
        apiPath,
        {
            Manager: value, 
        },
        'lambda',
    );
    
    return relativeData;
};
const deleteRelative = async (relativeId) => {
    //used by logged in user to delete their own account
    const apiPath = `/relatives/${relativeId}`;
    const deleteResponse = await Lambda.Delete(apiPath, {}, 'lambda');
    console.log({deleteResponse});
    return deleteResponse;
}

const updateResident = async (
    residentId: string,
    {
        FirstName = undefined,
        LastName = undefined,
        Image = undefined,
        exercise = undefined,
        rx = undefined,
        greetingContactOverride = undefined,
        isResponseEnabled = undefined,
        SpecialHandling = undefined,
        Private = undefined,
        SocialProfile = undefined,
        Cell = undefined,
    }: {
        FirstName?: string;
        LastName?: string;
        Image?: string;
        exercise?: string;
        rx?: string;
        greetingContactOverride?: string;
        isResponseEnabled?: boolean;
        SpecialHandling?: string;
        Private?: boolean;
        SocialProfile?: SocialProfileType;
        Cell?: string;
    },
) => {
    if (!residentId) {
        throw new Error('Resident ID is required');
    }
    const payload = {
        firstName: FirstName,
        lastName: LastName,
        profileImage: Image,
        exercise,
        rx,
        greetingContactOverride,
        isResponseEnabled,
        specialHandling: SpecialHandling,
        privacy: Private,
        socialProfile: SocialProfile,
        cell: Cell,
    };
    // Remove undefined values from the payload
    const filteredPayload =  {}
    Object.keys(payload).forEach((key) => {
        if (payload[key] !== undefined) {
            filteredPayload[key] = payload[key];
        }
    });

    if (Object.keys(filteredPayload).length === 0) {
        throw new Error('At least one field is required to update the resident profile');
    }

    const apiPath = `/residents/${residentId}`;
    const residentData = await Lambda.Put(apiPath, { ...filteredPayload }, 'lambda');
    return residentData;
};

export {
    getFamilyList,
    getA4hContacts,
    getRelativeDetail,
    updateRelative,
    updateRelativeImage,
    updateCanListenToStories,
    updateAnnouncementAlerts,
    updateCanRecieveRequests,
    updateCanSeeFeed,
    updateManager,
    deleteRelative,
    updateResident
};
