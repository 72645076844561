import { useState, useEffect, useContext, useMemo } from 'react';
import * as jsonexport from 'jsonexport/dist';
import { saveAs } from 'file-saver';

import UserContext from '../../../contexts/UserContext';
import {
    getRequests,
    getAcceptedRequests,
    getActivities,
    getArchivedRequests,
    getArchivedActivities,
} from '../../../services/activities';

const useFeedData = (lastUpdated: number, isArchived: boolean = false) => {
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);


    const isDisabledForPayment = activeRegistrantsIndexes[0] === 'all-users' ? false : !(activeRegistrantsIndexes
    .filter(
        (residentIndex) =>
            !listOfResidents[residentIndex].isDisabledForPayment
    ).length)
       

    const residentIds =
        activeRegistrantsIndexes[0] === 'all-users'
            ? listOfResidents
                  .filter((resident) => (resident.manager || resident.canSeeFeed) && !resident.isDisabledForPayment)
                  .map((resident) => resident._id)
            : activeRegistrantsIndexes
                .filter(
                    (residentIndex) =>
                        (listOfResidents[residentIndex].manager || listOfResidents[residentIndex].canSeeFeed) && !listOfResidents[residentIndex].isDisabledForPayment
                )
                .map((residentIndex) => listOfResidents[residentIndex]._id);

    const [isDownloadingReport, setIsDownloadingReport] = useState(false);
    const [feedFilters, setFeedFilters] = useState({
        messages: true,
        requests: true,
        activities: true,
    });
    const [pageNo, setPageNo] = useState(0);
    const [feed, setFeed] = useState<any>([]);
    const [morePages, setMorePages] = useState(true);
    const [paginating, setPaginating] = useState(false);
    const limit = 10;
    const columns = useMemo(
        () => [
            {
                Header: 'Resident',
                columns: [
                    {
                        Header: 'FirstName',
                        id: 'FirstName',
                        accessor: (row) => {
                            return row.registrantFirstName || row.Registrant_FirstName;
                        },
                    },
                    {
                        Header: 'LastName',
                        id: 'Lastname',
                        accessor: (row) => {
                            return row.registrantLastName || row.Registrant_LastName;
                        },
                    },
                ],
            },
            {
                Header: 'Feed Info',
                columns: [
                    {
                        Header: 'Feed Name',
                        accessor: 'Name',
                    },
                    {
                        Header: 'Feed Type',
                        accessor: 'type',
                    },
                    {
                        Header: 'Feed Sub-type',
                        id: 'feedSubType',
                        accessor: (row) => {
                            return row.RequestType_Name || row.activityType;
                        },
                    },
                    {
                        Header: 'Feed Details',
                        id: 'feedDetails',
                        accessor: (row) => {
                            const slot = row.RequestSlots || row.ActivitySlots || {};
                            const feedDetails = Object.entries(slot)
                                .map(([name, value]) => `${name}: ${value || "'Not Specified'"}`)
                                .join(', ');

                            return feedDetails || '-';
                        },
                    },
                    {
                        Header: 'Feed Time',
                        id: 'feedTime',
                        accessor: (row) => {
                            const time = row.activityTime || row.RequestedTime;
                            const date = new Date(time);

                            return time && date.toLocaleString();
                        },
                    },
                    {
                        Header: 'Status',
                        id: 'status',
                        accessor: (row) => {
                            return row.Status || '-';
                        },
                    },
                    {
                        Header: 'Accepted By',
                        id: 'acceptedBy',
                        accessor: (row) => {
                            return row.AcceptedByName ? row.AcceptedByName : '-';
                        },
                    },
                    {
                        Header: 'Accepted At',
                        id: 'acceptedAt',
                        accessor: (row) => {
                            return row.AcceptedTime ? new Date(row.AcceptedTime).toLocaleString() : '-';
                        },
                    },
                    {
                        Header: 'Archived',
                        id: 'isArchived',
                        accessor: (row) => {
                            return row.type === 'request' ? row.IsArchived || false : row.isArchived || false;
                        },
                    },
                ],
            },
        ],
        [],
    );

    const handleCheckBoxChange = (_event: any, data) => {
        const { name, checked } = data;
        setFeedFilters((prevFeedFilters) => ({
            ...prevFeedFilters,
            [name]: checked,
        }));
    };

    // ResidentId will possibly become an array in future
    const fetchFilteredFeedData = async (residentId, pageNumber, pageLimit, archived) => {
        const residentIds = residentId ? (residentId.constructor === Array ? residentId : [residentId]) : [];
        const filteredAPICalls: any[] = [];
        if (residentIds.length) {
            if (archived) {
                if (feedFilters.activities) {
                    filteredAPICalls.push(getArchivedActivities(residentIds, pageNumber, pageLimit));
                } else {
                    filteredAPICalls.push({ activities: [] });
                }

                if (feedFilters.messages && feedFilters.requests) {
                    filteredAPICalls.push(getArchivedRequests(residentIds, pageNumber, pageLimit, 'all'));
                } else if (feedFilters.messages && !feedFilters.requests) {
                    filteredAPICalls.push(getArchivedRequests(residentIds, pageNumber, pageLimit, 'greetings'));
                } else if (!feedFilters.messages && feedFilters.requests) {
                    filteredAPICalls.push(getArchivedRequests(residentIds, pageNumber, pageLimit, 'requests'));
                } else {
                    filteredAPICalls.push({ requests: [] });
                }
            } else {
                if (feedFilters.activities) {
                    filteredAPICalls.push(getActivities(residentIds, pageNumber, pageLimit));
                } else {
                    filteredAPICalls.push({ activities: [] });
                }

                if (feedFilters.messages && feedFilters.requests) {
                    filteredAPICalls.push(getRequests(residentIds, pageNumber, pageLimit, 'all'));
                } else if (feedFilters.messages && !feedFilters.requests) {
                    filteredAPICalls.push(getRequests(residentIds, pageNumber, pageLimit, 'greetings'));
                } else if (!feedFilters.messages && feedFilters.requests) {
                    filteredAPICalls.push(getRequests(residentIds, pageNumber, pageLimit, 'requests'));
                } else {
                    filteredAPICalls.push({ requests: [] });
                }
            }

            const [{ activities }, { requests }] = await Promise.all(filteredAPICalls);
            return { requests, activities };
        }
        return { requests: [], activities: [] };
    };
    const mergeRequestActivitiesAndSort = (requestFeedItems, activityFeedItems) => {
        const combinedFeed = requestFeedItems &&
            activityFeedItems && [
                ...requestFeedItems.map((request) => ({
                    ...request,
                    type: 'request',
                })),
                ...activityFeedItems.map((activity) => ({
                    ...activity,
                    type: 'activity',
                })),
            ];

        const sortedResults =
            combinedFeed &&
            combinedFeed.sort((a, b) => {
                const aTime = a.type === 'request' ? a.RequestedTime : a.activityTime;
                const bTime = b.type === 'request' ? b.RequestedTime : b.activityTime;

                return bTime - aTime;
            });

        return sortedResults;
    };
    const getPaginatedFeed = async () => {
        const { requests: filteredRequests, activities: filteredActivities } = await fetchFilteredFeedData(
            residentIds,
            pageNo,
            limit,
            isArchived,
        );

        if (
            (!filteredRequests || !filteredRequests.length || filteredRequests.length < limit) &&
            (!filteredActivities || !filteredActivities.length || filteredActivities.length < limit)
        ) {
            setMorePages(false);
        }
        return mergeRequestActivitiesAndSort(filteredRequests, filteredActivities);
    };

    const fetchFeed = async () => {
        setPaginating(true);
        const results = await getPaginatedFeed();
        results && pageNo === 0 ? setFeed([...results]) : setFeed((prevFeed) => [...prevFeed, ...results]);
        setPaginating(false);
    };

    const refresh = async () => {
        setPageNo(0);
        setMorePages(true);
        setPaginating(true);
        setFeed([]);
        const results = await getPaginatedFeed();

        results && setFeed([...results]);

        setPaginating(false);
    };

    const loadMore = () => {
        setPageNo((prevPageNo) => prevPageNo + 1);
    };

    const getReportData = async (residentIdList, page = 0) => {
        let requestFeed: any[] = [];
        let activityFeed: any[] = [];
        const pageSize = 100;
        const { requests: filteredRequests, activities: filteredActivities } = await fetchFilteredFeedData(
            residentIdList,
            page,
            pageSize,
            isArchived,
        );
        if (
            (!filteredRequests || (filteredRequests && filteredRequests.length < pageSize)) &&
            (!filteredActivities || (filteredActivities && filteredActivities.length < pageSize))
        ) {
            if (isArchived) {
                requestFeed = [...requestFeed, ...filteredRequests];
            } else {
                const acceptedRequestFeed = await getAcceptedRequests(residentIds);
                requestFeed = [...requestFeed, ...filteredRequests, ...acceptedRequestFeed.requests];
            }
            activityFeed = [...activityFeed, ...filteredActivities];
            return mergeRequestActivitiesAndSort(requestFeed, activityFeed);
        }
        requestFeed = [...requestFeed, ...filteredRequests];
        activityFeed = [...activityFeed, ...filteredActivities];
        return getReportData(residentIdList, page + 1);
    };

    useEffect(() => {
        fetchFeed();
    }, [pageNo]);

    useEffect(() => {
        refresh();
    }, [JSON.stringify(residentIds), lastUpdated, feedFilters]);

    const saveRequestReport = async () => {
        setIsDownloadingReport(true);
        const reports = await getReportData(residentIds);
        //@ts-ignore
        const flattenColumns = columns.reduce((accl, coln) => {
            // flatten the columns
            return [...accl, ...coln.columns];
        }, []);
        // build the output to resemble the onscreen page
        const transformedReports = reports.map((report) => {
            // @ts-ignore
            return flattenColumns.reduce((accl, col) => {
                const { Header, accessor, id } = col;
                const obj = {
                    [Header]: id ? accessor(report) : report[accessor],
                };
                return {
                    ...accl,
                    ...obj,
                };
            }, {});
        });

        // convert the json to csv
        // save the csv into a file
        jsonexport(transformedReports, (err, csv) => {
            const file = new File([csv], 'reports.csv', { type: 'text/csv;charset=utf-8' });
            saveAs(file, 'reports.csv');
            setIsDownloadingReport(false);
        });
    };

    return {
        isDownloadingReport,
        saveRequestReport,
        handleCheckBoxChange,
        feedFilters,
        canViewRequets: activeRegistrantsIndexes
            .map((index) => {
                if (index !== 'all-users') {
                    return listOfResidents[index];
                }
                return { manager: true, canRecieveRequests: true };
            })
            .reduce((acc, resident) => {
                return acc || resident.manager || resident.canRecieveRequests;
            }, false),
        activeRegistrantsIndexes,
        paginating,
        feed,
        morePages,
        loadMore,
        isDisabledForPayment
    };
};

export default useFeedData;
