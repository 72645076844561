import { createContext } from 'react';
// Common useContent to store appUrls and import where needed
interface appUrls {
    neighborsAndroidAppUrl: string;
    neighborsIosAppUrl: string;
}

const defaultValue: appUrls = {
    neighborsAndroidAppUrl: "https://play.google.com/store/apps/details?id=com.speak2software.neighbors",
    neighborsIosAppUrl: "https://apps.apple.com/us/app/speak2-neighbors/id6445915629"
};

const AppUrls = createContext(defaultValue);

export default AppUrls;