import * as Lambda from '../../services/LambdaApi';

const getServiceInstanceImage = async ({ facilityId, svcInstanceId, fileName }) => {
    const apiPath = `/s2l/${facilityId}/service-instance-image`;
    const params = {
        queryStringParameters: { Facility: facilityId, svcInstanceId, fileName }
    };
    const response = await Lambda.Get(apiPath, params, 'lambda');
    if (!response || !response.Result || !response.Result.Content) {
        throw new Error('Error fetching service instance image');
    }
    return response.Result.Content;
}

const getStandaloneSignageImage = async ({facilityId, contentType, contentId}) => {
    const apiPath = `/s2l/${facilityId}/signage-image`;
    const params ={
        queryStringParameters: {contentType, contentId}
    }
    const res = await Lambda.Get(apiPath, params, 'lambda');
    return res.Result.Content.signedUrl;
}

export { getServiceInstanceImage, getStandaloneSignageImage};