import React, { useEffect, useContext, useState } from 'react';
import { Form, Button, Checkbox, Dimmer, Loader, Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import UserContext from '../../../contexts/UserContext';
import config from '../../../config';

import ImageCropModal from '../ImageCropModal';
import { converterDataURItoBlob, getUploadUrl, uploadToSignedUrl } from '../../../services/imageUpload';
import {
    getRelativeDetail,
    updateRelative,
    updateRelativeImage,
    updateCanListenToStories,
    updateCanRecieveRequests,
    updateCanSeeFeed,
    updateManager,
    updateAnnouncementAlerts
} from '../../../services/family';

import UpdateContactName from './UpdateA4HContactName';

import '../../pages/family_friends/style.less';
import { Registrant } from '../../../neighbors/types/Registrant';

declare var process: {
    env: {
        SERVER_ENV: string;
        REACT_APP_PLATFORM: string;
    };
};

const envConfig = config[process.env.SERVER_ENV] || config.development;
const BUCKET_URL = envConfig.profileImageBucketUrl;

interface Relative {
    _id: string;
    FirstName: string;
    LastName: string;
    Username: string;
    Image: string;
    Manager?: boolean;
    canListenToStories?: boolean;
    Relationship?: string;
    canRecieveRequests?: boolean;
    canSeeFeed?: boolean;
    a4hDetails: {
        contactId: string | undefined;
        reciprocalAssociationContact: string | undefined; 
        a4hAddrBookId: string | undefined;
        a4hCommProfileId: string | undefined;
        contact: {
            name: string;
            phoneNumbers:[{
                number: string
            }]
        } | undefined
    } | undefined
    email?: string
    announcementAlerts?: boolean 
}

const RelativeDetail = (props) => {
    const { userObject, setUser } = useContext(UserContext);
    const residentId = userObject.registrants.list[userObject.registrants.activeRegistrantsIndexes]._id;
    const residentName = userObject.registrants.list[userObject.registrants.activeRegistrantsIndexes].FirstName;
    const [relative, setRelative] = useState<Relative>();
    const [loading, setLoading] = useState<boolean>(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [canEditPersonalDetails, setCanEditPersonalDetails] = useState<Boolean>(false);
    const [canEditRelationalDetails, setCanEditRelationalDetails] = useState<Boolean>(false);
    const [canUpdateListenToStories, setCanUpdateListenToStories] = useState<Boolean>(false);
    const [canUpdateRecieveRequests, setCanUpdateRecieveRequests] = useState<Boolean>(false);
    const [canUpdateToManager, setCanUpdateToManager] = useState<Boolean>(false);
    const [isEditNameOpen, setIsEditNameOpen] = useState(false);
    const [isAnnouncementAlert, setIsAnnouncementAlert] = useState(false);
    const [isUpdatingAnnouncementAlerts, setIsUpdatingAnnouncementAlerts] = useState(false);

    const [formData, setFormData] = useState<Partial<Relative>>({
        FirstName: '',
        LastName: '',
        Relationship: '',
    });

    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [imageEditor, setImageEditor] = useState();
    const [relativeImage, setRelativeImage] = useState<String>('');
    const [email, setEmail] = useState('');

    const relativeId = props.location.pathname.split('/').pop();

    useEffect(() => {
        reload()
    }, [residentId, relativeId]);

    const reload = async () => {
        setLoading(true);

        await getRelativeDetail(residentId, relativeId).then((relativeData) => {
            setRelative(relativeData.relative);
            setRelativeImage(relativeData.relative.Image || '');
            setCanEditPersonalDetails(relativeData.canEditPersonalDetails);
            setCanEditRelationalDetails(relativeData.canEditRelationalDetails);
            setCanUpdateListenToStories(!!relativeData.canUpdateListenToStories);
            setCanUpdateRecieveRequests(!!relativeData.canUpdateRecieveRequests);
            setCanUpdateToManager(relativeData.canUpdateToManager);
            setFormData({
                FirstName: relativeData.relative.FirstName,
                LastName: relativeData.relative.LastName,
                Relationship: relativeData.relative.Relationship,
            });
            relativeData.relative && relativeData.relative.email && setEmail(relativeData.relative.email);
            relativeData.relative && relativeData.relative.announcementAlerts && setIsAnnouncementAlert(relativeData.relative.announcementAlerts);
            setLoading(false);
        });

    }

    const handleChange = (input) => (event) => {
        setFormData({
            ...formData,
            [input]: event.target.value,
        });
    };

    const onSubmit = async ({ FirstName, LastName, Relationship }: Partial<Relative>) => {
        const payload: Partial<Relative> = {};

        if (canEditPersonalDetails) {
            payload.FirstName = FirstName;
            payload.LastName = LastName;
        }

        if (canEditRelationalDetails && process.env.REACT_APP_PLATFORM !== 'neighbors') {
            payload.Relationship = Relationship || undefined;
        }

        if(canEditPersonalDetails || canEditRelationalDetails){
            payload.email = email;
        }
        setIsUpdating(true);

        await updateRelative(residentId, relativeId, payload);

        if (process.env.REACT_APP_PLATFORM === 'neighbors') {
            setUser(
                {
                    ...userObject,
                    profile: {
                        ...userObject.profile,
                        FirstName,
                        LastName,
                    },
                    registrants: {
                        ...userObject.registrants,
                        list: userObject.registrants.list.map((resident: Registrant) => {
                            if ((resident._id === residentId)) {
                                if (resident.managerType === 'Resident')
                                    return {
                                        ...resident,
                                        SocialProfile: {
                                            ...resident.SocialProfile,
                                            FirstName,
                                            LastName,
                                        }
                                    };
                                else 
                                    return resident
                            }
                            return resident;
                        })
                    }
                }
            );
        } else {
            userObject.profile._id === relativeId &&
                setUser({
                    ...userObject,
                    profile: {
                        ...userObject.profile,
                        FirstName,
                        LastName,
                    },
                });
        }

        toast.success('Relative details updated', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });
        setIsUpdating(false);
    };

    const onClickSave = async () => {
        if (imageEditor) {
            setIsUploadingImage(true);
            // @ts-ignore
            const canvas = imageEditor.getImageScaledToCanvas();

            try {
                const { signedUrl } = await getUploadUrl(residentId, relativeId);
                const dataUrl = canvas.toDataURL();
                const blob = converterDataURItoBlob(dataUrl);
                const res = await uploadToSignedUrl(blob, signedUrl);
                if (res.ok) {
                    await new Promise((resolve) => setTimeout(resolve, 800));
                    setImageModalOpen(false);
                    const imageUrl = `${BUCKET_URL}/${relativeId}?${Date.now()}`;
                    const result = await updateRelativeImage(residentId, relativeId, imageUrl);

                    userObject.profile._id === relativeId &&
                        setUser({
                            ...userObject,
                            profile: {
                                ...userObject.profile,
                                Image: imageUrl,
                            },
                        });

                    if (result.success) {
                        setRelativeImage(`${imageUrl}`);
                        toast.success('Image uploaded successfully!', {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                } else {
                    toast.error('Could not update image. Please try again.', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            } catch (e) {
                console.log(e);
                toast.error('Could not update image. Please try again.', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            setIsUploadingImage(false);
        }
        return;
    };

    const updateCanListenToResidentStories = async (value) => {
        if (!relative) return;

        setLoading(true);
        await updateCanListenToStories(residentId, relative && relative._id, value);

        toast.success(
            value
                ? `${relative && relative.FirstName} can now listen to stories sent to ${residentName}`
                : `${relative && relative.FirstName} can no longer listen to stories sent to ${residentName}`,
            {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            },
        );
        setLoading(false);
        relative && setRelative({ ...relative, canListenToStories: value });
    };
    const updateCanSeeFeedAndRecieveRequestsForResident = async (value) => {
        if (!relative) return;

        setLoading(true);
        await updateCanRecieveRequests(residentId, relative && relative._id, value);
        await updateCanSeeFeed(residentId, relative && relative._id, value);

        toast.success(
            value
                ? `${
                      relative && relative.FirstName
                  } can now see the feed, recieve or act on requests for ${residentName}`
                : `${
                      relative && relative.FirstName
                  } can no longer see the feed, recieve or act on requests for ${residentName}`,
            {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            },
        );
        setLoading(false);
        relative && setRelative({ ...relative, canRecieveRequests: value, canSeeFeed: value });
    };

    const toggleManager = async (value) => {
        if (!relative) return;
        setLoading(true);
        await updateManager(residentId, relative && relative._id, value);

        toast.success(
            value
                ? `${
                      relative && relative.FirstName
                  } is now a manager for ${residentName}`
                : `${
                      relative && relative.FirstName
                  } is no longer a manager for ${residentName}`,
            {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            },
        );
        setLoading(false);
        relative && setRelative({ ...relative, Manager: value });

    };

    const handleAnnouncementAlertToggle = async (value) => {
        setIsUpdatingAnnouncementAlerts(true);
        await updateAnnouncementAlerts(residentId, relative && relative._id, value);
        toast.success(
            value
                ? `Successfully enabled announcement alerts`
                : `Disabled announcement alerts`,
            {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            },
        );
        setIsUpdatingAnnouncementAlerts(false);
        setIsAnnouncementAlert(isAnnouncementAlert => !isAnnouncementAlert);
    }

    return (
        <div className="relative-list relative-detail">
            <Dimmer className="mt-80" active={loading} inverted>
                <Loader active={loading} />
            </Dimmer>

            <ImageCropModal
                setImageEditor={setImageEditor}
                setModalOpen={setImageModalOpen}
                onClickSave={onClickSave}
                open={imageModalOpen}
                loading={isUploadingImage}
            />

            {relative && (
                <Form
                    onSubmit={(e) => {
                        e.preventDefault();
                        onSubmit(formData);
                        // here check for canEdits and form the payload and send to the backned
                        // console.log('formData', formData);
                    }}
                >
                    <div className="relative-info no-border">
                        <div
                            className="relative-image-container"
                            onClick={() => {
                                canEditPersonalDetails && setImageModalOpen(true);
                            }}
                        >
                            {relativeImage ? (
                                <div
                                    className="relative-image"
                                    style={{ background: `url(${relativeImage}) no-repeat` }}
                                ></div>
                            ) : (
                                <div className="relative-avatar">
                                    {relative && !!relative.FirstName && relative.FirstName.charAt(0)}{' '}
                                    {relative && !!relative.LastName && relative.LastName.charAt(0)}
                                </div>
                            )}
                        </div>
                        <div className="flex-1">
                            {canEditPersonalDetails ? (
                                <>
                                    <Form.Field>
                                        <Form.Input
                                            value={formData.FirstName}
                                            type="text"
                                            name="First Name"
                                            onChange={handleChange('FirstName')}
                                            placeholder="email or phone number"
                                            required
                                            autoComplete="false"
                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input
                                            value={formData.LastName}
                                            type="text"
                                            name="Last Name"
                                            onChange={handleChange('LastName')}
                                            placeholder="email or phone number"
                                            required
                                            autoComplete="false"
                                        />
                                    </Form.Field>
                                </>
                            ) : (
                                <>
                                    <div className="relative-field">{relative.FirstName}</div>
                                    <div className="relative-field">{relative.LastName}</div>
                                </>
                            )}
                        </div>
                    </div>

                    <div className="relative-field">{relative.Username}</div>

                    {process.env.REACT_APP_PLATFORM !== 'neighbors' ? canEditRelationalDetails ? (
                        <Form.Field>
                            <Form.Input
                                value={formData.Relationship || ''}
                                type="text"
                                name="Relationship"
                                onChange={handleChange('Relationship')}
                                placeholder="Relationship"
                                autoComplete="false"
                            />
                        </Form.Field>
                    ) : (
                        <div className="relative-field">{relative.Relationship || 'Relationship not set'}</div>
                    ) : <></>}
                    {canEditPersonalDetails || canEditRelationalDetails ? (
                        <Form.Field>
                            <Form.Input
                                value={email}
                                type="email"
                                name="email"
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                }}
                                placeholder="Email for announcement alerts"
                                autoComplete="false"
                            />
                        </Form.Field>
                    ) : (
                        <div className="relative-field">{relative.email || 'email not set'}</div>
                    )}
                    <div className="relative-field">
                        {
                            isUpdatingAnnouncementAlerts 
                            ? <Loader active inline/>
                            : <Form.Field>
                                <Checkbox
                                    type="checkbox"
                                    toggle
                                    label="Announcement alerts"
                                    checked={isAnnouncementAlert}
                                    onClick={(e, data) => 
                                        handleAnnouncementAlertToggle(data.checked)
                                    }
                                />
                            </Form.Field>
                        }
                    </div>

                    {process.env.REACT_APP_PLATFORM !== 'neighbors' ? (typeof canUpdateListenToStories === 'boolean' && canUpdateListenToStories) ? (
                        <div className="relative-field">
                            <Form.Field>
                                <Checkbox
                                    readOnly={userObject.profile._id === relativeId}
                                    disabled={userObject.profile._id === relativeId}
                                    toggle
                                    checked={relative.canListenToStories || relative.Manager}
                                    type="checkbox"
                                    label={`Can ${formData.FirstName} listen to the stories sent to ${residentName}`}
                                    onChange={(event, data) => {
                                        console.log(data);
                                        const { checked } = data;
                                        updateCanListenToResidentStories(checked);
                                        // updateCanListenToStories()
                                    }}
                                />
                            </Form.Field>
                        </div>
                    ) : (
                        relative.canListenToStories && (
                            <div className="relative-field">
                                <p>
                                    {' '}
                                    {formData.FirstName} can listen to stories sent to {residentName}
                                </p>
                            </div>
                        )
                    ) : <></>}
                    {process.env.REACT_APP_PLATFORM !== 'neighbors' ? (typeof canUpdateRecieveRequests === 'boolean' && canUpdateRecieveRequests) ? (
                        <div className="relative-field">
                            <Form.Field>
                                <Checkbox
                                    readOnly={userObject.profile._id === relativeId}
                                    disabled={userObject.profile._id === relativeId}
                                    toggle
                                    checked={relative.canRecieveRequests || relative.Manager}
                                    type="checkbox"
                                    label={`Can ${formData.FirstName} see the feed and recieve and act on requests for ${residentName}`}
                                    onChange={(event, data) => {
                                        console.log(data);
                                        const { checked } = data;
                                        updateCanSeeFeedAndRecieveRequestsForResident(checked);
                                    }}
                                />
                            </Form.Field>
                        </div>
                    ) : (
                        relative.canRecieveRequests && (
                            <div className="relative-field">
                                <p>
                                    {formData.FirstName} see the feed and can recieve and act on requests for{' '}
                                    {residentName}
                                </p>
                            </div>
                        )
                    ) : <></>}
                    {process.env.REACT_APP_PLATFORM !== 'neighbors' ? (typeof canUpdateToManager === 'boolean' && canUpdateToManager) ? (
                        <div className="relative-field">
                            <Form.Field>
                                <Checkbox
                                    readOnly={userObject.profile._id === relativeId}
                                    disabled={userObject.profile._id === relativeId}
                                    toggle
                                    checked={relative.Manager}
                                    type="checkbox"
                                    label={`Make ${formData.FirstName} manager for ${residentName}`}
                                    onChange={(event, data) => {
                                        console.log(data);
                                        const { checked } = data;
                                        toggleManager(checked);
                                    }}
                                />
                            </Form.Field>
                        </div>
                        
                    ) : ( relative.Manager &&
                         (
                            <div className="relative-field">
                                <p>
                                    {formData.FirstName} is a manager for{' '}{residentName}
                                </p>
                            </div>
                        )
                    ) : <></>}
                    {
                        process.env.REACT_APP_PLATFORM !== 'neighbors' ? relative?.a4hDetails?.contact?.name ? <div className="relative-field">
                            <div>
                                AddressBook Name: {relative?.a4hDetails?.contact?.name} <a><Icon
                                    className="addr-name-edit"
                                    name="edit"
                                    onClick={() => {
                                        console.log("clicked");
                                        setIsEditNameOpen(true)
                                    }}
                                >

                                </Icon>
                                </a>
                            </div>
                            {isEditNameOpen && (
                                <UpdateContactName
                                    isModalOpen={isEditNameOpen}
                                    residentId={residentId}
                                    contactId={relativeId}
                                    close={() => {
                                        console.log('Close')
                                        setIsEditNameOpen(false)
                                    }}
                                    inputName={relative?.a4hDetails?.contact?.name}
                                    reload={reload}
                                />
                            )}

                        </div>:<></> : <></>
                    }

                    <div className="button-container">
                        <Button
                            className="ui primary large basic button"
                            type="submit"
                            loading={isUpdating}
                            disabled={!(canEditPersonalDetails || canEditRelationalDetails)}
                        >
                            <i className="plus circle icon"></i> Submit
                        </Button>
                    </div>
                </Form>
            )}
        </div>
    );
};

export default RelativeDetail;
