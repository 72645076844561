import React, { useEffect } from 'react';
import { Dimmer, Loader, Card } from 'semantic-ui-react';
import InviteItem from '../inviteItem';
import { withRouter } from 'react-router';

import './style.less';

const InviteItemList = (props) => {
    useEffect(() => {
        console.log('isFetching, ', props.isFetching);
    }, [props.isFetching]);

    const items = props.invites.map((inv) => (
        <InviteItem
            key={inv._id}
            inviteId={inv._id}
            registrantName={inv.registrantName}
            registrantImage={inv.registrantImage}
            acceptInvite={props.acceptInvite}
            rejectInvite={props.rejectInvite}
        />
    ));
    return (
        <div>
            <Dimmer active={props.isFetching} inverted className="mt-80">
                <Loader active={props.isFetching} />
            </Dimmer>
            {items.length ? (
                <div className="invite-item-list">
                    <Card.Group>{items}</Card.Group>
                </div>
            ) : (
                <div className="no-invites-text">
                    <p>
                        These are invites sent by other Alexa Users. If you hit “Accept”, you will be added to their
                        Circle of Trust and they will be added to your account as an Alexa User.
                    </p>
                    <p>If you do not see any invitations, you do not have any pending invites.</p>
                </div>
            )}
        </div>
    );
};

export default withRouter(InviteItemList);
