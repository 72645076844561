import * as Lambda from '../../services/LambdaApi';
import { paginationWrapper } from '../../utils/paginationWrapper';
import { Announcement } from '../types/Announcement';

const fetchCommunityAnnouncements = async (
    {
        facilityId,
        filter,
        pagingSorting,
    }: {
        facilityId: string;
        filter: {
            startTimestampISO: string;
            endTimestampISO: string;
            residentId: string;
        };
        pagingSorting: {
            page_size: number;
            page_no: number;
        };
    }
) => {
    if (!facilityId) throw new Error('Facility ID is required');
    if (!filter.startTimestampISO) throw new Error('Start Timestamp is required');
    if (!filter.endTimestampISO) throw new Error('End Timestamp is required');
    if (!filter.residentId) throw new Error('Resident ID is required');

    const apiPath = `/s2l/${facilityId}/listAnnouncements`;
    const params = {
        filter,
        pagingSorting,
    };

    const response = await Lambda.Post(apiPath, params, 'lambda');
    if (!response || !response.Result || !response.Result.Content) {
        throw new Error('Error fetching announcements');
    }
    return response.Result.Content as { Result: Announcement[]; TotRecords: number }
};

const fetchAllCommunityAnnouncements = async (
    facilityId: string,
    filter: {
        startTimestampISO: string;
        endTimestampISO: string;
        residentId: string;
    },
) => {
    if (!facilityId) throw new Error('Facility ID is required');
    if (!filter.startTimestampISO) throw new Error('Start Timestamp is required');
    if (!filter.endTimestampISO) throw new Error('End Timestamp is required');
    if (!filter.residentId) throw new Error('Resident ID is required');
    
    return await paginationWrapper(
        {
            apiCall: fetchCommunityAnnouncements,
            params: {
                facilityId,
                filter,
            },
        }
    ) as Announcement[];
}

export { fetchCommunityAnnouncements, fetchAllCommunityAnnouncements };
