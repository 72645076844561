import * as Lambda from '../../services/LambdaApi';
import { MealItem } from '../contexts/cart/CartContext';
import { DiningRequestInstance, FetchOrderFromOrderIdParams, FetchOrderParams, RequestInstance } from '../types/OrderStatus';

const fetchOrders = async ({ facilityId, statusArr, pageNo = 1, pageSize = 100, startDateTime, endDateTime, residentId }: FetchOrderParams) => {
    const apiPath = `/s2l/${facilityId}/listServiceRequests`;
    const params =
    {
        Filter: {
            Status: statusArr, // Array of strings, can pass multiple values to fetch multiple types of requests e.g ["Accepted", "Closed", "Open"] will fetch both closed and open requests
            StartDateTime: startDateTime, // date time string in the format YYYY-MM-DDTHH:mm:ss
            EndDateTime: endDateTime, // date time string in the format YYYY-MM-DDTHH:mm:ss
            Registrant: residentId // ResidentId of the resident whose requests are to be fetched
        },
        QueryOptions: {
            page_no: pageNo,
            page_size: pageSize,
            sort: [
                {
                    RequestedTime: "asc"
                }
            ]
        }
    };

    const response = await Lambda.Post(apiPath, params, 'lambda');
    return response;
};

const fetchOrdersFromOrderId = async ({ facilityId, OrderId, statusArr, residentId }:Partial<FetchOrderFromOrderIdParams>) => {
    try {
        const apiPath = `/s2l/${facilityId}/listServiceRequests`;
        const params =
        {
            Filter: {
                Status: statusArr, // Array of strings, can pass multiple values to fetch multiple types of requests e.g ["Accepted", "Closed", "Open"] will fetch both closed and open requests
                OrderId: OrderId,
                Registrant: residentId // ResidentId of the resident whose requests are to be fetched
            },
            QueryOptions: {
                page_no: 1,
                page_size: 100
            }
        };
        const response = await Lambda.Post(apiPath, params, 'lambda');
        if(!response || !response.Result || !response.Result.Content || !response.Result.Content.Result) {
            throw new Error('Error fetching orders.');
        }
        return response.Result.Content.Result as RequestInstance[];
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Error fetching orders.');
    }
};

const createOrder = async (facilityId: string, orderData: Partial<RequestInstance>) => {
    const apiPath = `/s2l/${facilityId}/createOrder`;
    const response = await Lambda.Post(apiPath, orderData, 'lambda');
    return response;
};

const createDiningOrder = async (facilityId: string, orderData: DiningRequestInstance) => {
    try {
        if (!orderData.Facility || !orderData.ResidentId || !orderData.mealItems || orderData.mealItems.length <= 0 || !orderData.orderType) {
            throw new Error('Facility, ResidentId, mealItems and orderType are required to create order.');
        }
        const apiPath = `/s2l/${facilityId}/diningOrder`;
        const response = await Lambda.Post(apiPath, orderData, 'lambda');
        if (!response || !response.Result || !response.Result.Success || !response.Result.Content) {
            throw new Error(response.Result.Error || 'Failed to create order');
        }
        return response.Result.Content;
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Error creating order');
    }
}

const updateOrder = async (facilityId: string, orderData: Partial<RequestInstance>) => {
    const apiPath = `/s2l/${facilityId}/updateOrder`;
    if (!orderData._id) throw new Error('Order Id is required to update order');
    const response = await Lambda.Post(apiPath, orderData, 'lambda');
    return response;
};

const fetchOrderImageUploadUrl = async (facilityId: string, orderId: string, imageName: string) => {
    const apiPath = `/s2l/${facilityId}/uploadOrderImage`;
    const params = {
        requestInstanceId: orderId,
        imageName: imageName
    };
    const response = await Lambda.Post(apiPath, params, 'lambda');
    return response;
};

const getOrderImage = async (facilityId: string, orderId: string, imageName: string) => {
    const apiPath = `/s2l/${facilityId}/order-image`;
    const params = {
        queryStringParameters: { requestInstanceId: orderId, imageName, Facility: facilityId },
    };
    const response = await Lambda.Get(apiPath, params, 'lambda');
    return response;

};

const cancelOrder = async (facilityId: string, orderId: string, closedById: string) => {
    try {
        const apiPath = `/s2l/${facilityId}/cancelOrder`;
        const params = {
            _id: orderId,
            closedById: closedById
        };
        const response = await Lambda.Post(apiPath, params, 'lambda');
        if (!response || !response.Result || !response.Result.Success || !response.Result.Content) {
            throw new Error(response.Result.Error || 'Error canceling order');
        }
        return response.Result.Content;
    } catch (err) {
        console.error(err);
        throw new Error(err instanceof Error ? err.message : 'Error canceling order');
    }
}

const handleGetQuantity = (mealItems: MealItem[], id: string) => {
    const item = mealItems.find((item) => String(item.serviceInstanceId) === String(id));
    return item ? item.Quantity : 0;
};

export { fetchOrders, createOrder, fetchOrderImageUploadUrl, updateOrder, getOrderImage, cancelOrder, createDiningOrder, fetchOrdersFromOrderId , handleGetQuantity};