import * as Lambda from './LambdaApi'

const fetchAttendeeObj = async (activityId, registrantId, facilityId) => {
    const apiPath = `/s2l/${facilityId}/fetchActivityAttendee`
    const params = {
        activityId,
        registrantId
    }
    const response = await Lambda.Post(apiPath, params, 'lambda')
    return response
}

const updateAttendeeObj = async (facilityId, attendeeObjId, newStatus) => {
    const apiPath = `/s2l/${facilityId}/attendee`
    const params = {
        attendeeObjId,
        status: newStatus
    }
    const response = await Lambda.Put(apiPath, params, 'lambda')
    if (response.Result.Success) {
        return response
    } else {
        throw Error('Something went wrong')
    }
}

const createAttendeeObj = async (facilityId, attendeeObj) => {
    const apiPath = `/s2l/${facilityId}/attendees`
    const params = attendeeObj
    const response = await Lambda.Post(apiPath, params, 'lambda')
    return response
}

export {
    fetchAttendeeObj,
    updateAttendeeObj,
    createAttendeeObj
}
