import React from "react";
import { Item, ItemContent, ItemDescription, ItemExtra, ItemHeader, ItemImage } from "semantic-ui-react";
import { Registrant } from "../../types/Registrant";
import { Staff } from "../../types/Staff";

interface StaffWithAdditionalItems extends Staff {
    Image?: string;
    UnitName?: string;
}

interface Props {
    item: Registrant | StaffWithAdditionalItems;
    handleListItemClick?: (item: Registrant | Staff) => void;
}

const UserDirectoryView = ({ item, handleListItemClick }: Props) => {
    return (
        <Item key={item._id} className="directory-item" onClick={() => handleListItemClick && handleListItemClick(item)}>
            <div className='directory-item-image'>
                <ItemImage circular size='tiny' src={item.Image || "/avatar_placeholder.png"} />
            </ div>
            <ItemContent>
                <ItemHeader className='directory-item-header'>{(item.FirstName || "") + " " + (item.LastName || "")} {item.isStaff && (item.isStaff ? " - Staff" : " - Resident")}</ItemHeader>
                {(typeof item.isStaff !== "boolean") && <ItemDescription> {item.UnitName ? ("Apartment " + item.UnitName) : "-"}</ItemDescription>}
                <ItemExtra style={{marginTop: "0px"}}>
                    {item.Email && (item.Email)}
                    <br />
                    {item.Cell && (item.Cell)}
                </ItemExtra>
            </ItemContent>

        </Item>
    );
};

export default UserDirectoryView;