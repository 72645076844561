import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/UserContext';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import StandaloneMenu from '../../components/Menu/Menu-standalone';
import { fetchFacilityDetails } from '../../services/Facilities';
import sendToast from '../../../utils/Toast';
import MenuDining from '../../components/Menu/Menu-dining';
import "./style.less";

const Menu = () => {
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const correspondingSpeak2Resident = listOfResidents[activeRegistrantsIndexes[0]]; // get the first resident from the list of active residents because neighbors web only allows one resident to be linked to one account
    const [loadingMenu, setLoadingMenu] = useState<boolean>(false);
    const [loadingFacilityDetails, setLoadingFacilityDetails] = useState<boolean>(false);
    const [facilityTimezone, setFacilityTimezone] = useState<string>('');
    const [diningView, setDiningView] = useState<boolean>(false);
    const [renderMenuComponent, setRenderMenuComponent] = useState<boolean>(false);

    useEffect(() => {
        const fetchFacilityDetail = async () => {
            setLoadingFacilityDetails(true);
            try {
                const facilityId = correspondingSpeak2Resident.Facility as string;
                const facilityDetails = await fetchFacilityDetails(facilityId);
                if (!facilityDetails.FacilityTimeZone) {
                    throw new Error('Unable to fetch timezone of the facility. Please contact support.');
                }
                setFacilityTimezone(facilityDetails.FacilityTimeZone as string);
                if (facilityDetails.featureConfigs && facilityDetails.featureConfigs.configurations && facilityDetails.featureConfigs.configurations.dining) {
                    setDiningView(true);
                }
            } catch (error) {
                sendToast('error', error instanceof Error ? error.message : 'Failed to fetch Facility Information');
            } finally {
                setLoadingFacilityDetails(false);
                setRenderMenuComponent(true);
            }
        };
        fetchFacilityDetail();
    }, []);

    return (
        <div>
            <Segment
                style={{
                    margin: '2px',
                    height: '60%',
                    minHeight: "75vh",
                    width: '100%',
                    overflow: 'auto',
                }}
            >
                <p className="heading">Menu</p>
                <hr className="spacing" />
                <div className="menu-calendar-view ">
                    <Dimmer active={loadingMenu || loadingFacilityDetails} inverted>
                        <Loader content="Loading Menu" />
                    </Dimmer>
                    {renderMenuComponent && (diningView === true ? <MenuDining /> :
                        <StandaloneMenu setLoadingMenu={setLoadingMenu} facilityTimezone={facilityTimezone} />
                    )}
                </div>
            </Segment>
        </div>
    );
};

export default Menu;