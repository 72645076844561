import React, { Component, useState, useEffect } from 'react';
import ContactInput from '../../components/ContactInput';

import { Form, Button, Progress, Checkbox } from 'semantic-ui-react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { toast } from 'react-toastify';
import { getUploadUrl, uploadToSignedUrl, converterDataURItoBlob } from '../../../services/imageUpload';
import ImageCropModal from '../../components/ImageCropModal';
import { putMessageRecipientImage, createMessageRecipient } from '../../../services/messageRecipients';
import config from '../../../config';

declare var process: {
    env: {
        SERVER_ENV: string;
    };
};

const envConfig = config[process.env.SERVER_ENV] || config.development;
const BUCKET_URL = envConfig.profileImageBucketUrl;

const CreateMessageRecipient = (props) => {
    const [contact, setContact] = useState('');
    const [contactType, setContactType] = useState('email');
    const [error, setError] = useState('');
    const [firstName, setfirstName] = useState('');
    const [lastName, setlastName] = useState('');
    const [creatingNewMessageRecipient, setCreatingNewMessageRecipient] = useState(false);
    const [usernameError, setUsernameError] = useState('');
    const [password, setPassword] = useState('');
    const [exercise, setExercise] = useState('');
    const [rx, setRx] = useState('');
    const [greetingContactOverride, setgreetingContactOverride] = useState('');
    const [isResponseEnabled, setIsResponseEnabled] = useState(false);

    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [imageEditor, setImageEditor] = useState();
    const [tempImage, setTempImage] = useState();
    const variant = props.variant ? props.variant : 'detailed';
    const { onSuccess } = props;
    // Refactor for repeats when you can breathe!

    const isUsernameValid = (contact) => {
        const emailRegExp = new RegExp(/\S+@\S+\.\S+/g);
        const e164RegExp = new RegExp(/^\+[1-9]\d{1,14}$/g);

        const isEmailValid = emailRegExp.test(contact);
        const isNumberValid = e164RegExp.test(contact);

        return isEmailValid || isNumberValid;
    };

    const createNewMessageRecipient = async (e) => {
        e.preventDefault();

        if (!isUsernameValid(contact.toLowerCase())) {
            setUsernameError('Email or Phone number not valid');
            return;
        }

        setCreatingNewMessageRecipient(true);
        setError('');
        try {
            const { residentId } = await createMessageRecipient(
                firstName,
                lastName,
                contact,
                exercise,
                rx,
                greetingContactOverride,
                password,
                isResponseEnabled,
            );

            if (tempImage) {
                const isImageUploadSuccess = await submitImage(residentId);

                !isImageUploadSuccess &&
                    toast.warn('Image update failed. Try uploading image in edit section', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
            }
            setCreatingNewMessageRecipient(false);

            toast.success('Message Recipient Created successfully', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            onSuccess && onSuccess(true);
            props.location.pathname !== '/signup' && props.history.push('/message-recipients');
        } catch (err) {
            onSuccess && onSuccess(false);
            console.log(err, 'err', err.response.data);
            setCreatingNewMessageRecipient(false);
            setError(err.response && err.response.data && err.response.data.message);
            toast.warn('Something went wrong', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const onClickSave = async () => {
        if (imageEditor) {
            // This returns a HTMLCanvasElement
            const canvas = imageEditor.getImageScaledToCanvas();
            setTempImage(canvas.toDataURL());
            setImageModalOpen(false);
        }
        return;
    };

    const submitImage = async (residentId) => {
        try {
            const { imageId, signedUrl } = await getUploadUrl(residentId);
            const blob = converterDataURItoBlob(tempImage);
            const res = await uploadToSignedUrl(blob, signedUrl);
            if (res.ok) {
                await setNewImageUrl(residentId);
            } else {
                return false;
            }
        } catch (e) {
            console.log(e);
            return false;
        }
        return true;
    };

    const setNewImageUrl = async (residentId) => {
        const newImage = `${BUCKET_URL}/${residentId}?${Date.now()}`;
        const result = await putMessageRecipientImage(newImage, residentId);
    };

    return (
        <div className="message-recipient-details relative-detail ml-8">
            <ImageCropModal
                setImageEditor={setImageEditor}
                setModalOpen={setImageModalOpen}
                onClickSave={onClickSave}
                open={imageModalOpen}
            />
            <Form onSubmit={createNewMessageRecipient} loading={creatingNewMessageRecipient} autoComplete="off">
                <div>
                    <div className="relative-info no-border">
                        {variant === 'detailed' && (
                            <div
                                className="relative-image-container"
                                onClick={() => {
                                    setImageModalOpen(true);
                                }}
                            >
                                {tempImage ? (
                                    <div
                                        className="relative-image"
                                        style={{ background: `url(${tempImage}) no-repeat` }}
                                    ></div>
                                ) : (
                                    <div className="relative-avatar add-photo-icon">
                                        <i className="plus icon"></i>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="flex-1">
                            <>
                                <Form.Field>
                                    <Form.Input
                                        type="text"
                                        required
                                        placeholder="First Name"
                                        onChange={(e) => setfirstName(e.target.value)}
                                        autoComplete="off"
                                        value={firstName}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        type="text"
                                        required
                                        placeholder="Last Name"
                                        onChange={(e) => setlastName(e.target.value)}
                                        autoComplete="off"
                                        value={lastName}
                                    />
                                </Form.Field>
                            </>
                        </div>
                    </div>
                    <div className="relative-field">
                        <ContactInput
                            onChange={(e) => {
                                console.log(e, 'eee');
                                setUsernameError('');
                                setContact(e);
                            }}
                            error={usernameError}
                            autoComplete="off"
                        />
                        <div className="password-field">
                            <Form.Input
                                required
                                className={password ? 'full-75' : 'full-width'}
                                type="password"
                                placeholder="Message Recipient's Password"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                autoComplete="new-password"
                            />
                            {password && (
                                <Progress
                                    percent={(password.length / 8) * 100}
                                    size="tiny"
                                    autoSuccess
                                    color="red"
                                    style={{
                                        width: '25%',
                                        marginBottom: '0px',
                                        marginTop: '25px',
                                        marginLeft: '2px',
                                    }}
                                />
                            )}
                        </div>
                        {variant === 'detailed' && (
                            <>
                                <Form.Input
                                    value={exercise}
                                    type="text"
                                    name="exercise"
                                    onChange={(e) => setExercise(e.target.value)}
                                    placeholder="Exercise"
                                    // label="Exercise"
                                    autoComplete="false"
                                />
                                <Form.Input
                                    value={rx}
                                    type="text"
                                    name="rx"
                                    onChange={(e) => setRx(e.target.value)}
                                    placeholder="Rx"
                                    // label="Rx"
                                    autoComplete="false"
                                />
                                <Form.Input
                                    value={greetingContactOverride}
                                    type="text"
                                    name="contactOverride"
                                    onChange={(e) => setgreetingContactOverride(e.target.value)}
                                    placeholder="Greeting Contact Override"
                                    // label="Greeting Contact Override"
                                    autoComplete="false"
                                />
                                <Form.Field>
                                    <Checkbox
                                        toggle
                                        checked={isResponseEnabled}
                                        type="checkbox"
                                        label={`Can the message recipient respond to messages ?`}
                                        className="speak2-checkbox"
                                        onChange={(event, data) => {
                                            setIsResponseEnabled(data.checked || false);
                                        }}
                                    />
                                </Form.Field>
                            </>
                        )}
                    </div>
                </div>
                <div>{error}</div>
                <div className="button-container">
                    <Button className="ui primary small button " type="submit" loading={creatingNewMessageRecipient}>
                        Create
                    </Button>
                </div>
            </Form>
        </div>
    );
};

export default CreateMessageRecipient;
