'use strict';
import React, { Component } from 'react';

import { Icon } from 'semantic-ui-react';
interface Menu {
    location: string;
    pageName: string;
    showResidentScroll: boolean;
    icon: Icon;
}

const getMenuItems = () => {
    let menus = [
        {
            location: '/activities',
            regex: /^\/activities([\/]|)$/,
            pageName: 'Feed',
            showResidentScroll: true,
            showToManagerOnly: false,
    
            icon: <Icon name="feed" />,
        },
        {
            location: '/messages',
            pageName: 'Messages',
            showResidentScroll: true,
            showToManagerOnly: false,
    
            icon: <Icon name="comments" />,
        },
        {
            location: '/reminders',
            pageName: 'Reminders',
            showResidentScroll: true,
            showToManagerOnly: false,
            icon: <Icon name="calendar alternate outline" />,
        },
        {
            location: '/news',
            pageName: 'Send Message',
            showResidentScroll: true,
            showToManagerOnly: false,
            icon: <Icon name="newspaper outline" />,
        },
        {
            location: '/community-activities',
            pageName: 'Community Activities',
            showResidentScroll: false,
            showToCommunityManagerOnly: true,
            icon: <Icon name="paper plane" />,
        },
        {
            location: '/community-menus',
            pageName: 'Community Menus',
            showResidentScroll: false,
            showToCommunityManagerOnly: true,
            icon: <Icon name="food" />,
        },
        {
            location: '/family_friends',
            pageName: 'Friends & Family',
            showResidentScroll: true,
            showToManagerOnly: false,
    
            icon: <Icon name="group" />,
        },
        {
            location: '/message-recipients',
            pageName: 'Alexa Users',
            showResidentScroll: true,
            showToManagerOnly: false,
    
            icon: <Icon name="address book" />,
        },
        {
            location: '/settings',
            pageName: 'Settings',
            showResidentScroll: true,
            showToManagerOnly: true,
    
            icon: <Icon name="book" />,
        },
        {
            location: '/archives',
            pageName: 'Archive',
            showResidentScroll: true,
            showToManagerOnly: true,
    
            icon: <Icon name="archive" />,
        },
        {
            location: '/payments', 
            pageName: 'Payments', 
            showResidentScroll: true, 
            showToManagerOnly: true,
    
            icon: <Icon name="credit card" />,
        }
    ];
    if (process.env.REACT_APP_PLATFORM === 'community') {
        menus = menus.filter((menuItem) => {
            if (['Feed', 'Reminders', 'Alexa Users', 'Settings', 'Archive', 'Payments'].includes(menuItem.pageName)) {
                return false
            }
            return true
        })
        menus = menus.map((menuItem) => {
            if (menuItem.pageName === 'Friends & Family') {
                return {
                    location: '/addressbook',
                    pageName: 'Address Book',
                    showResidentScroll: true,
                    showToManagerOnly: false,
            
                    icon: <Icon name="group" />,
                }
            } else if (menuItem.pageName === 'Community Activities') {
                return {
                    location: '/activities',
                    pageName: 'Activities',
                    showResidentScroll: true,
                    showToCommunityManagerOnly: false,
                    icon: <Icon name="paper plane" />,
                }
            } else if (menuItem.pageName === 'Community Menus') {
                return {
                    location: '/menu',
                    pageName: 'Menu',
                    showResidentScroll: true,
                    showToCommunityManagerOnly: false,
                    icon: <Icon name="food" />,
                }
            } else if (menuItem.pageName === 'Send Message') {
                return {
                    location: '/send-message',
                    pageName: 'Send Message',
                    showResidentScroll: true,
                    showToManagerOnly: false,
                    icon: <Icon name="newspaper outline" />,
                }
            }
            return menuItem
        })
        
        // Changing order of menu items to match mobile app

        const sendMessageIndex = menus.findIndex((menuItem) => {
            return menuItem.pageName === 'Send Message'
        })
        const sendMessageMenuItem = menus[sendMessageIndex]
        menus.splice(sendMessageIndex, 1)
        menus.unshift(sendMessageMenuItem)

        const addressBookIndex = menus.findIndex((menuItem) => {
            return menuItem.pageName === 'Address Book'
        })
        const addressBookMenuItem = menus[addressBookIndex]
        menus.splice(addressBookIndex, 1)
        menus.splice(2, 0, addressBookMenuItem)

        // Add Support section
        menus.push({
            location: '/support',
            pageName: 'Support',
            showResidentScroll: true,
            showToManagerOnly: false,
            icon: <Icon name="help" />,
        })

        // Add Notifications section after Menu section
        const notificationsMenuItem = ({
            location: '/notifications',
            pageName: 'Notifications',
            showResidentScroll: true,
            showToManagerOnly: false,
            icon: <Icon name='clipboard check' />
        })
        const menuIndex = menus.findIndex((menuItem) => {
            return menuItem.pageName === 'Menu'
        })
        menus.splice(menuIndex + 1, 0, notificationsMenuItem)
    }
    return menus
}

const menus = getMenuItems()

export default menus;
