import React, { useEffect, useContext, useState } from 'react';
import {  Checkbox, Form } from 'semantic-ui-react';


const StaffEnteredDetail = (props) => {


const {relative} = props.location.state

console.log(relative, "relative")

    return (
  <div className="" style={{
      marginTop:30
  }}>

<Form className='mt-4'>
                    <Form.Field  className='mt-4'>
                                        <Form.Input
                                            value={relative.RelativeName}
                                            type="text"
                                            name="First Name"
                                            placeholder="email or phone number"
                                            autoComplete="false"
                                            label={"Username"}

                                        />
                                    </Form.Field>
                                    <Form.Field>
                                        <Form.Input
                                            value={relative.Contact}
                                            type="text"
                                            name="First Name"
                                            placeholder="email or phone number"
                                            autoComplete="false"
                                            label={"Contact"}

                                        />
                                    </Form.Field>
              
                                    <Form.Field>
                                        <Form.Input
                                            value={relative.manager ? "Manager" : "Member"}
                                            type="text"
                                            name="First Name"
                                            placeholder="email or phone number"
                                            autoComplete="false"
                                            label={"Role"}

                                        />
                                    </Form.Field>



                                    <div className="relative-field">
<Form.Field>
        <Form.Input
            value={relative.contacts.reciprocals === "DISABLED" ? "DISABLED" : "ENABLED"}
            type="text"
            name="First Name"
            placeholder="email or phone number"
            autoComplete="false"
            label={"Inbound Calling"}

        />
        </Form.Field>
     </div>
</Form>

  </div>
       
    );
};

export default StaffEnteredDetail;
