import * as Lambda from './LambdaApi';

interface InviteInterface {
    _id: string;
    registrantId: string;
    registrantName: string;
    registrantImage?: string;
}

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const listInvites = async (): Promise<InviteInterface[]> => {
    try {
        const apiPath = `/invites`;
        const listInvitesResponse = await Lambda.Get(apiPath, {}, 'lambda');
        if (listInvitesResponse.success) {
            return listInvitesResponse.invites.map(
                ({ _id, Registrant: { Id: registrantId, FirstName, LastName, Image } }) => ({
                    _id,
                    registrantId,
                    registrantName: `${FirstName} ${LastName}`,
                    registrantImage: Image && `https://${Image}`,
                }),
            );
        }
        return [];
    } catch (error) {
        console.error(error);
        throw Error(error);
    }
};

const listResidentsInvites = async (residentId) => {
    try {
        const apiPath = `/residents/${residentId}/invites`;
        const listInviteResponse = await Lambda.Get(apiPath, {}, 'lambda');

        if (listInviteResponse.success) {
            return listInviteResponse.invites;
        }

        return Promise.reject(listInviteResponse.message);
    } catch (err) {
        console.error('err', err);
        const {
            response: {
                data: { message },
            },
        } = err;
        return Promise.reject({
            message: message || 'Something went wrong',
        });
    }
};

const replyInvite = async (inviteId, isInviteAccepted) => {
    try {
        const apiPath = `/invites/${inviteId}`;
        const replyInviteResponse = await Lambda.Put(apiPath, { isInviteAccepted }, 'lambda');
        if (replyInviteResponse.success) {
            return true;
        }
        return false;
    } catch (error) {
        console.error(error);
        throw Error(error);
    }
};

const sendInvite = async (residentId, contact, invitedRelative, message, announcementAlerts, email) => {
    try {
        const apiPath = `/invites`;
        const sendInviteResponse = await Lambda.Post(
            apiPath,
            {
                contact,
                residentId,
                invitedRelative,
                message,
                announcementAlerts,
                email
            },
            'lambda',
        );
        if (sendInviteResponse.success) {
            return true;
        }
    } catch (err) {
        console.log('err', err, err.response);
        const {
            response: {
                data: { message },
            },
        } = err;
        return Promise.reject({
            message: message || 'Something went wrong',
        });
    }
};

const resendInvite = async (inviteId) => {
    try {
        const apiPath = `/invites/${inviteId}`;
        const sendInviteResponse = await Lambda.Post(apiPath, {}, 'lambda');

        if (sendInviteResponse.success) {
            return true;
        }
    } catch (err) {
        console.log('err', err, err.response);
        const {
            response: {
                data: { message },
            },
        } = err;
        return Promise.reject({
            message: message || 'Something went wrong',
        });
    }
};

const updateInviteEmail = async (inviteId, email) => {
    try {
        const apiPath = `/invites/${inviteId}`;
        const updateInviteResponse = await Lambda.Put(apiPath, { email, updateEmail: true }, 'lambda');

        if (updateInviteResponse.success) {
            return true;
        }
    } catch (err) {
        console.log('err', err, err.response);
        const {
            response: {
                data: { message },
            },
        } = err;
        return Promise.reject({
            message: message || 'Something went wrong',
        });
    }
}

const removeInvite = async (inviteId) => {
    try {
        const apiPath = `/invites/${inviteId}`;
        const deleteInviteResponse = await Lambda.Delete(apiPath, {}, 'lambda');

        if (deleteInviteResponse.success) {
            return true;
        }
    } catch (err) {
        console.log('err', err, err.response);
        const {
            response: {
                data: { message },
            },
        } = err;
        return Promise.reject({
            message: message || 'Something went wrong',
        });
    }
};

const acceptInviteByCode = async (code) => {
    try {
        const apiPath = `/invites/tokens/${code}/accept`;
        const result = await Lambda.Post(apiPath, {}, 'lambda');

        if (result.success) {
            return true;
        }
    } catch (err) {
        const {
            response: {
                data: { message },
            },
        } = err;
        return Promise.reject({
            message: message || 'Something went wrong',
        });
    }
};

export { listInvites, replyInvite, sendInvite, listResidentsInvites, resendInvite, removeInvite, acceptInviteByCode, updateInviteEmail };
