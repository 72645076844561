import * as Lambda from '../../services/LambdaApi';
import { Registrant } from '../types/Registrant';

const listResidents = async ({ facilityId, search, page_no = 1, page_size = 50 }) => {
    const apiPath = `/s2l/${facilityId}/listResidents`;
    const params = {
        Filter: {
            IsActive: 1
        },
        Directory: true,
        QueryOptions: {
            page_no: page_no,
            page_size: page_size,
            sort: [
                {
                    LastName: "desc"
                }
            ]
        },
        Search: search
    };
    if (!search) {
        delete params.Search;
    }
    const response = await Lambda.Post(apiPath, params, 'lambda');
    const residents = response?.Result?.Content?.Result;
    const totRecords = response?.Result?.Content?.TotRecords;
    if (!residents || !totRecords) {
        throw new Error('Invalid response from listResidents');
    }
    return {
        Residents: residents,
        TotRecords: totRecords
    };
};

const listAllResidents = async (filter) => {
    try {
        const { Facility: facilityId } = filter;
        if (!facilityId) throw new Error('facilityId is required');
        const apiPath = `/s2l/${facilityId}/listResidents`;
        const params = {
            Filter: {
                IsActive: 1,
                ...filter
            },
        };

        const response = await Lambda.Post(apiPath, params, 'lambda');
        if (!response || !response.Result || !response.Result.Success || !response.Result.Content) {
            throw new Error(response.Result.Error || `Error fetching resident's interests`);
        }
        return response.Result.Content as { Result: Registrant[]; TotRecords: number; };

    } catch (error) {
        throw new Error("Error fetching Resident list");
    }
};

export { listResidents, listAllResidents };