import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';

import UserContext from '../../../contexts/UserContext';

const PrivateRoute = ({ component, path }) => {
    const {
        userObject: { authenticated },
    } = useContext(UserContext);
    if (!authenticated) {
        return <Redirect to={{ pathname: '/login' }} />;
    }
    if (path.includes('/login')) return <Redirect to={{ pathname: '/activities' }} />;
    return <Route path={path} component={component} />;
};

export default PrivateRoute;
