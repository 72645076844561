import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dimmer, Grid, Icon, Loader, Popup } from 'semantic-ui-react';
import { Restaurant } from '../../types/Asset';
import { fetchAllRestaurants } from '../../services/Asset';
import sendToast from '../../../utils/Toast';
import UserContext from '../../../contexts/UserContext';
import './style.less';
import DiningCart from '../../components/Dining/Cart';
 
const Dining = () => {
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const [restaurantsLoading, setRestaurantsLoading] = useState<boolean>(true);
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const activeResident = listOfResidents[activeRegistrantsIndexes[0]]; // get the first resident from the list of active residents because neighbors web only supports the resident who is logged in and we can't deselect or change it
    const history = useHistory();

    useEffect(() => {
        fetchRestaurants();
    }, []);

    const fetchRestaurants = async () => {
        try {
            setRestaurantsLoading(true);
            const filters = {
                Facility: activeResident.Facility as string,
            };
            const restaurants = await fetchAllRestaurants(filters);
            setRestaurants(restaurants || []);
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Error fetching restaurants');
        } finally {
            setRestaurantsLoading(false);
        }
    };

    const handleRedirectToOrderCreation = () => {
        history.push({
            pathname: `/create-order`,
        });
    };
    const handleShowMenu = (restaurantId: string) => {
        history.push({
            pathname: `/dining/${restaurantId}`,
        });
    };
    return (
        <div>
            <Dimmer inverted active={restaurantsLoading}>
                <Loader>Loading</Loader>
            </Dimmer>
            <div className="headingContainer">
                <Popup
                    content="Go back to create order page"
                    trigger={
                        <Icon
                            name="arrow left"
                            size="large"
                            className="backIcon"
                            onClick={handleRedirectToOrderCreation}
                        />
                    }
                />
                <div className="mainHeadingContainer">
                    <h1 className="heading">Choose your restaurant</h1>
                </div>
                <DiningCart />
            </div>
            <hr className="spacing" />
            <div className="center-self">
                <Grid columns={5} style={{ width: "100%" }}>
                    {restaurants && restaurants.length > 0 ? (
                        restaurants.map((restaurant) => (
                            <Grid.Column
                                key={restaurant._id}
                                style={{ cursor: 'pointer' }}
                                width={3}
                                onClick={() => handleShowMenu(restaurant._id)}
                            >
                                <div className="nameContainer nameContainerRest">
                                    <p className="diningName">{restaurant.AssetName}</p>
                                </div>
                            </Grid.Column>
                        ))
                    ) : (
                        <div className="noMenuContainer">
                            <p> No Restaurant's available for the facility.</p>
                        </div>
                    )}
                </Grid>
            </div>
        </div>
    );
};

export default Dining;
