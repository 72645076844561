import React, { useState } from 'react';
import { Form, Button, Progress, Popup } from 'semantic-ui-react';
import ContactInput from '../../../components/components/ContactInput';
import { fetchResidentDetailsFromToken } from '../../services/Invite';
import sendToast from '../../../utils/Toast';
import './style.less';

interface Props {
    firstName: string;
    lastName: string;
    error: string;
    disabledButton: boolean;
    code?: string;
    showCodeInput?: boolean;
    contact?: string;
    contactDisabled?: boolean;
    skipCodeVerification: boolean;
    setFirstName: (e) => any;
    setLastName: (e) => any;
    setCode: (e) => any;
    handleSignUpSubmit: (e, contact, password) => any;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setSkipCodeVerification: React.Dispatch<React.SetStateAction<boolean>>;
    setContact: React.Dispatch<React.SetStateAction<string>>;
}

const SignUpForNeighbors: React.FC<Props> = (props) => {
    const [contact, setContact] = useState<string>(props.contact ? props.contact : '');
    const [password, setPassword] = useState<string>('');
    const [isContactDisabled, setIsContactDisabled] = useState<boolean>(props.contactDisabled ? true : false);
    const [usernameError, setUsernameError] = useState<string>('');
    const [token, setToken] = useState<string>('');

    const isUsernameValid = (contact) => {
        const emailRegExp = new RegExp(/\S+@\S+\.\S+/g);
        const e164RegExp = new RegExp(/^\+[1-9]\d{1,14}$/g);
        const isEmailValid = emailRegExp.test(contact);
        const isNumberValid = e164RegExp.test(contact);
        return isEmailValid || isNumberValid;
    };

    const verifySignUpCode = async (e) => {
        e.preventDefault();
        props.setLoading(true);
        try {
            const response = await fetchResidentDetailsFromToken(token);
            if (response.isTokenValid) {
                const { resident } = response;
                if (resident) {
                    props.setCode(token);
                    props.setContact(resident.Cell || resident.Email);
                    props.setFirstName(resident.FirstName || "");
                    props.setLastName(resident.LastName || "");
                    props.setSkipCodeVerification(true);
                }
            } else {
                sendToast("error", "Invalid Signup Code");
            }
        } catch (err) {
            console.error(err);
            sendToast("error", err.message || "Error verifying signup code");
        } finally {
            props.setLoading(false);
        }
    };

    const handleClearStateFunction = () => {
        props.setCode("");
        props.setContact("");
        props.setFirstName("");
        props.setLastName("");
        props.setSkipCodeVerification(false);
    };

    const tokenCodeInput = () => {
        return (
            <>
                <Form.Field>
                    <Popup
                        content="We have filled in your code for you"
                        open
                        disabled={!props.code}
                        position='left center'
                        trigger={
                            <Form.Input
                                value={token}
                                type="text"
                                name="new-code"
                                placeholder={
                                    'Signup Code'
                                }
                                autoComplete="false"
                                onChange={(e) => setToken(e.currentTarget.value)}
                            />
                        }
                    />
                </Form.Field>
                <Button
                    disabled={token ? false : true}
                    onClick={verifySignUpCode}
                    primary
                >
                    Proceed to Register
                </Button>
            </>
        );
    };

    return (
        <>
            <Form
                error
                autoComplete="off"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (isUsernameValid(contact.toLowerCase())) {
                        props.handleSignUpSubmit(e, contact.toLowerCase(), password);
                    } else {
                        setUsernameError('Email or Phone number not valid');
                    }
                }}
            >
                {props.error && (
                    <Form.Field>
                        <div className="error-message">{props.error}</div>
                    </Form.Field>
                )}
                {props.skipCodeVerification ? (
                    <>
                        {props.code && <Form.Input
                            value={props.code}
                            type="text"
                            name="new-code"
                            // placeholder="Code (if you have been provided a code)"
                            placeholder={
                                process.env.REACT_APP_PLATFORM === 'neighbors'
                                    ? 'Signup Code'
                                    : 'Code (if you have been provided a code)'
                            }
                            autoComplete="false"
                            onChange={(e) => setToken(e.currentTarget.value)}
                            disabled={!!props.code}
                        />}
                        <ContactInput
                            contact={contact}
                            onChange={(e) => {
                                setUsernameError('');
                                setContact(e);
                            }}
                            isDisabled={isContactDisabled}
                            error={usernameError}
                        />
                        <Form.Field>
                            <Form.Input
                                value={props.firstName}
                                type="text"
                                name="firstName"
                                placeholder="First Name"
                                required
                                autoComplete="off"
                                onChange={(e) => props.setFirstName(e.currentTarget.value)}
                            />
                        </Form.Field>

                        <Form.Field>
                            <Form.Input
                                value={props.lastName}
                                type="text"
                                name="lastName"
                                placeholder="Last Name"
                                required
                                autoComplete="off"
                                onChange={(e) => props.setLastName(e.currentTarget.value)}
                            />
                        </Form.Field>

                        <Form.Field className="invite-container ml-8">
                            <Form.Input
                                value={password}
                                type="password"
                                name="password"
                                className={password ? 'full-75' : 'full-width'}
                                placeholder="Create Password"
                                required
                                autoComplete="new-password"
                                onChange={(e) => setPassword(e.currentTarget.value)}
                            />
                            {password && (
                                <Progress
                                    percent={(password.length / 8) * 100}
                                    size="tiny"
                                    autoSuccess
                                    color="red"
                                    style={{ width: '25%', marginBottom: '0px' }}
                                />
                            )}
                        </Form.Field>

                        <Button
                            disabled={!props.firstName || !props.lastName || !password}
                            type="submit"
                            primary
                        >
                            Register
                        </Button>
                    </>
                ) : (
                    tokenCodeInput()
                )}
                {props.code && <div style={{ textAlign: 'center', marginTop: "10px", color: "#4183C4" }}>
                    <p className="text-center" onClick={() => { handleClearStateFunction(); }} style={{ cursor: 'pointer' }}>Click here to change the Signup Code</p>
                    <hr></hr>
                </div>
                }
            </Form>
        </>
    );
};

export default SignUpForNeighbors;
