import React from 'react';
import { Dimmer, Grid, Loader } from 'semantic-ui-react';
import "./style.less";
import { Group } from '../../types/Group';
import LoadMore from '../../../utils/LoadMore';
import GroupDetailsCard from './GroupDetailsCard';

interface Props {
    groups: Group[];
    loading: boolean;
    hasMore: boolean;
    setPageNo: React.Dispatch<React.SetStateAction<number>>;
    facilityTimeZone: string;
}

const GroupList = ({ groups, loading, setPageNo, hasMore, facilityTimeZone }: Props) => {
    return (
        <div style={{ position: 'relative' }}>
            <Dimmer active={loading} inverted>
                <Loader />
            </Dimmer>
            <div style={{ minHeight: "80vh" }}>
                {groups && groups.length > 0 ? groups.map((group, index) => (
                    <div key={index} style={{ marginTop: "30px" }} >
                        <GroupDetailsCard group={group} facilityTimeZone={facilityTimeZone} />
                    </div>
                )) : <h4 className="center" style={{ marginTop: "20px" }}>No Groups found</h4>}
                <LoadMore isLoading={loading} hasMore={hasMore} next={() => { setPageNo((prev) => prev + 1); }} />
            </div>
        </div>
    );
};

export default GroupList;