import * as Lambda from './LambdaApi';
import { Auth } from 'aws-amplify';

interface signUpParams {
    registrantId?: string;
    password: string;
    firstName: string;
    lastName: string;
    token?: string;
    receivedContact?: string;
    contact?: string;
}

const checkIfUserIsLoggedin = async () => {
    try {
        const cognitoAuthSession = await Auth.currentAuthenticatedUser();
        return cognitoAuthSession

    } catch (err) {
        return false;
    }

}



const cognitoSignUp = async (signUpParams: signUpParams) => {
    const apiPath = '/registrations';
    const signUpResponse = await Lambda.Post(apiPath, signUpParams, 'lambda');
    return signUpResponse.relative;
};

const cognitoSignIn = async (username: string, password: string) => {
    try {
        const user = await Auth.signIn(username, password);
        if (user.attributes['custom:accountType'] === 'relative') {
            localStorage.setItem('haveLoggedInBefore', 'true');
            return user;
        }
        throw new Error('User is not confirmed');
    } catch (error) {
        if (error.message === 'User is not confirmed.') return 'not confirmed';
        throw Error(error);
    }
};

const cognitoSignOut = async (): Promise<any> => {
    try {
        return await Auth.signOut();
    } catch (error) {
        throw Error(error);
    }
};

const ConfirmToken = async (token, registrantId) => {
    try {
        const apiPath = `/registrations/invite-tokens/${token}`;
        const payloadObject = {
            queryStringParameters: {
                registrantId,
            },
        };
        const confirmTokenResponse = await Lambda.Get(apiPath, payloadObject, 'lambda');
        return confirmTokenResponse.isTokenValid;
    } catch (error) {
        throw Error(error);
    }
};

const resendConfirmationCode = async (username) => {
    try {
        return await Auth.resendSignUp(username);
    } catch (error) {
        throw Error(error);
    }
};

const forgotPassword = async (username) => {
    try {
        const result = await Auth.forgotPassword(username);
        console.log(result);
        return true;
    } catch (e) {
        console.log('er', e);
        return false;
    }
};

const setForgottenPassword = async (username, code, newPassword) => {
    try {
        const result = await Auth.forgotPasswordSubmit(username, code, newPassword);

        return true;
    } catch (e) {
        console.log(e);

        return false;
    }
};

const resetPassword = async (oldPassword, newPassword) => {
    try {
        const user = await Auth.currentAuthenticatedUser();
        const response = await Auth.changePassword(user, oldPassword, newPassword);
        console.log(response);
        return true;
    } catch (e) {
        console.log(e);
        if (['NotAuthorizedException', 'InvalidParameterException'].includes(e.code)) {
            return false;
        }
        return Promise.reject();
    }
};

const checkIfUserExists = async username => {
    try {
        const apiPath = `/registrations/users/${username}`;
        const userCheckResponse = await Lambda.Get(apiPath, {}, 'lambda');
        return userCheckResponse.success;
    } catch (error) {
        console.log(error);
        return false;
    }
};

export {
    cognitoSignUp,
    cognitoSignIn,
    cognitoSignOut,
    ConfirmToken,
    resendConfirmationCode,
    forgotPassword,
    setForgottenPassword,
    resetPassword,
    checkIfUserExists, 
    checkIfUserIsLoggedin
};
