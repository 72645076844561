import React, { useContext, useEffect, useState } from 'react';
import DocumentList from '../../components/Documents';
import "./style.less";
import UserContext from '../../../contexts/UserContext';
import { fetchCommunityDocs, fetchMyDocs } from '../../services/Documents';
import { Document } from '../../types/Document';
import { fetchFacilityDetails } from '../../services/Facilities';
import sendToast from '../../../utils/Toast';

const Documents = () => {

  const [communityDocs, setCommunityDocs] = useState<Document[]>([]);
  const [myDocs, setMyDocs] = useState<Document[]>([]);
  const [communityDocsLoading, setCommunityDocsLoading] = useState<boolean>(false);
  const [myDocsLoading, setMyDocsLoading] = useState<boolean>(false);
  const [facilityLoading, setFacilityLoading] = useState<boolean>(false);
  const [communityDocsPageNo, setCommunityDocsPageNo] = useState<number>(1);
  const [myDocsPageNo, setMyDocsPageNo] = useState<number>(1);
  const [communityDocsHasMore, setCommunityDocsHasMore] = useState<boolean>(true);
  const [myDocsHasMore, setMyDocsHasMore] = useState<boolean>(true);
  const [facilityTimezone, setFacilityTimezone] = useState<string>("");

  const {
    userObject: {
      registrants: { list: listOfResidents, activeRegistrantsIndexes },
    },
  } = useContext(UserContext);

  const activeResident = listOfResidents[activeRegistrantsIndexes[0]]; // get the first resident from the list of active residents because neighbors web only supports the resident who is logged in and we can't deselect or change it

  const pageSize = 10;

  useEffect(() => {
    (async () => {
      try {
        setFacilityLoading(true);
        const facilityRes = await fetchFacilityDetails(activeResident.Facility);
        if (!facilityRes.FacilityTimeZone) {
          throw new Error("Facility Timezone not found");
        }
        setFacilityTimezone(facilityRes.FacilityTimeZone);
      } catch (error) {
        sendToast('error', error);
      } finally {
        setFacilityLoading(false);
      }
    }
    )();
  }, []);

  useEffect(() => {
    // fetch community docs
    listCommunityDocs();
  }, [communityDocsPageNo]);

  useEffect(() => {
    // fetch my docs
    listMyDocs();
  }, [myDocsPageNo]);

  const listCommunityDocs = async () => {
    try {
      setCommunityDocsLoading(true);
      const response = await fetchCommunityDocs(activeResident.Facility, communityDocsPageNo, pageSize);
      if (response.Result.Content.TotRecords <= communityDocsPageNo * pageSize) {
        setCommunityDocsHasMore(false);
      }
      setCommunityDocs(response.Result.Content.Result);
    } catch (error) {
      sendToast('error', error);
    } finally {
      setCommunityDocsLoading(false);
    }
  };
  const listMyDocs = async () => {
    try {
      setMyDocsLoading(true);
      const response = await fetchMyDocs(activeResident.Facility, String(activeResident._id), myDocsPageNo, pageSize);
      if (response.Result.Content.TotRecords <= myDocsPageNo * pageSize) {
        setMyDocsHasMore(false);
      }
      setMyDocs(response.Result.Content.Result);
    } catch (error) {
      sendToast('error', error);
    } finally {
      setMyDocsLoading(false);
    }
  };

  return (
    <>
      <h1 className="heading">Docs</h1>
      <hr className="spacing" />
      <DocumentList docs={communityDocs} subHeading={"Community"} loading={communityDocsLoading || facilityLoading} hasMore={communityDocsHasMore} setPageNo={setCommunityDocsPageNo} facilityTimezone={facilityTimezone} />
      <hr />
      <DocumentList docs={myDocs} subHeading={"My Docs"} loading={myDocsLoading || facilityLoading} hasMore={myDocsHasMore} setPageNo={setMyDocsPageNo} facilityTimezone={facilityTimezone} />
    </>
  );
};

export default Documents;