import React, { useContext, useEffect, useState } from 'react';
import './style.less';
import { Button, Dimmer, Image, Loader, Modal, Segment, Table } from 'semantic-ui-react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import UserContext from '../../../contexts/UserContext';
import sendToast from '../../../utils/Toast';
import { fetchAllCommunityNews } from '../../services/CommunityNews';
import { CommunityNewsType } from '../../types/CommunityNews';
import moment from 'moment';
import { getStandaloneSignageImage } from '../../services/ImageUploader';

interface DateRange {
    startDate: string;
    endDate: string;
}

const CommunityNews = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const [communityNews, setCommunityNews] = useState<CommunityNewsType[]>([]);
    const [selectedDateRange, setSelectedDateRange] = useState<DateRange>({} as DateRange);
    const [selectedEvent, setSelectedEvent] = useState<Partial<CommunityNewsType> | null>(null);
    const [detailedModal, setDetailedModal] = useState<boolean>(false);
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const [selectedEventImage, setSelectedEventImage] = useState<string>('');

    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const correspondingSpeak2Resident = listOfResidents[activeRegistrantsIndexes[0]]; // get the first resident from the list of active residents because neighbors web only allows one resident to be linked to one account

    useEffect(() => {
        if (selectedDateRange.startDate && selectedDateRange.endDate)
            listCommunityNews();
    }, [selectedDateRange]);

    const listCommunityNews = async () => {
        try {
            setLoading(true);
            const filter = {
                startDate: { $gte: selectedDateRange.startDate },
                endDate: { $lt: selectedDateRange.endDate },
            };
            const news = await fetchAllCommunityNews(
                correspondingSpeak2Resident.Facility,
                filter
            );
            setCommunityNews(news);
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Failed to fetch Community News');
        } finally {
            setLoading(false);
        }
    };

    const handleMonthChange = ({ start, end }) => {
        const startDateTimeString = moment(start).format('YYYY-MM-DD');
        const endDateTimeString = moment(end).format('YYYY-MM-DD');
        setSelectedDateRange({
            startDate: startDateTimeString,
            endDate: endDateTimeString,
        });
    };

    const handleEventClick = async (info: any) => {
        try {
            setModalLoading(true);
            setDetailedModal(true);
            const id = info.event._def.extendedProps.signageImageId;
            const facilityId = info.event._def.extendedProps.facilityId;
            if (info.event._def.extendedProps.signageImageId) {
                const url = await getStandaloneSignageImage({
                    facilityId: facilityId,
                    contentType: 'communityMessages',
                    contentId: id,
                });
                setSelectedEventImage(url);
            } else {
                setSelectedEventImage('');
            }
            setSelectedEvent((prevInfo) => ({
                ...prevInfo,
                name: info.event.title,
                startDate: info.event.start,
                endDate: info.event.end || info.event.start,
                _id: info.event.id,
            }));
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Failed to fetch Community News image');
        } finally {
            setModalLoading(false);
        }
    };

    const closeDetailedModal = () => {
        setDetailedModal(false);
        setSelectedEvent(null);
        setSelectedEventImage('');
    };

    return (
        <div>
            <Segment
                style={{
                    margin: '2px',
                    height: '60%',
                    width: '100%',
                    overflow: 'auto',
                }}
            >
                <div className='calendarContainer'>
                    <Dimmer active={loading} inverted>
                        <Loader content="Loading" />
                    </Dimmer>
                    {/**Heading */}
                    <p className="heading">Community News</p>
                    <hr className="spacing" />
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                            right: 'today prev,next',
                        }}
                        events={[
                            ...communityNews.map((event) => {
                                return {
                                    title: event.name,
                                    start: new Date(event.startDate),
                                    end: new Date(event.endDate),
                                    signageImageId: event.signageImageId || '',
                                    facilityId: event.Facility || '',
                                    id: event._id,
                                };
                            })
                        ]}
                        datesSet={handleMonthChange}
                        eventClick={handleEventClick}
                        showNonCurrentDates={false}
                    />
                </div>
            </Segment>
            <Modal open={detailedModal} onClose={closeDetailedModal}>
                <Dimmer active={modalLoading} inverted>
                    <Loader />
                </Dimmer>
                <Modal.Header style={{ textAlign: "center" }}>Community News details</Modal.Header>
                <Modal.Content image>
                    <Modal.Description style={{ width: '100%' }}>
                        <Table>
                            <Table.Body>
                                {selectedEvent && selectedEvent.startDate ? (
                                    <Table.Row>
                                        <Table.Cell>Start Date:  </Table.Cell>
                                        <Table.Cell>
                                            {moment(selectedEvent.startDate).format('MMM D, YYYY')}
                                            {' ('}
                                            {moment(selectedEvent.startDate).fromNow()}
                                            {') '}
                                        </Table.Cell>
                                    </Table.Row>
                                ) : (
                                    ''
                                )}
                            </Table.Body>
                            <Table.Body>
                                {selectedEvent && selectedEvent.endDate ? (
                                    <Table.Row>
                                        <Table.Cell>End Date:  </Table.Cell>
                                        <Table.Cell>
                                            {moment(selectedEvent.endDate).format('MMM D, YYYY')}
                                            {' ('}
                                            {moment(selectedEvent.endDate).fromNow()}
                                            {') '}
                                        </Table.Cell>
                                    </Table.Row>
                                ) : (
                                    ''
                                )}
                            </Table.Body>
                            <Table.Header>
                                <Table.Row>
                                    <Table.Cell>Text:  </Table.Cell>
                                    <Table.Cell>{(selectedEvent && selectedEvent.name) || ''}</Table.Cell>
                                </Table.Row>
                            </Table.Header>
                        </Table>
                        {selectedEventImage && <Image style={{ margin: "auto" }} src={selectedEventImage} size='medium' />}
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="red" onClick={closeDetailedModal}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        </div>
    );
};

export default CommunityNews;