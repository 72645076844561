import React from 'react';
import ResidentInfo from '../../components/MyProfile/ResidentInfo';
import './style.less';
import AllConnectedResidents from '../../components/MyProfile/AllConnectedResidents';

const MyProfile = () => {
    return (
        <>
            <h1 className="heading">Manage Profiles</h1>
            <hr className="spacing" />
            <AllConnectedResidents />
            <hr className="spacing" />
            <ResidentInfo />
        </>
    );
};

export default MyProfile;