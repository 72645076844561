import React, { useState } from 'react'
import { Button, Dimmer, Form, Input, Loader, Modal, TextArea } from 'semantic-ui-react'
import '../../pages/calendar/style.less';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import {createCalendarEvent} from '../../services/Calendar'
import sendToast from '../../../utils/Toast';

const CreateCalendarEventModal = ({ closeCreateEventModal, openCreateCalendarModal ,createEventInfo, setRefreshCalendarEvents, refreshCalendarEvents}) => {
    const [event, setEvent] = useState<String>("")
    const [location, setLocation] = useState<String>("")
    const [startDateTimeString, setStartDateTimeString] = useState(() => moment(new Date()).format('YYYY-MM-DDTHH:mm:ss'));
    const [duration, setDuration] = useState<number>();
    const [isCreatingEvent, setIsCreatingEvent] = useState(false);

    const handleEventChnage= (e: any) => {
        setEvent(e.target.value)
    }

    const handleLocationChange = (e: any) => {  
        setLocation(e.target.value)
    }

    const handleDateChange = (date: Date) => {
        const startDateTimeString = moment(date).format('YYYY-MM-DDTHH:mm:ss');
        setStartDateTimeString(startDateTimeString);
    };

    const handleCreateEvent = async () => {
        try {
            setIsCreatingEvent(true);
            if (event.trim() === "" ||
                location.trim() === "" ||
                !event ||
                !startDateTimeString ||
                !location ||
                !createEventInfo.facilityId ||
                !createEventInfo.registrantIds) {
                sendToast('error', 'Please fill all the required fields.');
                return;
            }
            const endTime = duration
                ? moment(startDateTimeString).add(duration, 'm').format('YYYY-MM-DDTHH:mm:ss')
                : moment(startDateTimeString).add(30, 'm').format('YYYY-MM-DDTHH:mm:ss');

            if (duration && duration > 1440 || !moment(endTime, 'YYYY-MM-DDTHH:mm:ss', true).isValid()) {
                sendToast('error', 'Event duration should be less than 24 hours.');
                return;
            }
            const createEventResponse = await createCalendarEvent({
                facilityId: createEventInfo.facilityId,
                event: {
                    dateTimeString: startDateTimeString,
                    endDateTimeString: endTime,
                    registrantIds: createEventInfo.registrantIds,
                    text: event.trim(),
                    staffId: createEventInfo.staffId,
                    location: location.trim()
                }
            })
            if (createEventResponse) {
                sendToast('success', 'Event Created Successfully');
                closeCreateEventModal();
                setRefreshCalendarEvents(!refreshCalendarEvents);
            } else {
                sendToast('error', 'Error creating event');
                closeCreateEventModal();
            }
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Error creating event');
        } finally {
            setIsCreatingEvent(false);
        }
    }
    
    const handleDurationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        // Remove decimal values
        const sanitizedValue = value.replace(/[^\d]/g, '');
        const mins = parseInt(sanitizedValue, 10);
        setDuration(mins);
    }
    

    return ( 
        <>
            <Modal open={openCreateCalendarModal} onClose={closeCreateEventModal} style={{ width: "36rem" }} >
                <Dimmer active={isCreatingEvent} inverted>
                    <Loader  />
                </Dimmer>
                <Modal.Header className='createEventHeader'>Add to your Calendar</Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form className='createEventForm'>
                            <Form.Field style={{ width: "100%" }} required={true}>
                                <label>Event Name</label>
                                <TextArea placeholder='Event' onChange={handleEventChnage} />
                            </Form.Field>
                            <Form.Field required={true}>
                                <label>Event Date</label>
                                <div className="customDatePickerWidth">
                                    <DatePicker
                                        style={{ border: '1px solid #183466' }}
                                        placeholderText="Time and Date"
                                        dateFormat="M.d.Y h:mm aa"
                                        showTimeSelect
                                        timeIntervals={60}
                                        selected={startDateTimeString ? new Date(startDateTimeString) : moment(new Date()).format('YYYY-MM-DDTHH:mm:ss')}
                                        onChange={handleDateChange}
                                    />
                                </div>
                            </Form.Field>
                            <Form.Field>
                                <label>Duration in Minutes</label>
                                <Form.Input
                                    type="number"
                                    min="0"
                                    step="1"
                                    value={duration || ''}
                                    placeholder="Duration in Minutes"
                                    onChange={(e) => { handleDurationChange(e)}}
                                    onKeyPress={(e) => {
                                        // Prevent typing of ".", ",", "-" or any other non-digit characters
                                        if (e.key === '.' || e.key === ',' || e.key === '-' || e.key === '+') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            </Form.Field>
                            <Form.Field required={true}>
                                <label>Location</label>
                                <Input type='text' placeholder='Location' className='locationInput' onChange={handleLocationChange} />
                            </Form.Field>
                            <Button type="submit" className='recordEventBtn' onClick={handleCreateEvent}>
                                Record Event
                            </Button>
                        </Form>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    )
}

export default CreateCalendarEventModal