import FullCalendar from '@fullcalendar/react';
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import React, { useContext, useEffect, useState } from 'react';
import { Dimmer, Item, ItemContent, ItemDescription, ItemImage, Label, Loader, Modal } from 'semantic-ui-react';
import moment from 'moment-timezone';
import UserContext from '../../../contexts/UserContext';
import sendToast from '../../../utils/Toast';
import { fetchAllCommunityMenuItems } from '../../services/Menu';
import { MenuItem } from '../../types/Menu';
import { getStandaloneSignageImage, getServiceInstanceImage } from '../../services/ImageUploader';
import { customDateTimeFormat, capitalizeFirstLetter } from '../../../utils';

interface Props {
    setLoadingMenu: React.Dispatch<React.SetStateAction<boolean>>;
    facilityTimezone: string;

}
const StandaloneMenu = ({ setLoadingMenu, facilityTimezone }: Props) => {
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDateTimeString: moment().startOf('month').format(customDateTimeFormat),
        endDateTimeString: moment().endOf('month').format(customDateTimeFormat),
    });
    const [selectedMenuItem, setSelectedMenuItem] = useState<MenuItem>();
    const [menuItems, setMenuItems] = useState<Array<MenuItem>>([]);
    const [showMenuModal, setShowMenuModal] = useState<boolean>(false);
    const [imageLoading, setImageLoading] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string>('');

    // Collect resident information that corresponds to the logged in neighbors web user from UserContext
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const correspondingSpeak2Resident = listOfResidents[activeRegistrantsIndexes[0]]; // get the first resident from the list of active residents because neighbors web only allows one resident to be linked to one account

    useEffect(() => {
        fetchMenuItems();
    }, [selectedDateRange.startDateTimeString, selectedDateRange.endDateTimeString, facilityTimezone]);

    const fetchMenuItems = async () => {
        try {
            setLoadingMenu(true);
            if (selectedDateRange.startDateTimeString && selectedDateRange.endDateTimeString && facilityTimezone) {
                const startDateTimeString = moment
                    .tz(selectedDateRange.startDateTimeString, facilityTimezone)
                    .format(customDateTimeFormat);
                const endDateTimeString = moment.tz(selectedDateRange.endDateTimeString, facilityTimezone).format(customDateTimeFormat);
                const menuResp = await fetchAllCommunityMenuItems({
                    filter: {
                        Facility: correspondingSpeak2Resident.Facility,
                        startDateTimeString: startDateTimeString,
                        endDateTimeString: endDateTimeString,
                        calendarType: [correspondingSpeak2Resident.Unit.RoomCategory],
                    }
                });
                setMenuItems(menuResp);
            }
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Failed to fetch menu items');
        } finally {
            setLoadingMenu(false);
        }
    };

    const handleMonthChange = ({ start, end }) => {
        const startDateTimeString = moment(start).format(customDateTimeFormat);
        const endDateTimeString = moment(end).format(customDateTimeFormat);
        setSelectedDateRange({
            startDateTimeString,
            endDateTimeString,
        });
    };

    const handleEventClick = async (info) => {
        const selectedMenuId = info.event._def.publicId;
        const selectedMenu = menuItems.find((menu) => String(menu._id) === String(selectedMenuId));
        if (selectedMenu && selectedMenu.svcMenuInstance && selectedMenu.startDate) {
            const dateString = moment(selectedMenu.startDate, facilityTimezone).format('ddd MMM DD YYYY');
            selectedMenu.dateString = dateString;
        }
        setSelectedMenuItem(selectedMenu);
        setShowMenuModal(true);
        try {
            setImageLoading(true);
            let image: string = '';
            if (selectedMenu && selectedMenu.svcMenuInstance && selectedMenu.image) {
                image = await getServiceInstanceImage({
                    facilityId: selectedMenu.Facility,
                    svcInstanceId: String(selectedMenu.srcId || selectedMenu._id),
                    fileName: selectedMenu.image,
                });
            } else if (selectedMenu && selectedMenu.signageImageId) {
                image = await getStandaloneSignageImage({
                    facilityId: selectedMenu.Facility,
                    contentType: "menus",
                    contentId: selectedMenu.signageImageId,
                });
            } else if (selectedMenu) {
                const category = selectedMenu.svcMenuInstance ? selectedMenu.menuCategory : selectedMenu.category;
                image = `https://speak2-signage.s3.amazonaws.com/menus/default-images/${category}.png`;
            }
            setImageUrl(image);
        } catch (error) {
            sendToast('warn', error instanceof Error ? error.message : 'Failed to fetch image');
        } finally {
            setImageLoading(false);
        }
    };

    const getColor = (category: string) => {
        switch (category) {
            case 'breakfast':
                return '#2987CD';
            case 'lunch':
                return '#69B546';
            case 'dinner':
                return '#EAC31F';
            case 'snack':
                return '#E98530';
            case 'alternative':
                return '#E98530';
            default:
                return '#2987CD';
        }
    };

    return (
        <div>
            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin, rrulePlugin]}
                initialView="dayGridMonth"
                headerToolbar={{
                    right: 'today prev,next',
                }}
                showNonCurrentDates={false}
                events={[
                    ...menuItems.map((event) => {
                        const timeSlots = {
                            breakfast: 8,
                            lunch: 12,
                            dinner: 20,
                            snack: 18,
                            alternative: 22,
                        };
                        return event.audioId ? {
                            title: event.name
                                ? `${capitalizeFirstLetter(event.svcMenuInstance ? event.menuCategory : event.category)}:  ${event.name.replace(
                                    /(<([^>]+)>)/gi,
                                    '',
                                )}`
                                : '',
                            start: moment.tz(event.startDate, facilityTimezone).startOf('day').unix() * 1000 + timeSlots[event.svcMenuInstance ? event.menuCategory : event.category] * 60,
                            id: event._id,
                            backgroundColor: getColor(event.svcMenuInstance ? event.menuCategory : event.category),
                            classNames: [`category-${event.svcMenuInstance ? event.menuCategory : event.category}`, "audio"]
                        } : {
                            title: event.name
                                ? `${capitalizeFirstLetter(event.svcMenuInstance ? event.menuCategory : event.category)}:  ${event.name.replace(
                                    /(<([^>]+)>)/gi,
                                    '',
                                )}`
                                : '',
                            start: moment.tz(event.startDate, facilityTimezone).startOf('day').unix() * 1000 + timeSlots[event.svcMenuInstance ? event.menuCategory : event.category] * 60,
                            id: event._id,
                            backgroundColor: getColor(event.svcMenuInstance ? event.menuCategory : event.category),
                            classNames: [`category-${event.svcMenuInstance ? event.menuCategory : event.category}`]
                        };
                    }),
                ]}
                datesSet={handleMonthChange}
                eventClick={handleEventClick}
            />

            {/* </Segment> */}

            {/* Modal for displaying menu details */}
            <Modal open={showMenuModal} onClose={() => { setShowMenuModal(false); setImageUrl(''); }}>
                <Dimmer active={imageLoading} inverted>
                    <Loader content="Loading..." />
                </Dimmer>
                <Modal.Header>Menu details</Modal.Header>
                {selectedMenuItem && (
                    <Modal.Content>
                        <Modal.Description style={{ width: '100%' }}>
                            <Item className="menu-item-modal">
                                <div className='menu-modal-image'>
                                    <ItemImage size='medium' src={imageUrl} />
                                </ div>
                                <ItemContent className='menu-modal-content'>
                                    <div className='desc-item'>
                                        <Label basic > Item : </Label>
                                        <ItemDescription> {selectedMenuItem.alexaReadableString || selectedMenuItem.text || selectedMenuItem.name} </ItemDescription>
                                    </div>
                                    <div className='desc-item'>
                                        <Label basic > Date : </Label>
                                        <ItemDescription> {selectedMenuItem.dateString} </ItemDescription>
                                    </div>
                                    <div className='desc-item'>
                                        <Label basic > Category : </Label>
                                        <ItemDescription> {selectedMenuItem.menuCategory || selectedMenuItem.category || "-"} </ItemDescription>
                                    </div>
                                    {selectedMenuItem.shortDescription && (
                                        <div className='desc-item'>
                                            <Label basic > Short description : </Label>
                                            <ItemDescription> {selectedMenuItem.shortDescription} </ItemDescription>
                                        </div>
                                    )}
                                    {selectedMenuItem.longDescription && (
                                        <div className='desc-item'>
                                            <Label basic > Long description : </Label>
                                            <ItemDescription> {selectedMenuItem.longDescription} </ItemDescription>
                                        </div>
                                    )}
                                </ItemContent>
                            </Item>
                        </Modal.Description>
                    </Modal.Content>
                )}
            </Modal>
        </div >
    );
};

export default StandaloneMenu;
