import React, { useState, useEffect, useContext } from 'react';
import { Tab, TabProps, Dimmer, Loader, Button } from 'semantic-ui-react';
import queryString from 'query-string';

import SignUpComponent from '../../components/signUpComponent';
import LoginComponent from '../../components/loginComponent';
import * as Authentication from '../../../services/Authentication';
import * as Database from '../../../services/db';
import * as User from '../../../services/User';

import './style.less';
import ConnectedResident from '../../components/connectedResident';
import UserContext from '../../../contexts/UserContext';
import RedirectContext from '../../../contexts/RedirectContext';
import config from '../../../config';
import SignUpForNeighbors from '../../../neighbors/components/SignUpComponent/signUpForNeighbors';
import AppUrls from '../../../contexts/AppUrls';
import { communityLogoPath, neighborsLogoPath } from '../../../utils/logoPaths';

const LogIn = (props) => {
    const queryParams = queryString.parse(props.location.search);


    const haveLoggedInBefore = localStorage.getItem('haveLoggedInBefore');

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [code, setCode] = useState<string>('');

    const [activeIndex, setActiveIndex] = useState<number>(queryParams.signup ? 1 : haveLoggedInBefore ? 0 : 1);

    const [disabledButton, setDisabledButton] = useState<boolean>(queryParams.signup ? true : false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [signUpError, setSignUpError] = useState<string>('');
    const [logInError, setLogInError] = useState<string>('');
    const [skipCodeVerification, setSkipCodeVerification] = useState<boolean>(false);
    const [contact, setContact] = useState<string>('');
    const { setUser } = useContext(UserContext);
    const { neighborsAndroidAppUrl, neighborsIosAppUrl } = useContext(AppUrls);
    const toggleRedirect = useContext(RedirectContext);

    const env = process.env.SERVER_ENV || 'development';
    const envConfig = config[env];

    const handleLoginSubmit = async (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        username: string,
        password: string,
        newSignup: boolean = false,
    ): Promise<void> => {
        e.preventDefault();

        setLoading(true);

        try {
            const user = await Authentication.cognitoSignIn(username, password);
            console.log('username', user);
            const dbUserProfileResponse = await Database.getUserDbProfile();
            const userProfile = dbUserProfileResponse.userProfile;

            const connectedRegistrants = await Database.getRelatedRegistrants();

            const signedInUser = {
                cognitoUser: user,
                profile: userProfile,
                registrants: {
                    list: connectedRegistrants.residents,
                    activeRegistrantsIndexes:
                        connectedRegistrants.residents && connectedRegistrants.residents.length ? [0] : [],
                },
                username: user.username,
                authenticated: true,
            };

            if (!userProfile) throw Error('No user profile');
            if (process.env.REACT_APP_PLATFORM === 'neighbors') {
                const managedResident = signedInUser && signedInUser.registrants && signedInUser.registrants.list && signedInUser.registrants.list.find((registrant) => {
                    return registrant.Facility && (registrant.manager || registrant.isCommunityManager)
                })
                if (managedResident) {  // If the env is neighbors, then set active registrant to the managed resident
                    const indexOfManagedResident = signedInUser.registrants.list.indexOf(managedResident);
                    signedInUser.registrants.activeRegistrantsIndexes = [indexOfManagedResident];
                }
            }
            setUser(signedInUser);
            if (newSignup && connectedRegistrants.residents && connectedRegistrants.residents.length === 0) {
                toggleRedirect('/message-recipients');
            } else {
                if (process.env.REACT_APP_PLATFORM === 'community') {
                    toggleRedirect('/send-message');
                } else if (process.env.REACT_APP_PLATFORM === 'neighbors') {
                    toggleRedirect('/calendar');
                }
                else {
                    toggleRedirect('/activities');
                }
            }
            return;
        } catch (error) {
            console.log(error.message);
            const userExists = await Authentication.checkIfUserExists(username);
            if (!userExists) {
                setLogInError('User does not exist');
            } else {
                setLogInError('Unable to log in.');
            }
            setLoading(false);
        }
    };

    const handleSignUpSubmit = async (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        contact: string,
        password: string,
    ): Promise<any> => {
        e.preventDefault();

        setLoading(true);

        const signUpParams = { password, firstName, lastName };

        if (code) {
            // @ts-ignore
            signUpParams.token = code;
        }

        // @ts-ignore
        signUpParams.contact = contact;

        try {
            console.log('signup params,', signUpParams);
            await Authentication.cognitoSignUp(signUpParams);

            handleLoginSubmit(e, contact, password, true);
            return;
        } catch (error) {
            setLoading(false);
            setSignUpError('Unable to sign up user.');
        }
    };

    const toggleLoginOrSignup = () => {
        if (loginOrSignup === 'login') {
            setLoginOrSignup('signup')
        } else {
            setLoginOrSignup('login')
        }
    }

    const [loginOrSignup, setLoginOrSignup] = useState('login')
    const CommunityNeighborsLoginOrSignUpComponent = () => {
        if (!loading) {
            if (loginOrSignup === 'login') {
                return <LoginComponent handleLoginSubmit={handleLoginSubmit} error={logInError} locationState={props.location.state}/>
            } else if (loginOrSignup === 'signup') {
                return (
                    <div>
                        {process.env.REACT_APP_PLATFORM === 'neighbors' ? <SignUpForNeighbors
                            firstName={firstName}
                            lastName={lastName}
                            contact={contact}
                            setFirstName={setFirstName}
                            setLastName={setLastName}
                            code={code}
                            setCode={setCode}
                            handleSignUpSubmit={handleSignUpSubmit}
                            error={signUpError}
                            disabledButton={disabledButton}
                            setLoading={setLoading}
                            skipCodeVerification={skipCodeVerification}
                            setSkipCodeVerification={setSkipCodeVerification}
                            setContact={setContact}
                        /> :
                            <SignUpComponent
                                firstName={firstName}
                                lastName={lastName}
                                setFirstName={setFirstName}
                                setLastName={setLastName}
                                setCode={setCode}
                                handleSignUpSubmit={handleSignUpSubmit}
                                error={signUpError}
                                disabledButton={disabledButton}
                            />
                        }
                        <div style={{ textAlign: 'center' }}>
                            <br />
                            <a className="text-center" onClick={toggleLoginOrSignup} style={{ cursor: 'pointer' }}>Back to Login</a>
                        </div>
                    </div>
                )
            }
        }
    }

    const CommunitySignupButton = () => {
        if (loginOrSignup === 'login') {
            return (
                <div className="signup-container">
                    If you don't have an account
                    <Button className="signup-button" onClick={toggleLoginOrSignup} role="link" color="teal" basic>
                        Sign Up
                    </Button>
                </div>
            )
        } else if (loginOrSignup === 'signup') {
            return ''
        }
    }

    const CommunityAppLinksComponent = () => {
        if (!loading) {
            return (
                <div className="app-links">
                    <a
                        href="https://play.google.com/store/apps/details?id=com.speak2software.community"
                        className="play-store"
                    >
                        <img
                            alt="Get it on Google Play"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                        />
                    </a>
                    <a href="https://apps.apple.com/ua/app/speak2-community/id1596060585" className="app-store">
                        <img src="/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" />
                    </a>
                </div>
            )
        }
    }

    return (
        <div className='login-page'>
        <div className="login-wrapper">
            <Dimmer active={loading} inverted>
                <Loader active={loading} />
            </Dimmer>
            {process.env.REACT_APP_PLATFORM === 'community'
                && <img src={`${process.env.PUBLIC_URL}/logo-speak2-community.svg`} alt="Speak 2 Community Logo" className="logo" />}
            {process.env.REACT_APP_PLATFORM === 'neighbors' && <img src={`${process.env.PUBLIC_URL}${neighborsLogoPath}`} alt="Speak 2 Neighbors Logo" className="logo" />}
            {(process.env.REACT_APP_PLATFORM !== 'community' && process.env.REACT_APP_PLATFORM !== 'neighbors') && <img src={`${process.env.PUBLIC_URL}${communityLogoPath}`} alt="Speak 2 Family Logo" className="logo" />
            }
            
            {process.env.REACT_APP_PLATFORM === 'community' || process.env.REACT_APP_PLATFORM === 'neighbors'
            ?   CommunityNeighborsLoginOrSignUpComponent()
            :   <LoginComponent handleLoginSubmit={handleLoginSubmit} error={logInError} locationState={props.location.state}/>   
            }

                {process.env.REACT_APP_PLATFORM === 'community' || process.env.REACT_APP_PLATFORM === 'neighbors'
                    ? CommunitySignupButton()
                    : <div className="signup-container">
                        If you don't have an account
                        <Button className="signup-button" href={envConfig.signUpUrl} role="link" color="teal" basic>
                            Sign Up
                        </Button>
                    </div>

            }

            <h4 className="text-center">The app is available for download</h4>
            {process.env.REACT_APP_PLATFORM === 'community'
                ? CommunityAppLinksComponent()

                : <div className="app-links">
                    <a
                        href={process.env.REACT_APP_PLATFORM === "neighbors" ? neighborsAndroidAppUrl : "https://play.google.com/store/apps/details?id=com.speak2software.family&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"}
                        className="play-store"
                        target="_blank"
                    >
                        <img
                            alt="Get it on Google Play"
                            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                        />
                    </a>
                    <a href={process.env.REACT_APP_PLATFORM === "neighbors" ? neighborsIosAppUrl : "https://apps.apple.com/us/app/speak2-family/id1503580779"} className="app-store" target="_blank">
                        <img src="/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg" />
                    </a>
                </div>
            }
        </div>
        </div>
    );
};

function getDecodedUrlSegments(params) {
    try {
        let {
            t: token,
            r: registrantId,
            c: receivedContact,
            f: registrantFirstName,
            l: registrantLastName,
            i: image,
        } = params;
        return {
            token: atob(token || ''),
            registrantId: atob(registrantId || ''),
            receivedContact: atob(receivedContact || ''),
            registrantFirstName: atob(registrantFirstName || ''),
            registrantLastName: atob(registrantLastName || ''),
            // image: 'https://' + atob(image || ''),
            image: image ? 'https://' + atob(image) : undefined,
        };
    } catch (error) {
        return {
            token: '',
            registrantId: '',
            receivedContact: '',
            registrantFirstName: '',
            registrantLastName: '',
            image: '',
        };
    }
}

export default LogIn;
