import * as Lambda from '../../services/LambdaApi';
import { Document } from '../types/Document';

const fetchCommunityDocs = async (facilityId: string, pageNo: number = 1, pageSize: number = 100) => {
    const apiPath = `/s2l/${facilityId}/listDocs`;
    const params =
    {
        Filter: {
            sendCommunityDocs: true,
            includeAddedByName: true
        },
        pagingSorting: {
            page_size: pageNo * pageSize,
            page_no: 1,
            sort: [{
                DateAdded: "asc"
            }]
        },
    };

    const response = await Lambda.Post(apiPath, params, 'lambda');
    return response;
};

const fetchMyDocs = async (facilityId: string, residentId: string, pageNo: number = 1, pageSize: number = 100) => {
    const apiPath = `/s2l/${facilityId}/listDocs`;
    const params =
    {
        Filter: {
            residentId: residentId,
            includeAddedByName: true // this is if you want AddedByName in the response
        },
        pagingSorting: {
            page_size: pageNo * pageSize,
            page_no: 1,
            sort: [{
                DateAdded: "asc"
            }]
        }
    };

    const response = await Lambda.Post(apiPath, params, 'lambda');
    return response;
};

const fetchByDocIds = async (facilityId: string, docIds: string[]) => {
    try {
        const apiPath = `/s2l/${facilityId}/listDocs`;
        const params =
        {
            Filter: {
                _id: {
                    $in: docIds
                }
            },
            pagingSorting: {
                sort: [{
                    DateAdded: "asc"
                }]
            }
        };
        const response = await Lambda.Post(apiPath, params, 'lambda');
        if (!response || !response.Result || !response.Result.Content || !response.Result.Success) {
            throw new Error("Error fetching facility details");
        }
        return response.Result.Content as { Result: Document[]; TotRecords: number; };
    } catch (err) {
        throw new Error("Error fetching document details");
    }
};

export {
    fetchCommunityDocs,
    fetchMyDocs,
    fetchByDocIds
};