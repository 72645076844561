import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify, { Auth } from 'aws-amplify';

import config from './config';
import { BrowserRouter } from 'react-router-dom';

declare var process: {
    env: {
        SERVER_ENV: string;
    };
};

const envConfig = config[process.env.SERVER_ENV] || config.development;

Amplify.configure({
    Auth: {
        region: envConfig.region,

        userPoolId: envConfig.userPoolId,
        userPoolWebClientId: envConfig.appClientId,

        authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
    API: {
        endpoints: [
            {
                name: 'lambda',
                endpoint: envConfig.lambdaEndpoint,
                custom_header: async () => {
                    try {
                        return {
                            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        };
                    } catch (error) {
                        return null;
                    }
                },
            },{
                name: 'public', 
                endpoint: envConfig.lambdaEndpoint
            },{
                name: 'testLambda',
                endpoint: envConfig.testLambdaEndpoint,
                custom_header: async () => {
                    try {
                        return {
                            Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                        };
                    } catch (error) {
                        return null;
                    }
                },
            }
        ],
    },
});

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
