import React, { useContext, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Dimmer, Icon, Loader, Modal, Popup } from 'semantic-ui-react';
import moment from 'moment';
import momentTZLib from 'moment-timezone';
import "./style.less";
import StatusFilters from '../../components/OrderStatus/StatusFilters';
import CustomTable from '../../../components/components/CustomTable';
import { fetchFacilityDetails } from '../../services/Facilities';
import UserContext from '../../../contexts/UserContext';
import sendToast from '../../../utils/Toast';
import { fetchOrders } from '../../services/OrderStatus';
import { FetchOrderParams, RequestInstance } from '../../types/OrderStatus';
import { formatDateWithTZ } from '../../../utils/timezone';
import { Link } from 'react-router-dom';
import CreateServiceOrder from '../../components/OrderStatus/CreateServiceOrder';
import { Service } from '../../types/Service';
import { formatOrdersForTableView } from '../../../utils';

export interface DateRange {
    fromDate?: Date,
    toDate?: Date;
}

const OrderStatus = () => {
    const [dates, setDates] = useState<DateRange>({
        fromDate: (() => {
            const fromDate = new Date();
            fromDate.setDate(fromDate.getDate() - 7); // Subtract 7 days from the current date
            return fromDate;
        })(),
        toDate: (() => {
            const toDate = new Date();
            toDate.setDate(toDate.getDate() + 7); // Add 7 days to the current date
            return toDate;
        })(),
    });
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const calendarRef = useRef<HTMLHeadingElement>(null);
    const [facilityTimezone, setFacilityTimezone] = useState<string>("");
    const [facilityLoading, setFacilityLoading] = useState<boolean>(false);
    const [filterStatus, setFilterStatus] = useState<string>("");
    const [orders, setOrders] = useState<RequestInstance[]>([]);
    const [totalOpenOrders, setTotalOpenOrders] = useState<number>(0);
    const [totalClosedOrders, setTotalClosedOrders] = useState<number>(0);
    const [totalInProgressOrders, setTotalInProgressOrders] = useState<number>(0);
    const [pageNo, setPageNo] = useState<number>(1);
    const [totalDataLength, setTotalDataLength] = useState<number>(0);
    const [openCreateOrderModal, setOpenCreateOrderModal] = useState<boolean>(false);
    const [selectedService, setSelectedService] = useState<Service>({} as Service);
    const [selectedOrder, setSelectedOrder] = useState<RequestInstance>({} as RequestInstance);
    const [selectedCategory, setSelectedCategory] = useState<string>("");

    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const activeResident = listOfResidents[activeRegistrantsIndexes[0]]; // get the first resident from the list of active residents because neighbors web only supports the resident who is logged in and we can't deselect or change it
    const pageSize = 20;

    useEffect(() => {
        (async () => {
            try {
                setFacilityLoading(true);
                const facilityRes = await fetchFacilityDetails(activeResident.Facility);
                if (!facilityRes.FacilityTimeZone) {
                    throw new Error("Facility Timezone not found");
                }
                setFacilityTimezone(facilityRes.FacilityTimeZone);
            } catch (error) {
                sendToast('error', error);
            } finally {
                setFacilityLoading(false);
            }
        }
        )();
    }, []);

    useEffect(() => {
        if (dates.fromDate && dates.toDate) {
            listOrders();
        }
    }, [dates, filterStatus, pageNo]);

    const listOrders = async () => {
        const fromDateString = moment(dates.fromDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const toDateString = moment(dates.toDate).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        const orderFilter: FetchOrderParams = {
            facilityId: activeResident.Facility,
            statusArr: [],
            pageNo: pageNo,
            pageSize: pageSize,
            startDateTime: fromDateString,
            endDateTime: toDateString,
            residentId: String(activeResident._id),
        };
        try {
            setLoading(true);
            const [openOrders, inProgressOrders, closedOrders] = await Promise.all([fetchOrders({ ...orderFilter, statusArr: ["Open"] }), fetchOrders({ ...orderFilter, statusArr: ["Accepted"] }), fetchOrders({ ...orderFilter, statusArr: ["Closed"] })]);
            setTotalClosedOrders(closedOrders.Result.Content.TotRecords);
            setTotalOpenOrders(openOrders.Result.Content.TotRecords);
            setTotalInProgressOrders(inProgressOrders.Result.Content.TotRecords);
            if (filterStatus === "Open") {
                const formattedOrders = formatOrdersForTableView(openOrders.Result.Content.Result, facilityTimezone);
                setOrders(formattedOrders);
                setTotalDataLength(openOrders.Result.Content.TotRecords);
            } else if (filterStatus === "Accepted") {
                const formattedOrders = formatOrdersForTableView(inProgressOrders.Result.Content.Result, facilityTimezone);
                setOrders(formattedOrders);
                setTotalDataLength(inProgressOrders.Result.Content.TotRecords);
            }
            else if (filterStatus === "Closed") {
                const formattedOrders = formatOrdersForTableView(closedOrders.Result.Content.Result, facilityTimezone);
                setOrders(formattedOrders);
                setTotalDataLength(closedOrders.Result.Content.TotRecords);
            } else {
                const combinedRes = await fetchOrders({ ...orderFilter, statusArr: ["Open", "Accepted", "Closed"] }); // Extra call to fetch all three combined so that server pagination is proper
                setTotalDataLength(combinedRes.Result.Content.TotRecords);
                const combinedOrders = combinedRes.Result.Content.Result;
                combinedOrders.sort(function (a, b) {
                    const dateA = momentTZLib.tz(a.RequestedTime, facilityTimezone);
                    const dateB = momentTZLib.tz(b.RequestedTime, facilityTimezone);

                    if (dateB.isBefore(dateA)) {
                        return -1; // if b is in the past, put a first
                    } else if (dateA.isBefore(dateB)) {
                        return 1; // if a is in the past, put b first
                    } else {
                        return 0;
                    }
                });
                const formattedOrders = formatOrdersForTableView(combinedOrders, facilityTimezone);
                setOrders(formattedOrders);
            }
        } catch (error) {
            sendToast('error', error);
        }
        finally {
            setLoading(false);
        }
    };

    const formatDateRange = (dateRange: DateRange) => {
        const startDate = dateRange.fromDate ? moment(dateRange.fromDate).format('MM/DD/YYYY') : 'none';
        const endDate = dateRange.toDate ? moment(dateRange.toDate).format('MM/DD/YYYY') : 'none';
        if (!dateRange.toDate || startDate === endDate) return startDate;
        return `${startDate} - ${endDate}`;
    };

    const onOrderClick = (item: any) => {
        if (!item || !item.Service) {
            sendToast('error', 'Order or Service not found');
            return;
        }
        setSelectedOrder(item);
        setSelectedService(item.Service);
        setSelectedCategory(item.Service.category);
        setOpenCreateOrderModal(true);
    };

    return (
        <>
            <h1 className="heading">Order Status</h1>
            <hr className="spacing" />
            <div style={{ position: 'relative' }}>
                <Dimmer active={loading || facilityLoading} inverted>
                    <Loader active={loading || facilityLoading} />
                </Dimmer>
                <div ref={calendarRef}>
                    <Button
                        onClick={() => setShowDatePicker((prev) => !prev)}
                        className="date-range-selector"
                        size='small'
                    >
                        <Icon name="calendar alternate outline" size="large" />
                        <span style={{ fontSize: '12px' }}>{formatDateRange(dates)}</span>
                    </Button>

                    {showDatePicker && (
                        <div style={{ position: 'absolute', zIndex: 2 }}>
                            <DatePicker
                                selected={dates.fromDate}
                                startDate={dates.fromDate}
                                endDate={dates.toDate}
                                onChange={(startEndDates) => {
                                    const [start, end] = startEndDates;
                                    setDates({ ...dates, fromDate: start, toDate: end });
                                    if (start && end) {
                                        setShowDatePicker(false);
                                    }
                                }}
                                selectsRange
                                inline
                                style={{ position: 'absolute', top: 0, left: 0 }}
                            />
                        </div>
                    )}
                    <Popup
                        content="Place an order"
                        trigger={
                            <Link to={'/create-order'}>
                                <Icon name="add" className="button-icon" size="large" style={{ cursor: "pointer" }} />
                            </Link>
                        }
                        position='bottom center'
                    ></Popup>
                    <div className="statusFilters">
                        <StatusFilters filterStatus={filterStatus} setFilterStatus={setFilterStatus} totalOpenOrders={totalOpenOrders} totalClosedOrders={totalClosedOrders} totalInProgressOrders={totalInProgressOrders} isLoading={loading} setPageNo={setPageNo} />
                    </div>
                    <CustomTable
                        data={orders}
                        headers={[
                            'Order',
                            'OrderId',
                            'Day/Time',
                            'Assigned',
                            'Scheduled',
                        ]}
                        rowKeys={[
                            'OrderName',
                            'OrderId',
                            'DateTime',
                            'AcceptedByName',
                            'Scheduled',
                        ]}
                        formatString='MM/DD/YYYY HH:mm:ss'
                        facilityTimezone={facilityTimezone || ""}
                        setHeight='60vh'
                        pageNo={pageNo}
                        setPageNo={setPageNo}
                        totalDataLength={totalDataLength}
                        isServerPagination={true}
                        itemsPerPage={pageSize}
                        onRowClickHandler={(item) => onOrderClick(item)}
                    />
                    <Modal open={openCreateOrderModal} onClose={() => setOpenCreateOrderModal(false)} size="tiny">
                        <Modal.Content>
                            <CreateServiceOrder request={selectedOrder} service={selectedService || {} as Service} selectedCategory={selectedCategory} setOpenCreateOrderModal={setOpenCreateOrderModal} refreshData={listOrders}/>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button onClick={() => setOpenCreateOrderModal(false)}>Cancel form</Button>
                        </Modal.Actions>
                    </Modal>
                </div>
            </div>
        </>
    );
};

export default OrderStatus;