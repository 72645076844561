import * as Lambda from '../../services/LambdaApi';
import { Staff } from '../types/Staff';

const listAllStaff = async (filter) => {
    try {
        const { Facility: facilityId } = filter;
        if (!facilityId) throw new Error('facilityId is required');
        const apiPath = `/s2l/${facilityId}/listStaffUsers`;
        const params = {
            Filter: {
                IsActive: 1,
                ...filter
            },
        };
        const response = await Lambda.Post(apiPath, params, 'lambda');
        if (!response || !response.Result || !response.Result.Success || !response.Result.Content) {
            throw new Error(response.Result.Error || `Error fetching resident's interests`);
        }
        return response.Result.Content as { Result: Staff[]; TotRecords: number; };

    } catch (err) {
        throw new Error("Error fetching Staff list");
    }

};

export { listAllStaff };