import React, { useEffect, useContext, useState } from 'react';

import { Form, Button, Dimmer, Loader, Input, Popup } from 'semantic-ui-react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { getSettings, putSettings } from '../../../services/Messages';
import UserContext from '../../../contexts/UserContext';
import { toast } from 'react-toastify';

import './style.less';

interface NotificationSettings {
    automatedSmsNumber?: string;
    callButtonNumber?: string;
    automatedCallNumber?: string;
    messageText?: string;
}

const Settings = () => {
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    console.log(activeRegistrantsIndexes, listOfResidents, '----------');
    const residentId = listOfResidents[activeRegistrantsIndexes] && listOfResidents[activeRegistrantsIndexes]._id;

    const [currentPeriod, setCurrentPeriod] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const [notificationSettings, setNotificationSettings] = useState<NotificationSettings>({
        automatedSmsNumber: '',
        callButtonNumber: '',
        automatedCallNumber: '',
        messageText: '',
    });

    const setSettings = async (period) => {
        try {
            const payload = { storyPeriod: period };
            setLoading(true);
            const {
                updatedSettings: { StoryPeriod: messagePeriod },
            } = await putSettings(residentId, payload);
            setCurrentPeriod(messagePeriod);
            setLoading(false);
        } catch (error) {
            setError('Unable to update message period.');
            setLoading(false);
        }
    };

    const putNotificationSettings = async () => {
        try {
            setLoading(true);
            const payload = {
                ...notificationSettings,
                AutomatedCallNumber: notificationSettings.automatedCallNumber
                    ? notificationSettings.automatedCallNumber
                    : '',
                callButtonNumber: notificationSettings.callButtonNumber ? notificationSettings.callButtonNumber : '',
                automatedSmsNumber: notificationSettings.automatedSmsNumber
                    ? notificationSettings.automatedSmsNumber
                    : '',
            };
            const {
                success,
                AutomatedCallNumber,
                callButtonNumber,
                automatedSmsNumber,
                messageText,
            } = await putSettings(residentId, payload);

            if (!success) {
                toast.error('Could not update Notification Settings', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } else {
                toast.success('Updated Notification Settings successfully!', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            setLoading(false);
        } catch (error) {
            toast.error('Could not update Notification Settings', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!residentId) {
            return;
        }
        setLoading(true);
        getSettings(residentId)
            .then(({ storyPeriod, callButtonNumber, automatedSmsNumber, automatedCallNumber, messageText }) => {
                setCurrentPeriod(storyPeriod);
                setNotificationSettings({ callButtonNumber, automatedSmsNumber, automatedCallNumber, messageText });
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setError('Unable to get message settings.');
                toast.error('Unable to get message settings', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            });
    }, [residentId]);

    const handleNotificationSettingChange = (name) => (value) => {
        setNotificationSettings({
            ...notificationSettings,
            [name]: value,
        });
    };

    return (
        <div className="settings-page">
            <Form autoComplete="false" onSubmit={(e) => {}}>
                <Dimmer active={loading} inverted>
                    <Loader active={loading} />
                </Dimmer>
                <div className="notification-settings">
                    <h3 className="current-selection">
                        Notification Settings
                        <Popup
                            trigger={<Button icon="question circle" className="popup-button" />}
                            on="click"
                            wide="very"
                        >
                            Enter phone numbers that can be used to contact people as needed from a Message that has
                            been sent to your Feed.
                            <br />
                            <br />
                            <b>Call Button Number:</b> this is the number that will come up on your phone when you press
                            the “Call” button.
                            <br />
                            <b>SMS Button Number:</b> this is the number that will be used when sending an SMS message
                            on your phone.
                            <br />
                            <b>Automated SMS Number:</b> this will send an automated SMS message to the number entered.
                            The text of the SMS mes-sage is in the “Message Text” field.
                        </Popup>
                    </h3>

                    <Form.Group>
                        <PhoneInput
                            defaultCountry="US"
                            placeholder="Call Button Number"
                            name="callButtonNumber"
                            value={notificationSettings.callButtonNumber}
                            onChange={handleNotificationSettingChange('callButtonNumber')}
                        />
                        <PhoneInput
                            defaultCountry="US"
                            placeholder="SMS Button Number"
                            name="automatedSmsNumber"
                            value={notificationSettings.automatedSmsNumber}
                            onChange={handleNotificationSettingChange('automatedSmsNumber')}
                        />
                        <PhoneInput
                            defaultCountry="US"
                            placeholder="Automated SMS Number"
                            name="automatedCallNumber"
                            value={notificationSettings.automatedCallNumber}
                            onChange={handleNotificationSettingChange('automatedCallNumber')}
                        />
                        <Form.Input
                            defaultCountry="US"
                            placeholder="Message Text"
                            name="messageText"
                            value={notificationSettings.messageText}
                            onChange={(e) => {
                                handleNotificationSettingChange('messageText')(e.target.value);
                            }}
                        />
                    </Form.Group>
                    <Button className="small primary mt-1" onClick={putNotificationSettings}>
                        Save
                    </Button>
                </div>
                <div className="message-period">
                    <h3 className="current-selection">
                        Message Repeat Settings
                        <Popup
                            trigger={<Button icon="question circle" className="popup-button" />}
                            on="click"
                            wide="very"
                        >
                            {currentPeriod ? (
                                <h4 className="current-selection">
                                    {`Current Selection: `}
                                    <span className="capitalize">{currentPeriod}</span>
                                </h4>
                            ) : null}
                            <p>
                                E.g. "Weekly" will play messages for the 7 days that follow the submission and then be
                                archived. Consider how often and how many messages will be shared when choosing which
                                setting suits each Member you manage.
                            </p>
                            <p>
                                If you expect a lot of messages to be sent each week, you may want to select Weekly, so
                                they don’t remain past one week. If messages will be a bit less frequent, you may want
                                the messages to remain available “Monthly”. Expect lots and lots each day? Then maybe
                                Daily is the best option for you.
                            </p>
                        </Popup>
                    </h3>

                    {error ? <p className="error-period">{error}</p> : null}

                    <h2>Choose one of the below to determine how often messages are archived</h2>

                    <ul>
                        <li
                            className={`${currentPeriod === 'daily' ? 'active-message' : ''}`}
                            onClick={() => setSettings('daily')}
                        >
                            Daily
                        </li>
                        <li
                            className={`${currentPeriod === 'weekly' ? 'active-message' : ''}`}
                            onClick={() => setSettings('weekly')}
                        >
                            Weekly
                        </li>
                        <li
                            className={`${currentPeriod === 'monthly' ? 'active-message' : ''}`}
                            onClick={() => setSettings('monthly')}
                        >
                            Monthly
                        </li>
                    </ul>
                </div>
            </Form>
        </div>
    );
};

export default Settings;
