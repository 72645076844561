
import * as Lambda from '../../services/LambdaApi';

const fetchResidentDetailsFromToken = async (token: string) => {
    try {
        const apiPath = `/registrations/get-resident`;
        const payloadObject = {
            queryStringParameters: {
                token,
            },
        };
        const response = await Lambda.Get(apiPath, payloadObject, 'lambda');
        return response;
    } catch (error) {
        throw new Error("Error fetching resident details");
    }
};

export { fetchResidentDetailsFromToken };