export default {
    development: {
        // identityPoolId: 'us-east-1:66a2b483-d23f-4b59-bac1-cdf2dd3fcc66',
        region: 'us-east-1',
        userPoolId: 'us-east-1_pJFFRn0Yw',
        appClientId: '7ploat65puuldeniiektc4uvn8',
        // lambdaEndpoint: 'https://d4lk5hgk6b.execute-api.us-east-1.amazonaws.com/dev',
        lambdaEndpoint: "https://api.speak2family.com/development",
        // s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        profileImageBucketUrl: 'https://development-speak2-user-profile-images.s3.amazonaws.com',
        stripePublicKey: "pk_test_CtajViBJZ5PUixlEPkpIftD900ey68bibS", 
        signUpUrl: "https://stagings2f.wpengine.com/s2f-signup-full/",
    },
    production: {
        // identityPoolId: 'us-east-1:66a2b483-d23f-4b59-bac1-cdf2dd3fcc66',
        region: 'us-east-1',
        userPoolId: 'us-east-1_w2KY1GZOX',
        appClientId: '7p5doeoirq2115i5lia62mvl6e',
        lambdaEndpoint: 'https://api.speak2family.com/v1',
        // s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        profileImageBucketUrl: 'https://v1-speak2-user-profile-images.s3.amazonaws.com',
        stripePublicKey: "pk_live_Lb7HDYXwEfk6JCbPUNcqns4U00QHH3THRF",
        signUpUrl: "https://www.speak2family.com/s2f-signup-full/",
    },
    test: {
        identityPoolId: 'us-east-1:66a2b483-d23f-4b59-bac1-cdf2dd3fcc66',
        region: 'us-east-1',
        userPoolId: 'us-east-1_w2KY1GZOX',
        appClientId: '7p5doeoirq2115i5lia62mvl6e',
        lambdaEndpoint: 'https://d4lk5hgk6b.execute-api.us-east-1.amazonaws.com/dev',
        s3BucketUrl: 'https://speak2-cdn-dev.s3.amazonaws.com',
        profileImageBucketUrl: 'https://dev-speak2-user-profile-images.s3.amazonaws.com',
    },
};
