import React, { useContext, useEffect, useState } from 'react';
import "./style.less";
import { Button, Dimmer, Input, Item, ItemContent, ItemDescription, ItemGroup, ItemImage, Label, Loader, Modal } from 'semantic-ui-react';
import sendToast from '../../../utils/Toast';
import { listResidents } from '../../services/Resident';
import UserContext from '../../../contexts/UserContext';
import { Registrant } from '../../types/Registrant';
import UserDirectoryView from '../../components/ResidentSocial';

interface ResidentResponse {
    Residents: Registrant[];
    TotRecords: number;
}

const ResidentSocial = () => {
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const activeResident = listOfResidents[activeRegistrantsIndexes[0]]; // get the first resident from the list of active residents because neighbors web only supports the resident who is logged in and we can't deselect or change it.

    const [residents, setResidents] = useState<Registrant[]>([]);
    const [totRecords, setTotRecords] = useState(0);
    const [showLoadMore, setShowLoadMore] = useState(false);
    const [page_no, setPageNo] = useState(1);
    const [searchKey, setSearchKey] = useState('');
    const [searchedKey, setSearchedKey] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedResident, setSelectedResident] = useState<any>(null);
    const page_size = 40;

    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const residentResponse: ResidentResponse = await listResidents({ facilityId: activeResident.Facility, search: undefined, page_no: 1, page_size });
                setResidentResponse(residentResponse);
            } catch (error) {
                sendToast('error', error);
            } finally {
                setIsLoading(false);
            }
        }
        )();
    }, []);

    useEffect(() => {
        if (residents.length < totRecords) {
            setShowLoadMore(true);
        } else {
            setShowLoadMore(false);
        }
    }, [residents, totRecords]);

    const setResidentResponse = (residentResponse: ResidentResponse, isSearch = false) => {
        const { Residents, TotRecords }: ResidentResponse = residentResponse;
        Residents.forEach(async (resident: Registrant) => {
            if (resident.ResidentInterests && Object.keys(resident.ResidentInterests).length > 0) {
                const residentInterests = getResidentInterest(resident.ResidentInterests);
                resident.ResidentInterestsString = residentInterests + ".";
            }
        });
        if (isSearch) {
            setResidents([...Residents]);
        } else {
            setResidents([...residents, ...Residents]);
        }
        setTotRecords(TotRecords);
    };

    function getResidentInterest(interests: Object) {
        const residentInterests: string[] = [];
        function collectEndItems(obj: Object | Array<Object | string>) {
            if (Array.isArray(obj)) {
                obj.forEach(item => collectEndItems(item));
            } else if (typeof obj === 'object' && obj !== null) {
                Object.values(obj).forEach(value => collectEndItems(value));
            } else {
                residentInterests.push(obj);
            }
        }
        collectEndItems(interests);
        return residentInterests.join(', ');
    }

    const handleLoadMore = async () => {
        try {
            setIsLoading(true);
            const residentResponse: ResidentResponse = await listResidents({ facilityId: activeResident.Facility, search: searchedKey, page_no: page_no + 1, page_size });
            setResidentResponse(residentResponse);
            setPageNo(page_no + 1);
        } catch (error) {
            sendToast('error', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSearch = async () => {
        try {
            setIsLoading(true);
            setResidents([]);
            setTotRecords(0);
            setPageNo(1);
            setSearchedKey(searchKey);
            const residentResponse: ResidentResponse = await listResidents({ facilityId: activeResident.Facility, search: searchKey, page_no: 1, page_size });
            setResidentResponse(residentResponse, true);
        } catch (error) {
            sendToast('error', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleListItemClick = (resident) => {
        setSelectedResident(resident);
        setShowModal(true);
    };

    return (
        <div className='directory-container'>
            <div>
                <h1 className='heading'>Directory</h1>
            </div>
            <div className='search'>
                <Input
                    icon='search'
                    placeholder='Search...'
                    value={searchKey}
                    onChange={(e, data) => { setSearchKey(data.value); }}
                    action={{
                        icon: 'search',
                        onClick: handleSearch
                    }}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                />
            </div>
            <div className='division-one'>
                <Dimmer active={isLoading} inverted>
                    <Loader>Loading</Loader>
                </Dimmer>
                <ItemGroup className="directory-items">
                    {residents.map((item: Registrant) => {
                        return (
                            <UserDirectoryView item={item} handleListItemClick={handleListItemClick} />
                        );
                    })}
                </ItemGroup>
                {
                    showLoadMore && <div className='load-more-button'>
                        <Button onClick={handleLoadMore}>
                            Load More
                        </Button>
                    </div>
                }
            </div>
            {selectedResident && (
                <Modal size='small' centered open={showModal} onClose={() => setShowModal(false)}>
                    <Modal.Header>Resident Details</Modal.Header>
                    <Modal.Content>
                        <Modal.Description>
                            <Item className="resident-modal-item">
                                <div className='resident-modal-image'>
                                    <ItemImage circular size='small' src={selectedResident.Image || "/avatar_placeholder.png"} />
                                </div>
                                <ItemContent className='resident-modal-content'>
                                    <div className='desc-item'>
                                        <Label basic > Name: </Label>
                                        <ItemDescription> {(selectedResident.FirstName || "") + " " + (selectedResident.LastName || "")} </ItemDescription>
                                    </div>
                                    <div className='desc-item'>
                                        <Label basic > About: </Label>
                                        <ItemDescription> {selectedResident.SpecialHandling || "-"} </ItemDescription>
                                    </div>
                                    <div className='desc-item'>
                                        <Label basic > Apartment: </Label>
                                        <ItemDescription> {selectedResident.UnitName || "N/A"} </ItemDescription>
                                    </div>
                                    <div className='desc-item'>
                                        <Label basic > Interests: </Label>
                                        <ItemDescription> {selectedResident.ResidentInterestsString || "-"} </ItemDescription>
                                    </div>
                                    <div className='desc-item'>
                                        <Label basic > Email: </Label>
                                        <ItemDescription>{selectedResident.Email ? (
                                            <a href={`mailto:${selectedResident.Email}`}
                                                target="_blank"
                                                rel="noopener noreferrer">{selectedResident.Email}</a>
                                        ) : "N/A"}</ItemDescription>
                                    </div>
                                    <div className='desc-item'>
                                        <Label basic > Phone: </Label>
                                        <ItemDescription> {selectedResident.Cell || "N/A"} </ItemDescription>
                                    </div>
                                </ItemContent>
                            </Item>
                        </Modal.Description>
                    </Modal.Content>
                </Modal>
            )}
        </div>
    );
};

export default ResidentSocial;