import React, { FC } from 'react';
import { Message } from 'semantic-ui-react';
import { SidebarIcons } from '../../../types/sidebar';
import "./style.less";
interface Props {
    white: boolean;
    name: SidebarIcons;
    smallSize?: true;
}
const SidebarIcon: FC<Props> = ({ name, white, smallSize }) => {
    const mainIconColor = "#2987CD";

    const iconColor = white ? "white" : mainIconColor;
    if (name === "menu") {
        return <i style={{ color: iconColor, fontSize: '32px' }} className="fa-thin fa-plate-utensils" />;
    }

    if (name === "calendar") {
        return <i style={{ color: iconColor, fontSize: '32px' }} className="fa-thin fa-calendar" />;
    }

    if (name === "communityNews") {
        return <i style={{ color: iconColor, fontSize: '32px' }} className="fa-thin fa-newspaper" />;
    }

    if (name === "announcements") {
        return <i style={{ color: iconColor, fontSize: '32px' }} className="fa-thin fa-bullhorn" />;
    }

    if (name === "notifications") {
        return <i style={{ color: iconColor, fontSize: '32px' }} className="fa-thin fa-note-sticky" />;
    }

    if (name === "orderStatus") {
        return <i style={{ color: iconColor, fontSize: '32px' }} className="fa-thin fa-bell-concierge" />;
    }

    if (name === "groups") {
        return <i style={{ color: iconColor, fontSize: '32px' }} className="fa-thin fa-people-simple" />;
    }

    if (name === "documents") {
        return <i style={{ color: iconColor, fontSize: '32px' }} className="fa-thin fa-file-lines" />;
    }

    return (
        <Message error> Please provide a correct icon name</Message>
    );
};

export default SidebarIcon;