import momentTZLib from 'moment-timezone';
/**
 * Formats a date using the specified timezone and format string.
 * If no timezone is provided, it formats the date in the user's local timezone.
 * 
 * @param {Date|string|number} date - The date to format. Can be a Date object, a string, or a timestamp.
 * @param {string} formatString - The format string to use for formatting the date.
 * @param {string} [timezone] - Coverts the date to the specified timezone before formatting.
 * @returns {string} The formatted date. eg 2023-12-08T15:59:59
 */

export const formatDateWithTZ = (date: string | number, formatString: string, timezone: string): string => {
    const parsedDate = momentTZLib.tz(date, timezone);
    return parsedDate.format(formatString);
};