import React, { Component, useState, useEffect } from 'react';
import { Form, Dropdown, Message, Select, Flag } from 'semantic-ui-react';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { getCountries, getCountryCallingCode } from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';
import './style.less';

const CountrySelectDropDown = React.memo(({ onChange, country: value, isDisabled = false }) => {
    const countries = [
        { name: 'Afghanistan', countryCode: 'af' },
        { name: 'Aland Islands', countryCode: 'ax' },
        { name: 'Albania', countryCode: 'al' },
        { name: 'Algeria', countryCode: 'dz' },
        { name: 'American Samoa', countryCode: 'as' },
        { name: 'Andorra', countryCode: 'ad' },
        { name: 'Angola', countryCode: 'ao' },
        { name: 'Anguilla', countryCode: 'ai' },
        { name: 'Antigua', countryCode: 'ag' },
        { name: 'Argentina', countryCode: 'ar' },
        { name: 'Armenia', countryCode: 'am' },
        { name: 'Aruba', countryCode: 'aw' },
        { name: 'Australia', countryCode: 'au' },
        { name: 'Austria', countryCode: 'at' },
        { name: 'Azerbaijan', countryCode: 'az' },
        { name: 'Bahamas', countryCode: 'bs' },
        { name: 'Bahrain', countryCode: 'bh' },
        { name: 'Bangladesh', countryCode: 'bd' },
        { name: 'Barbados', countryCode: 'bb' },
        { name: 'Belarus', countryCode: 'by' },
        { name: 'Belgium', countryCode: 'be' },
        { name: 'Belize', countryCode: 'bz' },
        { name: 'Benin', countryCode: 'bj' },
        { name: 'Bermuda', countryCode: 'bm' },
        { name: 'Bhutan', countryCode: 'bt' },
        { name: 'Bolivia', countryCode: 'bo' },
        { name: 'Bosnia', countryCode: 'ba' },
        { name: 'Botswana', countryCode: 'bw' },
        { name: 'Bouvet Island', countryCode: 'bv' },
        { name: 'Brazil', countryCode: 'br' },
        { name: 'British Virgin Islands', countryCode: 'vg' },
        { name: 'Brunei', countryCode: 'bn' },
        { name: 'Bulgaria', countryCode: 'bg' },
        { name: 'Burkina Faso', countryCode: 'bf' },
        { name: 'Burma', countryCode: 'mm', alias: 'Myanmar' },
        { name: 'Burundi', countryCode: 'bi' },
        { name: 'Caicos Islands', countryCode: 'tc' },
        { name: 'Cambodia', countryCode: 'kh' },
        { name: 'Cameroon', countryCode: 'cm' },
        { name: 'Canada', countryCode: 'ca' },
        { name: 'Cape Verde', countryCode: 'cv' },
        { name: 'Cayman Islands', countryCode: 'ky' },
        { name: 'Central African Republic', countryCode: 'cf' },
        { name: 'Chad', countryCode: 'td' },
        { name: 'Chile', countryCode: 'cl' },
        { name: 'China', countryCode: 'cn' },
        { name: 'Christmas Island', countryCode: 'cx' },
        { name: 'Cocos Islands', countryCode: 'cc' },
        { name: 'Colombia', countryCode: 'co' },
        { name: 'Comoros', countryCode: 'km' },
        { name: 'Congo', countryCode: 'cd' },
        { name: 'Congo Brazzaville', countryCode: 'cg' },
        { name: 'Cook Islands', countryCode: 'ck' },
        { name: 'Costa Rica', countryCode: 'cr' },
        { name: 'Cote Divoire', countryCode: 'ci' },
        { name: 'Croatia', countryCode: 'hr' },
        { name: 'Cuba', countryCode: 'cu' },
        { name: 'Cyprus', countryCode: 'cy' },
        { name: 'Czech Republic', countryCode: 'cz' },
        { name: 'Denmark', countryCode: 'dk' },
        { name: 'Djibouti', countryCode: 'dj' },
        { name: 'Dominica', countryCode: 'dm' },
        { name: 'Dominican Republic', countryCode: 'do' },
        { name: 'Ecuador', countryCode: 'ec' },
        { name: 'Egypt', countryCode: 'eg' },
        { name: 'El Salvador', countryCode: 'sv' },
        { name: 'Equatorial Guinea', countryCode: 'gq' },
        { name: 'Eritrea', countryCode: 'er' },
        { name: 'Estonia', countryCode: 'ee' },
        { name: 'Ethiopia', countryCode: 'et' },
        { name: 'Europeanunion', countryCode: 'eu' },
        { name: 'Falkland Islands', countryCode: 'fk' },
        { name: 'Faroe Islands', countryCode: 'fo' },
        { name: 'Fiji', countryCode: 'fj' },
        { name: 'Finland', countryCode: 'fi' },
        { name: 'France', countryCode: 'fr' },
        { name: 'French Guiana', countryCode: 'gf' },
        { name: 'French Polynesia', countryCode: 'pf' },
        { name: 'French Territories', countryCode: 'tf' },
        { name: 'Gabon', countryCode: 'ga' },
        { name: 'Gambia', countryCode: 'gm' },
        { name: 'Georgia', countryCode: 'ge' },
        { name: 'Germany', countryCode: 'de' },
        { name: 'Ghana', countryCode: 'gh' },
        { name: 'Gibraltar', countryCode: 'gi' },
        { name: 'Greece', countryCode: 'gr' },
        { name: 'Greenland', countryCode: 'gl' },
        { name: 'Grenada', countryCode: 'gd' },
        { name: 'Guadeloupe', countryCode: 'gp' },
        { name: 'Guam', countryCode: 'gu' },
        { name: 'Guatemala', countryCode: 'gt' },
        { name: 'Guinea', countryCode: 'gn' },
        { name: 'Guinea-Bissau', countryCode: 'gw' },
        { name: 'Guyana', countryCode: 'gy' },
        { name: 'Haiti', countryCode: 'ht' },
        { name: 'Heard Island', countryCode: 'hm' },
        { name: 'Honduras', countryCode: 'hn' },
        { name: 'Hong Kong', countryCode: 'hk' },
        { name: 'Hungary', countryCode: 'hu' },
        { name: 'Iceland', countryCode: 'is' },
        { name: 'India', countryCode: 'in' },
        { name: 'Indian Ocean Territory', countryCode: 'io' },
        { name: 'Indonesia', countryCode: 'id' },
        { name: 'Iran', countryCode: 'ir' },
        { name: 'Iraq', countryCode: 'iq' },
        { name: 'Ireland', countryCode: 'ie' },
        { name: 'Israel', countryCode: 'il' },
        { name: 'Italy', countryCode: 'it' },
        { name: 'Jamaica', countryCode: 'jm' },
        { name: 'Jan Mayen', countryCode: 'sj', alias: 'Svalbard' },
        { name: 'Japan', countryCode: 'jp' },
        { name: 'Jordan', countryCode: 'jo' },
        { name: 'Kazakhstan', countryCode: 'kz' },
        { name: 'Kenya', countryCode: 'ke' },
        { name: 'Kiribati', countryCode: 'ki' },
        { name: 'Kuwait', countryCode: 'kw' },
        { name: 'Kyrgyzstan', countryCode: 'kg' },
        { name: 'Laos', countryCode: 'la' },
        { name: 'Latvia', countryCode: 'lv' },
        { name: 'Lebanon', countryCode: 'lb' },
        { name: 'Lesotho', countryCode: 'ls' },
        { name: 'Liberia', countryCode: 'lr' },
        { name: 'Libya', countryCode: 'ly' },
        { name: 'Liechtenstein', countryCode: 'li' },
        { name: 'Lithuania', countryCode: 'lt' },
        { name: 'Luxembourg', countryCode: 'lu' },
        { name: 'Macau', countryCode: 'mo' },
        { name: 'Macedonia', countryCode: 'mk' },
        { name: 'Madagascar', countryCode: 'mg' },
        { name: 'Malawi', countryCode: 'mw' },
        { name: 'Malaysia', countryCode: 'my' },
        { name: 'Maldives', countryCode: 'mv' },
        { name: 'Mali', countryCode: 'ml' },
        { name: 'Malta', countryCode: 'mt' },
        { name: 'Marshall Islands', countryCode: 'mh' },
        { name: 'Martinique', countryCode: 'mq' },
        { name: 'Mauritania', countryCode: 'mr' },
        { name: 'Mauritius', countryCode: 'mu' },
        { name: 'Mayotte', countryCode: 'yt' },
        { name: 'Mexico', countryCode: 'mx' },
        { name: 'Micronesia', countryCode: 'fm' },
        { name: 'Moldova', countryCode: 'md' },
        { name: 'Monaco', countryCode: 'mc' },
        { name: 'Mongolia', countryCode: 'mn' },
        { name: 'Montenegro', countryCode: 'me' },
        { name: 'Montserrat', countryCode: 'ms' },
        { name: 'Morocco', countryCode: 'ma' },
        { name: 'Mozambique', countryCode: 'mz' },
        { name: 'Namibia', countryCode: 'na' },
        { name: 'Nauru', countryCode: 'nr' },
        { name: 'Nepal', countryCode: 'np' },
        { name: 'Netherlands', countryCode: 'nl' },
        { name: 'Netherlandsantilles', countryCode: 'an' },
        { name: 'New Caledonia', countryCode: 'nc' },
        { name: 'New Guinea', countryCode: 'pg' },
        { name: 'New Zealand', countryCode: 'nz' },
        { name: 'Nicaragua', countryCode: 'ni' },
        { name: 'Niger', countryCode: 'ne' },
        { name: 'Nigeria', countryCode: 'ng' },
        { name: 'Niue', countryCode: 'nu' },
        { name: 'Norfolk Island', countryCode: 'nf' },
        { name: 'North Korea', countryCode: 'kp' },
        { name: 'Northern Mariana Islands', countryCode: 'mp' },
        { name: 'Norway', countryCode: 'no' },
        { name: 'Oman', countryCode: 'om' },
        { name: 'Pakistan', countryCode: 'pk' },
        { name: 'Palau', countryCode: 'pw' },
        { name: 'Palestine', countryCode: 'ps' },
        { name: 'Panama', countryCode: 'pa' },
        { name: 'Paraguay', countryCode: 'py' },
        { name: 'Peru', countryCode: 'pe' },
        { name: 'Philippines', countryCode: 'ph' },
        { name: 'Pitcairn Islands', countryCode: 'pn' },
        { name: 'Poland', countryCode: 'pl' },
        { name: 'Portugal', countryCode: 'pt' },
        { name: 'Puerto Rico', countryCode: 'pr' },
        { name: 'Qatar', countryCode: 'qa' },
        { name: 'Reunion', countryCode: 're' },
        { name: 'Romania', countryCode: 'ro' },
        { name: 'Russia', countryCode: 'ru' },
        { name: 'Rwanda', countryCode: 'rw' },
        { name: 'Saint Helena', countryCode: 'sh' },
        { name: 'Saint Kitts and Nevis', countryCode: 'kn' },
        { name: 'Saint Lucia', countryCode: 'lc' },
        { name: 'Saint Pierre', countryCode: 'pm' },
        { name: 'Saint Vincent', countryCode: 'vc' },
        { name: 'Samoa', countryCode: 'ws' },
        { name: 'San Marino', countryCode: 'sm' },
        { name: 'Sandwich Islands', countryCode: 'gs' },
        { name: 'Sao Tome', countryCode: 'st' },
        { name: 'Saudi Arabia', countryCode: 'sa' },
        { name: 'Scotland', countryCode: 'gb sct' },
        { name: 'Senegal', countryCode: 'sn' },
        { name: 'Serbia', countryCode: 'cs' },
        { name: 'Serbia', countryCode: 'rs' },
        { name: 'Seychelles', countryCode: 'sc' },
        { name: 'Sierra Leone', countryCode: 'sl' },
        { name: 'Singapore', countryCode: 'sg' },
        { name: 'Slovakia', countryCode: 'sk' },
        { name: 'Slovenia', countryCode: 'si' },
        { name: 'Solomon Islands', countryCode: 'sb' },
        { name: 'Somalia', countryCode: 'so' },
        { name: 'South Africa', countryCode: 'za' },
        { name: 'South Korea', countryCode: 'kr' },
        { name: 'Spain', countryCode: 'es' },
        { name: 'Sri Lanka', countryCode: 'lk' },
        { name: 'Sudan', countryCode: 'sd' },
        { name: 'Suriname', countryCode: 'sr' },
        { name: 'Swaziland', countryCode: 'sz' },
        { name: 'Sweden', countryCode: 'se' },
        { name: 'Switzerland', countryCode: 'ch' },
        { name: 'Syria', countryCode: 'sy' },
        { name: 'Taiwan', countryCode: 'tw' },
        { name: 'Tajikistan', countryCode: 'tj' },
        { name: 'Tanzania', countryCode: 'tz' },
        { name: 'Thailand', countryCode: 'th' },
        { name: 'Timorleste', countryCode: 'tl' },
        { name: 'Togo', countryCode: 'tg' },
        { name: 'Tokelau', countryCode: 'tk' },
        { name: 'Tonga', countryCode: 'to' },
        { name: 'Trinidad', countryCode: 'tt' },
        { name: 'Tunisia', countryCode: 'tn' },
        { name: 'Turkey', countryCode: 'tr' },
        { name: 'Turkmenistan', countryCode: 'tm' },
        { name: 'Tuvalu', countryCode: 'tv' },
        { name: 'U.A.E.', countryCode: 'ae', alias: 'United Arab Emirates' },
        { name: 'Uganda', countryCode: 'ug' },
        { name: 'Ukraine', countryCode: 'ua' },
        { name: 'United Kingdom', countryCode: 'gb', alias: 'uk' },
        { name: 'United States', countryCode: 'us', alias: 'America' },
        { name: 'Uruguay', countryCode: 'uy' },
        { name: 'US Minor Islands', countryCode: 'um' },
        { name: 'US Virgin Islands', countryCode: 'vi' },
        { name: 'Uzbekistan', countryCode: 'uz' },
        { name: 'Vanuatu', countryCode: 'vu' },
        { name: 'Vatican City', countryCode: 'va' },
        { name: 'Venezuela', countryCode: 've' },
        { name: 'Vietnam', countryCode: 'vn' },
        { name: 'Wales', countryCode: 'gb wls' },
        { name: 'Wallis and Futuna', countryCode: 'wf' },
        { name: 'Western Sahara', countryCode: 'eh' },
        { name: 'Yemen', countryCode: 'ye' },
        { name: 'Zambia', countryCode: 'zm' },
        { name: 'Zimbabwe', countryCode: 'zw' },
    ];

    return (
        <select
            value={value || 'US'}
            onChange={event => onChange(event.target.value || undefined)}
            disabled={isDisabled}
        >
            {countries.map(({ countryCode }) => (
                <option key={countryCode} value={countryCode.toUpperCase()}>
                    {/* +{getCountryCallingCode(country)} */}
                    {/* <Flag name={countryCode} /> */}
                    {countryCode.toUpperCase()}
                </option>
            ))}
        </select>
    );

    // const options = countries.map(({countryCode}) => ({
    //     key: countryCode,
    //     value: countryCode,
    //     flag: countryCode,
    // }))

    // return <Select lazyLoad fluid options={options} value={value} onChange={e => onChange(e.target.value || 'US')} />;
});

/**
 * Contact is a one time thing. If anything will be used to instantiate the local behaviour
 * We will keep track of the states inside, and only output either the email or a e164 number
 *
 */

const ContactInput = ({ contact = '', onChange, isDisabled = false, error = '', autoComplete = 'on' }) => {
    const [showDefaultCountryCode, setShowDefaultCountryCode] = useState(false);
    const [userContact, setUserContact] = useState('');
    const [countryCode, setCountryCode] = useState('US');

    useEffect(() => {
        // const e164RegExp = new RegExp(/^\+[1-9]\d{1,14}$/g);
        // const nationalNumber = new RegExp(/^[1-9]\d{1,14}$/g);

        // const isNumberE164 = e164RegExp.test(contact);
        // const isNumberNationalNumber = nationalNumber.test(contact);

        // if (isNumberE164) {
        //     onChange(contact);
        //     setShowDefaultCountryCode(false);
        // } else if (isNumberNationalNumber) {
        //     onChange(`+1${contact}`);
        // } else {
        //     onChange(contact);
        //     setShowDefaultCountryCode(false);
        // }

        const numberRegExp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g);

        const isNumber = numberRegExp.test(contact);

        if (contact && isNumber && contact.length > 1) {
            // basically,
            // show the national number
            // show the country
            const resp = parsePhoneNumber(contact);
            if (resp) {
                const { number, country, nationalNumber } = resp;
                onChange(number);
                setCountryCode(country);
                setUserContact(nationalNumber);
            } else {
                onChange(contact);
                setUserContact(contact);
            }
            setShowDefaultCountryCode(true);
        } else {
            // here will be the default country code
            onChange(contact);
            setUserContact(contact);
            setShowDefaultCountryCode(false);
        }
    }, [isDisabled, contact]);

    // const updateContactType = type => {
    //     localStorage.setItem('contactType', type);
    //     onChange('');
    //     setContactType(type);
    // };

    // const onCountryChange = country => {
    //     localStorage.setItem('countryCode', country);
    //     setCountryCode(country);
    // };

    const changeValue = (value, cc) => {
        const numberRegExp = new RegExp(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g);

        const isNumber = numberRegExp.test(value);

        if (value && isNumber && value.length > 1) {
            const resp = parsePhoneNumber(value, cc);
            if (resp) {
                const { number, country } = resp;
                onChange(number);
                if (country !== cc) {
                    setCountryCode(country);
                }
                setUserContact(value);
            } else {
                onChange(value);
                setUserContact(value);
            }

            setShowDefaultCountryCode(true);
        } else {
            onChange(value);
            setUserContact(value);

            setShowDefaultCountryCode(false);
            setCountryCode('US');
        }
    };

    return (
        <>
            <Form.Field className="invite-container ml-8">
                <div>
                    {showDefaultCountryCode && (
                        <CountrySelectDropDown
                            onChange={e => {
                                setCountryCode(e);
                                changeValue(userContact, e);
                            }}
                            isDisabled={isDisabled}
                            country={countryCode}
                        ></CountrySelectDropDown>
                    )}
                </div>
                <Form.Input
                    value={userContact}
                    type={'text'}
                    name="contact"
                    className="full-width"
                    placeholder={
                        process.env.REACT_APP_PLATFORM === 'community'
                        ?   "Cell"
                        :   "Email or Phone Number"   
                    }
                    required
                    disabled={isDisabled}
                    autoComplete={autoComplete}
                    autoCapitalize="none"
                    onChange={e => changeValue(e.target.value, countryCode)}
                />
            </Form.Field>
            {error && <Message header={error} error size="mini" />}
        </>
    );
};

export default React.memo(ContactInput);
