import React, { useEffect, FC, useState } from 'react';
import { Route, Switch, withRouter, Redirect, RouteComponentProps } from 'react-router-dom';
import { Dimmer, Grid, Loader, Message, Segment, Sidebar } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';

import UserContext from './contexts/UserContext';
import RedirectContext from './contexts/RedirectContext';
import PrivateRoute from './components/components/privateRouteComponent';
import LogIn from './components/pages/login';
import ForgotPasswordPage from './components/pages/forgot-password';
import ChangeForgotPasswordPage from './components/pages/change-forgotten-password';
import ProfileSettings from './components/pages/profile-settings';
import News from './components/pages/news';
import FamilyAndFriends from './components/pages/family_friends';
import RelativeDetail from './components/components/relativeDetail';
import Settings from './components/pages/settings';
import Invites from './components/pages/invites';
import MessageRecipients from './components/pages/messageRecipients';
import MessageRecipientDetails from './components/pages/messageRecipientDetails';
import CreateMessageRecipient from './components/pages/createMessageRecipient/sso';
import Messages from './components/pages/messages';
import MessageReplyDetails from './components/pages/messages/messageDetails';
import Downloads from './components/pages/downloads';
import CommunityDownloads from './components/pages/communityDownloads';
import Activities from './components/pages/activities';
import ActivityDetail from './components/components/ActivityDetail';
import ArchivedActivities from './components/pages/archivedActivities';
import RequestDetail from './components/components/RequestDetail';
import Header from './components/components/header';
import Signup from './components/pages/signup';
import SignUpComponent from './components/components/signUpComponent'
import CommunityActivities from './components/pages/communityActivities';
import CommunityMenus from './components/pages/communityMenus';
import CommunityNotifications from './components/pages/communityNotifications';
import AutoLogin from './components/pages/auto-login';
import SSOAlexaUser from './components/pages/sso';
import SSORedirect from './components/pages/sso/redirect';
import Checkout from './components/pages/checkout';
import PublicCheckout from './components/pages/checkout/public';
import CheckoutSuccess from './components/pages/checkout/public/success';
import AddressBookDetails from './components/pages/address-book-details'
import AddressBookContactDetails from './components/pages/address-book-contacts-details'
import Support from './components/pages/support'

import { Auth } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import Reminders from './components/pages/reminders';
import * as Database from './services/db';
import AlexaLinkingLogin from './components/pages/alexa-linking';

import Payments from './components/pages/checkout/payment';

import AcceptAlexaInvites from './components/pages/accept-alexa-invites';

import './App.css';
import 'semantic-ui-less/semantic.less';
import 'react-toastify/dist/ReactToastify.css';
import { Steps, Hints } from 'intro.js-react';
import 'intro.js/introjs.css';
import LinkResidentList from './components/pages/link-resident-list';
import InviteAlexaUser from './components/pages/invite-alexa-user';
import AlexaInviteSSORedirect from './components/pages/accept-alexa-invites/redirect';
import AlexaUserInvitationAcceptPage from './components/pages/accept-alexa-invites/new-ui';
import FNFInviteeSignup from './components/pages/fnfInviteeSignup';
import StaffEnteredDetail from './components/components/staffEnteredDetail';
import CustomSidebar from './components/components/CustomSidebar/CustomSidebar';
import './assets/fontawesome/css/fontawesome.css'; // parent css class required for child css to work
import './assets/fontawesome/css/light.css'; // import the relevant child css classes manually according to usage e.g fa-thin, fa-sharp etc.
import './assets/fontawesome/css/thin.css';
import './assets/fontawesome/css/sharp-light.css';
import './assets/fontawesome/css/solid.css';
import Documents from './neighbors/pages/documents';
import OrderStatus from './neighbors/pages/order-status';
import CreateOrder from './neighbors/pages/order-status/create';
import MyProfile from './neighbors/pages/my-profile';
import ResidentSocial from './neighbors/pages/resident-social';
import Groups from './neighbors/pages/groups';
import { neighborsLogoPath } from './utils/logoPaths';
import Announcements from './neighbors/pages/announcements';
import CommunityNews from './neighbors/pages/community-news';
import Calendar from './neighbors/pages/calendar';
import Dining from './neighbors/pages/dining';
import MenuPageForRestaurant from './neighbors/components/Dining/MenuPageForRestaurant';
import OrderBookingPage from './neighbors/components/Dining/OrderBookingPage';
import Menu from './neighbors/pages/menu';
import { CartProvider } from './neighbors/contexts/cart/Provider';
interface Props extends RouteComponentProps {}

interface userObjectInterface {
    cognitoUser: any;
    profile: any;
    registrants: any;
    username: any;
    authenticated: boolean;
}

const uninitiatedUserObject: userObjectInterface = {
    cognitoUser: {},
    profile: undefined,
    registrants: {
        list: [],
        activeRegistrantsIndexes: [],
    },
    username: undefined,
    authenticated: false,
};

const steps = [
    {
        element: '.feed-parent',
        intro: 'Welcome to your Feed. View all messages, requests, and activity of your Alexa Users here. Filter to view indvidual features and even download reports. If you accept a request, it will be added to your “My Requests” row.',
        position: 'top',
    },
    {
        element: '.Feedbutton',
        intro: 'Your feed. View messages, activities, and requests here',
        tooltipClass: 'feed-button-offset',
    },
    {
        element: '.Messagesbutton',
        intro: 'Text-style view of messages. See entire history conversations. ',
    },
    {
        element: '.Remindersbutton',
        intro: 'Set and manage reminders for your Alexa Users.',
    },
    {
        element: '.Send.Messagebutton',
        intro: 'Send individual or group messages directly',
    },
    {
        element: '.Familybutton',
        intro: 'Create your Circle of Trust. Add friends, family, and more via text or email. ',
    },
    {
        element: '.Alexa.Usersbutton',
        intro: 'Connect or add Alexa user(s). Sign in with their Amazon account',
    },
    {
        element: '.Settingsbutton',
        intro: 'Adjust your app settings including Notifications and message archives.',
        tooltipClass: 'feed-button-offset',
    },
    {
        element: '.resident-links',
        intro: 'The top row are your Alexa users. Select one, mutiple, or all to interact with',
        tooltipClass: 'resident-links-tooltip',
    },
];

const App: FC<Props> = (props) => {
    const [userObject, setUserObject] = useState(uninitiatedUserObject);
    const [loading, setLoading] = useState<boolean>(true);
    const [credentials, setCredentials] = useState({});
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [showSidebarProp, setShowSidebarProp] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);

    const setUser = (userObject) => {
        setUserObject(userObject);
    };

    const toggleRedirect = (redirect) => {
        props.history.push(redirect);
    };

    const isManager = () => {
        if (userObject && userObject.registrants) {
            return userObject.registrants.list.find((registrant) => registrant.manager);
        }
    };

    const isCommunityManager = () => {
        if (userObject && userObject.registrants) {
            if (process.env.REACT_APP_PLATFORM === 'community') {
                const managedResident = userObject.registrants.list.find((registrant) => {
                    return registrant.Facility && (registrant.manager || registrant.isCommunityManager)
                })
                if (managedResident) {
                    return true
                }
                return false
            }
            return userObject.registrants.list.find((registrant) => registrant.isCommunityManager);
        }
    };

    const reloadRegistrantLists = async () => {
        const connectedRegistrants = await Database.getRelatedRegistrants();

        let activeRegistrantsIndexes =
            (userObject.registrants &&
                userObject.registrants.activeRegistrantsIndexes &&
                userObject.registrants.activeRegistrantsIndexes[0]) ||
            0;
        if (process.env.REACT_APP_PLATFORM === 'neighbors') { // If the env is neighbors, then set active registrant to the managed resident
            const managedResident = userObject.registrants ? userObject.registrants.list.find((registrant) => registrant.manager) : null;
            const indexOfManagedResident = managedResident ? userObject.registrants.list.indexOf(managedResident) : 0;
            activeRegistrantsIndexes = indexOfManagedResident;
        }

        const registrants = {
            list: connectedRegistrants.residents,
            activeRegistrantsIndexes:
                connectedRegistrants.residents && connectedRegistrants.residents.length
                    ? [activeRegistrantsIndexes]
                    : [],
        };
        setUserObject({
            ...userObject,
            registrants,
        });
    };

    useEffect(() => { // Conditionally render browser's title and icon based on the community and neighbors web
        const title = document.getElementById('pageTitle');
        if (title && process.env.REACT_APP_PLATFORM === 'neighbors') {
            title.innerText = 'Speak2 Neighbors';
        }
        const largeIcon = document.getElementById('largeRelIcon') as HTMLLinkElement | null;
        const smallIcon = document.getElementById('smallRelIcon') as HTMLLinkElement | null;
        if (largeIcon && process.env.REACT_APP_PLATFORM === 'neighbors') {
            largeIcon.href = neighborsLogoPath;
        }
        if (smallIcon && process.env.REACT_APP_PLATFORM === 'neighbors') {
            smallIcon.href = neighborsLogoPath;
        }
    }, [])

    useEffect(() => {
        let cognitoUser;
        Auth.currentAuthenticatedUser({
            bypassCache: true,
        })
            .then((response) => {
                if (response.attributes && response.attributes['custom:accountType'] !== 'relative') {
                    return Auth.signOut();
                } else if (response !== 'not authenticated') {
                    cognitoUser = response;
                    return Database.getUserDbProfile();
                } else {
                    setLoading(false);
                    if (props.history.location.pathname !== '/login') {
                        toggleRedirect(props.history.location.pathname + props.history.location.search);
                    }
                    return;
                }
            })
            .then(async (profileResponse) => {
                const profile = profileResponse.userProfile;
                const connectedRegistrants = await Database.getRelatedRegistrants();
                const userObject = {
                    cognitoUser,
                    profile,
                    username: profileResponse.Username,
                    registrants: {
                        list: connectedRegistrants.residents,
                        activeRegistrantsIndexes:
                            connectedRegistrants.residents && connectedRegistrants.residents.length ? [0] : [],
                    },
                    authenticated: true,
                };
                if (process.env.REACT_APP_PLATFORM === 'neighbors') {
                    const managedResident = userObject && userObject.registrants && userObject.registrants.list && userObject.registrants.list.find((registrant) => {
                        return registrant.Facility && (registrant.manager || registrant.isCommunityManager)
                    })
                    if (managedResident) {  // If the env is neighbors, then set active registrant to the managed resident
                        const indexOfManagedResident = userObject.registrants.list.indexOf(managedResident);
                        userObject.registrants.activeRegistrantsIndexes = [indexOfManagedResident];
                    }
                }
                setUserObject(userObject);
                setLoading(false);
                // if (props.history.location.pathname !== '/login') {
                //   	toggleRedirect(props.history.location.pathname + props.history.location.search);
                // }
                setDataLoaded(true);
            })
            .catch((error) => {
                setLoading(false);
                if (props.history.location.pathname !== '/login') {
                    toggleRedirect(props.history.location.pathname + props.history.location.search);
                }
            });
    }, []);

    useEffect(() => {
        //using data loaded is a precautionary step which might not be necessarily be needed.
        dataLoaded &&
            !localStorage.getItem('feedIntroShown') &&
            props.history.location.pathname === '/activities' &&
            setTimeout(() => {
                window.innerWidth > 600 && setShowSidebarProp(true);
                setStepsEnabled(true);
            }, 200);
    }, [props.history.location.pathname, dataLoaded]);

    useEffect(() => {
        console.log(props.location);
    }, [props.location.pathname]);

    const RoutesComponent = () => {
        if (process.env.REACT_APP_PLATFORM === 'neighbors') {
            /*
                Neighbor's web app routes and components:
                Its different from community and speak2family web app
            */
            /*  
                If the user is Relationship manager to a resident, then show the neighbors interface.
                    Else show a message stating they are not a relationship manager along with the invite screen and the 
                    My profile screen to allow log out
            */
          const isRelationshipManager = isManager()

          if (isRelationshipManager) {
            return (
                <div>
                    <Sidebar.Pushable style={{ marginTop: '0px' }} as={Segment} className="App">
                        <Grid padded style={{ justifyContent: 'center' }}>
                            <Grid.Row columns={userObject.authenticated ? 2 : 1}>
                                {userObject.authenticated && (
                                    <Grid.Column width={2} style={{ padding: '20px 0 0 14px' }}>
                                        <CustomSidebar />
                                    </Grid.Column>
                                )}
                                <Grid.Column
                                    width={userObject.authenticated ? 13 : 15}
                                    style={{ padding: '20px 0 0 0', marginLeft: '15px' }}
                                >
                                    <CartProvider>
                                        <Sidebar.Pusher>
                                            <Switch>
                                                <PrivateRoute path="/documents" component={Documents} />
                                                <PrivateRoute path="/order-status" component={OrderStatus} />
                                                <PrivateRoute path="/create-order" component={CreateOrder} />
                                                <PrivateRoute path="/dining/order" component={OrderBookingPage} />
                                                <PrivateRoute path="/dining/:restaurantId/:menuId"component={OrderBookingPage} />
                                                <PrivateRoute path="/dining/:restaurantId" component={MenuPageForRestaurant} />
                                                <PrivateRoute path="/dining" component={Dining} />
                                                <PrivateRoute path="/manage-profiles" component={MyProfile} />
                                                <PrivateRoute path="/announcements" component={Announcements} />
                                                <PrivateRoute path="/resident-social" component={ResidentSocial} />
                                                <PrivateRoute path="/calendar" component={Calendar} />
                                                <PrivateRoute path="/menu" component={Menu} />
                                                <PrivateRoute path="/community-news" component={CommunityNews} />
                                                <PrivateRoute path="/groups" component={Groups} />
                                                <PrivateRoute path="/invites" component={Invites} />
                                                <PrivateRoute path={"/addressbook/staff/:relativeid"} component={StaffEnteredDetail} />
                                                <PrivateRoute path="/addressbook/:relativeId" component={RelativeDetail} />
                                                <PrivateRoute path="/addressbook" component={FamilyAndFriends} />
                                                <Route
                                                    path="/login/:token?/:registrantId?/:receivedContact?"
                                                    render={
                                                        userObject.authenticated
                                                            ? () => <Redirect to={{ pathname: '/' }} />
                                                            : (props) => <LogIn {...props} />
                                                    }
                                                />
                                                <Route
                                                    path="/forgot-password"
                                                    render={
                                                        userObject.authenticated
                                                            ? () => <Redirect to={{ pathname: '/calendar' }} />
                                                            : (props) => <ForgotPasswordPage {...props} />
                                                    }
                                                />
                                                <Route
                                                    path="/change-forgotten-password"
                                                    render={
                                                        userObject.authenticated
                                                            ? () => <Redirect to={{ pathname: '/calendar' }} />
                                                            : (props) => <ChangeForgotPasswordPage {...props} />
                                                    }
                                                />
                                                <Redirect from="/" to="/calendar" />
                                                <Redirect from="/*" to="/calendar" />
                                                <Route render={() => <h1>404 Not Found</h1>} />
                                            </Switch>
                                        </Sidebar.Pusher>
                                    </CartProvider>   
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Sidebar.Pushable>
                </div>
            );
          } else { // Below is the list of screens accessible to a person who is not a relationship manager. They should not access Neighbors web features and are only allowed to view the specific pages listed below.
            return (
                <Switch>
                    <Route
                        path="/login/:token?/:registrantId?/:receivedContact?"
                        render={
                            userObject.authenticated
                                ? () => <Redirect to={{ pathname: '/' }} />
                                : (props) => <LogIn {...props} />
                        }
                    />
                    <PrivateRoute
                        path="/invites"
                        component={(props) => (
                            <Segment
                                style={{
                                    margin: 'auto',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '80%',
                                }}
                            >
                                <Invites {...props} />
                                <Message info style={{width: '100%', textAlign: 'center'}}>You are not a relationship manager to anyone, please contact support to be invited as a relationship manager</Message>
                            </Segment>
                        )}
                    />
                    <Route
                        path="/forgot-password"
                        render={
                            userObject.authenticated
                                ? () => <Redirect to={{ pathname: '/invites' }} />
                                : (props) => <ForgotPasswordPage {...props} />
                        }
                    />
                    <Route
                        path="/change-forgotten-password"
                        render={
                            userObject.authenticated
                                ? () => <Redirect to={{ pathname: '/invites' }} />
                                : (props) => <ChangeForgotPasswordPage {...props} />
                        }
                    />
                    <Redirect from="/" to="/invites" />
                    <Redirect from="/*" to="/invites" />
                    <Route render={() => <h1>404 Not Found</h1>} />
                </Switch>
            );
          }      
        }

        if (process.env.REACT_APP_PLATFORM === 'community') {
            /*
            Skipping the following routes and components for Speak2 Community Website (as opposed to S2F website):
            Route, Component
            - /settings, Settings
            - /activities/:activityId, ActivityDetail
            - /archives, ArchivedActivities
            - /activities, Activities
            - /reminders, Reminders
            - /alexa-linking, AlexaLinkingLogin
            - /link-resident-list, LinkResidentList
            - /downloads, Downloads
            - /sso, SSOAlexaUser
            - /sso-redirect, SSORedirect
            - /checkout, Checkout
            - /public/checkout/:paymentManagerId, PublicCheckout
            - /payments, Payments
            - /public/checkout-success, CheckoutSuccess
            - /invite-alexa-user, InviteAlexaUser
            - /alexa-invite-sso-redirect, AlexaInviteSSORedirect


            Renaming the following routes for Speak2 Community Website (as opposed to S2F website):
            Old route, New route
            - /news, /send-message
            - /family_friends, /addressbook
            - /community-activities, /activities
            - /community-menus, /menu

            Changes in redirects:
            Route - Old redirect route, Old component - New redirect route, New Component
            - / - /activities, Activities - /send-message, News
            - /* - /activities, Activities - /send-message, News



            */
            
            return (
                <div style={{ maxWidth: "900px", margin: "auto" }}>
                    <Switch>
                        <PrivateRoute path="/send-message" component={News} />
                        <PrivateRoute path={"/addressbook/staff/:relativeid"} component={StaffEnteredDetail} />
                        <PrivateRoute path="/addressbook/:relativeId" component={RelativeDetail} />
                        <PrivateRoute path="/addressbook" component={FamilyAndFriends} />
                        <PrivateRoute path="/profile-settings" component={ProfileSettings} />
                        <PrivateRoute path="/message-recipients/:messageRecipientId/address-book/contacts/:contactId" component={AddressBookContactDetails} />
                        <PrivateRoute path="/message-recipients/:messageRecipientId/address-book" component={AddressBookDetails} />
                        <PrivateRoute path="/message-recipients/create" component={CreateMessageRecipient} />
                        <PrivateRoute
                            path="/message-recipients/:messageRecipientId"
                            component={MessageRecipientDetails}
                        />
                        <PrivateRoute path="/message-recipients" component={MessageRecipients} />
                        <PrivateRoute path="/messages/:messageRecipientId" component={MessageReplyDetails} />
                        <PrivateRoute path="/messages" component={Messages} />
                        <PrivateRoute path="/requests/:requestId" component={RequestDetail} />

                        <PrivateRoute
                            path="/activities"
                            component={
                                isCommunityManager()
                                    ? CommunityActivities
                                    : () => <Redirect to={{ pathname: '/send-message' }} />
                            }
                        />
                        <PrivateRoute
                            path="/menu"
                            component={
                                isCommunityManager()
                                    ? CommunityMenus
                                    : () => <Redirect to={{ pathname: '/send-message' }} />
                            }
                        />
                        <PrivateRoute
                            path="/notifications"
                            component={
                                isCommunityManager()
                                    ? CommunityNotifications
                                    : () => <Redirect to={{ pathname: '/send-message' }} />
                            }
                        />

                        <PrivateRoute
                            path="/support"
                            component={Support}
                        />
                        <Route
                            path="/login/:token?/:registrantId?/:receivedContact?"
                            render={
                                userObject.authenticated
                                    ? () => <Redirect to={{ pathname: '/send-message' }} />
                                    : (props) => <LogIn {...props} />
                            }
                        />
                        {/* <Route
                        path="/signup"
                        component={(props) => (
                            <SignUpComponent {...props} credentials={credentials} setCredentials={setCredentials} />
                        )}
                    /> */}
                        {/* <Route
                        path="/signup"
                        render={(props) => (
                            <Signup {...props} credentials={credentials} setCredentials={setCredentials} />
                        )}
                    /> */}
                        <Route
                            path="/forgot-password"
                            render={
                                userObject.authenticated
                                    ? () => <Redirect to={{ pathname: '/send-message' }} />
                                    : (props) => <ForgotPasswordPage {...props} />
                            }
                        />
                        <Route
                            path="/change-forgotten-password"
                            render={
                                userObject.authenticated
                                    ? () => <Redirect to={{ pathname: '/send-message' }} />
                                    : (props) => <ChangeForgotPasswordPage {...props} />
                            }
                        />
                        <Route path="/downloads/community" component={CommunityDownloads} />
                        <Route path="/auto-login" component={AutoLogin} />

                        <PrivateRoute path="/invites" component={Invites} />
                        <Route path="/accept-alexa-invites/:token" component={AcceptAlexaInvites} />
                        <Route path="/signup-fnf-invite" component={FNFInviteeSignup} />

                        <Redirect from="/" to="/send-message" />
                        <Redirect from="/*" to="/send-message" />
                        <Route render={() => <h1>404 Not Found</h1>} />
                    </Switch>
                </div>
            );

        }

        return (
            <div style={{ maxWidth: "900px", margin: "auto" }}>
                <Switch>
                    <PrivateRoute path="/news" component={News} />
                    <PrivateRoute
                        path="/settings"
                        component={isManager() ? Settings : () => <Redirect to={{ pathname: '/activities' }} />}
                    />
                    <PrivateRoute path="/family_friends/:relativeId" component={RelativeDetail} />
                    <PrivateRoute path="/family_friends" component={FamilyAndFriends} />
                    <PrivateRoute path="/profile-settings" component={ProfileSettings} />
                    <PrivateRoute path="/message-recipients/:messageRecipientId/address-book/contacts/:contactId" component={AddressBookContactDetails} />

                    <PrivateRoute path="/message-recipients/:messageRecipientId/address-book" component={AddressBookDetails} />
                    <PrivateRoute path="/message-recipients/create" component={CreateMessageRecipient} />
                    <PrivateRoute
                        path="/message-recipients/:messageRecipientId"
                        component={MessageRecipientDetails}
                    />
                    <PrivateRoute path="/message-recipients" component={MessageRecipients} />
                    <PrivateRoute path="/messages/:messageRecipientId" component={MessageReplyDetails} />
                    <PrivateRoute path="/messages" component={Messages} />
                    <PrivateRoute path="/requests/:requestId" component={RequestDetail} />
                    <PrivateRoute path="/activities/:activityId" component={ActivityDetail} />
                    <PrivateRoute path="/archives" component={ArchivedActivities} />
                    <PrivateRoute path="/activities" component={Activities} />
                    <PrivateRoute
                        path="/community-activities"
                        component={
                            isCommunityManager()
                                ? CommunityActivities
                                : () => <Redirect to={{ pathname: '/activities' }} />
                        }
                    />
                    <PrivateRoute
                        path="/community-menus"
                        component={
                            isCommunityManager()
                                ? CommunityMenus
                                : () => <Redirect to={{ pathname: '/activities' }} />
                        }
                    />
                    <PrivateRoute path="/reminders" component={Reminders} />

                    <Route path="/alexa-linking" component={AlexaLinkingLogin} />
                    <PrivateRoute path="/link-resident-list" component={LinkResidentList} />

                    <Route
                        path="/login/:token?/:registrantId?/:receivedContact?"
                        render={
                            userObject.authenticated
                                ? () => <Redirect to={{ pathname: '/activities' }} />
                                : (props) => <LogIn {...props} />
                        }
                    />
                    <Route
                        path="/signup"
                        render={(props) => (
                            <Signup {...props} credentials={credentials} setCredentials={setCredentials} />
                        )}
                    />
                    <Route
                        path="/forgot-password"
                        render={
                            userObject.authenticated
                                ? () => <Redirect to={{ pathname: '/activities' }} />
                                : (props) => <ForgotPasswordPage {...props} />
                        }
                    />
                    <Route
                        path="/change-forgotten-password"
                        render={
                            userObject.authenticated
                                ? () => <Redirect to={{ pathname: '/activities' }} />
                                : (props) => <ChangeForgotPasswordPage {...props} />
                        }
                    />
                    <Route path="/downloads/community" component={CommunityDownloads} />
                    <Route path="/downloads" component={Downloads} />
                    <Route path="/auto-login" component={AutoLogin} />
                    <PrivateRoute path="/sso" component={SSOAlexaUser} />

                    <Route path="/sso-redirect" component={SSORedirect} />
                    <PrivateRoute path="/invites" component={Invites} />
                    <PrivateRoute path="/checkout" component={Checkout} />
                    <Route path="/public/checkout/:paymentManagerId" component={PublicCheckout}></Route>
                    <PrivateRoute path="/payments" component={Payments} />
                    <Route path="/public/checkout-success" component={CheckoutSuccess}></Route>
                    <PrivateRoute path="/invite-alexa-user" component={InviteAlexaUser} />
                    <Route path="/accept-alexa-invites/:token" component={AcceptAlexaInvites} />
                    {/* <Route path="/alexa-invite-accept-page" component={AlexaUserInvitationAcceptPage} /> */}
                    <Route path="/signup-fnf-invite" component={FNFInviteeSignup} />
                    <Route path="/alexa-invite-sso-redirect" component={AlexaInviteSSORedirect} />
                    <Redirect from="/" to="/activities" />
                    <Redirect from="/*" to="/activities" />
                    <Route render={() => <h1>404 Not Found</h1>} />
                </Switch>
            </div>
        );
    }

    return (
        <UserContext.Provider value={{ userObject, setUser, reloadRegistrantLists }}>
            {process.env.REACT_APP_PLATFORM === 'community'
                ?   <Helmet>
                        <title>Speak2 Community</title>
                    </Helmet>
                :   ''
            }
            <RedirectContext.Provider value={toggleRedirect}>
                {loading ? (
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                ) : (
                    <>
                        {userObject.authenticated &&
                        !(
                            ['/signup', '/checkout', '/auto-login', '/public/checkout-success'].includes(
                                props.location.pathname,
                            ) ||
                            props.location.pathname.includes('/public/checkout') ||
                            props.location.pathname.includes('/accept-alexa-invites/')
                        ) ? (
                            <Header {...props} showSidebarProp={showSidebarProp} />
                        ) : null}
                        {RoutesComponent()}
                        {process.env.REACT_APP_PLATFORM !== "neighbors" ? <Steps
                            enabled={stepsEnabled}
                            steps={steps}
                            initialStep={0}
                            onExit={() => {
                                setShowSidebarProp(false);
                                localStorage.setItem('feedIntroShown', 'yes');
                            }}
                            onBeforeChange={(nextStepIndex) => {
                                if (nextStepIndex == 1) {
                                    setShowSidebarProp(true);
                                }
                                if (window.innerWidth <= 600) {
                                    if (nextStepIndex == 8) {
                                        setShowSidebarProp(false);
                                    }
                                    if (nextStepIndex == 7) {
                                        setShowSidebarProp(true);
                                    }
                                }
                            }}
                        /> : <></>}
                    </>
                )}

                <ToastContainer />
            </RedirectContext.Provider>
        </UserContext.Provider>
    );
};

export default withRouter(App);
