import React, { Component, useState } from 'react';
import { Tab, TabProps, Dimmer, Loader, Form, Button } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { forgotPassword, checkIfUserExists } from '../../../services/Authentication';
import { toast } from 'react-toastify';
import ContactInput from '../../components/ContactInput';
import { communityLogoPath, neighborsLogoPath } from '../../../utils/logoPaths';

const ForgotPasswordPage = (props) => {
    const [loading, setLoading] = useState(false);
    const [contact, setContact] = useState('');

    const onChange = (e) => {
        console.log(e, 'e');

        setContact(e);
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        console.log(contact);
        setLoading(true);
        const userExists = await checkIfUserExists(contact);
        if (userExists) {
            const result = await forgotPassword(contact);
            setLoading(false);

            if (result) {
                setTimeout(() => {
                    props.history.push(`/change-forgotten-password?contact=${encodeURIComponent(contact)}`);
                }, 1000);

                toast.success('Password Reset Requested, enter the code to reset it', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } else {
                toast.warn('Could not request a password reset', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } else {
            setLoading(false);
            toast.error('The user does not exist in the system. Please check the contact info you have entered', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    return (
        <div className="login-wrapper">
            <Dimmer active={loading} inverted>
                <Loader active={loading} />
            </Dimmer>
            
            {process.env.REACT_APP_PLATFORM === "neighbors" ?
                <img src={`${process.env.PUBLIC_URL}${neighborsLogoPath}`} alt="Speak 2 Neighbors Logo" className="logo" /> :
                <img src={`${process.env.PUBLIC_URL}${communityLogoPath}`} alt="Speak 2 Family Logo" className="logo" />
            }

            <Form loading={false} autoComplete="false" onSubmit={onSubmit}>
                {/* {props.error && (
                <Form.Field>
                    <div className="error-message">{props.error}</div>
                </Form.Field>
            )} */}
                <ContactInput onChange={onChange} />
                <Link to="/login">Back To Login</Link>
                <Button type="submit" primary>
                    Forgot Password
                </Button>
            </Form>
        </div>
    );
};

export default ForgotPasswordPage;
