import * as Lambda from './LambdaApi';

const getUploadUrl = async (seniorId = undefined, relativeId = undefined) => {
    try {
        const apiPath = '/profile-images';

        const response = await Lambda.Post(
            apiPath,
            {
                fileContentType: 'image/png',
                seniorId,
                relativeId
            },
            'lambda',
        );

        if (!response.success) return false;

        return response;
    } catch (err) {}
};

const uploadToSignedUrl = async (blob, url) => {
    const params = {
        method: 'PUT',
        body: blob,
    };

    return fetch(url, params);
};

const converterDataURItoBlob = dataURI => {
    //converts dataURI to blob. Using this implementation instead of 'toBlob' method of HTMLCanvas API as it is not supported in all browsers
    //https://stackoverflow.com/a/49616385
    let byteString;
    let mimeString;
    let ia;

    if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
    } else {
        byteString = encodeURI(dataURI.split(',')[1]);
    }
    // separate out the mime component
    mimeString = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0];

    // write the bytes of the string to a typed array
    ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
};

export { getUploadUrl, uploadToSignedUrl, converterDataURItoBlob };
