import * as Lambda from './LambdaApi';

const getStoryResponsesCount = async () => {
    const apiPath = `/story-responses`;
    try {
        const { unReadResponsesCount } = await Lambda.Get(apiPath, {}, 'lambda');

        return unReadResponsesCount;
    } catch (err) {
        console.log(err);
    }
};

const getAllStoriesForResident = async residentId => {
    const apiPath = `/story-responses/${residentId}`;
    try {
        const { storyResponses } = await Lambda.Get(apiPath, {}, 'lambda');

        return storyResponses;
    } catch (err) {
        console.log(err);
    }
};

const markAsRead = async residentId => {
    const apiPath = `/story-responses/${residentId}/read`;
    try {
        await Lambda.Post(apiPath, {}, 'lambda');

        return true;
    } catch (err) {
        console.log(err);
    }
};

export { getStoryResponsesCount, getAllStoriesForResident, markAsRead };
