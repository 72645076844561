import React, { useState, useEffect, useContext } from 'react';
import { Tab, TabProps, Dimmer, Loader, Form, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import ContactInput from '../../components/ContactInput';
import queryString from 'query-string';
import { setForgottenPassword, cognitoSignIn } from '../../../services/Authentication';
import UserContext from '../../../contexts/UserContext';
import * as Database from '../../../services/db';
import * as User from '../../../services/User';
import RedirectContext from '../../../contexts/RedirectContext';
import { communityLogoPath, neighborsLogoPath } from '../../../utils/logoPaths';

const ChangeForgottenPassword = (props) => {
    const [loading, setLoading] = useState(false);
    const [contact, setContact] = useState('');
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [defaultContactType, setDefaultContactType] = useState('');
    const [defaultContact, setDefaultContact] = useState('');

    const { setUser } = useContext(UserContext);
    const toggleRedirect = useContext(RedirectContext);

    const [isDisabledContact, setIsDisabledContact] = useState(false);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    // contact
    // code
    // new password

    // if these come by default, then make then non editable
    // for contact

    const getContactType = (contact) => {
        const testExp = new RegExp(/\@/);

        return testExp.test(contact) ? 'email' : 'phone';
    };

    useEffect(() => {
        const values = queryString.parse(props.location.search);
        if (values.contact) {
            setDefaultContactType(getContactType(values.contact));
            setDefaultContact(values.contact);
            setIsDisabledContact(true);
        }

        if (values.code) {
            setCode(values.code);
        }

        console.log(values);
    }, [props.location.search]);

    useEffect(() => {
        setIsSubmitDisabled(
            !contact || !code || !password || !confirmPassword || password.length < 8 || confirmPassword.length < 8,
        );
    }, [contact, code, password, confirmPassword]);

    const onSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);
        try {
            if (password !== confirmPassword) {
                setLoading(false);
                setError('Passwords does not match');
            } else {
                const changedPassword = await await setForgottenPassword(contact, code, password);

                if (!changedPassword) {
                    throw Error('');
                }

                const user = await cognitoSignIn(contact, password);
                const dbUserProfileResponse = await Database.getUserDbProfile(contact);
                const userProfile = dbUserProfileResponse.userProfile;

                const connectedRegistrants = await Database.getRelatedRegistrants();

                const signedInUser = {
                    cognitoUser: user,
                    profile: userProfile,
                    registrants: {
                        list: connectedRegistrants.residents,
                        activeRegistrantsIndexes:
                            connectedRegistrants.residents && connectedRegistrants.residents.length ? [0] : [],
                    },
                    username: user.username,
                    authenticated: true,
                };

                if (!userProfile) throw Error('No user profile');
                setUser(signedInUser);
                setLoading(false);
                toast.success('Password changed successfully, logging you in!', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                if (process.env.REACT_APP_PLATFORM === "neighbors")
                    toggleRedirect('/calendar');
                else
                    toggleRedirect('/activities');
            }
        } catch (e) {
            setLoading(false);
            toast.warn('Failed to reset your password, please verify your confirmation code', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const onContactChange = (e) => {
        console.log('e', e);
        setContact(e);
    };

    return (
        <div className="login-wrapper">
            <Dimmer active={loading} inverted>
                <Loader active={loading} />
            </Dimmer>
            {process.env.REACT_APP_PLATFORM === "neighbors" ?
                <img src={`${process.env.PUBLIC_URL}${neighborsLogoPath}`} alt="Speak 2 Neighbors Logo" className="logo" /> :
                <img src={`${process.env.PUBLIC_URL}${communityLogoPath}`} alt="Speak 2 Family Logo" className="logo" />
            }
            <Form loading={false} autoComplete="off" onSubmit={onSubmit}>
                <ContactInput
                    contact={defaultContact}
                    onChange={onContactChange}
                    defaultContactType={defaultContactType}
                    isDisabled={isDisabledContact}
                />

                <Form.Field>
                    <Form.Input
                        value={code}
                        type="text"
                        name="code"
                        placeholder="Enter the code"
                        required
                        autoComplete="false"
                        onChange={(e) => setCode(e.target.value)}
                    ></Form.Input>
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        value={password}
                        type="password"
                        name="password"
                        placeholder="Password"
                        minLength="8"
                        required
                        autoComplete="new-password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    <Form.Input
                        value={confirmPassword}
                        type="password"
                        name="confirmPassword"
                        placeholder="Retype Password"
                        minLength="8"
                        required
                        autoComplete="false"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </Form.Field>
                <Form.Field>
                    {error && (
                        <Form.Field>
                            <div className="error-message">{error}</div>
                        </Form.Field>
                    )}
                </Form.Field>
                <Link to="/login">Back To Login</Link>
                <Button type="submit" disabled={isSubmitDisabled} primary>
                    Save Password
                </Button>
            </Form>
        </div>
    );
};

export default ChangeForgottenPassword;
