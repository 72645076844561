import React, { FC, useContext, useState, useRef, RefObject, useEffect } from 'react';
import { Icon, Dimmer, Loader, Modal, Progress, Button } from 'semantic-ui-react';

import './style.less';

import UserContext from '../../../contexts/UserContext';
import AudioRecorder from './services/audioRecorder';
import {
    sendTextMessage,
    getUploadUrl,
    uploadToSignedUrl,
    sendAudioMessage,
    sendImageMessage,
    sendVideoMessage,
} from '../../../services/Messages';
import { getStoryResponsesCount } from '../../../services/storyResponses';
import RedirectContext from '../../../contexts/RedirectContext';
import Promise from 'bluebird';

interface Props {
    suppressResidentInfo: boolean;
}

let recorder;

const useCheckMobileScreen = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);

    return width <= 768;
};

const News: FC<Props> = (props) => {
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);

    const defaultInputMessage =
        'Type your message (it will be read using Alexa’s voice) or use one of the buttons to send using audio, video or photo';

    const [inputMessage, setInputMessage] = useState<string>(defaultInputMessage);
    const [recording, setRecordingIcon] = useState<boolean>(false);
    const [textAreaValue, setTextAreaValue] = useState<string>('');
    const [textAreaActive, setTextAreaActive] = useState<boolean>(false);
    const [audioExists, setAudioExists] = useState<boolean>(false);
    const [sendingMessage, setSendingMessage] = useState<boolean>(false);

    const [totalUnreadMessage, setTotalUnreadMessage] = useState(0);

    const textArea: RefObject<HTMLTextAreaElement> = useRef(null);
    const audioPlayer: RefObject<HTMLAudioElement> = useRef(null);
    const [audioBlob, setAudioBlob] = useState();
    const [failedMessageResidents, setFailedMessageResidents] = useState<any>([]);
    const [sendingBroadcast, setSendingBroadcast] = useState<boolean>(false);
    const [broadcastModal, setBroadcastModal] = useState<boolean>(false);
    const [message, setMessage] = useState<string>();
    const toggleRedirect = useContext(RedirectContext);
    const isMobile = useCheckMobileScreen();
    const [imageFile, setMessageImage] = useState();
    const [videoFile, setMessageVideo] = useState();

    const selectedRegistrantComponent = (registrants, activeIndexes) => {
        return activeIndexes.map((activeIndex, arrayIndex) => {
            const { FirstName, LastName, Image, _id } = registrants[activeIndex];
            const redirectUrl = `/message-recipients/${_id}`;
            return (
                <div className="registrant-info" key={arrayIndex}>
                    <div className="resident-picture-container">
                        {Image ? (
                            <div
                                className="resident-picture resident-image resident-image-bk"
                                style={{
                                    background: `url(${Image}) no-repeat`,
                                    backgroundPosition: 'center',
                                }}
                                onClick={() => {
                                    toggleRedirect(redirectUrl);
                                }}
                            />
                        ) : (
                            <div
                                className="resident-picture resident-avatar"
                                onClick={() => {
                                    toggleRedirect(redirectUrl);
                                }}
                            >
                                {!!FirstName && FirstName.charAt(0)} {!!LastName && LastName.charAt(0)}
                            </div>
                        )}
                    </div>
                    <p className="registrant-name">{`${FirstName} ${LastName}`}</p>
                </div>
            );
        });
    };

    const fileChange = async (event) => {
        const file = event.target.files[0];
        const fileType = file.type.split('/')[0];
        if (fileType === 'video') {
            setMessageVideo(file);
        } else {
            setMessageImage(file);
        }
    };

    useEffect(() => {
        if (activeRegistrantsIndexes[0] !== 'all-users') {
            const fetchTotalUnread = () => {
                getStoryResponsesCount().then((r) => {
                    if (r && activeRegistrantsIndexes && activeRegistrantsIndexes.length) {
                        const activeRegistrantsIndex = activeRegistrantsIndexes[0];

                        const { _id } = listOfResidents[activeRegistrantsIndex];
                        setTotalUnreadMessage(r[_id]);
                    }
                });
            };
            setTotalUnreadMessage(0);
            fetchTotalUnread();
            const intervalId = setInterval(fetchTotalUnread, 7000);

            return () => clearInterval(intervalId);
        }
        clearMessageInput(defaultInputMessage);
    }, [listOfResidents, activeRegistrantsIndexes]);

    const beginRecording = async () => {
        recorder = await AudioRecorder();
        recorder.record();

        setInputMessage('Recording...');
        setRecordingIcon(true);
    };

    const stopRecording = async () => {
        const blob = await recorder.stop();

        setAudioBlob(blob);

        setAudioExists(true);

        setInputMessage(defaultInputMessage);
        setRecordingIcon(false);
    };

    const activateTextarea = () => {
        setTextAreaActive(true);
        if (textArea.current) {
            textArea.current.focus();
        }
    };

    const deactivateTextarea = () => {
        setTextAreaActive(false);
    };

    const clearMessageInput = (message) => {
        setInputMessage(message);
        setAudioBlob(undefined);
        setAudioExists(false);
        setTextAreaValue('');
        deactivateTextarea();
        setSendingMessage(false);
        setMessageImage(undefined);
        setMessageVideo(undefined);
    };

    const sendMessage = async (residentId) => {
        if (audioBlob && !imageFile) {
            setSendingMessage(true);
            console.log('blob', audioBlob);
            const { audioId, signedUrl } = await getUploadUrl();
            const file = new File([audioBlob], audioId, {
                type: 'audio/wav',
            });
            const result = await uploadToSignedUrl(file, signedUrl);
            console.log('result', result);
            const messageSent = await sendAudioMessage(audioId.replace(/\.wav$/, '.mp3'), residentId);
            if (messageSent) {
                return true;
            }
        } else if (imageFile) {
            setSendingMessage(true);
            const type = imageFile && imageFile.type.split('/')[1];
            const { audioId: imageId, signedUrl } = await getUploadUrl(type);
            const result = await uploadToSignedUrl(imageFile, signedUrl);

            let audioFileId = undefined;
            if (audioBlob) {
                const { audioId, signedUrl } = await getUploadUrl();
                const file = new File([audioBlob], audioId, {
                    type: 'audio/wav',
                });
                const result = await uploadToSignedUrl(file, signedUrl);
                audioFileId = audioId;
            }

            const messageSent = await sendImageMessage(imageId, textAreaValue, audioFileId, residentId);
            if (messageSent) {
                return true;
            }
        } else if (videoFile) {
            setSendingMessage(true);
            const type = videoFile && videoFile.type.split('/')[1];
            const { audioId, signedUrl } = await getUploadUrl(type);

            const result = await uploadToSignedUrl(videoFile, signedUrl);
            const messageSent = await sendVideoMessage(audioId, residentId);
            if (messageSent) {
                return true;
            }
        } else if (textAreaValue) {
            setSendingMessage(true);
            const messageSent = await sendTextMessage(textAreaValue, residentId);
            if (messageSent) {
                return true;
            }
        }
        return false;
    };

    const submitMessage = async () => {
        const residentId = listOfResidents[activeRegistrantsIndexes]._id;
        const res = await sendMessage(residentId);
        res
            ? clearMessageInput('Your message has been sent successfully.')
            : clearMessageInput('There was a problem sending your message.');
    };

    const sendMessageToMultipleResidents = async (residentArray) => {
        const failedMessages: any[] = [];
        setFailedMessageResidents([]);
        setSendingBroadcast(true);

        try {
            await Promise.map(
                residentArray,
                (resident) => {
                    return sendMessage(resident._id);
                },
                { concurrency: 5 },
            ).then((responses) => {
                responses.map((response, index) => {
                    !response && failedMessages.push(residentArray[index]);
                });
            });
        } catch {
            setMessage('Something went wrong. Please try again');
        }

        setSendingBroadcast(false);
        setFailedMessageResidents(failedMessages);
    };

    const broadcastMessage = async () => {
        setBroadcastModal(true);
        await sendMessageToMultipleResidents(listOfResidents);
    };

    const retrySending = async () => {
        await sendMessageToMultipleResidents(failedMessageResidents);
    };
    const closeBroadcastModal = () => {
        setBroadcastModal(false);
        clearMessageInput(defaultInputMessage);
    };

    const sendMessageMultiMode = async () => {
        setBroadcastModal(true);
        await sendMessageToMultipleResidents(
            activeRegistrantsIndexes.map((residentIndex) => listOfResidents[residentIndex]),
        );
    };

    useEffect(() => {
        if (videoFile) {
            activeRegistrantsIndexes[0] !== 'all-users'
                ? activeRegistrantsIndexes.length > 1
                    ? sendMessageMultiMode()
                    : submitMessage()
                : broadcastMessage();
        }
    }, [videoFile]);

    const isDisabledForPayment = activeRegistrantsIndexes[0] === 'all-users' ? false : !(activeRegistrantsIndexes
    .filter(
        (residentIndex) =>
            !listOfResidents[residentIndex].isDisabledForPayment
    ).length)

    return (
        <>
            {isDisabledForPayment ? (
                <div className="no-invites">
                    This user(s) does not have an active Speak2 Family subscription. Please contact their Admin
                    to update their subscription to use Speak2 Family.
                </div>
            ) : (
                <div className="news-page">
                    {
                        <Modal
                            open={broadcastModal}
                            size="tiny"
                            className="broadcast-modal"
                            closeIcon
                            onClose={closeBroadcastModal}
                        >
                            <div className="modal-content">
                                <div>
                                    <Dimmer active={sendingBroadcast} inverted>
                                        <Loader>
                                            <h5>Sending messages</h5>
                                        </Loader>
                                    </Dimmer>
                                </div>
                                <div className="font-weight-600">{message}</div>

                                {!sendingBroadcast && failedMessageResidents.length > 0 && (
                                    <div>
                                        <span className="font-weight-600">Failed to send messages to:</span>
                                        <ul className="failed-list">
                                            {failedMessageResidents.map((resident) => {
                                                const { FirstName, LastName, Image, _id } = resident;
                                                return (
                                                    <li key={_id}>
                                                        <div className="relative-image-container">
                                                            {Image ? (
                                                                <div
                                                                    className="relative-image"
                                                                    style={{
                                                                        background: `url(${Image}) no-repeat`,
                                                                        backgroundPosition: 'center',
                                                                    }}
                                                                ></div>
                                                            ) : (
                                                                <div className="relative-avatar">
                                                                    {!!FirstName && FirstName.charAt(0)}{' '}
                                                                    {!!LastName && LastName.charAt(0)}
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="relative-name-container">
                                                            <p className="relative-name">
                                                                {FirstName} {LastName}
                                                            </p>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                        <Button className="primary tiny" onClick={retrySending}>
                                            Retry
                                        </Button>
                                    </div>
                                )}
                                {!sendingBroadcast && failedMessageResidents.length === 0 && (
                                    <>
                                        <p className="message-sent">All messages sent successfully</p>
                                        <Button className="primary tiny" onClick={closeBroadcastModal}>
                                            Close
                                        </Button>
                                    </>
                                )}
                            </div>
                        </Modal>
                    }
                    {activeRegistrantsIndexes && activeRegistrantsIndexes.length ? (
                        <>
                            {!!!props.suppressResidentInfo && (<div className="registrant-info-container">
                                {activeRegistrantsIndexes[0] !== 'all-users' ? (
                                    activeRegistrantsIndexes.length > 1 ? (
                                        <div className="registrant-info">
                                            <div className="resident-picture-container">
                                                <div className="resident-picture resident-avatar">
                                                    <Icon name="add user" />
                                                </div>
                                            </div>
                                            <p className="registrant-name">
                                                {[
                                                    activeRegistrantsIndexes.map(
                                                        (residentIndex) =>
                                                            `${listOfResidents[residentIndex].FirstName} ${listOfResidents[residentIndex].LastName}`,
                                                    ),
                                                ].join(', ')}
                                            </p>
                                        </div>
                                    ) : (
                                        selectedRegistrantComponent(listOfResidents, activeRegistrantsIndexes)
                                    )
                                ) : (
                                    <div className="registrant-info">
                                        <div className="resident-picture-container">
                                            <div className="resident-picture resident-avatar">
                                                <Icon name="users" />
                                            </div>
                                        </div>
                                        <p className="registrant-name">All Users</p>
                                    </div>
                                )}
                            </div>)}
                            <div className="create-message">
                                {activeRegistrantsIndexes[0] !== 'all-users' && !!totalUnreadMessage && (
                                    <p className="responses-text">
                                        You have <a href="/messages">{totalUnreadMessage}</a>
                                        responses from {listOfResidents[activeRegistrantsIndexes].FirstName}{' '}
                                        {listOfResidents[activeRegistrantsIndexes].LastName}
                                    </p>
                                )}
                                <div className="input-message">
                                    <Dimmer active={sendingMessage} inverted>
                                        <Loader active={sendingMessage} />
                                    </Dimmer>
                                    {textAreaActive || textAreaValue ? null : (
                                        <div className="input-message-info" onClick={activateTextarea}>
                                            <p>
                                                {inputMessage.split('|')[0]}
                                                <b>{inputMessage.split('|')[1]}</b>
                                            </p>
                                        </div>
                                    )}
                                    <textarea
                                        ref={textArea}
                                        name=""
                                        id=""
                                        onBlur={deactivateTextarea}
                                        value={textAreaValue}
                                        onChange={(e) => setTextAreaValue(e.target.value)}
                                        onClick={activateTextarea}
                                    />
                                    {imageFile && (
                                        <div className="preview-image-container">
                                            <img className="preview-image" src={URL.createObjectURL(imageFile)}></img>
                                        </div>
                                    )}
                                    {!imageFile && (
                                        <div className="recording-state-image">
                                            {recording ? (
                                                <div className="recording-button" onClick={stopRecording}></div>
                                            ) : (
                                                <>
                                                    <img src="/microphone.svg" onClick={beginRecording}></img>
                                                    {!audioExists && (
                                                        <label>
                                                            {isMobile ? (
                                                                <img src="/camera.svg"></img>
                                                            ) : (
                                                                <img src="/gallery.svg"></img>
                                                            )}
                                                            <input
                                                                style={{ display: 'none' }}
                                                                type="file"
                                                                accept="image/*,video/*"
                                                                multiple={false}
                                                                onChange={fileChange}
                                                            ></input>
                                                        </label>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <div className="buttons">
                                    {imageFile ? (
                                        <button onClick={() => clearMessageInput(defaultInputMessage)} className="playButton">
                                            <Icon name="cancel"></Icon>
                                            Cancel
                                        </button>
                                    ) : (
                                        <button
                                            disabled={!audioExists}
                                            className={`playButton ${audioExists ? 'activeAudio' : ''}`}
                                            onClick={() => {
                                                if (!audioPlayer || !audioPlayer.current) {
                                                    return;
                                                }
                                                audioPlayer.current.src = recorder.play();

                                                audioPlayer.current.play();
                                            }}
                                        >
                                            Play
                                        </button>
                                    )}

                                    <button
                                        className="submitButton"
                                        onClick={
                                            activeRegistrantsIndexes[0] !== 'all-users'
                                                ? activeRegistrantsIndexes.length > 1
                                                    ? sendMessageMultiMode
                                                    : submitMessage
                                                : broadcastMessage
                                        }
                                    >
                                        Submit
                                    </button>
                                </div>
                                <div style={{ visibility: 'hidden', height: 0 }}>
                                    <audio ref={audioPlayer}></audio>
                                </div>
                            </div>{' '}
                        </>
                    ) : (
                        <div>Please add a Message Recipient to send message</div>
                    )}
                </div>
            )}
        </>
    );
};

export default News;
