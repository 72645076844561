import React from 'react';
import { Button } from 'semantic-ui-react';
import "./style.less";

interface Props {
    filterStatus: string;
    setFilterStatus: React.Dispatch<React.SetStateAction<string>>;
    totalOpenOrders: number;
    totalClosedOrders: number;
    totalInProgressOrders: number;
    isLoading?: boolean;
    setPageNo: React.Dispatch<React.SetStateAction<number>>;
}

const StatusFilters = ({ filterStatus, setFilterStatus, totalOpenOrders = 0, totalClosedOrders = 0, totalInProgressOrders = 0, isLoading, setPageNo }: Props) => {

    const statusList = [{
        value: "Open",
        color: "#0E8FD7",
        text: `OPEN - ${totalOpenOrders}`,
    },
    {
        value: "Closed",
        color: "#E98530",
        text: `CLOSED - ${totalClosedOrders}`,
    },
    {
        value: "Accepted",
        color: "#6BAD4C",
        text: `IN PROGRESS - ${totalInProgressOrders}`,
    }];

    const handleFilterChange = (filter: string) => {
        setPageNo(1);
        if (filterStatus === filter) // If you click on the same filter again, it will deselect it
            setFilterStatus("");
        else
            setFilterStatus(filter);
    };
    return (
        <div className="filtersContainer">
            {statusList.map((status) => (
                <Button key={status.value} loading={isLoading} disabled={isLoading} onClick={() => handleFilterChange(status.value)} style={{ backgroundColor: filterStatus === status.value ? "white" : status.color, color: filterStatus === status.value ? status.color : "white", width: "226px", border: '1px solid', borderColor: filterStatus === status.value ? status.color : "white", borderRadius: "20px" }}>{status.text}</Button>
            ))}
        </div>
    );
};

export default StatusFilters;
