import * as Lambda from '../../services/LambdaApi';
import { paginationWrapper } from '../../utils/paginationWrapper';
import { AssetByIdFilter, Restaurant } from '../types/Asset';

interface RestaurantListFilter {
    Facility: string;
}

const fetchRestaurants = async ({
    filter,
    pagingSorting,
}: {
    filter: RestaurantListFilter;
    pagingSorting: {
        page_size: number;
        page_no: number;
    };
}) => {
    try {
        if (!filter.Facility) throw new Error('Facility ID is required');
        const apiPath = `/s2l/${filter.Facility}/listAssets`;
        const params =
        {
            Filter: {
                ...filter,
                AssetType: 'Restaurant'
            },
            pagingSorting
        };

        const response = await Lambda.Post(apiPath, params, 'lambda');
        if (!response || !response.Result || !response.Result.Success || !response.Result.Content) {
            throw new Error(response.Result.Error || `Error fetching resident's interests`);
        }
        return response.Result.Content as { Result: Restaurant[]; TotRecords: number; };
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Error fetching restaurants');
    }
};

const fetchAllRestaurants = async (filter: RestaurantListFilter) => {
    try {
        if (!filter.Facility) throw new Error('Facility ID is required');
        return await paginationWrapper(
            {
                apiCall: fetchRestaurants,
                params: {
                    filter: filter
                },
            }
        ) as Restaurant[];
    } catch (error) {
        throw new Error(error instanceof Error ? error.message : 'Error fetching restaurants');
    }
};

const getAssetById = async (filter: AssetByIdFilter) => {
    try {
        if (!filter.Facility) throw new Error('Facility ID is required');
        const apiPath = `/s2l/${filter.Facility}/getAssetById`;
        const params = {
            queryStringParameters: {
                _id: filter.AssetId
            }
        };
        const response = await Lambda.Get(apiPath, params, 'lambda');
        if (!response || !response.Result || !response.Result.Content) {
            throw new Error('Error fetching restaurant details');
        }
        return response.Result.Content;
    } catch (error) {
        throw new Error("Error fetching restaurant details");
    }
};

export { fetchRestaurants, fetchAllRestaurants, getAssetById };