import * as Lambda from '../../services/LambdaApi';
import { Registrant } from '../types/Registrant';

const fetchResidentInterests = async (facilityId: string, residentId: string) => {
    try {
        const apiPath = `/s2l/${facilityId}/listUserInterests`;
        const response = await Lambda.Post(apiPath, { residentId }, 'lambda');
        if (!response || !response.Result || !response.Result.Success || !response.Result.Content) {
            throw new Error(response.Result.Error || `Error fetching resident's interests`);
        }
        return response.Result.Content;
    } catch (err) {
        console.error(err);
        throw new Error(err instanceof Error ? err.message : 'Error fetching resident interests');
    }
};

const getDefaultInterestsModel = async (facilityId: string) => {
    try {
        const apiPath = `/s2l/${facilityId}/default-resident-interests`;
        const response = await Lambda.Get(apiPath, {}, 'lambda');
        if (!response || !response.Result || !response.Result.Success || !response.Result.Content) {
            throw new Error(response.Result.Error || 'Error fetching default resident interests model');
        }
        return response.Result.Content;
    }
    catch (err) {
        console.error(err);
        throw new Error(err instanceof Error ? err.message : 'Error fetching resident interests');
    }
};

const getResidentDetails = async (facilityId: string, residentId: string): Promise<Registrant> => {
    try {
        const apiPath = `/s2l/${facilityId}/registrant`;
        const params = {
            queryStringParameters: { _id: residentId },
        };
        const response = await Lambda.Get(apiPath, params, 'lambda');
        if (!response || !response.Result || !response.Result.Success || !response.Result.Content) {
            throw new Error(response.Result.Error || 'Error fetching resident details');
        }
        return response.Result.Content;
    } catch (err) {
        console.error(err);
        throw new Error(err instanceof Error ? err.message : 'Error fetching resident details');
    }
};

const upsertResidentInterests = async (facilityId: string, residentId: string, interests: any) => {
    try {
        const apiPath = `/s2l/${facilityId}/upsertUserInterests`;
        const params = {
            ...interests, residentId
        };
        const response = await Lambda.Post(apiPath, params, 'lambda');
        if (!response || !response.Result || !response.Result.Success) {
            throw new Error(response.Result.Error || 'Error updating resident interests');
        }
        return response.Result.Content;
    } catch (err) {
        console.error(err);
        throw new Error(err instanceof Error ? err.message : 'Error upserting resident interests');
    }
};

export {
    fetchResidentInterests,
    getDefaultInterestsModel,
    getResidentDetails,
    upsertResidentInterests
};