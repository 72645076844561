import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Dimmer, Dropdown, Grid, Icon, Loader, Popup } from 'semantic-ui-react';
import { fetchAllRestaurantMenuItems, fetchMenuItems } from '../../services/Menu';
import moment from 'moment';
import UserContext from '../../../contexts/UserContext';
import sendToast from '../../../utils/Toast';
import { ServicesType } from '../../types/ServiceInstance';
import { getAssetById } from '../../services/Asset';
import "./style.less";
import MenuItemCard from './MenuItemCard';
import { customDateTimeFormat, menuPage } from '../../../utils';
import DiningCart from './Cart';
import { MealItem, useCart } from '../../contexts/cart/CartContext';
import { ServiceAddOn } from '../../types/Service';
import { cloneDeep } from 'lodash';

interface RouteParams {
	restaurantId: string;
}

const MenuPageForRestaurant = () => {
	const [filteredMeals, setFilteredMeals] = useState<ServicesType[]>([]);
	const [allMeals, setAllMeals] = useState<ServicesType[]>([]);
	const [filteredMealsLoading, setFilteredMealsLoading] = useState<boolean>(false);
	const [restaurantName, setRestaurantName] = useState<string>("");
	const mealTypes = ["No filter", 'Breakfast', 'Brunch', 'Lunch', 'Snack', 'Dinner', 'All Day'];
	const { restaurantId } = useParams<RouteParams>();
	const history = useHistory();
	const { mealItems, addToCart, updateCart } = useCart();
	const {
		userObject: {
			registrants: { list: listOfResidents, activeRegistrantsIndexes },
		},
	} = useContext(UserContext);
	const activeResident = listOfResidents[activeRegistrantsIndexes[0]];

	useEffect(() => {
		fetchRestaurantsMeals();
	}, [restaurantId]);


	const fetchRestaurantsMeals = async () => {
		try {
			setFilteredMealsLoading(true);
			const filter = {
				Facility: activeResident.Facility,
				startDate: moment(new Date()).startOf('day').format(customDateTimeFormat),
				endDate: moment(new Date()).endOf('day').format(customDateTimeFormat),
				Asset: String(restaurantId),
				source: 'Menu',
				calendarType: [activeResident.Unit.RoomCategory.toString()],
			};
			const [filteredMeals, restaurant] = await Promise.all([
				fetchAllRestaurantMenuItems({ filter }),
				getAssetById({ Facility: activeResident.Facility, AssetId: String(restaurantId) }),
			]);
			setRestaurantName(restaurant.response.AssetName || '');
			setFilteredMeals(filteredMeals || []);
			setAllMeals(filteredMeals || []);
		} catch (error) {
			sendToast('error', error instanceof Error ? error.message : 'Error fetching restaurants');
		} finally {
			setFilteredMealsLoading(false);
		}
	};

	const handleRedirectToRestaurantSelection = () => {
		history.push({
			pathname: `/dining`,
		});
	};

	const handleFilter = (mealType: string) => {
		if (mealType === "No filter") {
			setFilteredMeals(allMeals);
			return;
		}
		const filteredMeals = allMeals.filter((meal) => {
			return meal.menuCategory && meal.menuCategory === mealType.toLowerCase();
		});
		setFilteredMeals(filteredMeals);
	};

	const handleAddToCart = async (menuItem: ServicesType, selectedAddOns: ServiceAddOn[]) => {
		try {
			setFilteredMealsLoading(true);
			// Retrieve cart items from local storage
			const cartItems = cloneDeep(mealItems);
			if(cartItems.length > 0) {
				const _ids = cartItems.map((item: MealItem) => item.serviceInstanceId);
				const filter = {
					Facility: activeResident.Facility,
					startDate: moment(new Date()).startOf('day').format(customDateTimeFormat),
					endDate: moment(new Date()).endOf('day').format(customDateTimeFormat),
					_ids: _ids,
					source: 'Menu',
				};
				const response:ServicesType[] = await fetchMenuItems({ filter });
				if (response.length > 0) {
					response.forEach((serviceinstance: ServicesType) => {
                        if (
                            menuItem.menuCategory !== 'all day' &&
                            menuItem.menuCategory !== 'snack' &&
                            serviceinstance.menuCategory !== 'all day' &&
                            serviceinstance.menuCategory !== 'snack' &&
                            serviceinstance.menuCategory !== menuItem.menuCategory
						) {
							setFilteredMealsLoading(false);
							const menuCategory = menuItem.menuCategory &&
								menuItem.menuCategory.charAt(0).toUpperCase() + menuItem.menuCategory.slice(1);
							const serviceCategory = serviceinstance.menuCategory &&
								serviceinstance.menuCategory.charAt(0).toUpperCase() + serviceinstance.menuCategory.slice(1);
							throw new Error(`${menuCategory} items cannot be ordered with a ${serviceCategory}.`);
						}
                    });
				}
			}
			addToCart({
				serviceInstanceId: menuItem._id,
				serviceAddOns: selectedAddOns,
				Quantity: 1,
			});
			sendToast("success", "Successfully Added to the cart");
		} catch (error) {
			sendToast("error", error instanceof Error ? error.message : "Failed to add to cart")
		} finally {
			setFilteredMealsLoading(false);
		}
	};

	return (
		<div>
			<Dimmer inverted active={filteredMealsLoading}>
				<Loader>Loading</Loader>
			</Dimmer>
			<div className="headingContainer">
				<Popup
					content="Go back to choose another restaurant"
					trigger={
						<Icon
							name="arrow left"
							size="large"
							className="backIcon"
							onClick={handleRedirectToRestaurantSelection}
						/>
					}
				/>
				<div className="mainHeadingContainer">
					<h1 className="heading">Select Your meal</h1>
				</div>
				<DiningCart restaurantId={restaurantId}/>
			</div>
			<hr className="spacing" />
			<Grid>
				<Grid.Column width={9}  >
					{restaurantName &&
						<div className="nameContainerMenu" >
							<p className="diningName">{restaurantName}</p>
						</div>
					}
				</Grid.Column>
				<Grid.Column width={7} >
					<Dropdown
						placeholder='Select Meal'
						fluid
						selection
						options={mealTypes.map((mealType, index) => ({
							key: `${mealType}-${index}`,
							text: mealType,
							value: mealType,
						}))}
						onChange={(e, { value }) => { handleFilter(value as string); }}
						style={{ width: "50%", float: "right" }}
					/>
				</Grid.Column>
			</Grid>
			{
				filteredMeals && filteredMeals.length > 0 ? (
					<div className='restaurantContainer' >
						<Grid columns={3}>
							{filteredMeals.map((menuItem) => {
								return (
									<Grid.Column stretched width={5} key={menuItem._id}>
										<MenuItemCard source={menuPage} menuItem={menuItem} addToCart={handleAddToCart} />
									</Grid.Column>
								);
							})}
						</Grid>
					</div>
				) : (
					<div className="noMenuContainer">
						<p>No menu available for today.</p>
					</div>
				)
			}
		</div>
	);
};

export default MenuPageForRestaurant;
