import React, { useContext, useEffect } from 'react';
import { ReactNode, useState } from "react";
import { CartContext, CartContextType, getInitialCartState, MealItem } from "./CartContext";
import UserContext from '../../../contexts/UserContext';
import moment from 'moment';
import { customDateTimeFormat } from '../../../utils';
import { fetchMenuItems } from '../../services/Menu';
import sendToast from '../../../utils/Toast';
import { ServicesType } from '../../types/ServiceInstance';

export const CartProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [mealItems, setMealItems] = useState<MealItem[]>([]);

    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const activeResident = listOfResidents[activeRegistrantsIndexes[0]];

    useEffect(() => {
        fetchAndFilterTodayMealItems();
    }, []);
    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(mealItems));
    }, [mealItems]);

    let fetchAndFilterTodayMealItems = async () => {
        try {
            const storedCartItems = localStorage.getItem('cartItems');
            const parsedCartItems = storedCartItems ? JSON.parse(storedCartItems) as MealItem[] : [];
            const _ids = parsedCartItems.map((item) => item.serviceInstanceId);
            if (_ids && _ids.length > 0) {
                const response = await fetchCartItems(_ids)
                if (!response) {
                    throw new Error('Failed to fetch cart items');
                }
                const todayStart = moment().startOf('day').format(customDateTimeFormat);
                const todayEnd = moment().endOf('day').format(customDateTimeFormat);
                const todayMealItems = response.filter((item: ServicesType) => {
                    return item.startDate && item.endDate && item.startDate <= todayEnd && item.endDate >= todayStart;
                })
                const todayIds = todayMealItems.map((item: ServicesType) => item._id);
                const filteredCartItems = parsedCartItems.filter((item) => todayIds.includes(item.serviceInstanceId));
                setMealItems(filteredCartItems);
            }
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Error fetching meal details');
        }
    }
    const fetchCartItems = async (_ids: string[]) => {
        const filter = {
            Facility: activeResident.Facility,
            startDate: moment(new Date()).startOf('day').format(customDateTimeFormat),
            endDate: moment(new Date()).endOf('day').format(customDateTimeFormat),
            _ids: _ids,
            source: 'Menu',
        };
        const response = await fetchMenuItems({ filter });
        return response;
    }
    
    // Add meal item to the cart
    const addToCart = (item: MealItem) => {
        setMealItems(prevItems => [...prevItems, item]);
    };

    const updateCart = (items: MealItem[]) => {
        setMealItems(items);
    } 

    // Remove meal item from the cart by serviceInstanceId
    const removeFromCart = (serviceInstanceId: string) => {
        setMealItems(prevItems => prevItems.filter(item => item.serviceInstanceId !== serviceInstanceId));
    };
 
    // Clear the entire cart
    const clearCart = () => {
        setMealItems([]);
    };

    // Provide the context value to consumers
    const contextValue: CartContextType = {
        mealItems,
        addToCart,
        removeFromCart,
        clearCart,
        updateCart,
    };

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};
