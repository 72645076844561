import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';
import { Dimmer, Loader, Button, Icon, Accordion, Popup, Modal } from 'semantic-ui-react';
import queryString from 'query-string';

import SignUpComponent from '../../components/signUpComponent';
import * as Authentication from '../../../services/Authentication';
import * as Database from '../../../services/db';

import './style.less';
import UserContext from '../../../contexts/UserContext';
import RedirectContext from '../../../contexts/RedirectContext';
import { toast } from 'react-toastify';
import { getOrderById, associateReceiptWithUser } from '../../../services/orders';

const FNFInviteeSignup = ({ credentials, setCredentials, ...props }) => {
    const { userObject, reloadRegistrantLists } = useContext(UserContext);
    const [loading, setLoading] = useState(true);
    const queryParams = queryString.parse(props.location.search);
    const [contact, setContact] = useState('');
    const toggleRedirect = useContext(RedirectContext);

    useEffect(() => {
        if (userObject.profile) {
            toggleRedirect('/');
        }
        const orderId = queryParams.orderId
            ? Array.isArray(queryParams.orderId)
                ? queryParams.orderId[0]
                : queryParams.orderId
            : '';
        console.log('here', Date.now(), orderId);
        if (orderId) {
            // here call the api with the orderId
            // set the contact
            // set loading false
            getOrderById(orderId).then((res) => {
                console.log('res', res);
                if (res && res.contact) {
                    if (res.isExistingUser) {
                        toast.success('You already have an account, redirecting to login!', {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });

                        props.history.push({
                            pathname: '/login',
                            state: {
                                contact: res.contact,
                            },
                        });
                    } else {
                        setContact(res.contact);
                        setLoading(false);
                    }
                }
                return setLoading(false);
            });
        } else {
            setLoading(false);
        }
    }, []);

    // here get the orderId
    // we wait for the loading,
    // if the orderId does not exist,
    return loading ? (
        <></>
    ) : (
        <div className="signup-page-wrapper fnf-signup">
            <First
                location={props.location}
                contact={contact}
                setCredentials={(contact, password) => {
                    setCredentials({ contact, password });
                }}
                reloadRegistrantLists={reloadRegistrantLists}
            ></First>
        </div>
    );
};

const ScheduleCall = () => {
    return (
        <div className="info info-grey">
            <div className="icon-section">
                <img className="icon-img" src="./schedule-call.png" alt="" />
            </div>
            <a href="https://email.speak2family.com/meetings/speak2matt/support-call-for-speak2-family" target="_blank">
                <h3 className="info-title">Schedule a Call</h3>
            </a>
        </div>
    );
};

const callAppleHandler = (email, password) => {
    try {
        // @ts-ignore
        window.webkit.messageHandlers.observer.postMessage({
            email,
            password,
        });
    } catch (err) {
        console.log('not really apple');
    }
};

const callAndroidHandler = (email, password) => {
    try {
        //@ts-ignore
        window.Speak2AndroidJSInterface.handleLogin(email, password);
    } catch (err) {
        console.log('not android');
    }
};

const First = (props) => {
    const queryParams = queryString.parse(props.location.search);

    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [code, setCode] = useState<string>(
        queryParams.token ? (Array.isArray(queryParams.token) ? queryParams.token[0] : queryParams.token) : '',
    );

    const contact =
        props.contact ||
        (queryParams.receivedContact
            ? Array.isArray(queryParams.receivedContact)
                ? queryParams.receivedContact[0]
                : queryParams.receivedContact
            : '');
    const [appOrderId, setAppOrderId] = useState<string>(
        queryParams.order_id
            ? Array.isArray(queryParams.order_id)
                ? queryParams.order_id[0]
                : queryParams.order_id
            : '',
    );
    const showCodeInput = queryParams.token ? true : false;
    const [loading, setLoading] = useState<boolean>(false);
    const [signUpError, setSignUpError] = useState<string>('');
    const disabledButton = queryParams.signup ? true : false;

    const { setUser } = useContext(UserContext);
    const toggleRedirect = useContext(RedirectContext);

    const handleGoToWebsiteButtonClick = async (contact, password) => {
        callAndroidHandler(contact, password);
        callAppleHandler(contact, password);
        // await props.reloadRegistrantLists();
        toggleRedirect('/');
    };

    const handleLoginSubmit = async (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        username: string,
        password: string,
        newSignup: boolean = false,
    ): Promise<void> => {
        e.preventDefault();

        setLoading(true);

        try {
            const user = await Authentication.cognitoSignIn(username, password);
            const dbUserProfileResponse = await Database.getUserDbProfile();
            const userProfile = dbUserProfileResponse.userProfile;

            const connectedRegistrants = await Database.getRelatedRegistrants();

            const signedInUser = {
                cognitoUser: user,
                profile: userProfile,
                registrants: {
                    list: connectedRegistrants.residents,
                    activeRegistrantsIndexes:
                        connectedRegistrants.residents && connectedRegistrants.residents.length ? [0] : [],
                },
                username: user.username,
                authenticated: true,
            };

            if (!userProfile) throw Error('No user profile');
            setUser(signedInUser);
            if (newSignup && connectedRegistrants.residents && connectedRegistrants.residents.length === 0) {
                //toggleRedirect('/message-recipients');
            } else {
                //toggleRedirect('/activities');
            }
            return;
        } catch (error) {
            setLoading(false);
            toast.error('Unable to log in.', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const handleSignUpSubmit = async (
        e: React.MouseEvent<HTMLDivElement, MouseEvent>,
        contact: string,
        password: string,
    ): Promise<any> => {
        e.preventDefault();

        setLoading(true);

        // if (!token || !registrantId || !receivedContact) {
        //     setSignUpError('Unauthorized registration attempt');
        // }
        if (password.length < 6) {
            setLoading(false);
            toast.error('Password should be at least 6 characters long', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            return;
        }

        const signUpParams = { password, firstName, lastName };

        if (code) {
            // @ts-ignore
            signUpParams.token = code;
        }

        // @ts-ignore
        signUpParams.contact = contact;

        try {
            await Authentication.cognitoSignUp(signUpParams);

            try {
                await handleLoginSubmit(e, contact, password, true);
            } catch (error) {
                console.log(error.message);
                throw new Error('Login error');
            }

            if (appOrderId) {
                await associateReceiptWithUser(appOrderId);
            }

            toast.success('Signed up successfully', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            // @ts-ignore
            handleGoToWebsiteButtonClick(contact, password);
            return;
        } catch (error) {
            console.log(error.message);
            if (error.message.search('409')) {
                setSignUpError('This user ID is already registered');
            } else if (error.message === 'Login error') {
                setSignUpError('Unable to log in after signing up. Please go to the login page and try again');
            } else {
                setSignUpError('Unable to sign up user');
            }
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="header step-1">Join Speak2Family</div>
            <div className="content">
                <div className="content-row">
                    <div className="signup-column">
                        <Dimmer active={loading} inverted>
                            <Loader active={loading} />
                        </Dimmer>
                        <div className="mt1">
                            <SignUpComponent
                                firstName={firstName}
                                lastName={lastName}
                                setFirstName={setFirstName}
                                setLastName={setLastName}
                                code={code}
                                setCode={setCode}
                                contact={contact}
                                contactDisabled={props.contact ? true : false}
                                showCodeInput={showCodeInput}
                                handleSignUpSubmit={handleSignUpSubmit}
                                error={signUpError}
                                disabledButton={disabledButton}
                            />
                            <div className="mt1">
                                <b>
                                    Already have an account? <a href="/login">Login here</a>
                                </b>
                            </div>
                        </div>
                        <ScheduleCall />
                        <h4 className="visit-speak2">
                            Visit{' '}
                            <a target="_blank" href="https://www.speak2family.com">
                                speak2family.com
                            </a>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FNFInviteeSignup;
