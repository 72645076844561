import React, { useEffect } from 'react';
import { Form, Dropdown, Input } from 'semantic-ui-react';
import Flatpickr from 'react-flatpickr';

import './style.less';
import 'flatpickr/dist/themes/material_green.css';

const CreateReminderForm = ({handleSubmit, formData, handleChange, editMode, onEditCancel}) => {
    const repeatOptions = [
        {
            key: 'once',
            text: 'Once',
            value: 'Once',
        },
        {
            key: 'hourly',
            text: 'Hourly',
            value: 'Hourly',
        },
        {
            key: 'daily',
            text: 'Daily',
            value: 'Daily',
        },
        {
            key: 'weekly',
            text: 'Weekly',
            value: 'Weekly',
        },
        {
            key: 'monthly',
            text: 'Monthly',
            value: 'Monthly',
        },
    ];

    return <>
    <Form onSubmit={ handleSubmit }>
        <Form.Group>
            <Form.Field
                required
                style={{paddingBottom: '0.5rem'}} 
            >
                <Flatpickr
                    placeholder='Time, Date'
                    value={editMode ? new Date(`${formData.timestamp.month} ${formData.timestamp.day}, ${formData.timestamp.year} ${formData.timestamp.timeHours}:${formData.timestamp.timeMinutes}:00`) : formData.timestamp}
                    // value={editMode ? new Date(`${formData.timestamp.month} ${formData.timestamp.day}, ${formData.timestamp.year} ${formData.timestamp.timeHours}:${formData.timestamp.timeMinutes}:00`) : formData.timestamp}
                    options={{
                        enableTime: true,
                        // defaultDate: new Date(formData.timestamp) || 'today',
                        //This value needs to be connected to edit date value
                        defaultHour: new Date().getHours(),
                        defaultMinute: new Date().getMinutes(),
                        minuteIncrement:1,
                        minDate: 'today', 
                        //minTime: Date.now(), //If uncommented, this doesn't allow times less than the current time, even for future dates
                        dateFormat: 'h:i K,  m-d-Y',
                        // time_24hr: true,
                    }} 
                    onChange={(d)=>{
                        // console.log('Handle change on datepicker', d[0])
                        handleChange('timestamp', d[0].getTime());
                    }}
                />
            </Form.Field>
            <Form.Field 
                required 
                style={{width:'40%'}}
            >
                <Input
                    fluid
                    type='text'
                    placeholder='Reminder text'   
                    value={formData.desc}
                    onChange={(event, data) => handleChange('desc', data.value)} 
                    style={{marginBottom: '0.75rem'}} 
                />
            </Form.Field>
            <Form.Field style={{marginRight: 'auto', /*paddingTop: '0.5rem'*/}} value={formData.frequency}> 
                {/* Frequency {' '} */}
                <Dropdown  
                    placeholder='Frequency'
                    selection
                    options={repeatOptions}
                    value={formData.frequency}
                    onChange={(event, data) => handleChange('frequency', data.value)}
                >
                </Dropdown>
            </Form.Field>  
            { editMode && <Form.Button
                style={{marginLeft: 'auto', float: 'right'}} 
                // disabled={!(formData && formData.timestamp && formData.timestamp > Date.now() && formData.desc)}
                type='reset' 
                circular 
                icon='close'
                onClick={onEditCancel}
            /> }           
                
                <Form.Button
                style={{marginLeft: 'auto', float: 'right'}} 
                disabled={!(formData && formData.timestamp && formData.desc && formData.frequency)}
                type='submit' 
                circular 
                icon='plus'
                onClick={()=>{}}
                />
        </Form.Group>
    </Form>
    </>;
};

CreateReminderForm.defaultProps = {onEditCancel: undefined};

export default CreateReminderForm;
