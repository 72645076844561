import React, { useEffect, useState } from 'react';
import { Checkbox } from 'semantic-ui-react';
import "./style.less";
import { ServiceAddOn } from '../../types/Service';
import { useCart } from '../../contexts/cart/CartContext';
import { carouselCart } from '../../../utils';

interface ServiceAddOnsProps {
    menuId: string;
    source: string;
    serviceAddOns: ServiceAddOn[];
    setSelectedAddons: React.Dispatch<React.SetStateAction<ServiceAddOn[]>>;
}

const ServiceAddOns: React.FC<ServiceAddOnsProps> = ({ menuId, serviceAddOns, setSelectedAddons, source }) => {
    const [localSelectedAddOns, setLocalSelectedAddOns] = useState<ServiceAddOn[]>([]);
    const { mealItems , updateCart} = useCart();
    
    useEffect(() => {
        const selectedItem = mealItems.find((item) => item.serviceInstanceId === menuId)
        if (selectedItem && selectedItem.serviceAddOns && Array.isArray(selectedItem.serviceAddOns) && selectedItem.serviceAddOns.length > 0) {
            setSelectedAddons(selectedItem.serviceAddOns)
            setLocalSelectedAddOns(selectedItem.serviceAddOns)
        }
    }, [mealItems]);

    useEffect(() => {
        if (serviceAddOns && source !== carouselCart) {
            const initialSelectedAddOns = serviceAddOns.filter((addOn) => addOn.required);
            setSelectedAddons(initialSelectedAddOns);
            setLocalSelectedAddOns(initialSelectedAddOns);
        }
    }, [serviceAddOns]);

    const handleAddOnSelection = (addOn: ServiceAddOn, checked: boolean) => {
        const selectedItem = mealItems.find((item) => item.serviceInstanceId === menuId);
        if (selectedItem) {
            selectedItem.serviceAddOns = checked ? [...selectedItem.serviceAddOns, addOn] : selectedItem.serviceAddOns.filter((item) => item.id !== addOn.id);
            updateCart([...mealItems]);
        }
        if (checked) {
            setLocalSelectedAddOns((prev) => [...prev, addOn]);
            setSelectedAddons((prev) => [...prev, addOn]);
        } else {
            setLocalSelectedAddOns((prev) => prev.filter((item) => item.id !== addOn.id));
            setSelectedAddons((prev) => prev.filter((item) => item.id !== addOn.id));
        }
    };

    return (
        <div style={{ width: "100%" }}>
            {serviceAddOns && serviceAddOns.length > 0 && serviceAddOns.map((addOn, index) => {
                const isAddonSelected = localSelectedAddOns.some((selectedAddOn) => selectedAddOn.id === addOn.id);
                return (
                    <div key={index} className="center-self-gap" >
                        <p className="addon-name">{addOn.itemName}</p>
                        <Checkbox
                            onChange={(e, { checked }) => handleAddOnSelection(addOn, checked || false)}
                            disabled={addOn.required}
                            checked={addOn.required || isAddonSelected}
                        />
                        <p style={{fontSize: "18px"}}>$ {addOn.itemCost}</p>
                    </div>
                )
            })}
        </div>
    );
};

export default ServiceAddOns;
