import { createContext } from 'react';

interface userObjectInterface {
    cognitoUser: any;
    profile: any;
    registrants: any;
    username: any;
    authenticated: boolean;
}
interface defaultUserInterface {
    userObject: userObjectInterface;
    setUser: Function;
    reloadRegistrantLists: Function;
}

const defaultValue: defaultUserInterface = {
    userObject: {
        cognitoUser: {},
        profile: undefined,
        registrants: {
            list: [],
            activeRegistrantsIndexes: [],
        },
        username: undefined,
        authenticated: false,
    },
    setUser: userObject => {},
    reloadRegistrantLists: () => {},
};

const UserContext = createContext(defaultValue);

export default UserContext;
