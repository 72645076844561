export const paginationWrapper = async ({
    apiCall,
    params,
    sorting = undefined,
    page_size = 500,
}: {
    apiCall: (...params: any) => Promise<{ Result: any[]; TotRecords: number }>;
    params: any;
    sorting?: any;
    page_size?: number;
}) => {
    const totalData: any[] = [];
    let currentResponse: any[] = [];
    let TotRecords = 0;
    const pagingSorting: any = {
        page_size,
        page_no: 1,
    };
    if (sorting && Array.isArray(sorting) && sorting.length > 0) {
        pagingSorting.sort = sorting;
    }
    do {
        const response = await apiCall({ ...params, pagingSorting });
        TotRecords = response.TotRecords;
        currentResponse = response.Result;
        totalData.push(...response.Result);
        pagingSorting.page_no++;
    } while (totalData.length < TotRecords && currentResponse.length > 0);
    return totalData;
};
