import { createContext, useContext } from 'react';
import { ServiceAddOn } from '../../types/Service';

// Define the structure of a meal item
export interface MealItem {
    serviceInstanceId: string;
    serviceAddOns: ServiceAddOn[];
    Quantity: number;
}

// Define the structure of the cart context
export interface CartContextType {
    mealItems: MealItem[];
    addToCart: (item: MealItem) => void;
    updateCart: (items: MealItem[]) => void;
    removeFromCart: (serviceInstanceId: string) => void;
    clearCart: () => void;
}

// Function to get initial cart state from local storage
export const getInitialCartState = (): MealItem[] => {
    const storedCartItems = localStorage.getItem('cartItems');
    return storedCartItems ? JSON.parse(storedCartItems) as MealItem[] : [];
};

// Initial values for the cart context
const initialCartState: CartContextType = {
    mealItems: getInitialCartState(),
    addToCart: () => { },
    updateCart: () => { },
    removeFromCart: () => { },
    clearCart: () => { }
};

// Create the context with initial values
export const CartContext = createContext<CartContextType>(initialCartState);

// Custom hook to use the cart context
export const useCart = () => {
    return useContext(CartContext);
};
