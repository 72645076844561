import React, { useState } from 'react';
import { Card, Image, Button } from 'semantic-ui-react';

import './style.less';

interface Props {
    inviteId: string;
    registrantName: string;
    registrantImage: string;
}

const InviteItem = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [registrantFirstName, registrantLastName ] = props.registrantName.split(' ');
    return (
        <Card className="invite-item">
            <Card.Content>
                <div className="invite-header">
                
                    <div className={`relative-image-container`} >
                        {props.registrantImage ? (
                                    <div
                                        className="relative-image resident-image-bk"
                                        style={{
                                            background: `url(${props.registrantImage}) no-repeat`,
                                            backgroundPosition: 'center',
                                        }}
                                    />
                                ) : (
                                    <div className="relative-avatar">
                                        {
                                            !!registrantFirstName &&
                                            registrantFirstName.charAt(0)}{' '}
                                        { 
                                            !!registrantLastName &&
                                            registrantLastName.charAt(0)}
                                    </div>
                                )}
                    </div>
                    {props.registrantName}
                </div>
                <Card.Description>
                    <Button
                        basic
                        positive
                        loading={isLoading}
                        icon="add"
                        onClick={() => {
                            setIsLoading(true);
                            props.acceptInvite(props.inviteId).then(() => {
                                setIsLoading(false);
                            });
                        }}
                        content="Accept"
                        id="actionButton"
                    />
                    <Button
                        basic
                        negative
                        loading={isLoading}
                        icon="play"
                        onClick={() => {
                            setIsLoading(true);
                            props.rejectInvite(props.inviteId).then(() => {
                                setIsLoading(false);
                            });
                        }}
                        content="Reject"
                        id="actionButton"
                    />
                </Card.Description>
            </Card.Content>
        </Card>
    );
};

export default InviteItem;
