import React, { useEffect, useContext, useState } from 'react';
import { getFamilyList, getA4hContacts } from '../../../services/family';
import { Modal, Form, Button, Dimmer, Loader, Dropdown, Icon, ModalHeader, FormInput, ModalActions } from 'semantic-ui-react';
import UserContext from '../../../contexts/UserContext';
import './style.less';
import RedirectContext from '../../../contexts/RedirectContext';
import 'react-phone-number-input/style.css';
import InviteModal from './inviteModal';
import { listResidentsInvites, resendInvite, removeInvite, updateInviteEmail } from '../../../services/Invite';
import { toast } from 'react-toastify';
import config from '../../../config';
import { updateRelative } from '../../../services/family';
import { Steps, Hints } from 'intro.js-react';
import { useHistory } from 'react-router-dom';

declare var process: {
    env: {
        SERVER_ENV: string;
        REACT_APP_PLATFORM?: string
    };
};

interface Relative {
    _id: string;
    FirstName: string;
    LastName: string;
    Image: string;
    Manager?: boolean;
    Relationship?: string;
    isActive: boolean;
    // RelativeName?:string
    RelativeId?:string
    isStaffEntered?:boolean
    contact?:number
}

interface Invitation {
    _id: string;
    Token: string;
    RegistrantId: string;
    Contact: string;
    Registrant?: {
        _id?: string;
        FirstName?: string;
        LastName?: string;
        Image?: string;
    };
    RelativeId?: string;
    AddedBy?: string;
    ResendCount?: number;
    LastInviteSent?: number;
    email?: string;
}

const steps = [
    {
        element: '.invite-modal-root',
        intro:
            'Enter an email or cell phone number to invite the person you want participating in your Speak2 account. You can set their permissions here.',
    },
    {
        element: '.bottom-invite-button',
        intro: 'NavigationTo add more people to your Circle of Trust, click the Invite button. ',
    },
];

const FamilyAndFriends = () => {
    const [loading, setLoading] = useState(false);
    const [resendingInvite, setResendingInvite] = useState(false);
    const [deletingInvite, setDeletingInvite] = useState(false);
    const history = useHistory()
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
            profile: { _id: userId },
        },
    } = useContext(UserContext);
    const residentId = listOfResidents[activeRegistrantsIndexes] && listOfResidents[activeRegistrantsIndexes]._id;

    const [availableInvites, setAvailableInvites] = useState<Number>(0);
    const [relatives, setRelatives] = useState<Relative[]>([]);
    const toggleRedirect = useContext(RedirectContext);
    const [IsModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [invitations, setInvitations] = useState<Invitation[]>([]);
    const [isUserManager, setIsUserManager] = useState(false);
    const [stepsEnabled, setStepsEnabled] = useState(false);
    const [showEmailPopup, setShowEmailPopup] = useState(false);
    const [selectedInvite, setSelectedInvite] = useState<Invitation>();
    const [email, setEmail] = useState<string>('');

    const isValidEmail = (email) => {
        const emailRegExp = new RegExp(/^(([^<>()\[\]\.,;:\s@"]+(\.[^<>()\[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const isEmailValid = emailRegExp.test(email);
        return isEmailValid;
    };

    const updateEmailAndSendInvite = async () => {
        setLoading(true);
        if (!email || !isValidEmail(email)) {
            setLoading(false);
            toast.error('Invalid email', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } else {
            // update invite here
            await updateInviteEmail(selectedInvite && selectedInvite._id, email)
            // call resend invite
            await sendInviteAgain({...selectedInvite as Invitation, email})
            setShowEmailPopup(false);
        }
    }

    const sendInviteAgain = async (invite: Invitation) => {
        console.log('invite', invite)
        if (!invite || !invite.email) {
            setSelectedInvite(invite);
            setShowEmailPopup(true);
            return;
        }
        try {
            setResendingInvite(true);
            await resendInvite(invite._id);
            setResendingInvite(false);
            toast.success('Invite Re-Sent Successfully', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            reload();
        } catch (err) {
            setResendingInvite(false);

            toast.warn('Something went wrong', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const deleteInvite = async (inviteId) => {
        try {
            setDeletingInvite(true);
            await removeInvite(inviteId);
            setDeletingInvite(false);
            toast.success('Invite Deleted Successfully', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            reload();
        } catch (err) {
            setDeletingInvite(false);

            toast.warn('Something went wrong', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const reload = () => {
        setLoading(true);
        setInvitations([]);
        setRelatives([]);
        setIsUserManager(false);

        if (!residentId) {
            setLoading(false);
            return;
        }
        const selectedResident = listOfResidents[activeRegistrantsIndexes[0]]
        const facilityId = selectedResident.Facility
        if (process.env.REACT_APP_PLATFORM === 'community' || process.env.REACT_APP_PLATFORM === 'neighbors') {
            Promise.all([getFamilyList(residentId), getA4hContacts(facilityId, residentId), listResidentsInvites(residentId)]).then(
                ([relativeData, a4hContactsData, invitationData]) => {    
                    setInvitations([...invitationData]);
                    const manager = relativeData.relatives.filter((e) => e.Manager);
                    if (manager.length && manager[0]._id === userId) {
                        setIsUserManager(true);
                    }
                    const regularRelatives = relativeData.relatives.filter((e) => !e.Manager);
                const editedA4hContacts = a4hContactsData?.Result?.Content.map((contact) =>{
                    const editedObj = {
                        ...contact,
                        _id:contact.RelativeId,
                        FirstName:contact.RelativeName, 
                        LastName:" ",
                        isStaffEntered: !!contact.contacts,
                        contact:contact.Contact
                    }

                    return editedObj
                })

                    //quick hack will fail in multi manager    
                    setRelatives([...manager, ...regularRelatives, ...editedA4hContacts]);
                    setAvailableInvites(relativeData.availableInvites);
                    setLoading(false);
                    !localStorage.getItem('familyIntroShown') &&
                        setTimeout(() => {
                            setIsModalOpen(true);
                            setStepsEnabled(true);
                        }, 200);
                },
            );
        }

     if(!facilityId){
        Promise.all([getFamilyList(residentId), listResidentsInvites(residentId)]).then(
            ([relativeData, invitationData]) => {
                setInvitations([...invitationData]);
                const manager = relativeData.relatives.filter((e) => e.Manager);
                if (manager.length && manager[0]._id === userId) {
                    setIsUserManager(true);
                }
                const regularRelatives = relativeData.relatives.filter((e) => !e.Manager);

                setRelatives([...manager, ...regularRelatives]);
                setAvailableInvites(relativeData.availableInvites);
                setLoading(false);
                !localStorage.getItem('familyIntroShown') &&
                    setTimeout(() => {
                        setIsModalOpen(true);
                        setStepsEnabled(true);
                    }, 200);
            },
        );
     }
    };
    useEffect(() => {
        reload();
    }, [residentId]);

    const navigate = (to: string) => {
        toggleRedirect(to);
        return;
    };

    const submitInvite = () => {
        setIsModalOpen(false);
    };

    const disableRelative = async (relativeId) => {
        const payLoad = {
            isActive: false,
        };

        try {
            setLoading(true);
            await updateRelative(residentId, relativeId, payLoad);
            toast.success('User Deleted Successfully', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });

            reload();
        } catch {
            toast.warn('Something went wrong', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            setLoading(false);
        }
    };
    const reEnableRelative = async (relativeId) => {
        const payLoad = {
            isActive: true,
        };

        try {
            setLoading(true);

            await updateRelative(residentId, relativeId, payLoad);
            toast.success('User Re-enabled Successfully', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });

            reload();
        } catch {
            toast.warn('Something went wrong', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            setLoading(false);
        }
    };

    const a4hNavigatehandler = (relative:Relative) =>{
        history.push({
            pathname:`/addressbook/staff/${relative.RelativeId}`,
            state:{
                relative,
            }
            
        })
    }

    const  relativeNavigateHanlder = (_id:string) => {
        navigate(`/${(process.env.REACT_APP_PLATFORM === "community" || process.env.REACT_APP_PLATFORM === 'neighbors') ? "addressbook" : "family_friends"}/${_id}`)
    }
    return (
        <div>
            <div className="pb-70">
                <div className="relative-list">
                    <Dimmer className="pt-80" active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>

                    {relatives && relatives.length
                        ? relatives
                              .filter((relative) => relative.isActive === true)
                              .map((relative, index) => {
                                  const { _id, FirstName, LastName, Image } = relative;
           
                                  return (
                                      <div className="relative-info-container" key={relative._id || relative.RelativeId}>
                                          <div
                                              className="relative-info"
                                              key={_id}
                                              role="link"
                                            onClick={() => {
                                                if(relative.RelativeId){
                                                    a4hNavigatehandler(relative)
                                                }else  if(relative._id){
                                                    relativeNavigateHanlder(_id)  
                                                }
                                            }}
                                          >
                                              <div className="relative-image-container">
                                                  {Image ? (
                                                      <div
                                                          className="relative-image"
                                                          style={{ background: `url(${Image}) no-repeat` }}
                                                      ></div>
                                                  ) : (
                                                      <div className="relative-avatar">
                                                          {relative &&
                                                              !!relative.FirstName &&
                                                              relative.FirstName.charAt(0)}{' '}
                                                          {relative &&
                                                              !!relative.LastName &&
                                                              relative.LastName.charAt(0)}
                                                      </div>
                                                  )}
                                              </div>
                                              <div className="relative-name-container">
                                                  <p className="relative-name">{`${FirstName} ${LastName}`} {relative.contact ? ` : ${relative.contact}` : ""}</p>
                                                  <p className="relative-name disabled mb-0">
                                                      {relative.Manager && 'Relationship Manager'}
                                                  </p>
                                                  <p className="relative-name disabled mb-0">
                                                      {relative.Relationship
                                                          ? relative.Relationship
                                                          : relative.Manager
                                                          ? ''
                                                          : relative.isStaffEntered ? "Staff entered contact"
                                                          : 'Relationship'}
                                                  </p>
                                              </div>
                                          </div>
                                          {isUserManager && !relative.Manager && !relative.isStaffEntered && (
                                              <div className="delete-relative">
                                                  <Button
                                                      className="tiny basic red"
                                                      onClick={() => {
                                                          disableRelative(_id);
                                                      }}
                                                  >
                                                      <Icon name="trash" />
                                                      Delete{' '}
                                                  </Button>
                                              </div>
                                          )}
                                      </div>
                                  );
                              })
                        : ''}
                    <div>
                        {relatives &&
                        relatives.length &&
                        relatives.filter((relative) => relative.isActive === false).length > 0 ? (
                            <div className="invited-title">Disabled Users</div>
                        ) : (
                            ''
                        )}

                        {relatives && relatives.length
                            ? relatives
                                  .filter((relative) => relative.isActive === false)
                                  .map((relative) => {
                                      const { _id, FirstName, LastName, Image } = relative;
                                      return (
                                          <div className="relative-info-container">
                                              <div
                                                  className="relative-info"
                                                  key={relative._id || relative.RelativeId}
                                                  role="link"
                                                  onClick={() => navigate(`/family_friends/${_id}`)}
                                              >
                                                  <div className="relative-image-container">
                                                      {Image ? (
                                                          <div
                                                              className="relative-image"
                                                              style={{ background: `url(${Image}) no-repeat` }}
                                                          ></div>
                                                      ) : (
                                                          <div className="relative-avatar">
                                                              {relative &&
                                                                  !!relative.FirstName &&
                                                                  relative.FirstName.charAt(0)}{' '}
                                                              {relative &&
                                                                  !!relative.LastName &&
                                                                  relative.LastName.charAt(0)}
                                                          </div>
                                                      )}
                                                  </div>
                                                  <div className="relative-name-container">
                                                      <p className="relative-name">{`${FirstName} ${LastName}`}</p>
                                                      <p className="relative-name disabled mb-0">
                                                          {relative.Manager && 'Relationship Manager'}
                                                      </p>
                                                      <p className="relative-name disabled mb-0">
                                                          {relative.Relationship
                                                              ? relative.Relationship
                                                              : relative.Manager
                                                              ? ''
                                                              : 'Relationship'}
                                                      </p>
                                                  </div>
                                              </div>
                                              {isUserManager && !relative.Manager && (
                                                  <div className="delete-relative">
                                                      <Button
                                                          className="tiny basic green"
                                                          onClick={() => {
                                                              reEnableRelative(_id);
                                                          }}
                                                      >
                                                          <Icon name="check" />
                                                          Re-Enable{' '}
                                                      </Button>
                                                  </div>
                                              )}
                                          </div>
                                      );
                                  })
                            : ''}
                    </div>
                    {!loading && !relatives.length && !invitations.length && (
                        <div className="no-invites mt-20">
                            You have not set up any Friends or Family yet for this Member.
                        </div>
                    )}
                </div>
                {invitations && invitations.length ? (
                    <div className="relative-list invite-list">
                        <div className="invited-title">Invited</div>
                        {invitations.map((invite) => (
                            <div className="relative-info" key={invite._id}>
                                <div className="relative-name-container">
                                    <p className="relative-name">{invite.Contact}</p>
                                    <p className="relative-name-sm disabled">
                                        {invite.LastInviteSent && new Date(invite.LastInviteSent).toLocaleString()}
                                    </p>
                                </div>
                                <div>
                                    <Button
                                        className="tiny basic"
                                        disabled={
                                            !!(invite.ResendCount && invite.ResendCount > 4) ||
                                            !!(
                                                invite.LastInviteSent &&
                                                invite.LastInviteSent > Date.now() - 5 * 60 * 1000
                                            )
                                        }
                                        loading={resendingInvite}
                                        onClick={() => sendInviteAgain(invite)}
                                    >
                                        <i className="user plus icon"></i> Resend Invite
                                    </Button>
                                    {isUserManager ? (
                                        <Button
                                            className="tiny negative"
                                            loading={deletingInvite}
                                            onClick={() => deleteInvite(invite._id)}
                                        >
                                            <i className="user delete icon"></i> Delete Invite
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    ''
                )}
                {relatives.length || invitations.length ? (
                    <div className="invite-desc-text">
                        {/* Add Friends & Family by clicking the "Invite" button below. You can add up to 10 people on your
                        account. If you need more than 10, you can purchase another 5-pack for $.99. */}
                    </div>
                ) : (
                    ''
                )}
            </div>

            {IsModalOpen && (
                <InviteModal
                    residentId={residentId}
                    isModalOpen={IsModalOpen}
                    close={() => {
                        setIsModalOpen(false);
                        reload();
                    }}
                />
            )}

<Modal open={showEmailPopup} onClose={() => { setShowEmailPopup(false); setEmail("") }} size={'small'} closeIcon>
    <ModalHeader>
        <h5>
            We're currently experiencing problems with sending invitations via phone numbers. Please provide an email address so we can send the invite accordingly
        </h5>
    </ModalHeader>
    <Form onSubmit={() => updateEmailAndSendInvite()} error>
        <Modal.Content style={{marginTop:"5px"}}>
                <Dimmer active={loading} inverted>
                    <Loader active={loading} />
                </Dimmer>
                <label style={{margin:'12px'}}>Email</label>
                <FormInput
                    style={{ padding:'10px', width:'50%' }}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                    }}
                    required
                    name="Email"
                    placeholder="Email"
                ></FormInput>
        </Modal.Content>
        <ModalActions className="inviteBtn">
            <Button loading={loading} className="ui primary small button" type="submit" disabled={!isValidEmail(email)}>
                Save and Resend Invite
            </Button>
        </ModalActions>
    </Form>
</Modal>



            <Steps
                enabled={stepsEnabled}
                steps={steps}
                initialStep={0}
                onExit={() => {
                    localStorage.setItem('familyIntroShown', 'yes');
                }}
            />

            <div className="buy-container" style={{ maxWidth: process.env.REACT_APP_PLATFORM === 'neighbors' ? '78.125vw' : '900px' }}>
                {availableInvites > 0 ? (
                    <Button
                        className="primary large bottom-invite-button"
                        onClick={() => {
                            setIsModalOpen(true);
                        }}
                    >
                        <i className="plus circle icon"></i> Invite
                    </Button>
                ) : (
                    <Button className="primary large basic">
                        <i className="plus circle icon"></i> Buy More
                    </Button>
                )}
            </div>
        </div>
    );
};

export default FamilyAndFriends;
