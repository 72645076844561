import { API as awsAPI } from 'aws-amplify';

type Headers = { [key: string]: string };

const makeHeaders = (override: Headers = {}): Headers => {
    const defaultHeaders = { 'Content-Type': 'application/json' };
    return {
        ...defaultHeaders,
        ...override,
    };
};

const Post = async (apiPath, body, lambda) => {
    try {
        const headers = makeHeaders();
        const payload = { headers, body };
        const postResponse = await awsAPI.post(lambda, apiPath, payload);
        if (!postResponse.success) throw new Error(postResponse.error);
        return postResponse;
    } catch (error) {
        return Promise.reject(error);
    }
};

const Get = async (apiPath, payload, lambda) => {
    try {
        const getResponse = await awsAPI.get(lambda, apiPath, payload);
        if (!getResponse.success) throw new Error(getResponse.error);
        return getResponse;
    } catch (error) {
        throw Error(error);
    }
};

const Put = async (apiPath, body, lambda) => {
    try {
        const headers = makeHeaders();
        const payload = {
            headers,
            body,
        };
        const putResponse = await awsAPI.put(lambda, apiPath, payload);
        if (!putResponse.success) throw new Error(putResponse.error);
        return putResponse;
    } catch (error) {
        throw Error(error);
    }
};

const Delete = async (apiPath, body, lambda) => {
    try {
        const headers = makeHeaders();
        const payload = { headers };
        const deleteResponse = await awsAPI.del(lambda, apiPath, payload);
        if (!deleteResponse.success) throw new Error(deleteResponse.error);
        return deleteResponse;
    } catch (error) {
        throw Error(error);
    }
};

export { Post, Get, Put, Delete };
