import React, { useEffect, useContext, useState } from 'react';
import {
    Modal,
    Form,
    Dimmer,
    Loader,
    Dropdown,
    ModalHeader,
    ModalActions,
    Button,
    FormInput,
    TextArea,
    Checkbox,
} from 'semantic-ui-react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ContactInput from '../../components/ContactInput';
import { parsePhoneNumber } from 'react-phone-number-input';

import { sendInvite } from '../../../services/Invite';
import { toast } from 'react-toastify';
const InviteModal = ({ isModalOpen, close, residentId }) => {
    const [contact, setContact] = useState();
    const [invitationMode, setInvitationMode] = useState('email');
    const [error, setError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [isAnnouncementAlert, setIsAnnouncementAlert] =  useState(false);
    const [validForm, setValidForm] = useState(false);
    const [email, setEmail] = useState('');

    // Refactor for repeats when you can breathe!
    const isUsernameValid = (contact) => {
        const emailRegExp = new RegExp(/\S+@\S+\.\S+/g);
        const e164RegExp = new RegExp(/^\+[1-9]\d{1,14}$/g);

        const isEmailValid = emailRegExp.test(contact);
        const isNumberValid = e164RegExp.test(contact);

        return isEmailValid || isNumberValid;
    };

    const isValidEmail = (email) => {
        const emailRegExp = new RegExp(/^(([^<>()\[\]\.,;:\s@"]+(\.[^<>()\[\]\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
        const isEmailValid = emailRegExp.test(email);
        return isEmailValid;
    };
    
    useEffect(() => {
        const bool = !(email && isValidEmail(email) && isUsernameValid(contact) && firstName.length && typeof firstName && lastName.length && typeof lastName)
        setValidForm(bool)
    }, [firstName, lastName, message, contact, email]);

    const submitInvite = async () => {
        setError('');
        console.log(contact);
        if (!isUsernameValid(contact.toLowerCase())) {
            setUsernameError('Email or Phone number not valid');

            return;
        }

        try {
            setLoading(true);
            const response = await sendInvite(
                residentId,
                contact,
                { firstName, lastName },
                message ? message : `You have been invited to join Speak2 Family!`,
                isAnnouncementAlert,
                isValidEmail(email) ? email : ''
            );
            console.log('response: ', response);
            close();
            setFirstName('');
            setLastName('');
            setMessage('');
            setEmail('');
            setIsAnnouncementAlert(false);
            toast.success('Invite Sent Successfully', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } catch (err) {
            setError(err.message);
        }
        setLoading(false);
    };

    return (
        <Modal open={isModalOpen} onClose={() => close()} size={'tiny'} className="invite-modal-root" closeIcon>
            <ModalHeader>
                <h5>
                    Enter an email or cell number to invite this person to join Speak2 Family. They will be sent
                    instructions on how to join along with your message.
                </h5>
            </ModalHeader>
            <Form onSubmit={submitInvite} error>
                <Modal.Content className="fnf-invite-modal-content">
                    <Form.Field>
                    <Dimmer active={loading} inverted>
                        <Loader active={loading} />
                    </Dimmer>
                    <div className="name-section">
                        <FormInput
                            value={firstName}
                            onChange={(e) => {
                                setFirstName(e.target.value);
                            }}
                            required
                            name="FirstName"
                            placeholder="First Name"
                        ></FormInput>
                        <FormInput
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value);
                            }}
                            required
                            name="LastName"
                            placeholder="Last Name"
                        ></FormInput>
                    </div>
                    <ContactInput
                        onChange={(e) => {
                            setUsernameError('');
                            setContact(e);
                        }}
                        error={usernameError || error}
                    />
                    <TextArea
                        value={message}
                        onChange={(e) => {
                            console.log(e.currentTarget.value);
                            setMessage(e.currentTarget.value);
                        }}
                        name="message"
                        placeholder="Message(optional). Default - You have been invited to join Speak2 Family!"
                        type="textarea"
                        rows="2"
                    ></TextArea>
                    </Form.Field>
                    <Form.Field>
                        <FormInput
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            name="email"
                            placeholder="Email"
                        />
                    </Form.Field>
                    <Form.Field>
                        <Checkbox
                            label="Announcements"
                            value={'announcement'}
                            checked={isAnnouncementAlert}
                            onClick={() => setIsAnnouncementAlert(isAnnouncementAlert => !isAnnouncementAlert)}
                        />
                        <p style={{ color :"black"}}>Check to enable this person to receive announcements from the community.</p>
                    </Form.Field>
                </Modal.Content>

                <ModalActions className="inviteBtn">
                    <Button className="ui primary small button " type="submit" disabled={validForm}>
                        Send Invite
                    </Button>
                </ModalActions>
            </Form>
        </Modal>
    );
};

export default InviteModal;
