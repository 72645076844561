import React, { FC, useEffect, useState } from 'react';
import { Menu, Popup } from 'semantic-ui-react';
import { SidebarIcons } from '../../../types/sidebar';
import SidebarIcon from '../SidebarIcon';

interface MenuItemProps {
  iconName: SidebarIcons;
  link: string;
  name: string;
  disable: boolean;
}
const MenuItem: FC<MenuItemProps> = ({ iconName, name, disable }) => {
  const [isHovering, setIsHovering] = useState(false);

  const onMouseEnter = () => setIsHovering(true);

  const onMouseLeave = () => setIsHovering(false);

  return (
    <div onMouseEnter={() => onMouseEnter()} onMouseLeave={() => onMouseLeave()}>
      <Menu.Item
        disabled={disable}
        className='menu-item'
        style={{ display: "flex", justifyContent: "center", backgroundColor: !isHovering ? "white" : '#2987CD', border: "1px solid #2987CDCC", padding: '17px' }}
      >
        <Popup
          content={
            name.replace(name[0], name[0].toUpperCase())
          } // Capitalise the frist letter
          trigger={
            <>
              <SidebarIcon white={isHovering} name={iconName} />
            </>
          }
          open={isHovering}
          size={"tiny"}
          position="right center"
        />
      </Menu.Item>
    </div>
  );
};

export default MenuItem;