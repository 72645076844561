import React, { useContext, useEffect, useState } from 'react';
import { Accordion, Checkbox, CheckboxProps, GridColumn } from 'semantic-ui-react';
import UserContext from '../../../contexts/UserContext';
import { fetchResidentInterests, getDefaultInterestsModel, upsertResidentInterests } from '../../services/MyProfile';
import sendToast from '../../../utils/Toast';
import { cloneDeep } from 'lodash';

interface Props {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const ResidentInterests = ({ setLoading }: Props) => {
    const [residentInterests, setResidentInterests] = useState<any>({}); // This is the object that will be passed to the ResidentInterests component

    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
        userObject
    } = useContext(UserContext);

    const activeResident = listOfResidents[activeRegistrantsIndexes[0]]; // get the first resident from the list of active residents because neighbors web only supports the resident who is logged in and we can't deselect or change it

    useEffect(() => {
        (async () => {
            await fetchInterests();
        })();
    }, [userObject]);

    const fetchInterests = async () => {
        try {
            setLoading(true);
            const [interests, defaultInterests] = await Promise.all([fetchResidentInterests(activeResident.Facility, String(activeResident._id)), getDefaultInterestsModel(activeResident.Facility)]);
            if (interests && defaultInterests) {
                const requiredKeys = Object.keys(defaultInterests);
                const cleanedUpInterests = {};
                requiredKeys.forEach(key => {
                    if (interests[key])
                        cleanedUpInterests[key] = interests[key];
                });
                setResidentInterests(cleanedUpInterests);
            }
        } catch (err) {
            sendToast('error', err instanceof Error ? err.message : 'Error fetching resident interests');
        } finally {
            setLoading(false);
        }
    };

    const handleCheckBoxChange = async (
        event: React.MouseEvent<HTMLInputElement, MouseEvent> | React.FormEvent<HTMLInputElement>,
        { name, checked }: CheckboxProps
    ) => {
        try {
            setLoading(true);
            // Algo that dynamically updates the the selected key
            /*
                Example: 
                if you want to update the value of "Intellectual.tech.electronics" to true
                1. Clone the residentInterests object
                2. Split the key name by '.' resulting in ["Intellectual", "tech", "electronics"]
                3. Loop through the array of keys and store the current position as reference
                4. Keep doing this until your position is an object
                5. Update the final key with the checked value
            */
            const clonedInterests = cloneDeep(residentInterests);
            const objectKeys = (name as string).split('.');
            let position = clonedInterests;
            const finalKey = objectKeys[objectKeys.length - 1];
            for (let key in objectKeys) {
                if (typeof position[objectKeys[key]] === 'object')
                    position = position[objectKeys[key]];
            }
            position[finalKey] = checked;
            await upsertInterests(clonedInterests);
            await fetchInterests();
        } catch (err) {
            sendToast('error', err instanceof Error ? err.message : 'Error updating resident interests');
        } finally {
            setLoading(false);
        }
    };

    const upsertInterests = async (updatedInterests: any) => {
        try {
            await upsertResidentInterests(activeResident.Facility, String(activeResident._id), updatedInterests);
        } catch (err) {
            setLoading(false); // if error occurs then set loading to prevent infinite loading which was set to true from the parent which calls this function
            sendToast('error', err instanceof Error ? err.message : 'Error updating resident interests');
        }
    };

    const renderCheckboxes = (obj, parentName = '', positionRef: any = null) => {
        // Dynamically render checkboxes based on the object
        return Object.entries(obj).map(([key, value]) => {
            const itemNameKey = parentName ? `${parentName}.${key}` : key; // generate a unique key for each checkbox
            const formattedLabel = formatString(key); // format the key to a human readable string
            const newPositionRef = getCurrentReference(positionRef, parentName, key); // get the current reference of the key in object
            if (typeof value === 'object' && !Array.isArray(value)) { // if the value is an object, then it means it has children and hence render an accordian
                return (
                    <Accordion
                        className="spacingFlex"
                        style={{ width: '30%', borderBottom: '1px solid #2185D0', margin: '10px 50px' }}
                        key={itemNameKey}
                        panels={[{
                            title: formattedLabel, content: renderCheckboxes(value, itemNameKey, newPositionRef)
                        }]}
                    />
                );
            } else { // if the value is not an object, then it means it is a leaf node and hence render a checkbox
                return (
                    <GridColumn style={{ margin: '10px' }} key={itemNameKey}>
                        <Checkbox
                            className='interestCheckBox'
                            label={formattedLabel}
                            name={itemNameKey}
                            checked={!!value}
                            onChange={handleCheckBoxChange}
                        />
                    </GridColumn>
                );
            }
        });
    };

    const formatString = (str: string) => { // format the string to a human readable string
        const specialCases = {
            "tv": "TV",
            "nonFatMilk": "NonFat Milk",
            "milk": "1% Milk"
        };

        const specialCaseResult = specialCases[str.toLowerCase()];
        if (specialCaseResult) {
            return specialCaseResult;
        } else {
            // /(?=[A-Z])/) regex for uppercase lettter
            // /^\w/ regex for first letter
            return str.split(/(?=[A-Z])/).join(" ").replace(/^\w/, (c) => c.toUpperCase());
        }
    };

    const getCurrentReference = (positionRef: object, parentName: string, key: string) => {
        // if there is no position reference, then it means we are at the parent level, initialize the parent reference
        if (!positionRef) {
            // we have to do selected.parent.key since parent is already rendered and we are at the second level
            const parentRef = residentInterests[parentName];
            return parentRef[key];
        } else {
            return positionRef[key];
        }
    };

    const renderParentCheckboxes = () => {
        return Object.entries(residentInterests).map(([key, value]) => {
            return (
                <Accordion
                    style={{ borderBottom: '1px solid green', padding: '10px' }}
                    key={key}
                    panels={[{
                        title: key, content:
                            renderCheckboxes(value, key)
                    }]} />
            );
        });
    };

    return (
        <div>
            <Accordion style={{ boxShadow: 'none' }} fluid styled>
                {renderParentCheckboxes()}
            </Accordion>
        </div>
    );
};

export default ResidentInterests;