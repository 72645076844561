import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import './style.less';

interface Props {
    isLoading: boolean;
    hasMore: boolean;
    next: (any) => any;
    type?: "button" | "submit" | "reset";
    skipMargin?: boolean;
}

const LoadMore = (props: Props) => {
    return props.hasMore ? (
        <div className="admin-load-more" style={{ margin: props.skipMargin ? '0px auto' : '' }}>
            <Button type={props.type || "button"} primary loading={props.isLoading} onClick={props.next}>
                Load More
            </Button>
        </div>
    ) : (
        <div></div>
    );
};

// Define the propTypes for the LoadMore component
LoadMore.propTypes = {
    // next is a function prop that is required (func.isRequired)
    next: PropTypes.func.isRequired,
    // isLoading is a boolean prop that is required (bool.isRequired)
    isLoading: PropTypes.bool.isRequired,
};

export default LoadMore;
