import moment from 'moment-timezone';
import * as Lambda from '../../services/LambdaApi';
import { CheckInPayload } from '../types/CheckIn';

const fetchCurrentCheckInStatusOfResident = async (
    facilityId: string,
    registrantId: string,
    facilityTimeZone: string,
) => {
    try {
        const apiPath = `/s2l/${facilityId}/listCheckinCards`;
        const currentDate = moment.tz(facilityTimeZone);
        const startTimestamp = currentDate.startOf('day').valueOf();
        const endTimestamp = currentDate.endOf('day').valueOf();

        const payLoad = {
            Facility: facilityId,
            registrantId: registrantId,
            startTimestamp: startTimestamp,
            endTimestamp: endTimestamp,
            pagingSorting: {
                page_size: 500,
                page_no: 1,
            },
        };
        const response = await Lambda.Post(apiPath, payLoad, 'lambda');
        if (!response || !response.Result || !response.Result.Content || !response.Result.Success) {
            throw new Error('Error fetching Check In status of Registrant');
        }
        return response.Result.Content;
    } catch (error) {
        throw new Error('Error fetching Check In status of Registrant');
    }
};
const createCheckInCard = async (payLoad: CheckInPayload) => {
    try {
        const apiPath = `/s2l/${payLoad.Facility}/checkinCreate`;
        const response = await Lambda.Post(apiPath, payLoad, 'lambda');
        if (!response || !response.Result || !response.Result.Content || !response.Result.Success) {
            throw new Error('Error while upating the check in status.');
        }
        return response.Result.Content;
    } catch (error) {
        throw new Error('Error while updating the check in status.');
    }
};
const updateCheckinCard = async (payLoad: CheckInPayload) => {
    try {
        const apiPath = `/s2l/${payLoad.Facility}/updateCheckinCard`;
        const response = await Lambda.Post(apiPath, payLoad, 'lambda');
        if (!response || !response.Result || !response.Result.Content || !response.Result.Success) {
            throw new Error('Error while upating the check in status.');
        }
        return response.Result.Content;
    } catch (error) {
        throw new Error('Error while updating the check in status.');
    }
};

export { fetchCurrentCheckInStatusOfResident, createCheckInCard, updateCheckinCard };
