import React from 'react';
import { Card, Icon, Grid } from 'semantic-ui-react';
import { ServicesType } from '../../types/ServiceInstance';

interface MenuCardProps {
    menu: ServicesType;
    handleOrder: (selectedMenu: ServicesType) => void;
}
const MenuCard = ({ menu, handleOrder }: MenuCardProps) => {
    return (
        <Card style={{}} className="hover-enlarge-card" >
            <Card.Content>
                <Grid verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width={12}>
                            <div>
                                <h4>{menu.name}</h4>
                                <Card.Description>{menu.shortDescription}</Card.Description>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Icon size='large' color='green' name='exchange' />
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <i style={{ fontSize: "1.9rem", cursor: "pointer", color: "#2d2b87" }} onClick={() => handleOrder(menu)} className="fa-solid fa-bell-concierge"></i>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Card.Content>
        </Card>
    );
};

export default MenuCard;