import React, { useState, useEffect, useContext } from 'react';
import { Button, Icon, Input, Label, Dimmer, Loader, Checkbox } from 'semantic-ui-react';
import CheckoutModal from '../../components/Checkout/CheckoutModal.js';

import CardComponent from '../../components/Checkout/CheckoutComponent';
import {
    getPaymentIntentSecret,
    getPaymentMethod,
    listPaymentMethods,
    detachPaymentMethod,
} from '../../../services/quotas';
import { CardElement } from '@stripe/react-stripe-js';

import { getMyQuota, startSubscription, getUpcomingSubscription, getPromoCodeObject } from '../../../services/quotas';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import config from '../../../config';
import ShowCreditCard from '../../components/ShowCreditCard';
import './style.less';
import { toast } from 'react-toastify';
import RedirectContext from '../../../contexts/RedirectContext';
import UserContext from '../../../contexts/UserContext';

const envConfig = config[process.env.SERVER_ENV] || config.development;

const stripePromise = loadStripe(envConfig.stripePublicKey);

const Checkout = ({ paymentManagerId, invitationId }) => {
    const [numberOfUsers, setNumberOfUsers] = useState(0);
    const [openCheckoutModal, setOpenCheckoutModal] = useState(false); //shows 'Add card' button when set to false
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [currentQuota, setCurrentQuota] = useState(null);
    const [loading, setLoading] = useState(true);
    const toggleRedirect = useContext(RedirectContext);
    const { userObject, reloadRegistrantLists } = useContext(UserContext);
    const [subscribing, setSubscribing] = useState(false);
    const [error, setError] = useState('');
    const [couponCode, setCouponCode] = useState('');
    const [tempCouponCode, setTempCouponCode] = useState();
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [upcomingInvoices, setUpcomingInvoices] = useState();
    const [upcomingInvoiceLoading, setUpcomingInvoicesLoading] = useState(true);
    const [extName, setExtName] = useState('');
    const [extElements, setExtElements] = useState();
    const [extStripe, setExtStripe] = useState();
    const [cards, setCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState('');

    const [fetchingPromoCode, setFetchingPromoCode] = useState(false);
    useEffect(() => {
        setLoading(true);
        console.log('invitationId', invitationId);
        getMyQuota(paymentManagerId).then((e) => {
            console.log('e:', e);
            setLoading(false);

            const stripeCount = e.receipts
                .filter((e) => e.provider === 'stripe')
                .reduce((acc, { quota }) => acc + quota, 0);
            const nonStripeCount = e.receipts
                .filter((e) => e.provider !== 'stripe')
                .reduce((acc, { quota }) => acc + quota, 0);
            console.log(nonStripeCount, 'noc');
            e.stripeCount = stripeCount;
            setCurrentQuota(e);
            // Actual count of number of users is the
            // Total count - count of non stripe subscriptions
            setNumberOfUsers(e.residentCount - nonStripeCount);
            if (e?.receipts[0]?.providerSpecificData?.subscription?.default_payment_method) {
                setSelectedCard(e?.receipts[0]?.providerSpecificData?.subscription?.default_payment_method);
            }
        });
        //Get the cards here.
        listPaymentMethods(paymentManagerId)
            .then((e) => {
                e.paymentMethods.data && setCards(e.paymentMethods.data);
                const cardCount = e?.paymentMethods?.data?.length;
                setOpenCheckoutModal(!cardCount);
            })
            .catch((e) => {
                console.log(e);
            });
    }, []);

    useEffect(() => {
        if (numberOfUsers) {
            setUpcomingInvoicesLoading(true);
            getUpcomingSubscription(paymentManagerId, numberOfUsers, couponCode).then((invoice) => {
                setUpcomingInvoicesLoading(false);
                console.log(invoice);
                invoice && setUpcomingInvoices(invoice && invoice.invoice);
            });
        }
    }, [numberOfUsers, paymentManagerId, couponCode]);

    const fetchPromoCodeObject = async (couponCodeInput) => {
        setFetchingPromoCode(true);
        getPromoCodeObject(couponCodeInput).then((e) => {
            setFetchingPromoCode(false);
            if (e && e.success) {
                setCouponCode(couponCodeInput);

                toast.success('Successfully applied your coupon!', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } else if (e && !e.success) {
                setCouponCode('');

                toast.error(e.message, {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            } else {
                toast.error('Something went wrong. Please try again', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        });
    };

    const extSaveHandler = async () => {
        try {
            setSubscribing(true);
            setError('');
            const { clientSecret } = await getPaymentIntentSecret(paymentManagerId);

            const res = await extStripe?.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: extElements.getElement(CardElement),
                    billing_details: {
                        name: extName,
                    },
                },
            });

            console.log(res, 'res', extStripe);

            if (res.error && res.error.message) {
                setError(res.message || res.error.message);
                // formikOptions.setSubmitting(false);
                setSubscribing(false);
            } else {
                const { paymentMethod } = await getPaymentMethod(res.setupIntent.payment_method, paymentManagerId);
                setSubscribing(false);

                // formikOptions.setSubmitting(false);
                // setFinishedSuccessFullyAddingCard(true);
                // await delay(1000);
                console.log(paymentMethod);
                setPaymentMethod(paymentMethod);
                return paymentMethod;
            }
        } catch (err) {
            console.log('somerthing went wrong', err);
            setSubscribing(false);

            console.log('errrr', err);
            if (err.message || (err.error && err.error.message)) {
                setError(err.message || err.error.message);
            } else {
                setError('Something Went Wrong, please try again');
            }
        }
    };

    const handleSubscription = async () => {
        try {
            const paymentMethodCopy =
                cards.length && selectedCard
                    ? await getPaymentMethod(selectedCard, paymentManagerId).then((e) => {
                          return e.paymentMethod;
                      })
                    : await extSaveHandler();
            if (paymentMethodCopy) {
                console.log(invitationId);

                setError('');
                setSubscribing(true);
                const result = await startSubscription(
                    paymentMethodCopy.id,
                    numberOfUsers,
                    couponCode,
                    paymentManagerId,
                );

                toast.success('You have been subscribed successfully', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });

                // props.history.push('/activities');
                if (!paymentManagerId) {
                    setSubscribing(false);

                    toggleRedirect('/activities');
                } else if (invitationId) {
                    setSubscribing(false);

                    toggleRedirect(`/accept-alexa-invites/${invitationId}`);
                } else {
                    try {
                        await reloadRegistrantLists();
                    } catch (err) {
                        console.log('err');
                    }
                    setSubscribing(false);

                    toggleRedirect('/activities');
                    // toggleRedirect(`/public/checkout-success`);
                }
            } else {
                setError('Something went wrong, try again');
            }
        } catch (err) {
            setError('Something went wrong, try again');

            setSubscribing(false);

            console.log('err:', err);
        }
    };

    const deleteCard = async (paymentMethodId) => {
        try {
            const res = await detachPaymentMethod(paymentManagerId, paymentMethodId);
            if (res) {
                toast.success('Deleted the card successfully', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                const paymentMethodsRes = await listPaymentMethods(paymentManagerId);
                paymentMethodsRes.paymentMethods && setCards(paymentMethodsRes.paymentMethods.data);
            }
        } catch (e) {
            toast.error('Something went wrong. Please try again', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    return loading || !currentQuota ? (
        <Dimmer active={loading} inverted>
            <Loader active={loading} />
        </Dimmer>
    ) : (
        <>
            <Elements stripe={stripePromise}>
                <div className="checkout-page relative-list">
                    <p>
                        <img
                            // style="display: block; margin-left: auto; margin-right: auto;"
                            className="logo-img"
                            src="https://f.hubspotusercontent40.net/hubfs/8458943/Logos/logo-variations@0.25xblcrl.png"
                            alt="Speak2 Family"
                            width="127"
                            height="131"
                        />
                    </p>
                    <h2 className="title">
                        <span className="blue-color">Payment Information</span>
                    </h2>
                    <hr className="underline" align="center" />

                    <p className="details">
                        <strong>You are purchasing a subscription to Speak2 Family.&nbsp;&nbsp;</strong>
                        <br />
                        <br />
                        <p>
                            You currently have {currentQuota.stripeCount} active Speak2family (stripe){' '}
                            {currentQuota.stripeCount === 1 ? 'subscription' : 'subscriptions'}
                        </p>
                    </p>

                    <div className="flex number-of-users-section">
                        <div className="description">Choose Number of users</div>
                        <div className="increment-decrement">
                            <Input
                                labelPosition="right"
                                type="number"
                                value={numberOfUsers}
                                placeholder=""
                                onChange={(e) => {
                                    setNumberOfUsers(parseInt(e.target.value));
                                }}
                            >
                                <Label
                                    onClick={() => {
                                        numberOfUsers > 0 && setNumberOfUsers(numberOfUsers - 1);
                                    }}
                                >
                                    -
                                </Label>
                                <input />
                                <Label
                                    onClick={() => {
                                        setNumberOfUsers(numberOfUsers + 1);
                                    }}
                                >
                                    +
                                </Label>
                            </Input>
                        </div>
                    </div>
                    {upcomingInvoiceLoading ? (
                        <div>
                            <Loader active={upcomingInvoiceLoading} />
                        </div>
                    ) : (
                        <div>
                            {upcomingInvoices &&
                                upcomingInvoices.lines &&
                                upcomingInvoices.lines.data.map((item) => {
                                    return (
                                        <div className="upcoming-line-items">
                                            <div>{item.description}</div>
                                            <div className="amnt">$ {item.amount / 100}</div>
                                        </div>
                                    );
                                })}

                            {upcomingInvoices && upcomingInvoices.total_discount_amounts ? (
                                <div>
                                    {upcomingInvoices.total_discount_amounts.map((discount) => (
                                        <div className="upcoming-line-items">
                                            <div>{couponCode}</div>
                                            <div className="amnt"> - $ {discount.amount / 100}</div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <></>
                            )}
                            {upcomingInvoices ? (
                                <div className="upcoming-line-items-ttl">
                                    <div>
                                        Total Payable on{' '}
                                        {new Date(upcomingInvoices.next_payment_attempt * 1000).toLocaleDateString()}
                                    </div>
                                    <div className="amnt">$ {upcomingInvoices.amount_due / 100}</div>
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                    )}

                    {/* <ShowCreditCard
                        paymentMethod={paymentMethod}
                        setOpenCheckoutModal={setOpenCheckoutModal}
                        isEditCardOpen={openCheckoutModal}
                        paymentManagerId={paymentManagerId}
                    /> */}

                    <ul className="card-list">
                        {cards.length
                            ? cards.map((card) => {
                                  return (
                                      <li
                                          className={
                                              card.id === selectedCard ? 'card-list-item selected' : 'card-list-item'
                                          }
                                          onClick={() => {
                                              setSelectedCard(card.id);
                                          }}
                                          key={card.id}
                                      >
                                          <ShowCreditCard
                                              paymentMethod={card}
                                              setOpenCheckoutModal={setOpenCheckoutModal}
                                              isEditCardOpen={false}
                                              paymentManagerId={paymentManagerId}
                                              deleteCard={deleteCard}
                                          />
                                      </li>
                                  );
                              })
                            : ''}
                    </ul>

                    <CardComponent
                        extSaveHandler={cards.length ? undefined : extSaveHandler}
                        setExtName={setExtName}
                        setExtElements={setExtElements}
                        setExtStripe={setExtStripe}
                        open={openCheckoutModal}
                        cards={cards}
                        paymentManagerId={paymentManagerId}
                        setModalOpen={setOpenCheckoutModal}
                        submitPaymentMethod={(e) => {
                            setCards([...cards, e]);
                            setSelectedCard(e.id);
                            setOpenCheckoutModal(false);
                        }}
                    />

                    {/* <CardComponent
                        // extSaveHandler={extSaveHandler}
                        setExtName={setExtName}
                        setExtElements={setExtElements}
                        setExtStripe={setExtStripe}
                        open={openCheckoutModal}
                        setModalOpen={setOpenCheckoutModal}
                        paymentManagerId={paymentManagerId}
                        submitPaymentMethod={(e) => {
                            setPaymentMethod(e);
                            setOpenCheckoutModal(false);
                        }}
                    /> */}
                    <div className="coupon-code">
                        <div className="coupon-code-title">Coupon Code</div>
                        <div>
                            <Input
                                type="text"
                                value={tempCouponCode}
                                placeholder=""
                                className="coupon-text"
                                onChange={(e) => {
                                    setTempCouponCode(e.target.value);
                                }}
                            ></Input>
                            <br />
                            <Button
                                loading={fetchingPromoCode}
                                className="coupon-btn"
                                onClick={() => {
                                    fetchPromoCodeObject(tempCouponCode).then((e) => {
                                        console.log(e);
                                    });
                                    // setCouponCode(tempCouponCode);
                                }}
                                size="small"
                            >
                                Apply
                            </Button>
                        </div>
                    </div>

                    <div className="error">{error}</div>

                    <div className="terms-and-conditions">
                        Terms of Service
                        <br />
                        You agree to defend, indemnify and hold us and our suppliers, subsidiaries, licensors, and
                        licensees, and each of their officers, directors, shareholders, members, employees and agents
                        harmless from all allegations, judgments, awards, losses, liabilities, costs and expenses,
                        including but not limited to reasonable attorney
                        <br />
                        '92s fees, expert witness fees, and costs of litigation arising out of or based on (a)
                        Submissions or Content you submit, post to or transmit through the Service, (b) your use of the
                        Service, (c) your violation of this Agreement, (d) any conduct, activity or action which is
                        unlawful or illegal under any state, federal or common law, or is violative of the rights of any
                        individual or entity, engaged in, caused by, or facilitated in any way through the use of the
                        Service, and (e) destruction of real or tangible property or personal injury, including but not
                        limited to death, arising from or relating to your actions or inactions.
                        <br />
                        <br />
                        Governing Law and Jurisdiction; Arbitration
                        <br />
                        <br />
                        You agree that any claim or dispute arising out of or relating in any way to the Service will be
                        resolved solely and exclusively by binding arbitration, rather than in court, except that you
                        may assert claims in small claims court if your claims qualify. The FederalArbitration Act and
                        federal arbitration law apply to this agreement. The laws of the State of New York shall govern
                        this Agreement, and shall be used in any arbitration proceeding.
                        <br />
                        <br />
                        There is no judge or jury in arbitration, and court review of an arbitration award is limited.
                        However, an arbitrator can award on an individual basis the same damages and relief as a court
                        (including injunctive and declaratory relief or statutory damages), and must follow the terms of
                        this Agreement as a court would.
                        <br />
                        <br />
                        To begin an arbitration proceeding, you must send a letter requesting arbitration and describing
                        your claim to the following address: Speak2 Software, Inc., 8 Syngle Way, Morganville, New
                        Jersey 07751.Arbitration under this Agreement will be conducted by the American
                        ArbitrationAssociation (AAA) under its rules then in effect, shall be conducted inEnglish, and
                        shall be located in New York City, New York. Payment of all filing, administration and
                        arbitrator fees will be governed by the AAA
                        <br />
                        '92s rules.All aspects of the arbitration proceeding, and any ruling, decision or award by the
                        arbitrator, will be strictly confidential for the benefit of all parties.
                        <br />
                        <br />
                        You and Speak2 agree that any dispute resolution proceedings will be conducted only on an
                        individual basis and not in a class, consolidated or representative action. If for any reason a
                        claim proceeds in court rather than in arbitration, both you and Speak2 agree that each have
                        waived any right to a jury trial.
                        <br />
                        <br />
                        Notwithstanding the foregoing, you agree that we may bring suit in court to enjoin infringement
                        or other misuse of intellectual property or other proprietary rights.
                        <br />
                        <br />
                        To the extent arbitrations does not apply, you agree that any dispute arising out of or relating
                        to the Service, or to us, may only be brought by you in a state or federal court located in
                        NewYork City, New York. YOU HEREBY WAIVE ANY OBJECTION TO THIS VENUE AS INCONVENIENT OR
                        INAPPROPRIATE, AND AGREE TO EXCLUSIVE JURISDICTION AND VENUE INNEW YORK.
                        <br />
                        <br />
                        Policies for Children
                        <br />
                        <br />
                        The Service is not directed to individuals under the age of 13. In the event that we discover
                        that a child under the age of 13 has provided personally identifiable information to us, we will
                        make efforts to delete the child
                        <br />
                        '92s information if required by the Children
                        <br />
                        '92s OnlinePrivacy Protection Act. Please see the Federal Trade Commission
                        <br />
                        '92s website for (www.ftc.gov) for more information.
                        <br />
                        <br />
                        Notwithstanding the foregoing, pursuant to 47 U.S.C. Section 230 (d), as amended, we hereby
                        notify you that parental control protections are commercially available to assist you in
                        limiting access to material that is harmful to minors. More information on the availability of
                        such software can be found through publicly available sources. You may wish to contact your
                        internet service provider for more information.
                        <br />
                        <br />
                        General
                        <br />
                        <br />
                        Severability. If any provision of this Agreement is found for any reason to be unlawful, void or
                        unenforceable, then that provision will be given its maximum enforceable effect, or shall be
                        deemed severable from this Agreement and will not affect the validity and enforceability of any
                        remaining provision.
                        <br />
                        <br />
                        Revisions. This Agreement is subject to change on a prospective basis at any time. In the event
                        that we change this Agreement, you may be required to re-affirm the Agreement through use of the
                        Service or otherwise. Your use of the Service after the effective date of any changes will
                        constitute your acceptance of such changes.
                        <br />
                        <br />
                        No Partnership. You agree that no joint venture, partnership, employment, or agency relationship
                        exists between you and us as a result of this Agreement or your use of the Service.
                        <br />
                        <br />
                        Assignment. We may assign our rights under thisAgreement, in whole or in part, to any person or
                        entity at any time with or without your consent. You may not assign the Agreement without our
                        prior written consent, and any unauthorized assignment by you shall be null and void.
                        <br />
                        <br />
                        No Waiver. Our failure to enforce any provision of this Agreement shall in no way be construed
                        to be a present or future waiver of such provision, nor in any way affect the right of any party
                        to enforce each and every such provision thereafter. The express waiver by us of any provision,
                        condition or requirement of this Agreement shall not constitute a waiver of any future
                        obligation to comply with such provision, condition or requirement.
                        <br />
                        <br />
                        Notices. All notices given by you or required under this Agreement shall be in writing and
                        addressed to: Legal Department, Speak2Software, Inc., 8 Syngle Way, Morganville, New Jersey
                        07751, or sent via email to contact@speak2software.com.
                        <br />
                        <br />
                        Equitable Remedies. You hereby agree that we would be irreparably damaged if the terms of this
                        Agreement were not specifically enforced, and therefore you agree that we shall be entitled,
                        without bond, other security, or proof of damages, to appropriate equitable remedies with
                        respect to breaches of this Agreement, in addition to such other remedies as we may otherwise
                        have available to us under applicable laws.
                        <br />
                        <br />
                        Force Majeure. In no event shall we or our affiliates be liable to you for any damage, delay, or
                        failure to perform resulting directly or indirectly from a force majeure event.
                        <br />
                        <br />
                        Entire Agreement. This Agreement, including the documents expressly incorporated by reference,
                        constitutes the entire agreement between you and us with respect to the Service, and supersedes
                        all prior or contemporaneous communications, whether electronic, oral or written.
                        <br />
                        <br />
                        Copyright Policy
                        <br />
                        <br />
                        If you believe in good faith that any material posted on our Services infringes the copyright in
                        your work, please contact our copyright agent, designated under the Digital Millennium Copyright
                        Act (<br />
                        '93DMCA
                        <br />
                        '94) (17 U.S.C. <br />
                        'a7512(c)(3)), with correspondence containing the following:
                        <br />
                        <br />
                        <br />
                        uc0
                        <br />
                        &#9679; A physical or electronic signature of the owner, or a person authorized to act on behalf
                        of the owner, of the copyright that is allegedly infringed;
                        <br />
                        <br />
                        <br />
                        uc0
                        <br />
                        &#9679; Identification of the copyrighted work claimed to have been infringed;
                        <br />
                        <br />
                        <br />
                        uc0
                        <br />
                        &#9679; Identification, with information reasonably sufficient to allow its location of the
                        material that is claimed to be infringing;
                        <br />
                        <br />
                        <br />
                        uc0
                        <br />
                        &#9679; Information reasonably sufficient to permit us to contact you;
                        <br />
                        <br />
                        <br />
                        uc0
                        <br />
                        &#9679; A statement that you have a good faith belief that use of the material in the manner
                        complained of is not authorized by the copyright owner, its agent, or the law; and,
                        <br />
                        <br />
                        <br />
                        uc0
                        <br />
                        &#9679; A statement that the information in the notification is accurate, and under penalty of
                        perjury, that you are authorized to act on behalf of the owner of an exclusive right that is
                        allegedly infringed.
                        <br />
                        <br />
                        You acknowledge that if you fail to comply with all of the requirements of this policy, your
                        DMCA notice may not be valid. For any questions regarding this procedure, or to submit a
                        complaint, please contact our designated DMCA Copyright Agent:
                        <br />
                        <br />
                        Copyright Agent
                        <br />
                        Speak2 Software,Inc.
                        <br />
                        <br />
                        8 Syngle Way
                        <br />
                        <br />
                        Morganville, New Jersey 07751
                        <br />
                        e-mail: contact@speak2software.com
                        <br />
                        <br />
                        <br />
                        <br />
                        Complaint Policy (Including Trademark and Privacy)
                        <br />
                        <br />
                        If you believe in good faith that any material posted on the Service infringes any of your
                        rights other than in copyright, or is otherwise unlawful, you must send a notice to
                        contact@speak2software.com containing the following information:
                        <br />
                        <br />
                        <br />
                        uc0
                        <br />
                        &#9679; Your name, physical address, e-mail address and phone number;
                        <br />
                        <br />
                        <br />
                        uc0
                        <br />
                        &#9679; A description of the material posted on the Service that you believe violates your
                        rights or is otherwise unlawful, and which parts of said materials you believe should be
                        remedied or removed;
                        <br />
                        <br />
                        <br />
                        uc0
                        <br />
                        &#9679; Identification of the location of the material on the Service;
                        <br />
                        <br />
                        <br />
                        uc0
                        <br />
                        &#9679; If you believe that the material violates your rights, a statement as to the basis of
                        the rights that you claim are violated;
                        <br />
                        <br />
                        <br />
                        uc0
                        <br />
                        &#9679; If you believe that the material is unlawful or violates the rights of others, a
                        statement as to the basis of this belief;
                        <br />
                        <br />
                        <br />
                        uc0
                        <br />
                        &#9679; A statement under penalty of perjury that you have a good faith belief that use of the
                        material in the manner complained of is not authorized and that the information you are
                        providing is accurate to the best of your knowledge and in good faith; and
                        <br />
                        <br />
                        <br />
                        uc0
                        <br />
                        &#9679; Your physical or electronic signature.
                        <br />
                        <br />
                        If we receive a message that complies with all of these requirements, we will evaluate the
                        submission, and if appropriate, in our sole discretion, we will take action. We may disclose
                        your submission to the poster of the claimed violative material, or any other party.
                        <br />
                        <br />
                        All notices given by you or required under thisAgreement shall be in writing and addressed to:
                        Speak2 Software, Inc., 8 Syngle Way, Morganville, New Jersey 07751, or sent via email to
                        contact@speak2software.com.
                    </div>
                    <div>
                        <Checkbox
                            value={agreeToTerms}
                            onChange={(e, data) => {
                                const { checked } = data;
                                setAgreeToTerms(checked);
                            }}
                            label="I agree to terms and conditions"
                        />
                    </div>

                    <div className="action-container">
                        <Button
                            primary
                            large
                            onClick={handleSubscription}
                            disabled={!numberOfUsers || !agreeToTerms}
                            loading={subscribing}
                        >
                            Submit
                        </Button>
                        <Button
                            large
                            loading={subscribing}
                            onClick={() => {
                                toggleRedirect('/activities');
                            }}
                        >
                            Cancel
                        </Button>
                    </div>

                    <div className="stripe-section">
                        <p>
                            Secure Payments with&nbsp;
                            <br />
                            <img
                                src="https://f.hubspotusercontent40.net/hubfs/8458943/guides/Stripe%20wordmark%20-%20slate_sm.png"
                                alt="Stripe"
                                width="63"
                                height="30"
                            />
                        </p>
                        <hr className="underline" align="center" />
                    </div>

                    <div className="download-section">
                        <h2>Download Our Apps Here</h2>

                        <table className="download-table">
                            <tbody>
                                <tr>
                                    <td>
                                        <a
                                            title="Download App"
                                            href="https://apps.apple.com/us/app/speak2-family/id1503580779"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            <img
                                                src="https://email.speak2family.com/hs-fs/hubfs/Apple%20Store%20Download.png?width=240&amp;upscale=true&amp;name=Apple%20Store%20Download.png"
                                                alt="Apple Store"
                                                width="150"
                                                height="50"
                                            />
                                        </a>
                                    </td>

                                    <td>
                                        <a
                                            title="Google Store"
                                            href="https://play.google.com/store/apps/details?id=com.speak2software.family"
                                            target="_blank"
                                            rel="noopener"
                                        >
                                            <img
                                                src="https://email.speak2family.com/hs-fs/hubfs/Google%20Play.png?width=300&amp;upscale=true&amp;name=Google%20Play.png"
                                                alt="Google Store"
                                                width="181"
                                                height="70"
                                            />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div className="social-section">
                        <div>
                            <a
                                title="Speak2 Family"
                                href="https://www.facebook.com/speak2family/"
                                target="_blank"
                                rel="noopener"
                            >
                                <img
                                    src="https://email.speak2family.com/hs/hsstatic/TemplateAssets/static-1.24/img/hs_default_template_images/modules/Follow+Me+-+Email/facebook_circle_color.png"
                                    alt="Facebook"
                                    width="40"
                                    height="40"
                                />
                            </a>
                            <a
                                title="Speak2 Family"
                                href="https://www.linkedin.com/company/71667950/"
                                target="_blank"
                                rel="noopener"
                            >
                                <img
                                    src="https://email.speak2family.com/hs/hsstatic/TemplateAssets/static-1.24/img/hs_default_template_images/modules/Follow+Me+-+Email/linkedin_circle_color.png"
                                    alt="LinkedIn"
                                    width="40"
                                    height="40"
                                />
                            </a>
                            <a
                                title="Speak2 Family"
                                href="http://instagram.com/speak2family"
                                target="_blank"
                                rel="noopener"
                            >
                                <img
                                    src="https://email.speak2family.com/hs/hsstatic/TemplateAssets/static-1.24/img/hs_default_template_images/modules/Follow+Me+-+Email/instagram_circle_color.png"
                                    alt="Instagram"
                                    width="40"
                                    height="40"
                                />
                            </a>
                        </div>
                        <div className="bottom-speak2">
                            Speak2 Family, 1 Washington Pl 7th floor, Newark, New Jersey 07102, United States
                        </div>
                    </div>
                </div>
            </Elements>
        </>
    );
};

export default Checkout;
