import React, { FC } from 'react';
import { Icon } from 'semantic-ui-react';
import '../../pages/calendar/style.less';

interface Props {
    info: any;
}

const CalendarEventContent: FC<Props> = ({ info }) => {

    if (!info || !info.event) {
        console.error('Invalid event info:', info);
        return null; // If info or info.event is not available, return null
    }

    const { event } = info;
    const hasAudio = event.classNames && event.classNames.includes("audio");
    const startTime = info.timeText;
    const title = event.title || 'Untitled Event';

    return (
        <div className="custom-event-content" style={{ backgroundColor: event.backgroundColor || event.borderColor || '#0E8ED7' }}>
            <div className="fc-event-title-time" style={{ color: `${event.color || 'white'}`}} >
                <span id='eventStartTime'>{startTime}</span>
                <span >{title}</span>
            </div>
            {hasAudio && <Icon name='microphone' />}
        </div>
    );
};

export default CalendarEventContent;
