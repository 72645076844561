import React from 'react';
import { Dimmer, Grid, Loader, Segment } from 'semantic-ui-react';
import "./style.less";
import { Document } from '../../types/Document';
import moment from 'moment-timezone';
import LoadMore from '../../../utils/LoadMore';

interface Props {
    subHeading: string;
    docs: Document[];
    loading: boolean;
    hasMore: boolean;
    setPageNo: React.Dispatch<React.SetStateAction<number>>;
    facilityTimezone: string;
}

const DocumentList = ({ subHeading, docs, loading, setPageNo, hasMore, facilityTimezone }: Props) => {
    return (
        <div style={{ position: 'relative' }}>
            <Dimmer active={loading} inverted>
                <Loader />
            </Dimmer>
            <h3 className="center">{subHeading}</h3>
            <div className="docsContainer">
                {docs && docs.length > 0 ? docs.map((doc, index) => (
                    <Segment key={doc._id} basic style={{ backgroundColor: index % 2 == 0 ? "white" : "#E8E8E8" }}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column width={8} textAlign="left">
                                    <h4><a href={doc.attachedFiles && doc.attachedFiles[0].attachedLink} target="_blank">{doc.Name}</a></h4>
                                    <h4 className="nonBold">{doc.Description}</h4>
                                </Grid.Column>
                                <Grid.Column width={8} textAlign="right">
                                    <h4>{moment.tz(doc.DateAdded, facilityTimezone).format("MM/DD/YYYY")}</h4>
                                    <h4 className="nonBold">{doc.AddedByName}</h4>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Segment>
                )) : <h4 className="center">No documents found</h4>}
                <LoadMore isLoading={loading} hasMore={hasMore} next={() => { setPageNo((prev) => prev + 1); }} />
            </div>
        </div>
    );
};

export default DocumentList;