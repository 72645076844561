import * as Lambda from './LambdaApi';
import { Auth } from 'aws-amplify';
const getUserDbProfile = async () => {
    const response = await Auth.currentAuthenticatedUser();
    const { attributes } = response;

    const userId = attributes['custom:userId'];

    const apiPath = `/users/${userId}`;
    return Lambda.Get(apiPath, {}, 'lambda');
};

const createPermanentConnection = async (Token, RegistrantId) => {
    const apiPath = '/tokentopermanent';
    const payloadObject = {
        Token,
        RegistrantId,
    };
    return Lambda.Post(apiPath, payloadObject, 'lambda');
};

const getRelatedRegistrants = async () => {
    const apiPath = '/residents';
    return Lambda.Get(apiPath, {}, 'lambda');
};

export { getUserDbProfile, createPermanentConnection, getRelatedRegistrants };
