import React, { useState, useContext, useEffect } from 'react';
import { Sidebar, Menu, Icon, Button, Dropdown, Grid, Popup, Loader, Segment, DropdownMenu, DropdownItem, Confirm } from 'semantic-ui-react';

import * as Authentication from '../../../services/Authentication';
import menus from './menu';

import './style.less';
import UserContext from '../../../contexts/UserContext';
import RedirectContext from '../../../contexts/RedirectContext';
import { listInvites } from '../../../services/Invite';
import { Link } from 'react-router-dom';
import * as CheckIn from '../../../neighbors/services/CheckIn';
import { CheckInPayload } from '../../../neighbors/types/CheckIn';
import { neighborsFilledLogoPath } from '../../../utils/logoPaths';
import sendToast from '../../../utils/Toast';
import { fetchFacilityDetails } from '../../../neighbors/services/Facilities';
import { ChangePasswordModal } from '../../pages/profile-settings';
import { deleteRelative } from '../../../services/family';

interface InviteInterface {
    _id: string;
    registrantId: string;
    registrantName: string;
    registrantImage?: string;
}

const Header = ({ location, showSidebarProp }) => {
    const [showSidebar, setShowSidebar] = useState<boolean>(showSidebarProp);
    const [pageName, setPageName] = useState('Speak2 Family');
    const [isManagerRequired, setIsManagerRequired] = useState(false);
    const [showResidentScroll, setShowResidentScroll] = useState(true);

    const { userObject, setUser } = useContext(UserContext);

    const {
        registrants: { list: listOfResidents, activeRegistrantsIndexes },
        profile: relativeProfile
    } = userObject;

    const toggleRedirect = useContext(RedirectContext);
    const [invites, setInvites] = useState<InviteInterface[]>([]);
    const [isSendMessagesPage, setIsSendMessagesPage] = useState(false);
    const [isMultiSelectEnabled, setIsMultiSelectEnabled] = useState(false);
    const [isMultiMode, setIsMultiMode] = useState(false);
    const [multiModeSelectedResidentIndices, setMultiModeSelectedResidentIndices] = useState<number[]>([]);
    const [checkInStatus, setCheckInStatus] = useState<Boolean>(false);
    const [residentCheckInStatusObj, setResidentCheckInStatusObj] = useState<CheckInPayload>();
    const [isMailIn, setIsMailIn] = useState<Boolean | undefined>(false);
    const [isCheckInStatusLoading, setIsCheckInStatusLoading] = useState(false);
    const [isMailInLoading, setIsMailInLoading] = useState(false);
    const [confirmation, setConfirmation] = useState<boolean>(false);
    const [accountSettingsAction, setAccountSettingsAction] = useState<string>('');
    const [isChangePasswordModal, setIsChangePasswordModal] = useState<boolean>(false);
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);


    const activeResident = listOfResidents[activeRegistrantsIndexes[0]]; // get the selected resident to perform resident actions
    const headerIcons = [
        { iconName: 'fa-solid fa-people-arrows', link: '/resident-social', name: 'Resident/Social', color: '#0E8ED7' },
        {
            iconName: 'fa-solid fa-envelope',
            link: '/mail-is-in',
            name: isMailIn ? 'The mail has arrived.' : "The mail hasn't arrived yet today.",
            color: isMailIn ? '#69B546' : '#ed3737',
        },
        {
            iconName: 'fa-solid fa-circle-check',
            link: '/check-in',
            name: checkInStatus ? 'You have already Checked in for the Day.' : 'Check In',
            color: checkInStatus ? '#69B546' : '#0E8ED7',
        },
    ];

    const accountSettingOptions = [
        { key: 'invites', text: 'Invites', value: 'invites' },
        { key: 'address book', text: 'Address Book', value: 'address book' },
        { key: 'change password', text: 'Change Password', value: 'change password' },
        { key: 'log out', text: 'Log Out', value: 'log out' },
        { key: 'delete account', text: 'Delete Account', value: 'delete account' },
    ]

    const navigate = (to: string) => {
        setShowSidebar(false);
        toggleRedirect(to);
        return;
    };

    const handleLogout = async () => {
        try {
            await Authentication.cognitoSignOut();

            const signedOutUser = {
                authenticatedUser: undefined,
                profile: undefined,
                authenticated: false,
            };
            setUser(signedOutUser);
            toggleRedirect('/login');
        } catch (error) {
            console.log(error);
        }
    };

    const handleMultiSelectButtonClick = async () => {
        if (isMultiMode) {
            multiModeSelectedResidentIndices.length &&
                setUser({
                    ...userObject,
                    registrants: {
                        ...userObject.registrants,
                        activeRegistrantsIndexes: multiModeSelectedResidentIndices,
                    },
                });
            setMultiModeSelectedResidentIndices([]);
            setIsMultiMode(false);
        } else {
            setIsMultiMode(true);
            setMultiModeSelectedResidentIndices([]);
        }
    };

    const getCurrentPageSettings = () => {
        const path = location.pathname;

        const [page] = menus.filter((e) => {
            const pathRegexp = new RegExp(e.regex || e.location);

            return pathRegexp.test(path);
        });

        return page || {};
    };

    useEffect(() => {
        loadInvites();
    }, []);

    useEffect(() => {
        setShowSidebar(showSidebarProp);
    }, [showSidebarProp]);


    useEffect(() => {
        if (!activeResident || !activeResident._id) {
            return;
        }
        const fetchCurrentResidentDetails = async () => {
            setIsCheckInStatusLoading(true);
            const facilityTimeZone = await fetchFacilityDetail();
            if (facilityTimeZone) {
                await fetchCheckInStatus(facilityTimeZone);
            } else {
                sendToast('error', 'Error fetching facility details');
            }
            setIsCheckInStatusLoading(false);
        };
        fetchCurrentResidentDetails();
    }, [userObject]);

    const fetchFacilityDetail = async (): Promise<string | undefined> => {
        setIsMailInLoading(true);
        try {
            const facilityRes = await fetchFacilityDetails(activeResident.Facility);
            setIsMailIn(facilityRes.isMailIn);
            return facilityRes?.FacilityTimeZone;
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Error while fetching facility details');
        } finally {
            setIsMailInLoading(false);
        }
    };
    const fetchCheckInStatus = async (facilityTimeZone: string) => {
        try {
            const response = await CheckIn.fetchCurrentCheckInStatusOfResident(activeResident.Facility, String(activeResident._id), facilityTimeZone);
            const result = response?.Result?.[0];
            setResidentCheckInStatusObj(result);
            let isCheckedIn = false;
            if (result?.column && result.column !== 'other' && result.column !== 'not_checked_in') {
                isCheckedIn = true;
            }
            setCheckInStatus(isCheckedIn);
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Error while checking the check-in status');
        }
    };

    const loadInvites = async () => {
        try {
            if (userObject.profile) {
                // @ts-ignore
                const fetchedInvites = await listInvites(userObject.profile._id);
                setInvites(fetchedInvites);
            }
        } catch (error) {
            console.log('Something went wrong');
        }
    };

    useEffect(() => {
        const { pageName, showResidentScroll, showToManagerOnly } = getCurrentPageSettings();
        setPageName(pageName);
        console.log('Pagename: ', pageName)
        setIsManagerRequired(showToManagerOnly || false);
        setShowResidentScroll(showResidentScroll);
    }, [location.pathname]);

    useEffect(() => {
        if (process.env.REACT_APP_PLATFORM === 'community') {
            // const activityDetailPageRegex = /^\/activities\/?.*[a-z0-9A-Z]$/g;
            const requestDetailPageRegex = /^\/requests\/?.*[a-z0-9A-Z]$/g;
            const { pathname } = location;
            if (
                pathname === '/news' ||
                // pathname === '/activities' ||
                pathname === '/archives' ||
                // activityDetailPageRegex.test(pathname) ||
                requestDetailPageRegex.test(pathname)
            ) {
                setIsSendMessagesPage(true);
                setIsMultiSelectEnabled(true);
            } else {
                setIsSendMessagesPage(false);
                setIsMultiSelectEnabled(false);
                (userObject.registrants.activeRegistrantsIndexes[0] === 'all-users' ||
                    userObject.registrants.activeRegistrantsIndexes.length > 1) &&
                    setUser({
                        ...userObject,
                        registrants: { ...userObject.registrants, activeRegistrantsIndexes: [0] },
                    });
            }
        } else {
            const activityDetailPageRegex = /^\/activities\/?.*[a-z0-9A-Z]$/g;
            const requestDetailPageRegex = /^\/requests\/?.*[a-z0-9A-Z]$/g;
            const { pathname } = location;
            if (
                pathname === '/news' ||
                pathname === '/activities' ||
                pathname === '/archives' ||
                activityDetailPageRegex.test(pathname) ||
                requestDetailPageRegex.test(pathname)
            ) {
                setIsSendMessagesPage(true);
                setIsMultiSelectEnabled(true);
            } else {
                setIsSendMessagesPage(false);
                setIsMultiSelectEnabled(false);
                (userObject.registrants.activeRegistrantsIndexes[0] === 'all-users' ||
                    userObject.registrants.activeRegistrantsIndexes.length > 1) &&
                    setUser({
                        ...userObject,
                        registrants: { ...userObject.registrants, activeRegistrantsIndexes: [0] },
                    });
            }
        }
    }, [location.pathname]);

    const handleCheckInStatus = async () => {
        if (!activeResident || !activeResident._id) {
            sendToast('error', "Selected resident data is missing or incomplete. Please contact support.");
            return;
        }
        const payload: CheckInPayload = {
            column: 'checked_in',
            registrant: {
                _id: String(activeResident._id),
                FirstName: activeResident.FirstName || '',
                LastName: activeResident.LastName || '',
            },
            Facility: activeResident.Facility || '',
            registrantId: String(activeResident._id),
            roomName: activeResident.Unit.Name || '',
            roomId: activeResident.Unit.a4hRoomId || '',
            timestamp: Date.now(),
        };
        try {
            setIsCheckInStatusLoading(true);
            if (!checkInStatus && !residentCheckInStatusObj) {
                const response = await CheckIn.createCheckInCard(payload);
                if (response.status === "success") {
                    setCheckInStatus(true);
                }
            } else if (!checkInStatus && residentCheckInStatusObj) {
                const updatedCheckInStatusResponse: CheckInPayload = {
                    ...residentCheckInStatusObj,
                    column: "checked_in",
                    prev_col: residentCheckInStatusObj.column,
                };
                setResidentCheckInStatusObj(updatedCheckInStatusResponse);
                const response = await CheckIn.updateCheckinCard(updatedCheckInStatusResponse);
                if (response.status === "success") {
                    setCheckInStatus(true);
                }
            }
        } catch (error) {
            sendToast("error", "Error while updating the check in status");
        } finally {
            setIsCheckInStatusLoading(false);
        }
    };

    const handleManageResidents = () => {
        navigate('/manage-profiles');
    };

    const handleAccountSettingDropdownChange = (action: string) => {
        if (action === 'invites') {
            navigate('/invites');
            return;
        } else if (action === 'address book') {
            navigate('/addressbook');
            return;
        }
        setAccountSettingsAction(action);
        setConfirmation(true);
    };

    const cancelConfirmation = () => {
        setConfirmation(false);
        setAccountSettingsAction('');
    };

    const approveConfirmation = async () => {
        setConfirmLoading(true);
        if (accountSettingsAction === 'log out') {
            await handleLogout();
        } else if (accountSettingsAction === 'delete account') {
            await deleteUser();
        } else if (accountSettingsAction === 'change password') {
            setIsChangePasswordModal(true);
        }
        setConfirmLoading(false);
        setAccountSettingsAction('');
        setConfirmation(false);
    };

    const deleteUser = async () => {
        try {
            setConfirmLoading(true);
            if (!relativeProfile._id) {
                sendToast('error', 'User not found');
                return;
            };
            await deleteRelative(relativeProfile._id);
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Failed to delete account');
        } finally {
            setConfirmLoading(false);
            window.location.reload();
        }
    }

    const ProfileHeader = ({ userObject, openSideBar, pageName }) => {
        return (
            <div className="headerToolbar">
                <Button icon="bars" circular onClick={() => openSideBar()} />
                <span className="page-name">{pageName}</span>
                <div className="right">
                    <span className={`username ${invites.length && 'has-invites'}`}>
                        <Dropdown text={`${userObject.profile.FirstName}`}>
                            <Dropdown.Menu className="left">
                                <Dropdown.Item onClick={() => navigate('/profile-settings')} text="Profile Settings" />
                                <Dropdown.Item onClick={() => navigate('/invites')}>
                                    Invites
                                    <span className={`${!!invites.length ? 'invite-count' : 'no-display'}`}>
                                        {invites.length}
                                    </span>
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={handleLogout} text="Log Out" />
                            </Dropdown.Menu>
                        </Dropdown>
                    </span>
                </div>
            </div>
        );
    };

    const ConnectedRegistrantsList = ({ userObject, isManagerRequired }) => {
        if (!userObject.registrants || !userObject.registrants.list.length) return null;

        return userObject.registrants.list.map((registrant: any, index) => {
            if ((!registrant.manager && isManagerRequired) || !showResidentScroll) {
                return (
                    <div className="name-image-container">
                        <div className={`resident-image-container`} key={index}>
                            {registrant.Image ? (
                                <div
                                    className="resident-image resident-image-bk"
                                    style={{
                                        background: `url(${registrant.Image}) no-repeat`,
                                        backgroundPosition: 'center',
                                    }}
                                />
                            ) : (
                                <div className="relative-avatar">
                                    {registrant && !!registrant.FirstName && registrant.FirstName.charAt(0)}
                                    {registrant && !!registrant.LastName && registrant.LastName.charAt(0)}
                                </div>
                            )}
                            <div className="image-dimmer"></div>
                        </div>
                        <div data-tooltip={`${registrant.FirstName} ${registrant.LastName}`} data-position="top left">
                            <div className="registrant-name-header">
                                {registrant && `${registrant.FirstName} ${registrant.LastName}`}
                            </div>
                        </div>
                    </div>
                );
            }
            return (
                <div
                    key={registrant._id}
                    className={`name-image-container ${pageName === 'Activities' && !registrant.canRecieveRequests && !registrant.manager
                            ? 'disabled'
                            : registrant.isDisabledForPayment ? 'disabled-for-paid' : ''
                        }`}
                >
                    <div
                        className={`resident-image-container
						${!isMultiMode
                                ? index ===
                                    userObject.registrants.activeRegistrantsIndexes.find((elem) => elem === index)
                                    ? 'active-registrant'
                                    : ''
                                : multiModeSelectedResidentIndices.indexOf(index) !== -1
                                    ? 'active-registrant'
                                    : ''
                            }`}
                        key={index}
                        onClick={() => {
                            if (isMultiMode) {
                                const indexInMultiModeArray = multiModeSelectedResidentIndices.indexOf(index);
                                if (indexInMultiModeArray === -1) {
                                    setMultiModeSelectedResidentIndices((prevIndices) => [...prevIndices, index]);
                                } else {
                                    setMultiModeSelectedResidentIndices((prevIndices) =>
                                        prevIndices.filter((e) => e !== index),
                                    );
                                }
                            } else {
                                setUser({
                                    ...userObject,
                                    registrants: { ...userObject.registrants, activeRegistrantsIndexes: [index] },
                                });
                            }
                        }}
                    >
                        {registrant.Image ? (
                            <div
                                className="resident-image resident-image-bk"
                                style={{
                                    background: `url(${registrant.Image}) no-repeat`,
                                    backgroundPosition: 'center',
                                }}
                            />
                        ) : (
                            <div className="relative-avatar">
                                {registrant && !!registrant.FirstName && registrant.FirstName.charAt(0)}
                                {registrant && !!registrant.LastName && registrant.LastName.charAt(0)}
                            </div>
                        )}
                    </div>
                    <div data-tooltip={`${registrant.FirstName} ${registrant.LastName}`} data-position="top left">
                        <div className="registrant-name-header">
                            {registrant && `${registrant.FirstName} ${registrant.LastName}`}
                        </div>
                    </div>
                </div>
            );
        });
    };

    const AllResidentLink = ({ userObject, isManagerRequired }) => {
        return (
            <div key="all" className="name-image-container">
                <div
                    className={`resident-image-container
						${userObject.registrants.activeRegistrantsIndexes.find((elem) => elem === 'all-users') ? 'active-registrant' : ''}`}
                    key={'all-users'}
                    onClick={() => {
                        if (!isMultiMode) {
                            setUser({
                                ...userObject,
                                registrants: { ...userObject.registrants, activeRegistrantsIndexes: ['all-users'] },
                            });
                        }
                    }}
                >
                    <div className="relative-avatar">
                        <Icon disabled={isMultiMode} name="users" />
                    </div>
                    {isMultiMode && <div className="image-dimmer"></div>}
                </div>
                <div data-tooltip={'All Users'} data-position="top left">
                    <div className="registrant-name-header">All Users</div>
                </div>
            </div>
        );
    };

    const residentSocialFullName = (activeResident?.SocialProfile?.FirstName || activeResident?.SocialProfile?.LastName) ? (`${activeResident?.SocialProfile.FirstName} ${activeResident?.SocialProfile.LastName}`).trim() : null;
    const residentFullName = (`${activeResident?.FirstName} ${activeResident?.LastName}`).trim();
    return (
        <header className="header" style={{ padding: process.env.REACT_APP_PLATFORM === "neighbors" ? "15px 0 10px 0" : "" }}>
            {process.env.REACT_APP_PLATFORM === "neighbors" ?
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Link to={'/'}>
                        <img src={`${process.env.PUBLIC_URL}${neighborsFilledLogoPath}`} alt="Speak 2 Neighbors Logo" style={{ height: '40px', marginLeft: '35px' }} />
                    </Link>
                    {activeResident && (
                        <Popup
                            content="Manage Profiles"
                            trigger={
                                <Segment
                                    style={{ marginLeft: 'auto', cursor: 'pointer' }}
                                    onClick={handleManageResidents}
                                >
                                    Current Profile - <b>{residentSocialFullName || residentFullName}</b>
                                </Segment>
                            }
                            size={'tiny'}
                            position="bottom center"
                        />
                    )}
                    <Grid style={{ padding: '0px 30px', alignItems: "center" }} centered columns={5}>
                        {activeResident && headerIcons.map((el, i) => {
                            const isCheckInLoading = isCheckInStatusLoading && el.link === "/check-in";
                            const isMailoading = isMailInLoading && el.link === "/mail-is-in";
                            if (el.link === "/check-in" && !checkInStatus) {
                                return (
                                    <Grid.Column key={i} style={{ padding: '0px 20px', width: "53px" }} onClick={() => handleCheckInStatus()}>
                                        {isCheckInLoading ? (<Loader active inline="centered" size="small" className='header-loader' />) : <Popup
                                            size="mini"
                                            hoverable
                                            position='bottom center'
                                            content={
                                                el.name.replace(
                                                    el.name[0],
                                                    el.name[0].toUpperCase(),
                                                )
                                            }
                                            trigger={
                                                <div style={{ position: 'relative', display: 'inline-block' }}>
                                                    <i className={el.iconName} style={{ color: el.color, fontSize: '32px' }} />
                                                </div>
                                            }
                                        />}
                                    </Grid.Column>
                                );
                            } else {
                                return (
                                    <Grid.Column key={i} style={{ padding: '0px 20px', width: "53px" }}>
                                        {isMailoading ? (<Loader active inline="centered" size="small" className='header-loader' />) : (el.name === 'My Profile' || el.name === 'Resident/Social') ? <Link to={el.link}>
                                            <Popup
                                                size="mini"
                                                hoverable
                                                position='bottom center'
                                                content={
                                                    el.name.replace(
                                                        el.name[0],
                                                        el.name[0].toUpperCase(),
                                                    )
                                                }
                                                trigger={
                                                    <i className={el.iconName} style={{ color: el.color, fontSize: '32px' }} />
                                                }
                                            />
                                        </Link> :
                                            <Popup
                                                size="mini"
                                                hoverable
                                                position='bottom center'
                                                content={
                                                    el.name.replace(
                                                        el.name[0],
                                                        el.name[0].toUpperCase(),
                                                    )
                                                }
                                                trigger={
                                                    <div style={{ position: 'relative', display: 'inline-block' }}>
                                                        <i className={el.iconName} style={{ color: el.color, fontSize: '32px' }} />
                                                    </div>
                                                }
                                            />
                                        }
                                    </Grid.Column>
                                );
                            }
                        })
                        }
                        <Dropdown
                            icon='bars'
                            floating
                            className='icon'
                        >
                            <DropdownMenu direction='left'>
                                {accountSettingOptions.map((option) => (
                                    <DropdownItem key={option.key} onClick={() => handleAccountSettingDropdownChange(option.value)}>{option.text}</DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    </Grid>
                    <ChangePasswordModal contact={relativeProfile.Username} open={isChangePasswordModal} close={() => setIsChangePasswordModal(false)} />
                    <Confirm
                        open={confirmation}
                        onCancel={cancelConfirmation}
                        content={`Are you sure you want to ${accountSettingsAction}?`}
                        confirmButton={() => { return <Button type="button" primary content='Yes' disabled={confirmLoading} loading={confirmLoading} onClick={approveConfirmation} />; }}
                    />
                </div> :
                <></>
            }
            {process.env.REACT_APP_PLATFORM !== "neighbors" ? <>
                <div className="resident-links" style={{ maxWidth: "900px", margin: "auto" }}>
                    {isMultiSelectEnabled &&
                        (isMultiMode ? (
                            <div key="multi-ok" className="name-image-container">
                                <Button
                                    className="resident-image-container"
                                    icon="check"
                                    onClick={handleMultiSelectButtonClick}
                                    positive
                                    compact
                                ></Button>
                                <div data-tooltip={'Okay'} data-position="top left">
                                    <div className="registrant-name-header">OK</div>
                                </div>
                            </div>
                        ) : (
                            <div key="multi" className="name-image-container">
                                <Button
                                    className="resident-image-container"
                                    icon="add user"
                                    onClick={handleMultiSelectButtonClick}
                                    primary
                                    compact
                                ></Button>
                                <div data-tooltip={'Select multiple users'} data-position="top left">
                                    <div className="registrant-name-header">Multi</div>
                                </div>
                            </div>
                        ))}
                    {isSendMessagesPage && (
                        <AllResidentLink userObject={userObject} isManagerRequired={isManagerRequired} />
                    )}

                    <ConnectedRegistrantsList
                        userObject={userObject}
                        isManagerRequired={isManagerRequired}
                    ></ConnectedRegistrantsList>
                </div>
                <div className="menuBar" style={{ maxWidth: "900px", margin: "auto" }}>
                    <ProfileHeader openSideBar={() => setShowSidebar(true)} userObject={userObject} pageName={pageName} />
                    <Sidebar
                        as={Menu}
                        animation="push"
                        icon="labeled"
                        inverted
                        onHide={() => !showSidebarProp && setShowSidebar(false)}
                        vertical
                        visible={showSidebar}
                        width="thin"
                    >
                        {menus.map((menu) => {
                            if (menu.showToCommunityManagerOnly) {
                                const activeRegistrant = userObject.registrants.list[userObject.registrants.activeRegistrantsIndexes[0]];
                                return activeRegistrant && activeRegistrant.isCommunityManager ? (
                                    <Menu.Item
                                        as="a"
                                        onClick={() => navigate(menu.location)}
                                        key={menu.location}
                                        className={menu.pageName + 'button'}
                                    >
                                        {menu.icon}
                                        {menu.pageName}
                                    </Menu.Item>
                                ) : null;
                            }
                            return (
                                <Menu.Item
                                    as="a"
                                    onClick={() => navigate(menu.location)}
                                    key={menu.location}
                                    className={menu.pageName + 'button'}
                                >
                                    {menu.icon}
                                    {menu.pageName}
                                </Menu.Item>
                            );
                        })}
                    </Sidebar>
                </div></> : <></>}
        </header>
    );
};

export default Header;
