import * as Lambda from '../services/LambdaApi';

const fetchSignedUrl = async (facilityId: string, folder: string, fileName: string) => {
    try {
        const apiPath = `/s2l/${facilityId}/fetchS2LSignedURL`;
        const response = await Lambda.Post(apiPath, { Folder: folder, FileName: fileName }, 'lambda');
        if (!response || !response.Result || !response.Result.Content || !response.Result.Success) {
            throw new Error('Error while fetching signed URL.');
        }
        return response.Result.Content;
    } catch (error) {
        throw new Error('Error while fetching signed URL.');
    }
};

export { fetchSignedUrl };