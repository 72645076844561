import React, { useContext, useEffect, useState } from 'react'
import { Dimmer, Loader } from 'semantic-ui-react';
import './style.less';
import moment from 'moment-timezone';
import UserContext from '../../../contexts/UserContext';
import FullCalendar from '@fullcalendar/react';
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { fetchFacilityDetails } from '../../services/Facilities';
import sendToast from '../../../utils/Toast';
import { fetchAllCalendarEvents, fetchAllResidentCalendarEvents } from '../../services/Calendar';
import  { ServiceCalendar, DalilyActivity, ResidentCalendar, SelectedCalendarEvent } from '../../types/Calendar';
import SelectedCalendarEventModal from '../../components/Calendar/SelectedEventModal';
import CreateCalendarEventModal from '../../components/Calendar/CreateCalendarEventModal';
import CalendarEventContent from '../../components/Calendar/CalendarEventContent';

const convertToFacilityTimezone = (date: string | number | Date, facilityTimezone: string) => {
    return moment.tz(date, facilityTimezone).format('YYYY-MM-DDTHH:mm:ss');
};

const Calendar = () => {
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDateTimeString: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
        endDateTimeString: moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
    });
    const [loadingFacilityInfo, setLoadingFacilityInfo] = useState<boolean>(false);
    const [loadingCalendarEvents, setLoadingCalendarEvents] = useState<boolean>(false);
    const [residentCalendarEvents, setResidentCalendarEvents] = useState<ResidentCalendar[]>([]);
    const [normalCalendarEvents, setNormalCalendarEvents] = useState<DalilyActivity[]>([]);
    const [servicesCalendarEvents, setServicesCalendarEvents] = useState<ServiceCalendar[]>([]);
    const [facilityTimezone, setFacilityTimezone] = useState<string>('');
    const [openSelectedEventModal, setOpenSelectedEventModal] = useState<boolean>(false);
    const [selectedEventInfo, setSelectedEventInfo] = useState({} as SelectedCalendarEvent);
    const [openCreateEventModal, setOpenCreateEventModal] = useState<boolean>(false);
    const [refreshCalendarEvents, setRefreshCalendarEvents] = useState<boolean>(false);
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const correspondingSpeak2Resident = listOfResidents[activeRegistrantsIndexes[0]]; // The activeRegistrantsIndexes array holds the index of the currently active resident from the residents array. Therefore, activeRegistrantsIndexes[0] gives the index of the active resident. When the active resident changes, this index is updated in the userObject. < ////// TODO add this comment wherever necessary >
    
    const handleMonthChange = ({ start, end }) => {
        // Check if start and end are provided, else use current state values
        const startDate = start ? moment(start).format('YYYY-MM-DDTHH:mm:ss') : selectedDateRange.startDateTimeString;
        const endDate = end ? moment(end).format('YYYY-MM-DDTHH:mm:ss') : selectedDateRange.endDateTimeString;
    
        // Update state with the formatted date strings
        setSelectedDateRange({
            startDateTimeString: startDate,
            endDateTimeString: endDate,
        });
    };


    useEffect(() => {
        const fetchFacilityTimezone = async () => {
            setLoadingFacilityInfo(true);
            try {
                const facilityId = correspondingSpeak2Resident.Facility as string;
                const facilityDetails = await fetchFacilityDetails(facilityId); //// once this function is fixed to return only Facility obj, add type for this
                if (!facilityDetails.FacilityTimeZone) {
                    throw new Error('Unable to fetch timezone of the facility. Please contact support.');
                }
                setFacilityTimezone(facilityDetails.FacilityTimeZone as string);
            } catch (error) {
                sendToast('error', error instanceof Error ? error.message : 'Failed to fetch Facility Information');
            }
            setLoadingFacilityInfo(false);
        };
        fetchFacilityTimezone();
    }, []);

    function isServiceCalendar(event: ServiceCalendar | DalilyActivity): event is ServiceCalendar {
        return (event as ServiceCalendar).svcActivityInstance !== undefined;
    }

    useEffect(() => {
        let fecthCalenderActivities = async () => {
            if (selectedDateRange.startDateTimeString && selectedDateRange.endDateTimeString && facilityTimezone) {
                try {
                    setLoadingCalendarEvents(true);
                    setNormalCalendarEvents([]);
                    setServicesCalendarEvents([]);
                    setResidentCalendarEvents([]);
                    const startDateTime = moment.tz(selectedDateRange.startDateTimeString, facilityTimezone).format('YYYY-MM-DDTHH:mm:ss');
                    const endDateTime = moment.tz(selectedDateRange.endDateTimeString, facilityTimezone).format('YYYY-MM-DDTHH:mm:ss');
                    if (!correspondingSpeak2Resident.Unit.RoomCategory) {
                        throw new Error('Room Category not found');
                    }
                    const calendarResponse = await fetchAllCalendarEvents({
                        facilityId: correspondingSpeak2Resident.Facility,
                        Filter: {
                            startDateTimeString: startDateTime,
                            endDateTimeString: endDateTime,
                            calendarType: [correspondingSpeak2Resident.Unit.RoomCategory.toString()],
                        },
                        ResidentIds: [correspondingSpeak2Resident._id],
                    });
                    let serviceCalendarEvents: ServiceCalendar[] = [];
                    let normalCalendarEvents: DalilyActivity[] = [];
                    
                    calendarResponse.forEach((event: ServiceCalendar | DalilyActivity) => {
                        if (isServiceCalendar(event)) {
                            serviceCalendarEvents.push(event);
                        } else {
                            normalCalendarEvents.push(event);
                        }
                    });
                    setServicesCalendarEvents(serviceCalendarEvents);
                    setNormalCalendarEvents(normalCalendarEvents);
                    const residentCalendarResponse = await fetchAllResidentCalendarEvents({
                        facilityId: correspondingSpeak2Resident.Facility,
                        Filter: {
                            registrantId: correspondingSpeak2Resident._id,
                            startDate: startDateTime,
                            endDate: endDateTime,
                        }
                    });
                    setResidentCalendarEvents(residentCalendarResponse.Result);
                } catch (error) {
                    sendToast('error', error instanceof Error ? error.message : 'Failed to fetch Calendar Events');
                } finally {
                    setLoadingCalendarEvents(false);

                }
            }
        };
        fecthCalenderActivities();
    }, [selectedDateRange.startDateTimeString, selectedDateRange.endDateTimeString, facilityTimezone, refreshCalendarEvents])

    const handleEventClick = (info) => {
        if (!info || !info.event || !info.event.extendedProps) {
            console.error('Invalid event info:', info);
            return;
        }
        setSelectedEventInfo((prevInfo) => ({
            ...prevInfo,
            title: info.event.title,
            startTime: moment(info.event.start).format("hh:mm A"),
            location: info.event.extendedProps.location,
            description: info.event.extendedProps.description,
            longDescription: info.event.extendedProps.longDescription? info.event.extendedProps.longDescription : null,
            image: info.event.extendedProps.image || "",
            attendingStatus: info.event.extendedProps.attendingStatus,
            svcInstanceId: info.event.extendedProps.svcInstanceId ? info.event.extendedProps.svcInstanceId : null,
            typeOfRequest: info.event.extendedProps.typeOfRequest,
            contentId: info.event.extendedProps.contentId ? info.event.extendedProps.contentId : null,
            Facility: correspondingSpeak2Resident.Facility,
            registrantId: correspondingSpeak2Resident._id,
            attendingId: info.event.extendedProps.attendingId,
            showAttendance: info.event.extendedProps.showAttendance,
            registrant: {
                FirstName: correspondingSpeak2Resident.FirstName,
                LastName: correspondingSpeak2Resident.LastName,
            },
            docId: info.event.extendedProps.docId ? info.event.extendedProps.docId : null,
        }));
        setOpenSelectedEventModal(true);
    };

    const closeSelectedEventModal = () => {
        setOpenSelectedEventModal(false);
    }

    const handlePlusButtonClick = () => {
        setOpenCreateEventModal(true);
    };

    const closeCreateEventModal = () => {
        setSelectedEventInfo({} as SelectedCalendarEvent);
        setOpenCreateEventModal(false);
    }

    const createEventInfo = {
        registrantIds: [correspondingSpeak2Resident._id],
        facilityId: correspondingSpeak2Resident.Facility
    }
    return (
        <div className="menu-calendar-view">
            <Dimmer active={loadingFacilityInfo || loadingCalendarEvents} inverted>
                <Loader content="Loading Calendar Events" />
            </Dimmer>
                <div>
                    {/**Heading */}
                    <p className="heading">Calendar </p>
                    <hr className="spacing" />
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin, rrulePlugin]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                            right: 'plusButton today prev,next',
                        }}
                        customButtons={{
                            plusButton: { // Define the custom button
                                text: '+',
                                click: handlePlusButtonClick,
                            },
                        }}
                        events={[
                            ...servicesCalendarEvents.map((event) => ({
                                title: event.name,
                                start: convertToFacilityTimezone(event.startDate, facilityTimezone),
                                end: convertToFacilityTimezone(event.endDate, facilityTimezone),
                                location: event.category,
                                allDay: false, 
                                description: event.shortDescription,
                                longDescription: event.longDescription? event.longDescription : null,
                                image: event.image ? event.image : null,
                                attendingStatus: event.residentAttendees ? event.residentAttendees[0] : "notattending",
                                typeOfRequest: "service",
                                svcInstanceId: event.srcId ? event.srcId : event._id,
                                attendingId : event._id,
                                backgroundColor: event.residentAttendees && (event.residentAttendees[0].status === 'intend_attend' || event.residentAttendees[0].status === 'attended') ? 'green' : "#0E8ED7",
                                showAttendance: event.showAttendance ? event.showAttendance : false,
                                docId: event.DocId ? event.DocId : null,
                            })),
                            ...normalCalendarEvents.map((event) => ({
                                title: event.text,
                                start: convertToFacilityTimezone(event.dateTimeString, facilityTimezone),
                                location: event.category,
                                description: null,
                                allDay: false, 
                                image: null,
                                attendingStatus: event.residentAttendees ? event.residentAttendees[0] : "notattending",
                                typeOfRequest: "normal",
                                backgroundColor: event.residentAttendees && (event.residentAttendees[0].status === 'intend_attend' || event.residentAttendees[0].status === 'attended') ? 'green' : "#E98530",
                                contentId: event.srcId ? event.srcId : event._id,
                                attendingId: event._id,
                                showAttendance: event.showAttendance ? event.showAttendance : false,
                            })),
                            ...residentCalendarEvents.map((event) => ({
                                title: event.text,
                                start: convertToFacilityTimezone(event.dateTimeString, facilityTimezone),
                                end: convertToFacilityTimezone(event.endDateTimeString, facilityTimezone),
                                location: event.location,
                                description: null,
                                allDay: false, 
                                image: null,
                                attendingStatus: "Attended",
                                startTime: event.dateTimeString,
                                typeOfRequest: "resident",
                                backgroundColor: '#CA6EF2',
                                showAttendance: event.showAttendance ? event.showAttendance : true,
                            })),
                        ].sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())}
                        datesSet={handleMonthChange}
                        eventClick={handleEventClick}
                        eventContent={(info) => <CalendarEventContent info={info} />}
                        showNonCurrentDates={false}
                    />
                </div>
            {/** Open Single Event Modal */}
                <SelectedCalendarEventModal
                    closeSelectedEventModal={closeSelectedEventModal}
                    openSelectedEventModal={openSelectedEventModal}
                    selectedEventInfo={selectedEventInfo}
                    refreshCalendarEvents={refreshCalendarEvents}
                    setRefreshCalendarEvents={setRefreshCalendarEvents}
                />

            {/** create Event Modal */}
                <CreateCalendarEventModal
                    closeCreateEventModal={closeCreateEventModal}
                    openCreateCalendarModal={openCreateEventModal}
                    createEventInfo={createEventInfo}
                    refreshCalendarEvents={refreshCalendarEvents}
                    setRefreshCalendarEvents={setRefreshCalendarEvents}
                />
        </div>
    )
}

export default Calendar;