import React, { useState, useEffect, useContext } from 'react';
import { Form, Button, Modal, Progress, Checkbox, Label } from 'semantic-ui-react';
import { getUploadUrl, uploadToSignedUrl, converterDataURItoBlob } from '../../../services/imageUpload';
import UserContext from '../../../contexts/UserContext';
import {Link} from 'react-router-dom'
import './style.less';
import {
    getMessageRecipientDetails,
    putMessageRecipientDetails,
    putMessageRecipientImage,
    changeMessageRecipientPassword,
    updateIsResponseEnabled,
} from '../../../services/messageRecipients';
import config from '../../../config';
import { toast } from 'react-toastify';
import ImageCropModal from '../../components/ImageCropModal';

interface MessageRecipient {
    _id: string;
    FirstName: string;
    LastName: string;
    Image: string;
    manager?: boolean;
    Email?: string;
    Phone?: string;
}

declare var process: {
    env: {
        SERVER_ENV: string;
    };
};

const envConfig = config[process.env.SERVER_ENV] || config.development;
const BUCKET_URL = envConfig.profileImageBucketUrl;

const ChangePasswordModal = ({ residentId, open, close }) => {
    const [loading, setLoading] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');
    const [isChangePasswordDisable, setIsChangePasswordDisable] = useState(true);

    useEffect(() => {
        setIsChangePasswordDisable(
            !newPassword ||
                newPassword.length < 8 ||
                !confirmNewPassword ||
                confirmNewPassword.length < 8 ||
                newPassword !== confirmNewPassword,
            //there is no prompt to let a person know that 8 chars are required
        );

        if (newPassword && confirmNewPassword && confirmNewPassword !== newPassword) {
            setError('Passwords do not match');
        } else {
            setError('');
        }
    }, [newPassword, confirmNewPassword]);

    const updatePassword = async () => {
        setError('');
        setLoading(true);

        if (newPassword && newPassword.length < 8) {
            setError('Password must be at least 8 characters in length');
        } else {
            try {
                if (newPassword !== confirmNewPassword) {
                    setLoading(false);
                    setError('Passwords do not match');
                } else {
                    const result = await changeMessageRecipientPassword(residentId, newPassword); // await put new password

                    if (result) {
                        toast.success('Password reset successfully!', {
                            position: 'bottom-center',
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                        setLoading(false);

                        close();
                    } else {
                        setLoading(false);
                        setError('Something went wrong');
                    }
                }
            } catch (e) {
                setLoading(false);

                setError('Something went wrong');
            }
        }
    };

    const linkSSOWithResident = async () => {};

    return (
        <Modal
            className="modal-content"
            open={open}
            onClose={() => {
                close();
            }}
            closeIcon
            size={'small'}
        >
            <div className="change-password">
                <Form loading={loading} onSubmit={() => {}} autoComplete="off">
                    <div className="field password-field">
                        <Form.Input
                            value={newPassword}
                            type="password"
                            className={newPassword ? 'full-75' : 'full-width'}
                            name="newPassword"
                            placeholder="New Password"
                            required
                            onChange={(e) => setNewPassword(e.target.value)}
                            autoComplete="new-password"
                        ></Form.Input>

                        {newPassword && (
                            <Progress
                                percent={(newPassword.length / 8) * 100}
                                size="tiny"
                                autoSuccess
                                color="red"
                                style={{
                                    width: '25%',
                                    marginBottom: '0px',
                                    paddingTop: '1em',
                                    marginLeft: '2px',
                                    maxHeight: '3em',
                                }}
                            />
                        )}
                    </div>
                    <Form.Field>
                        <Form.Input
                            value={confirmNewPassword}
                            type="password"
                            name="confirmNewPassword"
                            placeholder="Confirm New Password"
                            required
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                            autoComplete="new-password"
                        ></Form.Input>
                    </Form.Field>

                    <Form.Field>
                        {error && (
                            <Form.Field>
                                <div className="error-message">{error}</div>
                            </Form.Field>
                        )}
                    </Form.Field>

                    <Button primary onClick={updatePassword} disabled={isChangePasswordDisable}>
                        Save New Password
                    </Button>
                </Form>
            </div>
        </Modal>
    );
};

const MessageRecipientDetails = (props) => {
    const { userObject, setUser, reloadRegistrantLists } = useContext(UserContext);
    const [activeMessageRecipientId, setActiveMessageRecipientId] = useState('');
    const [formData, setFormData] = useState();
    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const [imageEditor, setImageEditor] = useState();
    const [isLoading, setIsLoading] = useState(false);

    const [isOpenPasswordModal, setOpenPasswordModal] = useState(false);

    const [isResponseEnabled, setIsResponseEnabled] = useState(false);

    const closePasswordModal = () => {
        setOpenPasswordModal(false);
    };

    const openPasswordModal = () => {
        setOpenPasswordModal(true);
    };

    const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == 'x' ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    };
    const linkSSOWithResident = () => {
        const residentId = activeMessageRecipientId;
        const residentData = {
            residentId,
        };
        const state = uuidv4();

        localStorage.setItem(state, JSON.stringify(residentData));

        const hostname = window.location.hostname;
        const redirect_uri = `https://${hostname}/sso-redirect`;

        const url = `https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.cec7f0739d0c48929547c0965562f5b3&scope=alexa::skills:account_linking&response_type=code&redirect_uri=${redirect_uri}&state=${btoa(
            state,
        )}`;

        window.location.href = url;
    };

    const getData = async (residentId) => {
        setIsLoading(true);
        const recipientDetails = await getMessageRecipientDetails(residentId);
        setFormData(recipientDetails);
        setIsResponseEnabled(recipientDetails.isResponseEnabled);
        setIsLoading(false);
    };

    useEffect(() => {
        if (activeMessageRecipientId !== '') {
            props.history.push(`/message-recipients/${activeMessageRecipientId}`);
            getData(activeMessageRecipientId);
        }
    }, [activeMessageRecipientId]);

    useEffect(() => {
        if (activeMessageRecipientId) {
            setActiveMessageRecipientId(
                userObject.registrants.list[userObject.registrants.activeRegistrantsIndexes[0]]._id,
            );
        }
    }, [userObject.registrants.activeRegistrantsIndexes]);

    useEffect(() => {
        const residentIdFromPath = props.location.pathname.split('/').pop();
        const indexOfResidentFromPath = userObject.registrants.list
            .map((registrant) => registrant._id)
            .indexOf(residentIdFromPath);

        // if residentId in url doesn't belong to any resident
        if (indexOfResidentFromPath === -1) {
            props.history.push(`/message-recipients/${userObject.registrants.list[0]._id}`);
            setUser({
                ...userObject,
                registrants: { ...userObject.registrants, activeRegistrantsIndexes: [0] },
            });
            setActiveMessageRecipientId(userObject.registrants.list[0]._id);

            // if residentId in url is different from current active resident
        } else if (indexOfResidentFromPath !== userObject.registrants.activeRegistrantsIndexes[0]) {
            setUser({
                ...userObject,
                registrants: { ...userObject.registrants, activeRegistrantsIndexes: [indexOfResidentFromPath] },
            });
            setActiveMessageRecipientId(userObject.registrants.list[indexOfResidentFromPath]._id);

            // if residentId in url is same as current active resident
        } else if (indexOfResidentFromPath === userObject.registrants.activeRegistrantsIndexes[0]) {
            setActiveMessageRecipientId(userObject.registrants.list[indexOfResidentFromPath]._id);
        }
    }, []);

    const onClickSave = async () => {
        if (imageEditor) {
            setIsUploadingImage(true);
            const canvas = imageEditor.getImageScaledToCanvas();

            try {
                const { imageId, signedUrl } = await getUploadUrl(formData._id);
                const dataUrl = canvas.toDataURL();
                const blob = converterDataURItoBlob(dataUrl);
                const res = await uploadToSignedUrl(blob, signedUrl);
                if (res.ok) {
                    await new Promise((resolve) => setTimeout(resolve, 800));
                    setImageModalOpen(false);
                    setNewImageUrl();
                    toast.success('Image uploaded successfully!', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } else {
                    console.log('Image upload error');
                    toast.error('Could not update image. Please try again.', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            } catch (e) {
                console.log(e);
                toast.error('Could not update image. Please try again.', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            setIsUploadingImage(false);
        }
        return;
    };

    const handleChange = (name) => (e) => {
        setFormData({
            ...formData,
            [name]: e.target.value,
        });
    };

    const setNewImageUrl = async () => {
        const newImage = `${BUCKET_URL}/${formData._id}?${Date.now()}`;
        setFormData({
            ...formData,
            Image: `${newImage}`,
        });
        const result = await putMessageRecipientImage(newImage, formData._id);
        await reloadRegistrantLists();
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const result = await putMessageRecipientDetails(formData);
        toast.success('Details Updated', {
            position: 'bottom-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });
        setIsLoading(false);
        await reloadRegistrantLists();
    };

    const handleIsResponseEnabledChange = async (checked) => {
        console.log('checked', checked);
        setIsLoading(true);
        await updateIsResponseEnabled(checked, activeMessageRecipientId);
        setIsLoading(false);
        setIsResponseEnabled(checked);
    };

    return (
        <div className="message-recipient-details relative-detail">
            <ImageCropModal
                setImageEditor={setImageEditor}
                setModalOpen={setImageModalOpen}
                onClickSave={onClickSave}
                open={imageModalOpen}
                loading={isUploadingImage}
            />
            {formData && (
                <div>
                <Form onSubmit={handleSubmit} loading={isLoading}>
                    <div className="relative-info no-border">
                        <div
                            className="relative-image-container"
                            onClick={() => {
                                setImageModalOpen(true);
                            }}
                        >
                            {formData.Image ? (
                                <div
                                    className="relative-image"
                                    style={{ background: `url(${formData.Image}) no-repeat` }}
                                ></div>
                            ) : (
                                <div className="relative-avatar">
                                    {formData && !!formData.FirstName && formData.FirstName.charAt(0)}{' '}
                                    {formData && !!formData.LastName && formData.LastName.charAt(0)}
                                </div>
                            )}
                        </div>
                        <div className="flex-1">
                            <>
                                <Form.Field>
                                    <Form.Input
                                        value={formData.FirstName || ''}
                                        type="text"
                                        name="firstName"
                                        onChange={handleChange('FirstName')}
                                        placeholder="First Name"
                                        // label="First Name"
                                        readOnly={!formData.canEdit}
                                        required
                                        autoComplete="false"
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <Form.Input
                                        value={formData.LastName || ''}
                                        type="text"
                                        name="lastName"
                                        onChange={handleChange('LastName')}
                                        placeholder="Last Name"
                                        // label="Last Name"
                                        readOnly={!formData.canEdit}
                                        required
                                        autoComplete="false"
                                    />
                                </Form.Field>
                            </>
                        </div>
                    </div>
                    <div className="relative-field">
                        <Form.Field>
                            <Form.Input
                                value={formData.Contact || ''}
                                type="text"
                                name="contact"
                                onChange={handleChange('Contact')}
                                placeholder="Email or Phone Number"
                                readOnly={true}
                                autoComplete="false"
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                value={formData.Exercise || ''}
                                type="text"
                                name="exercise"
                                onChange={handleChange('Exercise')}
                                placeholder="Exercise"
                                // label="Exercise"
                                readOnly={!formData.canEdit}
                                autoComplete="false"
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                value={formData.Rx || ''}
                                type="text"
                                name="rx"
                                onChange={handleChange('Rx')}
                                placeholder="Rx"
                                // label="Rx"
                                readOnly={!formData.canEdit}
                                autoComplete="false"
                            />
                        </Form.Field>
                        <Form.Field>
                            <Form.Input
                                value={formData.GreetingContactOverride || ''}
                                type="text"
                                name="GreetingContactOverride"
                                onChange={handleChange('GreetingContactOverride')}
                                placeholder="Greeting Contact Override"
                                // label="Greeting Contact Override"
                                readOnly={!formData.canEdit}
                                autoComplete="false"
                            />
                        </Form.Field>
                        <div className="alexa-permissions-section">
                            
                            <Form.Field className="alexa-user-detail-field">
                                
                                {formData.alexaUserDetails&& formData.alexaUserDetails.alexaLinked ? <>Alexa Account Linked</>: <strong>Alexa Account is not linked to this user </strong>}
                                
                            </Form.Field>
                            
                            {formData.alexaUserDetails && formData.alexaUserDetails.alexaLinked ? (
                                <>

                                {(formData.alexaUserDetails.AllowedToSend || formData.alexaUserDetails.canSetReminders) ? (<>
                                <p>You have enabled the following permissions in your Alexa App
</p>
                                    <ul>
                                    {formData.alexaUserDetails.canSetReminders ? <li>Reminders</li>: <></>}
                                    {formData.alexaUserDetails.AllowedToSend ? <li>Notifications</li>: <></>}

                                    </ul>
                                </>):<></>}
                                

                                {(formData.alexaUserDetails.AllowedToSend && formData.alexaUserDetails.canSetReminders) ? <></>:(<>
                                    <p>You have <strong>NOT</strong> enabled the following permissions in your Alexa App
    </p>
                                        <ul>
                                        {formData.alexaUserDetails.canSetReminders ? <></>: <li>Reminders</li>}
                                        {formData.alexaUserDetails.AllowedToSend ? <></>:<li>Notifications</li>}
    
                                        </ul>
                                        <a href="https://f.hubspotusercontent40.net/hubfs/8458943/permissions-cheat-sheet.pdf" target="_blank" rel="noopener noreferrer">
                                            Click here for instructions
                                        </a>
                                    </>)}

                                    </>

                            ) : (
                                <> </>
                            )}
                        </div>

                        {formData.canUpdateMessageRepliesStatus ? (
                            <Form.Field>
                                <Checkbox
                                    toggle
                                    checked={isResponseEnabled}
                                    type="checkbox"
                                    label={`Can ${formData.FirstName} respond to messages ?`}
                                    className="speak2-checkbox"
                                    onChange={(event, data) => {
                                        console.log(data);
                                        const { checked } = data;
                                        handleIsResponseEnabledChange(checked);
                                        // updateCanListenToStories()
                                    }}
                                />
                            </Form.Field>
                        ) : (
                            <> </>
                        )}
                    </div>
                    {formData.canEdit ? (
                        <div className="button-container">
                            <Button
                                className="ui primary large basic button"
                                //loading={isUpdating}
                                type="button"
                                onClick={linkSSOWithResident}
                            >
                                {formData.alexaUserDetails ? 'Re-Link with Alexa' : 'Link with Alexa'}
                            </Button>
                            <Button
                                className="ui primary large basic button"
                                type="submit"
                                disabled={!formData.canEdit}
                                loading={isLoading}
                            >
                                {isLoading ? (
                                    <>
                                        <span>Saving Changes...</span>
                                    </>
                                ) : (
                                    <>
                                        <i className="plus circle icon"></i> <span>Save Changes </span>
                                    </>
                                )}
                            </Button>
                        </div>
                    ) : (
                        ''
                    )}
                    
                </Form>

                <div className="relative-info">
                    {
                        formData.unitDetails ? (
                            <Link to={`/message-recipients/${formData._id}/address-book`}>
                    <Button >
                        Open Address Book

                    </Button>

                    </Link>
                        ):<></>
                    }
                    
                    
                    </div>

                </div>

                
            )}
            {isOpenPasswordModal && (
                <ChangePasswordModal
                    residentId={activeMessageRecipientId}
                    open={isOpenPasswordModal}
                    close={closePasswordModal}
                />
            )}
            <div className="buy-container add-user-container">
                <Button
                    className="primary large add-alexa-user-button"
                    onClick={() => props.history.push('/message-recipients/create')}
                >
                    <i className="plus circle icon"></i> Create and Connect a New Alexa User
                </Button>
                <Button
                    className="primary large invite-alexa-user-button"
                    onClick={() => props.history.push('/invite-alexa-user')}
                >
                    <i className="plus circle icon"></i> Invite a New Alexa User to Join                </Button>
            </div>
        </div>
    );
};

export default MessageRecipientDetails;
