import React from 'react';
import { Button, Grid } from 'semantic-ui-react';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { ServicesType } from '../../types/ServiceInstance';
import sendToast from '../../../utils/Toast';
interface Props {
    selectedMenu: ServicesType;
}

const MenuDetailedView = ({ selectedMenu }: Props) => {
    const history = useHistory();
    const handleOrderSubmit = async (selectedMenu: ServicesType) => {
        try {
            if (selectedMenu.Asset && selectedMenu._id) {
                history.push({ pathname: `/dining/${selectedMenu.Asset}/${selectedMenu._id}`, state: { from: 'menu' } });
            } else {
                throw new Error("Menu item not associated with a restaurant or menu id not found");
            }
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : "Failed to order");
        }
    };
    return (
        <div style={{ width: "50%" }}>
            {selectedMenu && <Grid  >
                <Grid.Row>
                    <Grid.Column width={8}>
                        <h4>
                            {selectedMenu.name}
                        </h4>
                        <p>Meal Type - {selectedMenu.menuCategory}</p>
                        <p>Order Date - {moment(selectedMenu.startDate).format('YYYY-MM-DD')}</p>
                        <p>{selectedMenu.shortDescription}</p>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <div style={{ width: "100%", height: "100%", display: "flex", alignSelf: "center" }}>
                            {selectedMenu.image && selectedMenu.signedUrl && <img src={selectedMenu.signedUrl} alt='menu' style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'contain' }} />
                            }
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <div className='self-center' style={{ width: "100%" }}>
                        <Button
                            className='self-center'
                            color='facebook'
                            onClick={() => {
                                handleOrderSubmit(selectedMenu);
                            }}>Order</Button>
                    </div>
                </Grid.Row>
            </Grid>}
        </div>
    );
};

export default MenuDetailedView;