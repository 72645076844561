import React, { FormEvent, useContext, useEffect, useState } from 'react';
import { Button, Checkbox, Confirm, Dimmer, Form, Grid, GridColumn, GridRow, Loader, Segment } from 'semantic-ui-react';
import './style.less';
import { ChangePasswordModal } from '../../../components/pages/profile-settings';
import UserContext from '../../../contexts/UserContext';
import { deleteRelative, updateResident } from '../../../services/family';
import sendToast from '../../../utils/Toast';
import ResidentInterests from './ResidentInterests';
import { Registrant } from '../../types/Registrant';
import { converterDataURItoBlob, uploadToSignedUrl } from '../../../services/imageUpload';
import ImageCropModal from '../../../components/components/ImageCropModal';
import { fetchSignedUrl } from '../../../utils/imageUpload';
import { residentImageFolder, speak2CdnDevBucketUrl } from '../../../utils/s3Buckets';

const ResidentInfo = () => {

    const [specialHandling, setSpecialHandling] = useState<string>('');
    const [facilityRegisteredCell, setFacilityRegisteredCell] = useState<string>('');
    const [cell, setCell] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [hideProfile, setHideProfile] = useState<boolean>(false);
    const [isOpenModal, setOpenModal] = useState<boolean>(false);
    const [confirmDeleteAccount, setConfirmDeleteAccount] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [imageModalOpen, setImageModalOpen] = useState<boolean>(false);
    const [imageEditor, setImageEditor] = useState<any>();
    const [isUploadingImage, setIsUploadingImage] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState('');
    const [imageName, setImageName] = useState<string>('');

    const {
        userObject,
        setUser
    } = useContext(UserContext);

    const { profile: relativeProfile } = userObject; // Extract the Username from the profile object to be passed for actions like Change password
    const listOfResidents = (userObject && userObject.registrants && userObject.registrants.list) || [];
    const activeRegistrantsIndexes = (userObject && userObject.registrants && userObject.registrants.activeRegistrantsIndexes) || [];

    const activeResident = listOfResidents[activeRegistrantsIndexes[0]]; // get the first resident from the list of active residents for which the user is a relationship manager because neighbors web only supports the resident who is logged in and we can't deselect or change it
    const userHasRegistrants =
        userObject &&
            userObject.registrants &&
            userObject.registrants.list &&
            Array.isArray(userObject.registrants.list) &&
            userObject.registrants.list.length > 0
            ? true
            : false;
    const isRelationshipManager = userHasRegistrants && userObject.registrants.list.find((registrant) => registrant.manager) ? true : false;
    const allowEdit = isRelationshipManager && activeResident ? true : false;

    useEffect(() => {
        if (activeResident) {
            setSpecialHandling(activeResident.SpecialHandling || "");
            setFacilityRegisteredCell(activeResident.Cell || "");
            setHideProfile(activeResident.Private ? true : false);
            setCell(activeResident.SocialProfile?.Cell || '');
            setEmail(activeResident.SocialProfile?.Email || activeResident.Email || "");
            setFirstName(activeResident.SocialProfile?.FirstName || activeResident.FirstName || "");
            setLastName(activeResident.SocialProfile?.LastName || activeResident.LastName || "");
            setImageUrl(activeResident.SocialProfile?.Image || activeResident.Image || `/avatar_placeholder.png`);
        }
    }, [userObject]);

    const closeModal = () => {
        setOpenModal(false);
    };

    const handleInfoSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        try {
            if (allowEdit) {
                const residentId = activeResident._id;
                const residentPayload: Partial<Registrant> = {
                    SpecialHandling: specialHandling,
                    Private: hideProfile,
                    SocialProfile: {
                        Email: email,
                        FirstName: firstName,
                        LastName: lastName,
                        Cell: cell,
                    }
                };
                if (facilityRegisteredCell) {
                    residentPayload.Cell = facilityRegisteredCell;
                }
                const updatedResidentRes = await updateResident(residentId, residentPayload);
                setUser(
                    {
                        ...userObject,
                        registrants: {
                            ...userObject.registrants,
                            list: userObject.registrants.list.map((resident: Registrant) => {
                                if (resident._id === residentId) {
                                    return {
                                        ...resident,
                                        SpecialHandling: specialHandling,
                                        Private: hideProfile,
                                        SocialProfile: {
                                            ...resident.SocialProfile,
                                            Email: email,
                                            FirstName: firstName,
                                            LastName: lastName,
                                            Cell: cell,
                                        },
                                        Cell: facilityRegisteredCell
                                    };
                                }
                                return resident;
                            })
                        }
                    }
                );
                if (!updatedResidentRes) {
                    throw new Error('Error updating profile');
                }
                sendToast('success', 'Profile updated successfully');
            }
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Error updating profile');
        } finally {
            setLoading(false);
        }
    };

    const deleteUser = async () => {
        try {
            setLoading(true);
            if (!relativeProfile._id) {
                sendToast('error', 'User not found');
                return;
            };
            await deleteRelative(relativeProfile._id);
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Failed to delete account');
        } finally {
            setLoading(false);
            window.location.reload();
        }
    };

    const onClickSave = async () => {
        setLoading(true);
        if (imageEditor && imageName) {
            setIsUploadingImage(true);
            setImageUrl('');
            const canvas = imageEditor.getImageScaledToCanvas();
            try {
                const signedUrl = await fetchSignedUrl(activeResident.Facility, residentImageFolder, imageName);
                if (!signedUrl)
                    throw new Error('Could not fetch signed URL');
                const dataUrl = canvas.toDataURL();
                const blob = converterDataURItoBlob(dataUrl);
                const res = await uploadToSignedUrl(blob, signedUrl);

                if (res.ok) {
                    const url = `${speak2CdnDevBucketUrl}/${residentImageFolder}/${imageName}`;
                    const residentId = activeResident._id;
                    const residentPayload: Partial<Registrant> = {
                        SocialProfile: {
                            Image: url,
                        }
                    };
                    const res = await updateResident(residentId, residentPayload);
                    if (!res)
                        throw new Error('Could not update image');
                    setImageUrl(url);
                    setUser(
                        {
                            ...userObject,
                            registrants: {
                                ...userObject.registrants,
                                list: userObject.registrants.list.map((resident: Registrant) => {
                                    if (resident._id === residentId) {
                                        return {
                                            ...resident,
                                            SocialProfile: {
                                                ...resident.SocialProfile,
                                                Image: url
                                            }
                                        };
                                    }
                                    return resident;
                                })
                            }
                        }
                    );
                    setImageModalOpen(false);
                    sendToast('success', 'Profile Image Updated');
                } else {
                    setIsUploadingImage(false);
                    throw new Error('Could not update image. Please try again.');
                }
            } catch (e) {
                setIsUploadingImage(false);
                sendToast('error', 'Could not update image. Please try again.');
            } finally {
                setIsUploadingImage(false);
                setLoading(false);
            }
        }
        return;
    };

    return (
        <>
            <Dimmer active={loading} inverted>
                <Loader style={{ margin: 'auto' }} active={loading}>Loading</Loader>
            </Dimmer>
            <Segment style={{ width: '80%', margin: 'auto' }}>
                <ImageCropModal
                    setImageEditor={setImageEditor}
                    setModalOpen={setImageModalOpen}
                    onClickSave={onClickSave}
                    open={imageModalOpen}
                    loading={isUploadingImage}
                    borderRadius={125}
                    setImageName={setImageName}
                />
                <Form onSubmit={(e) => handleInfoSubmit(e)}>
                    <Grid columns={2} centered>
                        {allowEdit && (
                            <GridColumn
                                width={4}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            >
                                <div
                                    className="circle"
                                    onClick={() => {
                                        setImageModalOpen(true);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <img src={imageUrl || activeResident?.SocialProfile?.Image || activeResident?.Image || `/avatar_placeholder.png`} />
                                </div>
                            </GridColumn>
                        )}
                        <GridColumn width={12}>
                            {allowEdit && (
                                <Form.TextArea
                                    value={specialHandling}
                                    placeholder="About Me"
                                    onChange={(e: any) => setSpecialHandling(e.target.value)}
                                    label="About Me"
                                />
                            )}
                            <Grid>
                                <GridRow className="customPadding" columns={allowEdit && facilityRegisteredCell ? 3 : 2}>
                                    <GridColumn>
                                        <Form.Input
                                            value={cell}
                                            placeholder="Cell"
                                            onChange={(e) => setCell(e.target.value)}
                                            label="Cell"
                                            required={true}
                                            readOnly={allowEdit ? false : true}
                                        />
                                    </GridColumn>
                                    {allowEdit && facilityRegisteredCell && (
                                        <GridColumn>
                                            <Form.Input
                                                value={facilityRegisteredCell}
                                                placeholder="Registered cell"
                                                onChange={(e) => setFacilityRegisteredCell(e.target.value)}
                                                label="Registered cell"
                                            />
                                        </GridColumn>
                                    )}
                                    <GridColumn>
                                        <Form.Input
                                            value={email}
                                            placeholder="Email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            type={'email'}
                                            label="Email"
                                            readOnly={allowEdit ? false : true}
                                        />
                                    </GridColumn>
                                </GridRow>
                                <GridRow className="customPadding">
                                    <GridColumn width={8}>
                                        <Form.Input
                                            required={true}
                                            value={firstName}
                                            placeholder="First name"
                                            onChange={(e) => setFirstName(e.target.value)}
                                            label="First name"
                                            readOnly={allowEdit ? false : true}
                                        />
                                    </GridColumn>
                                    <GridColumn width={8}>
                                        <Form.Input
                                            required={true}
                                            value={lastName}
                                            placeholder="Last name"
                                            onChange={(e) => setLastName(e.target.value)}
                                            label="Last name"
                                            readOnly={allowEdit ? false : true}
                                        />
                                    </GridColumn>
                                </GridRow>
                            </Grid>
                        </GridColumn>
                    </Grid>
                    {allowEdit && (
                        <Grid centered columns={1}>
                            <GridColumn style={{ textAlign: 'center' }}>
                                <Checkbox
                                    label="Hide my details"
                                    onChange={(e, data) => setHideProfile(data.checked ? true : false)}
                                    checked={hideProfile}
                                />
                            </GridColumn>
                        </Grid>
                    )}
                    <Grid>
                        <GridColumn style={{ textAlign: 'center' }}>
                            {allowEdit && (
                                <Button className="customButton" basic color="blue" type="submit">
                                    Save Changes
                                </Button>
                            )}
                        </GridColumn>
                    </Grid>
                </Form>
            </Segment>

            <ResidentInterests setLoading={setLoading} />

            <ChangePasswordModal contact={relativeProfile.Username} open={isOpenModal} close={closeModal} />

            <Confirm
                className="deleteAccount"
                open={confirmDeleteAccount}
                onCancel={() => setConfirmDeleteAccount(false)}
                onConfirm={deleteUser}
                content="Are you sure you want to delete your account?"
            />
        </>
    );
};

export default ResidentInfo;