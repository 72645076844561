import * as Lambda from '../../services/LambdaApi';
import { paginationWrapper } from '../../utils/paginationWrapper';
import { CommunityNewsType } from '../types/CommunityNews';

const fetchCommunityNews = async (
    {
        facilityId,
        filter,
        pagingSorting,
    }: {
        facilityId: string;
        filter: {
            startDate: object;
            endDate: object;
        };
        pagingSorting: {
            page_no: number;
            page_size: number;
        };
    }
) => {
    if (!facilityId) throw new Error('Facility ID is required');
    if (!filter.startDate) throw new Error('Start date is required');
    if (!filter.endDate) throw new Error('End date is required');

    const apiPath = `/s2l/${facilityId}/daily-affirmations`;
    const params = {
        Filter: {
            startDate: filter.startDate,
            endDate: filter.endDate,
        },
        ...(pagingSorting && { QueryOptions: pagingSorting })
    };

    const response = await Lambda.Post(apiPath, params, 'lambda');
    if (!response || !response.Result || !response.Result.Content) {
        throw new Error('Error fetching community news');
    }
    return response.Result.Content as { Result: CommunityNewsType[]; TotRecords: number; };
};

const fetchAllCommunityNews = async (
    facilityId: string,
    filter: {
        startDate: object;
        endDate: object;
    },
) => {
    if (!facilityId) throw new Error('Facility ID is required');
    if (!filter.startDate) throw new Error('Start Timestamp is required');
    if (!filter.endDate) throw new Error('End Timestamp is required');

    return await paginationWrapper(
        {
            apiCall: fetchCommunityNews,
            params: {
                facilityId,
                filter,
            },
        }
    ) as CommunityNewsType[];
};

export { fetchCommunityNews, fetchAllCommunityNews };