import React, { ChangeEvent, FormEvent, useContext, useEffect, useState } from 'react';
import { Button, Grid, Form, Segment, Checkbox, Icon, Dimmer, Loader, Card, CardContent, Image } from 'semantic-ui-react';
import "./style.less";
import DatePicker from 'react-datepicker';
import { Service, ServiceAddOn } from '../../types/Service';
import { formatDateWithTZ } from '../../../utils/timezone';
import momentTZ from 'moment-timezone';
import { fetchFacilityDetails } from '../../services/Facilities';
import sendToast from '../../../utils/Toast';
import UserContext from '../../../contexts/UserContext';
import { FetchOrderFromOrderIdParams, RequestInstance } from '../../types/OrderStatus';
import { cancelOrder, createOrder, fetchOrderImageUploadUrl, fetchOrdersFromOrderId, getOrderImage, updateOrder } from '../../services/OrderStatus';
import { categoryColorMapping } from '../../pages/order-status/create';
import { uploadToSignedUrl } from '../../../services/imageUpload';
import { fetchServiceImage } from '../../services/Service';
import { useHistory } from 'react-router-dom';
import moment from 'moment-timezone';
import { getServiceInstanceImage } from '../../services/ImageUploader';
import { formatOrdersForTableView } from '../../../utils';
import CloseAssociatedOrdersModal from './CloseAssociatedOrdersModal';
import { fetchByDocIds } from '../../services/Documents';
import { Document } from '../../types/Document';

interface Props {
    request?: RequestInstance;
    service: Service;
    selectedCategory: string;
    setOpenCreateOrderModal: React.Dispatch<React.SetStateAction<boolean>>;
    refreshData?: () => void;
}

const CreateServiceOrder = ({ request, service, selectedCategory, setOpenCreateOrderModal, refreshData }: Props) => {

    const [selectedServiceAddOns, setSelectedServiceAddOns] = useState<ServiceAddOn[]>([]);
    const [orderName, setOrderName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [startDate, setStartDate] = useState<string>('');
    const [facilityTimezone, setFacilityTimezone] = useState<string>('');
    const [facilityLoading, setFacilityLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [selectedImage, setSelectedImage] = useState<File | null>(null);
    const [imageLink, setImageLink] = useState<string>('');
    const [currentImage, setCurrentImage] = useState<string>('');
    const [associatedOrders, setAssociatedOrders] = useState<RequestInstance[]>([]);
    const [openCloseAssociatedOrdersModal, setOpenCloseAssociatedOrdersModal] = useState<boolean>(false);
    const [serviceDoc, setServiceDoc] = useState<Document | null>(null);

    const history = useHistory();
    const serviceAddOns = (service && service.ServiceAddOns) ? service.ServiceAddOns : [];

    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const activeResident = listOfResidents[activeRegistrantsIndexes[0]]; // get the first resident from the list of active residents because neighbors web only supports the resident who is logged in and we can't deselect or change it
    useEffect(() => {
        (async () => {
            try {
                setFacilityLoading(true);
                const facilityRes = await fetchFacilityDetails(activeResident.Facility);
                if (!facilityRes.FacilityTimeZone) {
                    throw new Error("Facility Timezone not found");
                }
                setFacilityTimezone(facilityRes.FacilityTimeZone);
            } catch (error) {
                sendToast('error', error);
            } finally {
                setFacilityLoading(false);
            }
        }
        )();
    }, []);

    useEffect(() => {
        (async () => {
            setLoading(true);
            const allAddOns = service && service.ServiceAddOns ? service.ServiceAddOns : [];
            if (service && service.DocId) {
                const { Result: doc } = await fetchByDocIds(activeResident.Facility, [service.DocId]);
                setServiceDoc(doc[0]);
            }
            if (request) { // Edit view
                setOrderName(request.Name || '');
                setDescription(request.Description || '');
                setStartDate(request.ScheduledTime?.startDate || '');
                if (request.ServiceAddOns && Array.isArray(request.ServiceAddOns) && request.ServiceAddOns.length > 0)
                    setSelectedServiceAddOns(request.ServiceAddOns);
                else {
                    const requiredAddons: ServiceAddOn[] = [];
                    allAddOns.map((addon: ServiceAddOn) => {
                        if (addon.required)
                            requiredAddons.push(addon);
                    });
                    setSelectedServiceAddOns(requiredAddons);
                }
                setImageLink(request.ImageUrl || '');
                if (request.ImageName) {
                    setCurrentImage(request.ImageName);
                    await fetchOrderImage();
                }
                if (service.image && !service.imageURL) {
                    await getServiceImage();
                }
            } else { // create view (we need required addons checked in both edit and create views though)
                const requiredAddons: ServiceAddOn[] = [];
                allAddOns.map((addon: ServiceAddOn) => {
                    if (addon.required)
                        requiredAddons.push(addon);
                });
                setSelectedServiceAddOns(requiredAddons);
            }
            setLoading(false);
        }
        )();
    }, [request, service]);

    useEffect(() => {
        if (request && request.OrderId) {
            fetchAllAssociatedOrders();
        }
    }, [request]);

    const fetchAllAssociatedOrders = async () => {
        try {
            const orderFilter: Partial<FetchOrderFromOrderIdParams> = {
                facilityId: activeResident.Facility,
                statusArr: ["Open", "Accepted"],
                residentId: String(activeResident._id),
                OrderId: request && request.OrderId
            };
            const response = await fetchOrdersFromOrderId(orderFilter);
            const formattedOrders = formatOrdersForTableView(response, facilityTimezone);
            setAssociatedOrders(formattedOrders);
        } catch (error) {
            sendToast("error", error instanceof Error ? error.message : 'Failed to fetch associated orders');
        }
    }

    const fetchOrderImage = async () => {
        if (request && request.ImageName) {
            try {
                const imgRes = await getOrderImage(request.Facility, String(request._id), request.ImageName);
                if (imgRes.Result.Content && imgRes.Result.Success) {
                    setCurrentImage(request.ImageName);
                    setImageLink(imgRes.Result.Content);
                } else {
                    throw new Error(imgRes.Result.Error || 'Failed to get order image');
                }
            } catch (error) {
                sendToast('error', error instanceof Error ? error.message : 'Failed to get order image');
            }
        }
    };

    const getServiceImage = async () => {
        if (service.image) {
            try {
                let imgRes = null;
                if (request && request.OriginalServiceInstanceId) {
                    imgRes = await getServiceInstanceImage({ svcInstanceId: String(service._id), facilityId: service.Facility, fileName: service.image });
                } else {
                    imgRes = await fetchServiceImage({ serviceId: String(service._id), Facility: service.Facility, fileName: service.image });
                }
                service.imageURL = imgRes || "";
            } catch (error) {
                sendToast('error', error instanceof Error ? error.message : 'Failed to get service image');
            }
        }
    };

    const handleServiceAddons = (id: string, value: boolean) => {
        let updatedAddOns = JSON.parse(JSON.stringify(selectedServiceAddOns)); // deep copy of the selectedServiceAddOns state array
        if (value) {
            serviceAddOns.forEach(((addon) => {
                if (addon.id === id) {
                    updatedAddOns.push(addon);
                }
            }));
        } else {
            updatedAddOns = updatedAddOns.filter((addon: ServiceAddOn) => addon.id !== id);
        }
        setSelectedServiceAddOns(updatedAddOns);
    };

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!service || !activeResident || !activeResident.Unit || !startDate || !orderName) {
            sendToast('warn', 'Missing required fields to create and order');
            return;
        }
        if (!moment(startDate).isValid()) {
            sendToast('warn', 'Invalid start date');
            return;
        }
        try {
            setLoading(true);
            const startDateMomentObj = momentTZ(startDate);
            const endDate = startDateMomentObj.clone().add(15, 'minutes'); // set end date to 15 minutes after the start date by default
            const order: Partial<RequestInstance> = {
                Category: service.category,
                ResidentId: String(activeResident._id),
                OrderName: orderName,
                Description: description,
                ScheduledTime: {
                    startDate: momentTZ(startDate).format('YYYY-MM-DDTHH:mm:ss'),
                    endDate: momentTZ(endDate).format('YYYY-MM-DDTHH:mm:ss'),
                },
                Facility: String(activeResident.Facility),
                RequestChannel: "Web",
                ...(selectedServiceAddOns && selectedServiceAddOns.length > 0 && { ServiceAddOns: selectedServiceAddOns }),
                OriginalServiceId: String(service._id)
            };
            const response = await createOrder(activeResident.Facility, order);
            if (!response.Result.Content) {
                throw new Error(response.Result.Error || 'Failed to create order');
            }
            const createdOrderId = response.Result.Content;
            if (selectedImage) {
                await uploadImageFile(createdOrderId);
                const updateRes = await updateOrder(
                    activeResident.Facility,
                    {
                        _id: createdOrderId,
                        ImageName: selectedImage.name,
                    });
                if (!updateRes.Result.Content.updated && updateRes.Result.Content.message) { // backend will return updated as false with a message as to why updated is false
                    throw new Error(updateRes.Result.Content.message);
                }
            }
            sendToast('success', 'Order created successfully');
            setOpenCreateOrderModal(false);
            history.push('/order-status');
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Failed to create order');
        } finally {
            setLoading(false);
        }
    };

    const uploadImageFile = async (orderId: string) => {
        if (selectedImage) {
            const putUrl = await fetchOrderImageUploadUrl(activeResident.Facility, orderId, selectedImage.name);
            await uploadToSignedUrl(selectedImage, putUrl.Result.Content);
        }
    };

    const handleImageChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const selectedFile = event.target.files[0];
            if (selectedFile) {
                setSelectedImage(selectedFile);
                setCurrentImage('');
                setImageLink('');
            }
        }
    };

    const cancelRequest = async () => {
        if (request) {
            try {
                setLoading(true);
                const res = await cancelOrder(request.Facility, String(request._id), request.ResidentId);
                if (!res) throw new Error('Failed to cancel order');
                sendToast('success', 'Order canceled successfully');
                setOpenCreateOrderModal(false);
                setOpenCloseAssociatedOrdersModal(false);
                if (refreshData) refreshData();
            } catch (error) {
                sendToast('error', error instanceof Error ? error.message : 'Failed to cancel order');
            } finally {
                setLoading(false);
            }
        } else {
            sendToast('warn', 'No request to cancel');
        }
    };
    
    const cancelAllAssociatedRequest = () => {
        setOpenCloseAssociatedOrdersModal(true);
    };

    const onClose = () => {
        setOpenCloseAssociatedOrdersModal(false);
    };

    const cancelAllOpenAndAcceptedOrders = async () => {
        if (associatedOrders && associatedOrders.length > 0) {
            try {
                setLoading(true);
                const cancelPromises = associatedOrders.map((order) => cancelOrder(order.Facility, String(order._id), order.ResidentId));
                const res = await Promise.all(cancelPromises);
                if (!res) throw new Error('Failed to cancel order');
                sendToast('success', 'Order canceled successfully');
                setOpenCreateOrderModal(false);
                setOpenCloseAssociatedOrdersModal(false);
                if (refreshData) refreshData();
            } catch (error) {
                sendToast('error', error instanceof Error ? error.message : 'Failed to cancel order');
            } finally {
                setLoading(false);
            }
        } else {
            sendToast('warn', `No request's to cancel`);
        }
    }

    return (
        <div>
            <Dimmer active={loading || facilityLoading} inverted>
                <Loader active={loading || facilityLoading} />
            </Dimmer>
            {request && <h3 className="statusHeading">Status: {request.Status}</h3>}
            {request && request.OrderId && <h3 className='statusHeading'>Order Id: {request.OrderId}</h3>}
            <Grid>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <h4>{`${activeResident.FirstName.trim() || ''} ${activeResident.LastName.trim() || ''}`}</h4>
                        <p>
                            Room: {activeResident.Unit && activeResident.Unit.Name ? activeResident.Unit.Name : 'N/A'}
                        </p>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Button
                            fluid
                            style={{
                                backgroundColor: categoryColorMapping[selectedCategory] || 'gray',
                                color: 'white',
                                marginBottom: '10px',
                                width: '171px',
                                borderRadius: '70px',
                                height: '60px',
                                float: 'right',
                            }}
                            className="category-button"
                        >
                            {selectedCategory}
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Form autoComplete="false" onSubmit={(e) => handleSubmit(e)} style={{ marginTop: '10px' }}>
                <Card style={{ height: '100%', width: '100%', cursor: 'pointer' }}>
                    <CardContent>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div style={{ flex: 1 }}>
                                <h4>{service.name}</h4>
                                <p>{service.shortDescription}</p>
                                <p>$ {(service && service.defaults && service.defaults.externalCost) || '0'}</p>
                            </div>
                            <Image
                                size="mini"
                                src={service.imageURL || `${process.env.PUBLIC_URL}/dummy-image.png`}
                                style={{ width: 'auto', height: 'auto', maxHeight: '50px' }}
                            />
                        </div>
                        <div style={{ display: 'flex', }}>
                            {serviceDoc && <>
                                <p style={{ marginLeft: "auto" }} >Attached Doc: <a href={serviceDoc.attachedFiles && serviceDoc.attachedFiles[0].attachedLink} target="_blank" rel="noopener noreferrer"><span style={{ fontSize: '1em', fontWeight: 'bold' }}>{serviceDoc.Name}</span></a></p>
                            </>}
                        </div>
                    </CardContent>
                </Card>
                {serviceAddOns.length > 0 ? ( // support total and cost display
                    <>
                        <Segment vertical>
                            {serviceAddOns.map((item: any) => {
                                const isAddonPresentInRequest = !!selectedServiceAddOns.find(
                                    (addon) => addon.id == item.id,
                                ); // we check if the addon is present in the request object array if its there we mark it as checked else we mark is as unchecked
                                return (
                                    <div
                                        key={item.id}
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            margin: '5px 0px',
                                            padding: '0px 20px',
                                        }}
                                    >
                                        <div>
                                            <label style={{ flexGrow: 1, marginRight: '10px' }}>{item.itemName}</label>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Checkbox
                                                    disabled={item.required || (request ? true : false)}
                                                    checked={item.required || isAddonPresentInRequest}
                                                    onChange={(event: React.FormEvent<HTMLInputElement>, { checked }) =>
                                                        handleServiceAddons(item.id, checked as boolean)
                                                    }
                                                />
                                            </div>
                                            <div style={{ width: '50px', textAlign: 'right' }}>
                                                <span>
                                                    <b>{item.itemCost} $</b>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </Segment>
                        <Segment vertical>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <span style={{ margin: '0px 20px 5px 0' }}>
                                    <b>
                                        Total: {Number(selectedServiceAddOns.reduce((acc, addon) => acc + addon.itemCost, 0) + Number(service && service.defaults && service.defaults.externalCost) || 0)} $
                                    </b>
                                </span>
                            </div>
                        </Segment>
                    </>
                ) : (
                    ''
                )}
                {/* Order name */}
                <Form.Field>
                    <Form.Input
                        required={true}
                        value={orderName || ''}
                        placeholder="Enter Order Name *"
                        onChange={(e) => setOrderName(e.target.value)}
                        readOnly={request ? true : false}
                    />
                </Form.Field>
                {/* Order description */}
                <Form.Field>
                    <Form.TextArea
                        value={description || ''}
                        onChange={(e) => setDescription(e.currentTarget.value)}
                        placeholder="Order Notes"
                        readOnly={request ? true : false}
                    />
                </Form.Field>
                {request && request.StaffId && request.AcceptedByName && (
                    <p className="acceptedPara">This request has been assigned to {request.AcceptedByName}</p>
                )}
                {request && request.Status === 'Closed' ? (
                    request.ClosedByResident ? (
                        <p className="status">Canceled by resident</p>
                    ) : (
                        <p className="status">Closed by {request.ClosedByName}</p>
                    )
                ) : (
                    <></>
                )}
                {/* Scheduled time */}
                <Form.Field>
                    <Grid columns={1} centered>
                        <Grid.Column width={8} style={{ textAlign: "center" }}>
                            <DatePicker
                                customInput={
                                    <div
                                        style={{
                                            position: 'relative',
                                            boxShadow: '0px 1px 2px 0 rgba(34, 36, 38, 0.15)',
                                        }}
                                    >
                                        <Icon
                                            name="calendar alternate outline"
                                            style={{
                                                position: 'absolute',
                                                top: '45%',
                                                left: '10px',
                                                transform: 'translateY(-50%)',
                                            }}
                                        />
                                        <input
                                            style={{ paddingLeft: '30px' }}
                                            placeholder="Requested Time *"
                                            required={true}
                                            readOnly={request ? true : false}
                                            onChange={(e) => {}}
                                            value={
                                                startDate
                                                    ? momentTZ(startDate).format('M.D.Y h:mm A')
                                                    : request
                                                    ? formatDateWithTZ(
                                                          request.RequestedTime,
                                                          'M.D.Y h:mm A',
                                                          facilityTimezone || '',
                                                      )
                                                    : ''
                                            }
                                        />
                                    </div>
                                }
                                minDate={new Date()} // Set the minimum to today
                                dateFormat="M.d.Y h:mm aa"
                                showTimeSelect
                                timeIntervals={15}
                                selected={startDate ? Date.parse(startDate || new Date().toISOString()) : null}
                                onChange={(date: Date) => {
                                    if (!date) return;
                                    const dateTime = momentTZ(date).format('YYYY-MM-DDTHH:mm:ss');
                                    setStartDate(dateTime);
                                }}
                                readOnly={request ? true : false}
                            />
                        </Grid.Column>
                    </Grid>
                </Form.Field>
                {/* Image input component */}
                <div style={{ textAlign: 'center', margin: '20px 0px' }}>
                    <input
                        id="imageInput"
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                        disabled={request ? true : false}
                    />
                    {currentImage && !selectedImage && imageLink ? (
                        <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                            <img src={imageLink} style={{ maxWidth: '100px', maxHeight: '100px' }} />
                        </label>
                    ) : (
                        <div style={{ cursor: 'pointer' }}>
                            {!selectedImage && (
                                <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                                    <Icon name="images" size="huge" />
                                </label>
                            )}
                            {selectedImage && (
                                <label htmlFor="imageInput" style={{ cursor: 'pointer' }}>
                                    <img
                                        src={URL.createObjectURL(selectedImage)}
                                        style={{ maxWidth: '100px', maxHeight: '100px' }}
                                    />
                                </label>
                            )}
                        </div>
                    )}
                </div>
                <Form.Field>
                    <Grid columns={1} centered>
                        {!request && (
                            <Grid.Column width={6}>
                                <Button
                                    disabled={!orderName || (!startDate && !request)}
                                    primary
                                    fluid
                                    size="medium"
                                    type="submit"
                                >
                                    Send
                                </Button>
                            </Grid.Column>
                        )}
                        {request && (
                            <Grid.Column width={6}>
                                <Button
                                    fluid
                                    size="medium"
                                    onClick={associatedOrders && associatedOrders.length > 1 ? cancelAllAssociatedRequest : cancelRequest}
                                    disabled={request.Status === 'Closed'}
                                    type="button"
                                >
                                    Cancel Order
                                </Button>
                            </Grid.Column>
                        )}
                    </Grid>
                </Form.Field>
            </Form>
            <CloseAssociatedOrdersModal
                open={openCloseAssociatedOrdersModal}
                onClose={onClose}
                cancelRequest={cancelRequest}
                cancelAllOpenAndAcceptedOrders={cancelAllOpenAndAcceptedOrders}
                associatedOrders={associatedOrders}
                facilityTimezone={facilityTimezone}
                loading={loading}
            />
        </div>
    );
};

export default CreateServiceOrder;