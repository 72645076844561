import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Loader, Button, Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import UserContext from '../../../contexts/UserContext';
import RedirectContext from '../../../contexts/RedirectContext';
import {
    getRequest,
    acceptRequest,
    closeRequest,
    unAcceptRequest,
    toggleRequestArchivedStatus,
    respondToGreeting,
    convertGreetingToRequest,
    convertGreetingToActivity,
} from '../../../services/activities';
import { sendTextMessage } from '../../../services/Messages';

import './style.less';

interface Request {
    _id: string;
    Request_Type: string;
    RequestType_Icon: string;
    RequestType_Name: string;
    Registrant_FirstName: string;
    Registrant_LastName: string;
    Registrant_Image: string;
    Name: string;
    RequestedTime: number;
    Status: string;
    Department_Icon: string;
    ClosedTime: number;
    AcceptedBy: string;
    AcceptedTime: number;
    AcceptedByName: string;
    RequestSlots: object;
    Registrant: string;
    IsArchived: boolean;
}

// We resend notification and then close toast, this also runs spinner
const ResendNotification = ({ closeToast, resendNotification, message }) => {
    return (
        <div>
            <div className="notification-retry-message">{message}</div>
            <div className="notification-retry-button">
                <Button
                    className="ui primary tiny button "
                    onClick={async () => {
                        resendNotification();
                        closeToast();
                    }}
                >
                    Retry notification
                </Button>
            </div>
        </div>
    );
};

const RequestDetail = () => {
    const {
        userObject: {
            profile: { _id: profileId, FirstName: userFirstName, LastName: userLastName },
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const toggleRedirect = useContext(RedirectContext);

    const residentId = listOfResidents[activeRegistrantsIndexes] && listOfResidents[activeRegistrantsIndexes]._id;
    const residentFirstName =
        listOfResidents[activeRegistrantsIndexes] && listOfResidents[activeRegistrantsIndexes].FirstName;
    const [request, setRequest] = useState<Request | null>();

    const [isLoading, setIsLoading] = useState(true);

    const { requestId } = useParams();

    const loadActivity = async () => {
        setIsLoading(true);
        const { request: requestNew } = await getRequest(requestId);

        setRequest(requestNew);
        setIsLoading(false);
    };

    useEffect(() => {
        loadActivity();
    }, [requestId]);

    const isUserManagerOfRegistrant = () => {
        const residentDetails = listOfResidents.filter((resident) => request && resident._id === request.Registrant)[0];
        return residentDetails.manager === true;
    };

    const navigate = (to: string) => {
        toggleRedirect(to);
        return;
    };

    const sendToast = (type = 'warn', message) => {
        if (type === 'success') {
            toast.success(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        } else if (type === 'warn') {
            toast.warn(message, {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    const reOpenReq = async (requestId) => {
        setIsLoading(true);

        const requestName = request && request.RequestType_Name;
        const name = requestName?.includes('_') ? requestName.split('_')[1] : requestName;
        const textToSend = `I would not be able to help you with your ${
            name === 'Generic' ? '' : name
        } request. Someone else will get in touch with you soon!`;
        const textOnSuccess = 'Request was unassigned';
        const textOnRetry = `Request was unassigned successfully, but we failed to notify ${residentFirstName}`;

        try {
            const result = await unAcceptRequest(requestId);
            console.log('result: ', result);
            await sendAcceptedNotificationMessage(residentId, textToSend, textOnSuccess, textOnRetry);
        } catch (err) {
            sendToast('warn', err.message);
        }
    };

    const sendNotification = (requestId) => {
        setIsLoading(true);
        respondToGreeting(requestId)
            .then(() => {
                setIsLoading(false);
                sendToast('success', 'We have sent Automated Notification');
                return navigate('/activities');
            })
            .catch((err) => {
                sendToast('warn', err.message);
                return navigate('/activities');
            });
    };

    const toggleReqArchivedStatus = (requestId, isArchived) => {
        setIsLoading(true);
        toggleRequestArchivedStatus(requestId, isArchived)
            .then(() => {
                setIsLoading(false);
                sendToast('success', `${!isArchived ? 'Unarchived' : 'Archived'} the request`);
                return navigate('/activities');
            })
            .catch((err) => {
                sendToast('warn', err.message);
                return navigate('/activities');
            });
    };

    const sendAcceptedNotificationMessage = async (residentId, textToSend, textOnSuccess, textOnRetry) => {
        setIsLoading(true);
        try {
            await sendTextMessage(textToSend, residentId);
            setIsLoading(false);

            sendToast('success', textOnSuccess);
            navigate('/activities');
        } catch (err) {
            setIsLoading(false);
            toast(
                //@ts-ignore
                <ResendNotification
                    message={textOnRetry}
                    resendNotification={async () => {
                        setIsLoading(true);
                        try {
                            await sendTextMessage(textToSend, residentId);
                            setIsLoading(false);
                            sendToast('success', 'Successfully Notified');
                            navigate('/activities');

                            // here send the notification if it is sent
                        } catch (err) {
                            setIsLoading(false);
                            sendToast('warn', 'Failed to Send the message. Please try Manually');
                            navigate('/activities');
                        }
                    }}
                />,
                {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                },
            );
        }
    };

    const acceptReq = async (requestId) => {
        setIsLoading(true);

        const requestName = request && request.RequestType_Name;
        const name = requestName?.includes('_') ? requestName.split('_')[1] : requestName;
        const textToSend = `I have accepted your request ${name === 'Generic' ? '' : `for ${name}`}`;
        const textOnSuccess = 'Request was successfully accepted';
        const textOnRetry = `Request was accepted successfully, but we failed to notify ${residentFirstName}`;

        try {
            const result = await acceptRequest(requestId);
            console.log('result: ', result);
            await sendAcceptedNotificationMessage(residentId, textToSend, textOnSuccess, textOnRetry);
        } catch (err) {
            sendToast('warn', err.message);
        }
    };

    const closeReq = (requestId) => {
        setIsLoading(true);
        closeRequest(requestId)
            .then(() => {
                setIsLoading(false);
                sendToast('success', 'Closed the request');
                return navigate('/activities');
            })
            .catch((err) => {
                sendToast('warn', err.message);
                return navigate('/activities');
            });
    };

    const convertGreetingToReq = (requestId) => {
        setIsLoading(true);
        convertGreetingToRequest(requestId)
            .then(() => {
                setIsLoading(false);
                sendToast('success', 'Converted the message to request');
                return navigate('/activities');
            })
            .catch((err) => {
                sendToast('warn', err.message);
                return navigate('/activities');
            });
    };
    const convertGreetingToAct = (requestId) => {
        setIsLoading(true);
        convertGreetingToActivity(requestId)
            .then(() => {
                setIsLoading(false);
                sendToast('success', 'Converted the message to activity');
                return navigate('/activities');
            })
            .catch((err) => {
                sendToast('warn', err.message);
                return navigate('/activities');
            });
    };

    return (
        <div className="relative-list request-detail">
            {isLoading && <Loader active={isLoading} />}
            {request && (
                <div className="request-view mt-10">
                    <div>
                        {request.Registrant_Image && (
                            <img className="tiny circular img circular-image" src={request.Registrant_Image} alt="" />
                        )}
                    </div>
                    <div className="details">
                        <div className="title">{request.Name}</div>
                        <div className="resident-name">{`${request.Registrant_FirstName} ${request.Registrant_LastName}`}</div>
                        {Object.entries(request.RequestSlots).map(([name, value]) => (
                            <div className="detail">
                                {name}: {value}
                            </div>
                        ))}
                        <div className="timestamp">Created: {new Date(request.RequestedTime).toLocaleString()} </div>

                        {request.AcceptedTime && (
                            <>
                                <div className="status">
                                    Accepted By: <span className="name">{request.AcceptedByName}</span>{' '}
                                </div>
                                <div className="timestamp">
                                    Accepted Time: {new Date(request.AcceptedTime).toLocaleString()}{' '}
                                </div>
                            </>
                        )}
                        {request.Status === 'Closed' && (
                            <div className="timestamp mb-12">
                                Closed Time: {new Date(request.ClosedTime).toLocaleString()}{' '}
                            </div>
                        )}
                        {request.RequestType_Name !== 'Greetings' && (
                            <div className="status">
                                Status: <span className={request.Status}>{request.Status}</span>
                            </div>
                        )}
                        <div className="requestControl">
                            {request.RequestType_Name === 'Greetings' && (
                                <div className="actions">
                                    <div className="mb-4">
                                        <Button className="ui primary tiny button">
                                            <Icon name="call" /> Call
                                        </Button>
                                        <Button className="ui primary tiny button">
                                            <Icon name="comment" />
                                            SMS
                                        </Button>
                                        <Button
                                            className="ui primary tiny button"
                                            loading={isLoading}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                sendNotification(request._id);
                                            }}
                                        >
                                            <Icon name="bell" />
                                            Notify
                                        </Button>
                                    </div>
                                    <Button
                                        className="ui primary basic tiny button"
                                        loading={isLoading}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            convertGreetingToReq(request._id);
                                        }}
                                    >
                                        Convert to Request
                                    </Button>
                                    <Button
                                        className="ui primary basic tiny button"
                                        loading={isLoading}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            convertGreetingToAct(request._id);
                                        }}
                                    >
                                        Convert to Activity
                                    </Button>
                                    <Button
                                        className="ui primary basic tiny button"
                                        type="button"
                                        loading={isLoading}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleReqArchivedStatus(request._id, !request.IsArchived);
                                        }}
                                    >
                                        <img className="icon archive-button-icon" src="/archive.png"></img>
                                        {request.IsArchived ? 'Unarchive' : 'Archive'}
                                    </Button>
                                </div>
                            )}
                            {request.RequestType_Name !== 'Greetings' &&
                                request.Status !== 'Open' &&
                                request.AcceptedBy === profileId && (
                                    <Button
                                        className="ui primary small button"
                                        type="button"
                                        loading={isLoading}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            reOpenReq(request._id);
                                        }}
                                    >
                                        Re-Open
                                    </Button>
                                )}
                            {request.RequestType_Name !== 'Greetings' && request.Status === 'Open' && (
                                <Button
                                    className="ui primary small button"
                                    type="button"
                                    loading={isLoading}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        acceptReq(request._id);
                                    }}
                                >
                                    Accept
                                </Button>
                            )}
                            {request.RequestType_Name !== 'Greetings' && request.Status === 'Accepted' && (
                                <Button
                                    className="ui primary small button"
                                    type="button"
                                    loading={isLoading}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        closeReq(request._id);
                                    }}
                                >
                                    Close
                                </Button>
                            )}
                            {request.RequestType_Name !== 'Greetings' &&
                                request.Status === 'Closed' &&
                                isUserManagerOfRegistrant() && (
                                    <Button
                                        className="ui primary small button"
                                        type="button"
                                        loading={isLoading}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            toggleReqArchivedStatus(request._id, !request.IsArchived);
                                        }}
                                    >
                                        {request.IsArchived ? 'Unarchive' : 'Archive'}
                                    </Button>
                                )}
                        </div>
                    </div>
                    <div>
                        {request.RequestType_Icon && (
                            <img className="tiny circular img" src={request.RequestType_Icon} alt="" />
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default RequestDetail;
