import * as Lambda from './LambdaApi';

const listCommunities = async (pageNo = 0, limit = 20) => {
    try {
        const apiPath = `/communities`;
        let params = { pageNo };
        console.log('listCommunitiesResponse');
        const listCommunitiesResponse = await Lambda.Get(apiPath, {}, 'lambda');
        console.log(listCommunitiesResponse);
        if (listCommunitiesResponse.success) {
            return listCommunitiesResponse.communities;
        }
        return [];
    } catch (error) {
        console.error(error);
        throw Error(error);
    }
};

const getCommunityDetails = async () => {
    try {
        const apiPath = `/communities`;
        let params = {};
        const listCommunitiesResponse = await Lambda.Get(apiPath, {}, 'lambda');
        console.log(listCommunitiesResponse);
        if (listCommunitiesResponse.success) {
            return listCommunitiesResponse;
        }
        return [];
    } catch (error) {
        console.error(error);
        throw Error(error);
    }
};

const saveMenuItem = async (relativeId, params = {}) => {
    try {
        const apiPath = `/relatives/${relativeId}/community-menus`;
        await Lambda.Post(apiPath, params, 'lambda');
        return true;
    } catch (error) {
        console.error(error);
        throw Error(error);
    }
};

const listCommunityMenus = async (facilityId, filters) => {
    try {
        // const apiPath = `/relatives/${relativeId}/community-menus`;
        const apiPath = `/s2l/${facilityId}/menus`
        // let params = { pageNo };

        const listCommunitiesResponse = await Lambda.Post(apiPath, {Filter: filters}, 'lambda');
        if (listCommunitiesResponse.success) {
            return listCommunitiesResponse.Result.Content.Result;
        }
        return [];
    } catch (error) {
        console.error(error);
        throw Error(error);
    }
};


const listCommunityActivities = async (relativeId, pageNo = 0, limit = 20) => {
    try {
        const apiPath = `/relatives/${relativeId}/community-activities`;
        let params = { pageNo };
        const listCommunitiesResponse = await Lambda.Get(apiPath, {}, 'lambda');
        if (listCommunitiesResponse.success) {
            return listCommunitiesResponse.communityActivities;
        }
        return [];
    } catch (error) {
        console.error(error);
        throw Error(error);
    }
};

const updateMenuItem = async (relativeId, params) => {
    try {
        const apiPath = `/relatives/${relativeId}/community-menus/${params._id}`;
        await Lambda.Put(apiPath, params, 'lambda');
        return true;
    } catch (error) {
        console.error(error);
        throw Error(error);
    }
    
}
const deleteMenuItem = async (relativeId, menuId) => {
    try {
        const apiPath = `/relatives/${relativeId}/community-menus/${menuId}`;
        await Lambda.Delete(apiPath,{}, 'lambda');
        return true;
    } catch (error) {
        console.error(error);
        throw Error(error);
    }
    
}
const updateActivity = async (relativeId, params) => {
    try {
        const apiPath = `/relatives/${relativeId}/community-activities/${params._id}`;
        await Lambda.Put(apiPath, params, 'lambda');
        return true;
    } catch (error) {
        console.error(error);
        throw Error(error);
    }
    
}

const saveActivity = async (relativeId, params = {}) => {
    try {
        const apiPath = `/relatives/${relativeId}/community-activities`;
        await Lambda.Post(apiPath, params, 'lambda');
        return true;
    } catch (error) {
        console.error(error);
        throw Error(error);
    }
};

const deleteActivity = async (relativeId, activityId) => {
    try {
        const apiPath = `/relatives/${relativeId}/community-activities/${activityId}`;
        await Lambda.Delete(apiPath,{}, 'lambda');
        return true;
    } catch (error) {
        console.error(error);
        throw Error(error);
    }
    
}

export { listCommunities, getCommunityDetails, saveMenuItem, listCommunityMenus, listCommunityActivities, updateMenuItem , updateActivity, saveActivity, deleteMenuItem, deleteActivity };
