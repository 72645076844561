import React, { useState } from 'react';
import {
    Grid, GridColumn, Segment, Container, Button, ModalHeader,
    ModalContent,
    Icon,
    Modal,
    Loader,
    Dimmer,
    ItemGroup,
} from "semantic-ui-react";
import { Group } from "../../types/Group";
import { fetchByDocIds } from '../../services/Documents';
import { Document } from '../../types/Document';
import moment from 'moment-timezone';
import sendToast from '../../../utils/Toast';
import './style.less';
import UserDirectoryView from '../ResidentSocial';
import { listAllResidents } from '../../services/Resident';
import { listAllStaff } from '../../services/Staff';
import { Staff } from '../../types/Staff';
import { Registrant } from '../../types/Registrant';

interface Props {
    group: Group;
    facilityTimeZone: string;
}

const GroupDetailsCard = ({ group, facilityTimeZone }: Props) => {
    const [docsModal, setDocsModal] = useState<boolean>(false);
    const [memberModal, setMemberModal] = useState<boolean>(false);
    const [selectedDocs, setSelectedDocs] = useState<Document[]>([]);
    const [selectedGroup, setSelectedGroup] = useState<Group>();
    const [loading, setLoading] = useState<boolean>(false);
    const [memberLoading, setMemberLoading] = useState<boolean>(false);
    const [memberList, setMemberList] = useState<(Registrant | Staff)[]>([]);

    const handleDocsModal = async (selectedGroup: Group) => {
        try {
            setLoading(true);
            setSelectedGroup(selectedGroup);
            const selectedDocIds = selectedGroup.DocIds;
            const facilityId = selectedGroup.Facility;
            const { Result: selectedDocs } = await fetchByDocIds(facilityId, selectedDocIds);
            setSelectedDocs(selectedDocs);
            setDocsModal(true);
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : "Error fetching documents");
        } finally {
            setLoading(false);
        }
    };

    const handleMemberModal = async (selectedGroup: Group) => {
        try {
            setMemberLoading(true);
            setSelectedGroup(selectedGroup);
            const facilityId = selectedGroup.Facility;
            const residentIds = selectedGroup.ResidentIds;
            const staffIds = selectedGroup.StaffIds;
            const staffFilter = {
                Facility: facilityId,
                _id: {
                    $in: staffIds
                },
            };
            const residentFilter = {
                Facility: facilityId,
                _id: {
                    $in: residentIds
                },
            };
            const [{ Result: residentDetails = [] }, { Result: staffDetails = [] }] = await Promise.all([
                listAllResidents(residentFilter),
                listAllStaff(staffFilter)
            ]);
            const members = [
                ...residentDetails.map((resident: Registrant) => {
                    resident.isStaff = false;
                    return resident;
                }),
                ...(staffDetails).map((staff: Staff) => {
                    staff.isStaff = true;
                    return staff;
                })];
            setMemberList(members as (Registrant | Staff)[]);
            setMemberModal(true);
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : "Error fetching documents");
        } finally {
            setMemberLoading(false);
        }
    };

    return (
        <>
            <Segment className='group-card' style={{ width: '50%', margin: 'auto', border: "0.094rem solid #000000", borderRadius: "10px" }}>
                <Grid columns={2} centered>
                    <GridColumn width={5} style={{ display: 'flex', alignItems: 'center', }}>
                        <div className="circle">
                            <img src={group.ImageUrl ? group.ImageUrl : `${process.env.PUBLIC_URL}/No_Image_Placeholder.jpg`} alt="No image found" />
                        </div>
                    </GridColumn>
                    <GridColumn width={9}>
                        <Container>
                            <Grid columns={1} >
                                <Grid.Row>
                                    <Grid.Column>
                                        {/* Content for first row */}
                                        <h3 style={{ fontWeight: "bold" }}>{group.Name} </h3>
                                        <p>{group.Description}</p>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <div className="styled-input" onClick={() => { handleMemberModal(group); }}>
                                            <Icon name='user' circular className="icon-wrapper" />
                                            <div className="input-placeholder">Members</div>
                                            <Dimmer active={memberLoading} inverted>
                                                <Loader />
                                            </Dimmer>
                                        </div>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        {group.URL}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Container>
                    </GridColumn>
                </Grid>
                <div style={{ display: 'flex', justifyContent: 'flex-end', padding: "10px 30px" }}>
                    <Button
                        style={{ backgroundColor: 'rgb(24,52,102)', color: '#ffff' }}
                        content="Docs"
                        size="medium"
                        icon="file outline"
                        loading={loading}
                        onClick={() => { handleDocsModal(group); }}
                    />
                </div>
            </Segment>
            <Modal
                open={docsModal}
                onClose={() => setDocsModal(false)}
                onOpen={() => setDocsModal(true)}
            >
                <ModalHeader style={{ position: "relative" }} >
                    <Button className='back-button' onClick={() => setDocsModal(false)} primary>
                        <Icon name='chevron left' /> Back
                    </Button>
                    <div className='self-center' style={{ color: "#2987CD" }}>
                        <h3>{selectedGroup && selectedGroup.Name}</h3>
                    </div>
                </ModalHeader>
                <ModalContent image scrolling>
                    <div style={{ width: "100vw" }}>
                        {selectedDocs && selectedDocs.length > 0 ? selectedDocs.map((doc, index) => (
                            <Segment key={doc._id} basic style={{ backgroundColor: index % 2 == 0 ? "white" : "#E8E8E8" }}>
                                <Grid>
                                    <Grid.Row>
                                        <Grid.Column width={8} textAlign="left">
                                            <h4><a href={doc.attachedFiles && doc.attachedFiles[0].attachedLink} target="_blank">{doc.Name}</a></h4>
                                            <h4 className="nonBold">{doc.Description}</h4>
                                        </Grid.Column>
                                        <Grid.Column width={8} textAlign="right">
                                            <h4>{moment.tz(doc.DateAdded, facilityTimeZone).format("MM/DD/YYYY, hh:mm a")}</h4>
                                            <h4 className="nonBold">{doc.AddedByName}</h4>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Segment>
                        )) : <h4 className="center">No documents found</h4>}
                    </div>
                </ModalContent>
            </Modal>
            <Modal centered open={memberModal} onClose={() => setMemberModal(false)}>
                <Modal.Header>
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        Member Details
                    </div>
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description className='memberList'>
                        <ItemGroup className='directory-items'>
                            {memberList && memberList.length > 0 ? (
                                memberList.map((item, index) => (
                                    <UserDirectoryView key={index} item={item} />
                                ))) : <h4 className="center">No Members found</h4>}
                        </ItemGroup>
                    </Modal.Description>
                </Modal.Content>
            </Modal>
        </>
    );
};

export default GroupDetailsCard;