import * as Lambda from '../../services/LambdaApi';
import { paginationWrapper } from '../../utils/paginationWrapper';
import { DalilyActivity, ServiceCalendar } from '../types/Calendar';

const fetchCalendarEvents = async ({ facilityId, Filter, ResidentIds, pagingSorting }) => {
    const apiPath = `/s2l/${facilityId}/listAllActivitiesWithAttendees`;
    const payload = {
        Filter,
        ResidentIds,
        pagingSorting
    }
    const response = await Lambda.Post(apiPath, payload, 'lambda');
    if (!response || !response.Result || !response.Result.Content) {
        throw new Error('Error fetching calendar events');
    }
    return response.Result.Content;
}

const fetchAllCalendarEvents = async ({ facilityId, Filter, ResidentIds }) => {
    return await paginationWrapper(
        {
            apiCall: fetchCalendarEvents,
            params: {
                facilityId,
                Filter,
                ResidentIds,
            },
        }
    ) as  ServiceCalendar[] | DalilyActivity[];
}

const fetchAllResidentCalendarEvents = async ({ facilityId, Filter }) => {
    const payload = {
        Filter
    }
    const apiPath = `/s2l/${facilityId}/listResidentCalendar`;
    const response = await Lambda.Post(apiPath, payload, 'lambda');
    if (!response || !response.Result || !response.Result.Content) {
        throw new Error('Error fetching resident calendar events');
    }
    return response.Result.Content;
};

const createCalendarEvent = async ({ facilityId, event }) => {
    const apiPath = `/s2l/${facilityId}/createResidentCalendar`;
    const response = await Lambda.Post(apiPath, event, 'lambda');
    if (!response || !response.Result || !response.Result.Content) {
        throw new Error('Error creating calendar event');
    }
    return response.Result.Success;
}

const changeServiceActivityAttendingStatus = async ({ facilityId, payload }) => {
    const apiPath = `/s2l/${facilityId}/serviceInstanceAttendees`;
    const response = await Lambda.Post(apiPath, payload, 'lambda');
    if (!response || !response.Result || !response.Result.Content) {
        throw new Error('Error changing attending status');
    }
    return response.Result.Success;
}

const changeNormalActivityAttendingStatus = async ({ facilityId, payload }) => {
    const apiPath = `/s2l/${facilityId}/attendees`;
    const response = await Lambda.Post(apiPath, payload, 'lambda');
    if (!response || !response.Result || !response.Result.Content) {
        throw new Error('Error changing attending status');
    }
    return response.Result.Success;
}

export { fetchAllCalendarEvents , fetchAllResidentCalendarEvents, createCalendarEvent, changeServiceActivityAttendingStatus, changeNormalActivityAttendingStatus};