import React from 'react';
import Icon from 'semantic-ui-react/dist/commonjs/elements/Icon';
import { useCart } from '../../contexts/cart/CartContext';
import { useHistory } from 'react-router-dom';
interface Props {
    restaurantId?: string;
}

const DiningCart: React.FC<Props> = ({ restaurantId }) => {
    const { mealItems } = useCart();
    const history = useHistory();
    const handleShowMeal = async () => {
        history.push({
            pathname: `/dining/order`,
            state: { redirectRestaurantId: restaurantId }
        });
    };
    return (
        <div className='cartContainer' onClick={() => { handleShowMeal(); }}>
            <div className='cartLength'>
                {mealItems && mealItems.length || 0}
            </div>
            <Icon
                name="cart"
                size="big"
                className="backIcon"
            />
        </div>
    );
};

export default DiningCart;