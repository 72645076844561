import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/UserContext';
import { fetchGroupImage, fetchGroups } from '../../services/Groups';
import { Group } from '../../types/Group';
import sendToast from '../../../utils/Toast';
import GroupList from '../../components/GroupList';
import { Promise } from 'bluebird';
import { fetchFacilityDetails } from '../../services/Facilities';
import "./style.less";

const Groups = () => {
    const [groups, setGroups] = useState<Group[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageNo, setPageNo] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [facilityTimeZone, setFacilityTimeZone] = useState<string>("");
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const activeResident = listOfResidents[activeRegistrantsIndexes[0]];
    const pageSize = 5;

    useEffect(() => {
        getGroupsList();
    }, [pageNo]);

    useEffect(() => { getFacilityTimezone(); }, []);

    const getGroupsList = async () => {
        try {
            // fetch groups
            setLoading(true);
            const filter = {
                Facility: activeResident.Facility,
                ResidentId: String(activeResident._id),
            };
            const groups = await fetchGroups(filter, pageNo, pageSize);
            if (groups.TotRecords <= pageNo * pageSize) {
                setHasMore(false);
            }
            if (groups.Result.length > 0) {
                const groupsWithImages = await Promise.map(
                    groups.Result,
                    async (group: Group) => {
                        try {
                            if (group.ImageName) {
                                const response = await fetchGroupImage(group.Facility, group.ImageName, group._id);
                                group.ImageUrl = response;
                            }
                            return group;
                        } catch (error) {
                            sendToast("warn", error instanceof Error ? error.message : "Error in fetching group images");
                        }
                    },
                    { concurrency: 5 },
                );
                setGroups((prev) => { return [...prev, ...groupsWithImages]; });
            }
        } catch (error) {
            console.error(error);
            sendToast('error', error instanceof Error ? error.message : "Error fetching group list");
        } finally {
            setLoading(false);
        }
    };

    const getFacilityTimezone = async () => {
        try {
            const facilityRes = await fetchFacilityDetails(activeResident.Facility);
            if (!facilityRes.FacilityTimeZone) {
                throw new Error("Facility Timezone not found");
            }
            setFacilityTimeZone(facilityRes.FacilityTimeZone);
        } catch (error) {
            sendToast('error', Error instanceof Error ? error.message : "Error fetching facility timezone");
        }
    };

    return (
        <div>
            <h1 className="heading">Group</h1>
            <hr className="spacing" />
            <div style={{ width: "100%" }}>
                <GroupList groups={groups} loading={loading} setPageNo={setPageNo} hasMore={hasMore} facilityTimeZone={facilityTimeZone} />
            </div>
        </div>
    );
};

export default Groups;