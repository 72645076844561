import React, { useState, useEffect, useContext } from 'react';
import { Button, Modal, Form, Dimmer, Loader } from 'semantic-ui-react';
import UserContext from '../../../contexts/UserContext';
import InviteItemList from '../../components/inviteItemList';
import { listInvites, replyInvite, acceptInviteByCode } from '../../../services/Invite';
import { toast } from 'react-toastify';
import './style.less';

interface InviteInterface {
    _id: string;
    registrantId: string;
    registrantName: string;
    registrantImage?: string;
}

const Invites = () => {
    const { userObject, reloadRegistrantLists } = useContext(UserContext);
    const [isFetchingInvites, setIsFetchingInvites] = useState(false);
    const [invites, setInvites] = useState<InviteInterface[]>([]);
    const [error, setError] = useState('');
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [invitationCode, setInvitationCode] = useState<string>('');
    const loadInvites = async () => {
        console.log('LoadInvites called', userObject);
        try {
            if (userObject.profile) {
                // @ts-ignore
                const fetchedInvites = await listInvites(userObject.profile._id);
                console.log(fetchedInvites);
                setInvites(fetchedInvites);
            }
            setIsFetchingInvites(false);
        } catch (error) {
            setError('Something went wrong');
            setIsFetchingInvites(false);
        }
    };

    const acceptInvite = async (inviteId) => {
        try {
            await replyInvite(inviteId, true);
            toast.success('Invite Accepted successfully', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            await reloadRegistrantLists();
            await loadInvites();
        } catch (error) {
            console.log('error', error);
            setError('Something went wrong');
        }
    };

    const rejectInvite = async (inviteId) => {
        try {
            await replyInvite(inviteId, false);
            toast.warn('Invite Rejected successfully', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            await loadInvites();
        } catch (error) {
            setError('Something went wrong');
        }
    };

    useEffect(() => {
        setIsFetchingInvites(true);
        loadInvites();
    }, [userObject]);

    const submitCode = async (code) => {
        console.log('code');
        try {
            setIsFetchingInvites(true);
            const isAccepted = await acceptInviteByCode(code);
            setIsFetchingInvites(false);
            if (isAccepted) {
                console.log('Added! ');
                toast.success('Accepted successfully', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                setIsModalOpen(false);
                await reloadRegistrantLists();
                await loadInvites();
            } else {
                console.log('error: ');
                toast.error('Accepting failed', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        } catch (e) {
            setIsFetchingInvites(false);
            console.log('error: ', e);
            toast.error('Something went wrong. Please try again', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    return (
        <div className="invites">
            <Modal
                open={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setInvitationCode('');
                }}
                size="tiny"
                closeIcon
                className="invite-accept-modal"
            >
                <Dimmer active={isFetchingInvites}>
                    <Loader/>
                </Dimmer>
                <Modal.Header>
                    <h5>Accept invite</h5>
                </Modal.Header>
                <Form>
                    <Modal.Content>
                        <Form.Input
                            type="text"
                            value={invitationCode}
                            onChange={(e) => {
                                setInvitationCode(e.currentTarget.value);
                            }}
                            placeholder="Code"
                        ></Form.Input>
                    </Modal.Content>
                    <Modal.Actions className="">
                        <Button
                            className="ui primary small button "
                            type="button"
                            onClick={() => submitCode(invitationCode)}
                        >
                            Save
                        </Button>
                    </Modal.Actions>
                </Form>
            </Modal>
            {error && <div>{error}</div>}
            <InviteItemList
                invites={invites}
                isFetching={isFetchingInvites}
                acceptInvite={acceptInvite}
                rejectInvite={rejectInvite}
            />
            <div className="buy-container" style={{ maxWidth: process.env.REACT_APP_PLATFORM === 'neighbors' ? '78.125vw' : '900px' }}>
                <Button
                    className="primary large basic"
                    onClick={() => {
                        setIsModalOpen(true);
                    }}
                >
                    <i className="plus circle icon"></i> Accept
                </Button>
            </div>
        </div>
    );
};

export default Invites;
