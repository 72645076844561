import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { fetchMenuItems } from '../../services/Menu';
import UserContext from '../../../contexts/UserContext';
import sendToast from '../../../utils/Toast';
import { ServicesType } from '../../types/ServiceInstance';
import { Dimmer, Form, Grid, Icon, Loader, Popup, Radio } from 'semantic-ui-react';
import { getAssetById } from '../../services/Asset';
import "./style.less";
import MenuItemCard from './MenuItemCard';
import { ServiceAddOn } from '../../types/Service';
import { cart, customDateTimeFormat } from '../../../utils';
import { createDiningOrder } from '../../services/OrderStatus';
import { MealItem, useCart } from '../../contexts/cart/CartContext';
import CartCarousel from './CartCarousel';
import { cloneDeep } from 'lodash';

interface RouteParams {
    restaurantId: string;
    menuId: string;
}
interface LocationState {
    from?: string;
    redirectRestaurantId?: string;
}

const OrderBookingPage = () => {
    const [mealDetails, setMealDetails] = useState<ServicesType>();
    const [mealDetailsLoading, setMealDetailsLoading] = useState<boolean>(false);
    const [restaurantName, setRestaurantName] = useState<string>("");
    const [selectedDeliveryOption, setSelectedDeliveryOption] = useState<string>('');
    const [singleItemSelectedAddon, setSingleItemSelectedAddon] = useState<ServiceAddOn[]>([]);
    const [totalPrice, setTotalPrice] = useState<number>(0);
    const [cartItems, setCartItems] = useState<ServicesType[]>([]);
    const [SpecialNotes, setSpecialNotes] = useState<string>('');
    const [SingleItemQuantity, setSingleItemQuantity] = useState<number>(1);
    const [fetchCartItemsFlag, setFetchCartItemsFlag] = useState<boolean>(false);
    const { restaurantId, menuId } = useParams<RouteParams>();
    const history = useHistory();
    const location = useLocation<LocationState>();
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const activeResident = listOfResidents[activeRegistrantsIndexes[0]];
    const { mealItems, clearCart } = useCart()

    useEffect(() => {
        // if we have menuId and restaurantId then we will fetch only one menu item as it is created from menu page else we will fetch all the items from the cart
        if (menuId && restaurantId) {
            fetchOneMenu();
        } else {
            !fetchCartItemsFlag && fetchCartItems();
        }
    }, [mealItems]);
    useEffect(() => {
        calculateTotalPrice();
    }, [mealDetails, singleItemSelectedAddon, SingleItemQuantity, cartItems, mealItems]);

    const fetchOneMenu = async () => {
        try {
            setMealDetailsLoading(true);
            const filter = {
                Facility: activeResident.Facility,
                startDate: moment(new Date()).startOf('day').format(customDateTimeFormat),
                endDate: moment(new Date()).endOf('day').format(customDateTimeFormat),
                _id: menuId,
                source: 'Menu',
            };
            const [selectedMeal, restaurant] = await Promise.all([
                fetchMenuItems({ filter }),
                getAssetById({ Facility: activeResident.Facility, AssetId: String(restaurantId) }),
            ]);
            setMealDetails(selectedMeal[0]);
            setRestaurantName(restaurant.response.AssetName);
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Error fetching meal details');
        } finally {
            setMealDetailsLoading(false);
        }
    };
    const fetchCartItems = async () => {
        if (mealItems.length === 0) {
            return;
        }
        try {
            setMealDetailsLoading(true);
            setFetchCartItemsFlag(true);
            const _ids = mealItems.map((item) => item.serviceInstanceId);
            const filter = {
                Facility: activeResident.Facility,
                startDate: moment(new Date()).startOf('day').format(customDateTimeFormat),
                endDate: moment(new Date()).endOf('day').format(customDateTimeFormat),
                _ids: _ids,
                source: 'Menu',
            };
            const response = await fetchMenuItems({ filter });
            setCartItems(response);
        } catch (error) {
            sendToast('error', 'Failed to fetch cart Details.');
        } finally {
            setMealDetailsLoading(false);
        }
    };

    const calculateTotalPrice = () => {
        // if we have menuId and restaurantId then we will calculate the total price for only one item else we will calculate the total price for all the items in the cart.
        let total = 0;
        if (mealDetails && restaurantId && menuId) {
            total =
                mealDetails.defaults && mealDetails.defaults.externalCost
                    ? Number(mealDetails.defaults.externalCost)
                    : 0;
            if (singleItemSelectedAddon) {
                singleItemSelectedAddon.forEach((addOn) => {
                    total += Number(addOn.itemCost);
                });
            }
            total *= SingleItemQuantity;
        } else {
            mealItems && mealItems.length > 0 && mealItems.forEach((item) => {
                const meal = cartItems.find((cartItem) => String(cartItem._id) === item.serviceInstanceId);
                if (meal) {
                    total += meal.defaults && meal.defaults.externalCost ? Number(meal.defaults.externalCost) : 0;
                    if (item.serviceAddOns && item.serviceAddOns.length > 0) {
                        item.serviceAddOns.forEach((addOn: ServiceAddOn) => {
                            total += Number(addOn.itemCost);
                        });
                    }
                }
                total *= item.Quantity;
            }
            );
        }
        setTotalPrice(total);
    };
    
    const handleRedirectToMealSelection = () => {
        const fromPage = location.state && location.state.from;
        const redirectRestaurantId = location.state && location.state.redirectRestaurantId;
        if (fromPage === 'menu') {
            history.goBack();
        } else if (restaurantId || redirectRestaurantId) {
            history.push({
                pathname: `/dining/${restaurantId || redirectRestaurantId}`,
            });
        } else {
            history.push({
                pathname: `/dining`,
            });
        }
    };

    const handleChange = (
        e: React.FormEvent<HTMLInputElement>,
        { value }: any
    ) => {
        setSelectedDeliveryOption(value);
    };

    const handleSubmit = async () => {
        try {
            setMealDetailsLoading(true);
            if (!selectedDeliveryOption || !activeResident.Facility || !activeResident._id || (restaurantId && !menuId)) {
                throw new Error('Please select delivery option');
            }
            let mealItemsArr: MealItem[] = [];
            if (menuId && restaurantId) { // when we will create the order from menu page mealItems will be only one Single Item
                mealItemsArr = [
                    {
                        serviceInstanceId: menuId,
                        serviceAddOns: singleItemSelectedAddon,
                        Quantity: SingleItemQuantity,
                    },
                ];
            } else { // when we will create the order from Dining page
                mealItemsArr = cloneDeep(mealItems);
            }
            const orderData = {
                Facility: activeResident.Facility,
                ResidentId: String(activeResident._id),
                specialNotes: SpecialNotes,
                orderType: selectedDeliveryOption,
                mealItems: mealItemsArr,
            };
            const response = await createDiningOrder(activeResident.Facility, orderData);
            if (response) {
                sendToast('success', 'Order created successfully');
                handleRedirectToMealSelection();
                if (!menuId && !restaurantId) { // we will only clear the cart when it is order is created from dining page
                    clearCart()
                }
            } else {
                throw new Error('Error while placing order.');
            }
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Error while placing order');
        } finally {
            setMealDetailsLoading(false);
        }
    };

    return <div>
        <Dimmer inverted active={mealDetailsLoading}>
            <Loader>Loading</Loader>
        </Dimmer>
        <Form>
            <div className="headingContainer">
                <Popup
                    content="Go back to choose meal"
                    trigger={
                        <Icon
                            name="arrow left"
                            size="large"
                            className="backIcon"
                            onClick={handleRedirectToMealSelection}
                        />
                    }
                />
                <div className="mainHeadingContainer">
                    <h1 className="heading">Place your order</h1>
                </div>
            </div>
            <hr className="spacing" />
            {
                !menuId && !restaurantId &&
                <CartCarousel
                    mealDetailsLoading={mealDetailsLoading}
                    cartItems={cartItems}
                    setCartItems={setCartItems}                    
                />
            }
            <div className='center-self-column'>
                <div style={{ width: "30%" }}>
                    {restaurantName &&
                        <div className='nameCont'>
                            <div className="nameContainerOrder" >
                                <p className="diningName">{restaurantName}</p>
                            </div>
                        </div>
                    }
                    <div className='center-self top-ten'>
                        {
                            mealDetails &&
                            <MenuItemCard
                                menuItem={mealDetails}
                                source={cart}
                                setSingleItemSelectedAddon={setSingleItemSelectedAddon}
                                setSingleItemQuantity={setSingleItemQuantity}
                                SingleItemQuantity={SingleItemQuantity}
                                handleRedirectToMealSelection={handleRedirectToMealSelection}
                            />
                        }
                    </div>
                    <hr className="spacing-half" />
                    <Grid columns={2} style={{ fontSize: "20px" }}>
                        <Grid.Column width={7}>
                            Total:
                        </Grid.Column>
                        <Grid.Column width={9}>
                            ${totalPrice}
                        </Grid.Column>
                    </Grid>
                    <Form.TextArea
                        placeholder='Special Notes:'
                        style={{ width: "100%", marginTop: "20px", padding: "5px" }}
                        value={SpecialNotes}
                        onChange={(e) => setSpecialNotes((e.target as HTMLTextAreaElement).value)}
                    />
                    <Form.Field>
                        <Radio
                            label='Delivery'
                            name='orderType'
                            value='delivery'
                            checked={selectedDeliveryOption === 'delivery'}
                            onChange={handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label='Pre order for pick up'
                            name='orderType'
                            value='pickUp'
                            checked={selectedDeliveryOption === 'pickUp'}
                            onChange={handleChange}
                        />
                    </Form.Field>
                    <Form.Field>
                        <Radio
                            label='Pre order for sit in'
                            name='orderType'
                            value='sitIn'
                            checked={selectedDeliveryOption === 'sitIn'}
                            onChange={handleChange}
                        />
                    </Form.Field>
                    <div className='center-self'>
                        <Form.Group>
                            <Form.Button onClick={handleSubmit}>Send</Form.Button>
                            <Form.Button onClick={handleRedirectToMealSelection}>Cancel</Form.Button>
                        </Form.Group>
                    </div>
                </div>
            </div>
        </Form>
    </div>;
};

export default OrderBookingPage;
