import React, { Component, useState, useContext, useEffect, useCallback } from 'react';
import { Form, Button, Progress } from 'semantic-ui-react';
import { Dimmer, Loader, Modal } from 'semantic-ui-react';
import UserContext from '../../../contexts/UserContext';
import { resetPassword } from '../../../services/Authentication';
import { updateRelative, updateRelativeImage, deleteRelative } from '../../../services/family';
import { toast } from 'react-toastify';
import { getUploadUrl, uploadToSignedUrl, converterDataURItoBlob } from '../../../services/imageUpload';
import ImageCropModal from '../../components/ImageCropModal';
import './style.less';
import config from '../../../config';

declare var process: {
    env: {
        SERVER_ENV: string;
    };
};
interface Relative {
    _id: string;
    FirstName: string;
    LastName: string;
    Image: string;
    Manager?: boolean;
    Relationship?: string;
    Username?: string;
}

const envConfig = config[process.env.SERVER_ENV] || config.development;

const ChangePasswordModal = ({ contact, open, close }) => {
    const [loading, setLoading] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');
    const [isChangePasswordDisable, setIsChangePasswordDisable] = useState(true);

    useEffect(() => {
        setIsChangePasswordDisable(
            !oldPassword ||
                oldPassword.length < 8 ||
                !newPassword ||
                newPassword.length < 8 ||
                !confirmNewPassword ||
                confirmNewPassword.length < 8,
        );
    }, [oldPassword, newPassword, confirmNewPassword]);

    const updatePassword = async () => {
        setError('');
        setLoading(true);

        try {
            if (newPassword !== confirmNewPassword) {
                setLoading(false);
                setError('Passwords do not match');
            } else {
                const result = await resetPassword(oldPassword, newPassword);

                if (result) {
                    toast.success('Password reset successfully!', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    setLoading(false);

                    close();
                } else {
                    setLoading(false);

                    setError('Old Password is incorrect');
                }
            }
        } catch (e) {
            setLoading(false);

            setError('Something went wrong');
        }
    };

    return (
        <Modal
            className="modal-content"
            open={open}
            onClose={() => {
                close();
            }}
            closeIcon
            size={'small'}
        >
            <div className="change-password">
                <Form loading={loading} onSubmit={() => {}}>
                    <Form.Field>
                        <Form.Input
                            value={oldPassword}
                            type="password"
                            name="oldPassword"
                            placeholder="Old Password"
                            required
                            onChange={e => setOldPassword(e.target.value)}
                        ></Form.Input>
                    </Form.Field>

                    <div className="field password-field">
                        <Form.Input
                            value={newPassword}
                            type="password"
                            className={newPassword ? 'full-75' : 'full-width'}
                            name="newPassword"
                            placeholder="New Password"
                            required
                            onChange={e => setNewPassword(e.target.value)}
                        ></Form.Input>
                        {newPassword && (
                            <Progress
                                percent={(newPassword.length / 8) * 100}
                                size="tiny"
                                autoSuccess
                                color="red"
                                style={{
                                    width: '25%',
                                    marginBottom: '0px',
                                    paddingTop: '1em',
                                    marginLeft: '2px',
                                    maxHeight: '3em',
                                }}
                            />
                        )}
                    </div>

                    <Form.Field>
                        <Form.Input
                            value={confirmNewPassword}
                            type="password"
                            name="confirmNewPassword"
                            placeholder="Confirm New Password"
                            required
                            onChange={e => setConfirmNewPassword(e.target.value)}
                        ></Form.Input>
                    </Form.Field>

                    <Form.Field>
                        {error && (
                            <Form.Field>
                                <div className="error-message">{error}</div>
                            </Form.Field>
                        )}
                    </Form.Field>

                    <Button primary onClick={updatePassword} disabled={isChangePasswordDisable}>
                        Save New Password
                    </Button>
                </Form>
            </div>
        </Modal>
    );
};

const ProfileSettings = props => {
    const [loading, setLoading] = useState(true);
    const { userObject, setUser } = useContext(UserContext);
    const [relative, setRelative] = useState<Relative>();
    const [isUpdating, setIsUpdating] = useState(false);
    const [formData, setFormData] = useState({
        FirstName: '',
        LastName: '',
    });
    const [isOpenModal, setOpenModal] = useState(false);

    const closeModal = () => {
        setOpenModal(false);
    };

    const openModal = () => {
        setOpenModal(true);
    };

    const deleteUser = async() => {
        await deleteRelative(relative?._id);
        window.location.reload();
    }

    useEffect(() => {
        setFormData({
            FirstName: userObject.profile.FirstName,
            LastName: userObject.profile.LastName,
        });
        setRelative({ ...userObject.profile });
        setImageUrl(userObject.profile.Image);
        setLoading(false);
    }, [userObject]);

    const handleChange = name => e => {
        setFormData({
            ...formData,
            [name]: e.target.value,
        });
    };

    const onSubmit = async e => {
        e.preventDefault();
        const residentId = userObject.registrants.list[0]._id;
        const relativeId = userObject.profile._id;
        const payload = {
            FirstName: formData.FirstName,
            LastName: formData.LastName,
        };
        setLoading(true);
        try {
            await updateRelative(residentId, relativeId, payload);
            setUser({
                ...userObject,
                profile: {
                    ...userObject.profile,
                    FirstName: formData.FirstName,
                    LastName: formData.LastName,
                },
            });
            toast.success('Details Updated', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            setLoading(false);
        } catch (error) {
            toast.error('Could not update subaccount name.', {
                position: 'bottom-center',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            setLoading(false);
            console.log("error in update", error);
        }
    };

    const [imageModalOpen, setImageModalOpen] = useState(false);
    const [imageEditor, setImageEditor] = useState();
    const [isUploadingImage, setIsUploadingImage] = useState(false);
    const BUCKET_URL = envConfig.profileImageBucketUrl;
    const [imageUrl, setImageUrl] = useState('');

    const onClickSave = async () => {
        if (imageEditor) {
            setIsUploadingImage(true);
            // This returns a HTMLCanvasElement
            setImageUrl('');
            const canvas = imageEditor.getImageScaledToCanvas();

            try {
                const { imageId, signedUrl } = await getUploadUrl();
                const dataUrl = canvas.toDataURL();
                const blob = converterDataURItoBlob(dataUrl);
                const res = await uploadToSignedUrl(blob, signedUrl);

                setIsUploadingImage(false);
                if (res.ok) {
                    const url = `${BUCKET_URL}/${relative && relative._id}?${Date.now()}`;
                    const result = await updateRelativeImage(
                        userObject.registrants.list[0]._id,
                        relative && relative._id,
                        url,
                    );
                    setUser({
                        ...userObject,
                        profile: {
                            ...userObject.profile,
                            Image: url,
                        },
                    });
                    setImageModalOpen(false);
                    setImageUrl(url);
                    toast.success('Profile Image Updated', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                } else {
                    console.log('Image upload error');
                    setIsUploadingImage(false);
                    toast.error('Could not update image. Please try again.', {
                        position: 'bottom-center',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            } catch (e) {
                console.log(e);
                setIsUploadingImage(false);
                console.log(e);
                toast.error('Could not update image. Please try again.', {
                    position: 'bottom-center',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        }
        return;
    };

    return (
        <div className="relative-list relative-detail profile-settings">
            <ImageCropModal
                setImageEditor={setImageEditor}
                setModalOpen={setImageModalOpen}
                onClickSave={onClickSave}
                open={imageModalOpen}
                loading={isUploadingImage}
            />
            <Form onSubmit={onSubmit} loading={loading}>
                <div className="relative-info no-border">
                    <div
                        className="relative-image-container"
                        onClick={() => {
                            setImageModalOpen(true);
                        }}
                    >
                        <div className="relative-image" style={{ background: `url(${imageUrl}) no-repeat` }}></div>
                        <div className="relative-avatar">
                            {relative && !!relative.FirstName && relative.FirstName.charAt(0)}{' '}
                            {relative && !!relative.LastName && relative.LastName.charAt(0)}
                        </div>
                    </div>
                    <div className="flex-1">
                        <>
                            <Form.Field>
                                <Form.Input
                                    value={formData.FirstName}
                                    type="text"
                                    name="First Name"
                                    onChange={handleChange('FirstName')}
                                    placeholder="First Name"
                                    required
                                    autoComplete="false"
                                />
                            </Form.Field>
                            <Form.Field>
                                <Form.Input
                                    value={formData.LastName}
                                    type="text"
                                    name="Last Name"
                                    onChange={handleChange('LastName')}
                                    placeholder="Last Name"
                                    required
                                    autoComplete="false"
                                />
                            </Form.Field>
                        </>
                    </div>
                </div>

                <div className="relative-field">{relative && relative.Username}</div>
                <div className="button-container">
                    <Button
                        className="ui primary large basic button"
                        loading={isUpdating}
                        type="button"
                        onClick={openModal}
                    >
                        Change Password
                    </Button>

                    <Button className="ui primary large basic button" type="submit" loading={isUpdating}>
                        <i className="plus circle icon"></i> Save Changes
                    </Button>
                </div>
                <div className="button-container">
                        <Button
                            type="button"
                            onClick={deleteUser}
                        >
                            Delete account
                        </Button>
                </div>
            </Form>
            <ChangePasswordModal contact={userObject.profile.Username} open={isOpenModal} close={closeModal} />
        </div>
    );
};

export default ProfileSettings;
export { ChangePasswordModal };