import React, { useState, useEffect } from 'react';
import { Form, Button } from 'semantic-ui-react';
import PhoneInput, { getCountries } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import ContactInput from '../ContactInput';
import './style.less';
import { Link } from 'react-router-dom';

interface Props {
    error: string;
    locationState?: any;
    handleLoginSubmit: (e, contact, password) => any;
}

const LoginComponent: React.FC<Props> = (props) => {
    const [contact, setContact] = useState<string>('');
    const [stateContact, setStateContact] = useState('');
    const [password, setPassword] = useState<string>('');

    const [contactType, setContactType] = useState('email');

    useEffect(() => {
        if (props.locationState?.contact) {
            setStateContact(props.locationState?.contact);
        }
    }, [props.locationState]);

    const onContactChange = (inputContact) => {
        // const phoneNumberRegExp = new RegExp(/^[0-9+?]{4,}$/);

        // if (phoneNumberRegExp.test(inputContact)) {
        //     // here change to mobile input if not input
        //     if (contactType === 'email') {
        //         setContactType('phone');
        //     }
        // } else {
        //     // here change to contact input if not already one
        //     if (contactType === 'phone') {
        //         setContactType('email');
        //     }
        // }
        console.log(inputContact, 'dddd');
        setContact(inputContact);
    };

    return (
        <Form
            loading={false}
            autoComplete="on"
            onSubmit={(e) => props.handleLoginSubmit(e, contact.toLowerCase(), password)}
        >
            {props.error && (
                <Form.Field>
                    <div className="error-message">{props.error}</div>
                </Form.Field>
            )}
            {/* <Form.Field>
                {contactType === 'email' ? (
                    <Form.Input
                        value={contact}
                        type="text"
                        name="contact"
                        placeholder="email or phone number"
                        required
                        autoFocus={true}
                        autoComplete="false"
                        onChange={e => onContactChange(e.currentTarget.value)}
                    />
                ) : (
                    <PhoneInput
                        defaultCountry="US"
                        value={contact}
                        autoFocus={true}
                        onChange={phone => {
                            onContactChange(phone);
                        }}
                    />
                )}
            </Form.Field> */}
            <ContactInput
                onChange={(e) => onContactChange(e)}
                contact={stateContact}
                isDisabled={props.locationState?.contact ? true : false}
            />
            <Form.Field>
                <Form.Input
                    value={password}
                    type="password"
                    name="password"
                    placeholder="Password"
                    required
                    autoComplete="password"
                    onChange={(e) => setPassword(e.currentTarget.value)}
                />
            </Form.Field>
            <Link to="/forgot-password"> Forgot Password? </Link>
            <Button type="submit" primary>
                Log In
            </Button>
        </Form>
    );
};

export default LoginComponent;
