import React, { Dispatch, SetStateAction, useState } from 'react';
import { Icon, Dimmer, Loader, Grid } from 'semantic-ui-react';
import './style.less';
import { ServicesType } from '../../types/ServiceInstance';
import MenuItemCard from './MenuItemCard';
import { useCart } from '../../contexts/cart/CartContext';
import { carouselCart } from '../../../utils';
import { handleGetQuantity } from '../../services/OrderStatus';

interface Props {
    mealDetailsLoading: boolean;
    cartItems: ServicesType[];
    setCartItems: Dispatch<SetStateAction<ServicesType[]>>;
}

const CartCarousel = ({ mealDetailsLoading, cartItems, setCartItems }: Props) => {
    const [startIndex, setStartIndex] = useState<number>(0);
    const initialItemsToShow = 3;
    const { mealItems } = useCart();

    const handleNext = () => {
        if (startIndex + initialItemsToShow < cartItems.length) {
            setStartIndex((prevIndex) => prevIndex + 1);
        }
    };

    const handlePrev = () => {
        if (startIndex > 0) {
            setStartIndex((prevIndex) => prevIndex - 1);
        }
    };

    const visibleCartItems = cartItems.slice(startIndex, startIndex + initialItemsToShow);

    return (
        <div className="restaurant-filter">
            <Dimmer inverted active={mealDetailsLoading}>
                <Loader active={mealDetailsLoading} />
            </Dimmer>
            {cartItems && cartItems.length > 0 && (
                <div className="carousel-arrows-container">
                    <Icon name="angle left" className="angle left" onClick={handlePrev} />
                </div>
            )}
            {visibleCartItems && visibleCartItems.length > 0 ? (
                <Grid columns={initialItemsToShow} style={{ width: '100%' }} centered>
                    {visibleCartItems.map((cartItem) => {
                        return (
                            <Grid.Column key={cartItem._id as string} stretched>
                                <MenuItemCard
                                    menuItem={cartItem}
                                    source={carouselCart}
                                    quantity={handleGetQuantity(mealItems, cartItem._id)}
                                    setCartItems={setCartItems}
                                />
                            </Grid.Column>
                        );
                    })}
                </Grid>
            ) : (
                <div style={{ width: '100%', margin: '50px' }}>
                    <p style={{ textAlign: 'center' }}>No Cart Items to show. Please add meals.</p>
                </div>
            )}
            {cartItems && cartItems.length > 0 && (
                <div className="carousel-arrows-container">
                    <Icon name="angle right" className="angle right" onClick={handleNext} />
                </div>
            )}
        </div>
    );
};

export default CartCarousel;
