import * as Lambda from '../../services/LambdaApi';

const fetchClassifiedCategories = async ({ facilityId }) => {
    const apiPath = `/s2l/${facilityId}/getServiceCategories`;

    const params = {
        queryStringParameters: { classifiedCategories: true },
    };
    const response = await Lambda.Get(apiPath, params, 'lambda');

    return response;
};


const fetchAllServicesInCategory = async ({ facilityId, category, pageNo, pageSize }) => {
    const apiPath = `/s2l/${facilityId}/listServices`;
    const params =
    {
        Filter: {
            category: { $in: category }
        },
        QueryOptions: {
            page_no: 1,
            page_size: pageNo * pageSize,
            sort: [{ name: "desc" }]
        }
    };

    const response = await Lambda.Post(apiPath, params, 'lambda');
    return response;
};

const fetchServiceImage = async ({ serviceId, Facility, fileName }) => {
    const apiPath = `/s2l/${Facility}/service-image`;
    const params = {
        queryStringParameters: { serviceId, Facility, fileName },
    };
    const response = await Lambda.Get(apiPath, params, 'lambda');
    if (!response || !response.Result || !response.Result.Content) {
        throw new Error('Error fetching service image');
    }
    return response.Result.Content;
};

export { fetchClassifiedCategories, fetchAllServicesInCategory, fetchServiceImage };