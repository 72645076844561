import * as Lambda from '../../services/LambdaApi';
import { Group } from '../types/Group';

const fetchGroups = async (filter, pageNo: number = 1, pageSize: number = 100) => {
    try {
        if (!filter.Facility) throw new Error('Facility ID is required');
        const apiPath = `/s2l/${filter.Facility}/listDetailedGroups`;
        const params =
        {
            Filter: {
                ...filter
            },
            pagingSorting: {
                page_size: pageSize,
                page_no: pageNo
            }
        };

        const response = await Lambda.Post(apiPath, params, 'lambda');
        if (!response || !response.Result || !response.Result.Success || !response.Result.Content) {
            throw new Error(response.Result.Error || `Error fetching resident's interests`);
        }
        return response.Result.Content as { Result: Group[]; TotRecords: number; };
    } catch (error) {
        throw new Error("Error fetching Groups list");
    }
};

const fetchGroupImage = async (facilityId: string, imageName: string, groupId: string) => {
    try {
        if (!imageName || !facilityId || !groupId) {
            throw Error("Invalid parameters for fetching images");
        }
        const apiPath = `/s2l/${facilityId}/group-image`;
        const queryParams = {
            queryStringParameters: {
                groupId: String(groupId),
                imageName: imageName,
                Facility: String(facilityId)
            }
        };
        const response = await Lambda.Get(apiPath, queryParams, 'lambda');
        if (!response || !response.Result || !response.Result.Success || !response.Result.Content) {
            throw new Error(response.Result.Error || `Error fetching resident's interests`);
        }
        return response.Result.Content as string;
    } catch (error) {
        throw new Error("Error fetching Group Image");
    }
};
export { fetchGroups, fetchGroupImage };