import React from 'react';
import { Menu } from 'semantic-ui-react';
import MenuItem from './MenuItem';
import { Link } from 'react-router-dom';
import { SidebarIcons } from '../../../types/sidebar';
const CustomSidebar = () => {

    const sidebarIcons: { name: string; path: string; iconName: SidebarIcons; }[] = [
        { name: 'Calendar', path: '/calendar', iconName: 'calendar' },
        { name: 'Menu', path: '/menu', iconName: 'menu' },
        { name: 'Community News', path: '/community-news', iconName: 'communityNews' },
        { name: 'Announcements', path: '/announcements', iconName: 'announcements' },
        { name: 'Order Status', path: '/order-status', iconName: 'orderStatus' },
        { name: 'Groups', path: '/groups', iconName: 'groups' },
        { name: 'Documents', path: '/documents', iconName: 'documents' },
    ];

    const renderSidebar = () => {
        if (sidebarIcons && sidebarIcons.length) { 
            return (
                <Menu vertical style={{ width: "85%" }}>
                    {sidebarIcons.map((el) => {
                        return (
                            (el.name === "Documents" || el.name == "Order Status" || el.name === "Announcements" || el.name ==="Groups" || el.name === "Community News" || el.name === "Calendar" || el.name === "Menu") ?  
                                <Link to={`${el.path}`} key={el.name}>
                                    <MenuItem
                                        link={el.path}
                                        name={el.name}
                                        disable={false}
                                        iconName={el.iconName}
                                        key={el.name}
                                    />
                                </Link>
                                : <MenuItem
                                    link={el.path}
                                    name={el.name}
                                    disable={false}
                                    iconName={el.iconName}
                                    key={el.name}
                                />
                        );
                    })}
                </Menu>
            );
        } else {
            return <></>;
        }
    };

    return <>{renderSidebar()}</>;
};

export default CustomSidebar;