import React, { useState, useContext } from 'react';
import { Form , Button, TextArea, Message } from 'semantic-ui-react';
import { postSupportRequest } from '../../../services/support';

import './style.less';

import UserContext from '../../../contexts/UserContext';

const Support = () => {
    const { userObject, setUser } = useContext(UserContext);
    const [supportText, setSupportText] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const [submitError, setSubmitError] = useState(false)
    const [notFacilityResident, setNotFacilityResident] = useState(false)

    const handleSubmitSupportRequest = async () => {
        setSubmitting(true)
        
        const activeRegistrantIndex = userObject.registrants.activeRegistrantsIndexes[0]
        const activeRegistrantId = userObject.registrants.list[activeRegistrantIndex]._id
        const facilityId = userObject.registrants.list[activeRegistrantIndex].Facility

        if (facilityId) {
            const response = await postSupportRequest(supportText, activeRegistrantId, facilityId)
            if (response?.Result?.Success) {
                setSubmitSuccess(true)
                setSupportText('')
                setTimeout(() => {
                    setSubmitSuccess(false)
                }, 5000)
            }
            else {
                setSubmitError(true)
                setTimeout(() => {
                    setSubmitError(false)
                }, 5000)
            }
        } else {
            setNotFacilityResident(true)
            setTimeout(() => {
                setNotFacilityResident(false)
            }, 5000)
        }
        setSubmitting(false)
    }

    return (
        <div className="relative-list food-menu">
            <div className=" food-menu">
                <h2>Get help here</h2>
                <div className="create-menu">
                    <Form>
                        <TextArea 
                            placeholder="Please enter your support request here" 
                            rows={5} 
                            value={supportText}
                            onChange={(e) => {
                                setSupportText(e.target.value)
                            }}
                        >

                        </TextArea>
                        <br/>
                        <br/>
                        <Button
                            primary={supportText ? true : false}
                            disabled = {
                                submitting || !supportText.length || ['<p></p>', '<p><br></p>'].includes(supportText) ? true : false
                            }
                            onClick={handleSubmitSupportRequest}
                            loading={submitting}
                        >
                            Submit
                        </Button>
                    </Form>
                    {submitSuccess
                    ?   <Message positive>
                            <p><b>Support request submitted!</b></p>
                            <p>
                                We will get back to you shortly.
                            </p>
                        </Message>
                    :   ''
                    }
                    {submitError
                    ?   <Message negative>
                            <p><b>Sorry, something went wrong</b></p>
                        </Message>
                    :   ''
                    }
                    {notFacilityResident
                    ?   <Message negative>
                            <p><b>The selected resident isn't a facilty resident</b></p>
                            <p>Please select a facility resident to submit a support request.</p>
                        </Message>
                    :   ''
                    }
                </div>
            </div>
        </div>
    )
}

export default Support;
