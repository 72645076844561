import * as Lambda from '../../services/LambdaApi';
import { paginationWrapper } from '../../utils/paginationWrapper';
import { MenuItem } from '../types/Menu';
import { ServicesType } from '../types/ServiceInstance';
interface RestaurantFilter {
    Facility: string;
    startDate: string;
    endDate: string;
    calendarType?: Array<String>;
    Asset?: string;
    source?: string;
}

const fetchCommunityMenuItems = async (
    {
        filter,
        pagingSorting,
    }: {
        filter: {
            Facility: string;
            startDateTimeString: string;
            endDateTimeString: string;
            calendarType?: Array<String>;
        };
        pagingSorting: {
            page_size: number;
            page_no: number;
        };
    }
) => {
    if (!filter.Facility) throw new Error('Facility ID is required');
    if (!filter.startDateTimeString) throw new Error('Start Timestamp is required');
    if (!filter.endDateTimeString) throw new Error('End Timestamp is required');

    const apiPath = `/s2l/${filter.Facility}/listAllMenu`;
    const params = {
        Filter: filter,
        pagingSorting,
    };

    const response = await Lambda.Post(apiPath, params, 'lambda');
    if (!response || !response.Result || !response.Result.Content || !response.Result.Content) {
        throw new Error('Error fetching announcements');
    }
    return response.Result.Content as { Result: MenuItem[]; TotRecords: number; };
};
const fetchRestaurantMenuItems = async (
    {
        filter,
        pagingSorting,
    }: {
        filter: RestaurantFilter;
        pagingSorting: {
            page_size: number;
            page_no: number;
        };
    }
) => {
    if (!filter.Facility) throw new Error('Facility ID is required');
    if (!filter.startDate) throw new Error('Start Timestamp is required');
    if (!filter.endDate) throw new Error('End Timestamp is required');
    filter.source = 'Menu';
    const apiPath = `/s2l/${filter.Facility}/listServiceInstance`;
    const params = {
        Filter: filter,
        pagingSorting,
    };
    const response = await Lambda.Post(apiPath, params, 'lambda');
    if (!response || !response.Result || !response.Result.Content || !response.Result.Success) {
        throw new Error('Error fetching menu items');
    }
    return response.Result.Content as { Result: ServicesType[]; TotRecords: number; };
};

const fetchAllCommunityMenuItems = async (
    {
        filter
    }: {
        filter: {
            Facility: string;
            startDateTimeString: string;
            endDateTimeString: string;
            calendarType?: Array<String>;
        };
    }
) => {
    if (!filter.Facility) throw new Error('Facility ID is required');
    if (!filter.startDateTimeString) throw new Error('Start Timestamp is required');
    if (!filter.endDateTimeString) throw new Error('End Timestamp is required');
    return await paginationWrapper(
        {
            apiCall: fetchCommunityMenuItems,
            params: {
                filter
            },
        }
    ) as MenuItem[];
};

const fetchAllRestaurantMenuItems = async (
    {
        filter
    }: {
        filter: RestaurantFilter;
    }
) => {
    const menuFilter = filter;
    if (!menuFilter.Facility) throw new Error('Facility ID is required');
    if (!menuFilter.startDate) throw new Error('Start Timestamp is required');
    if (!menuFilter.endDate) throw new Error('End Timestamp is required');
    menuFilter.source = 'Menu'; // this should be hard coded 

    return await paginationWrapper(
        {
            apiCall: fetchRestaurantMenuItems,
            params: {
                filter: menuFilter

            },
        }
    ) as ServicesType[];
};

const fetchMenuItems = async (
    {
        filter
    }: {
        filter: {
            Facility: string;
            startDate: string;
            endDate: string;
            source: string;
            _id?: string;
            _ids?: string[];
        };
    }
) => {
    if (!filter.Facility) throw new Error('Facility ID is required');
    if (!filter.startDate) throw new Error('Start Timestamp is required');
    if (!filter.endDate) throw new Error('End Timestamp is required');
    if (!filter.source) throw new Error('Category is required');
    if (!filter._id && (!filter._ids || filter._ids.length === 0)) {
        throw new Error('Either Item ID (_id) or IDs (ids) are required');
    }
    const apiPath = `/s2l/${filter.Facility}/listServiceInstance`;
    const params = {
        Filter: filter,
    };
    const response = await Lambda.Post(apiPath, params, 'lambda');
    if (!response || !response.Result || !response.Result.Content || !response.Result.Success) {
        throw new Error('Error fetching menu item');
    }
    return  response.Result.Content.Result;
};

export { fetchCommunityMenuItems, fetchAllCommunityMenuItems, fetchAllRestaurantMenuItems, fetchMenuItems };
