import React, { useContext, useEffect, useState } from 'react';
import { Card, Container, Dimmer, Grid, Icon, Loader, Modal } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import MenuCard from './MenuCard';
import sendToast from '../../../utils/Toast';
import moment from 'moment';
import { fetchAllRestaurantMenuItems } from '../../services/Menu';
import UserContext from '../../../contexts/UserContext';
import MenuDetailedView from './DetailedView';
import { ServicesType } from '../../types/ServiceInstance';
import { customDateTimeFormat } from '../../../utils';

const MenuDining = () => {
    const [calendarDate, setCalendarDate] = useState<Date>(new Date());
    const [menuItems, setMenuItems] = useState<ServicesType[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedMenu, setSelectedMenu] = useState<ServicesType | null>(null);
    const [orderModal, setOrderModal] = useState<boolean>(false);
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const correspondingSpeak2Resident = listOfResidents[activeRegistrantsIndexes[0]];

    useEffect(() => {
        fetchMenuItems();
    }, [calendarDate]);

    const fetchMenuItems = async () => {
        try {
            setLoading(true);
            const startDate = moment(calendarDate).startOf('day').format(customDateTimeFormat);
            const endDate = moment(calendarDate).endOf('day').format(customDateTimeFormat);
            const filter = {
                Facility: correspondingSpeak2Resident.Facility,
                startDate: startDate,
                endDate: endDate,
                calendarType: [correspondingSpeak2Resident.Unit.RoomCategory.toString()],
            };
            const response = await fetchAllRestaurantMenuItems({ filter });
            setMenuItems(response);
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : "Failed to fetch Menu items");
        } finally {
            setLoading(false);
        }
    };

    const handleOrder = async (selectedMenu) => {
        setSelectedMenu(selectedMenu);
        setOrderModal(true);
    };
    return (
        <div style={{ marginTop: "50px" }}>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={4}>
                        <div className='calendar-container customDatePickerWidth'>
                            <DatePicker
                                open={true}
                                onChange={(date: Date) => {
                                    setCalendarDate(date);
                                }}
                                style={{ border: '1px solid #183466' }}
                                inline
                                selected={calendarDate}
                                onMonthChange={(date: Date) => {
                                    setCalendarDate(date);
                                }}
                            />
                        </div>
                    </Grid.Column>
                    <Grid.Column width={12} >
                        <Dimmer active={loading} inverted>
                            <Loader active={loading} />
                        </Dimmer>
                        <Container className='menucard-container'>
                            {menuItems && menuItems.length ? menuItems.map(menuItem => (
                                <MenuCard key={menuItem._id} menu={menuItem} handleOrder={handleOrder} />
                            ))
                                : <Card style={{ width: "80%", background: "#cbeafc" }}>
                                    <Card.Content>
                                        <Card.Description>No Meal items available for the selected date.</Card.Description>
                                    </Card.Content>
                                </Card>
                            }
                        </Container>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Modal
                open={orderModal}
                onClose={() => {
                    setOrderModal(false);
                    setSelectedMenu(null);
                }}
                onOpen={() => setOrderModal(true)}
            >
                <Modal.Header style={{ position: "relative" }} >
                    <div onClick={() => setOrderModal(false)} >
                        <p style={{ fontSize: "1.1rem", cursor: "pointer" }}><Icon name='chevron left' /> Menu</p>
                    </div>
                </Modal.Header>
                <Modal.Content image scrolling>
                    <div className='self-center' style={{ width: "100%" }}>
                        {selectedMenu && <MenuDetailedView selectedMenu={selectedMenu} />}
                    </div>
                </Modal.Content>
            </Modal>
        </div>
    );
};

export default MenuDining;