import React, { useContext, useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import rrulePlugin from '@fullcalendar/rrule';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Button, Dimmer, Loader, Message, Modal, Segment, Image, Table } from 'semantic-ui-react';
import UserContext from '../../../contexts/UserContext';
import moment from 'moment-timezone';
import './style.less';

import { fetchAllCommunityAnnouncements } from '../../services/Announcements';
import { fetchFacilityDetails } from '../../services/Facilities';
import sendToast from '../../../utils/Toast';
import { Announcement } from '../../types/Announcement';

interface AnnouncementEvent {
    title: string;
    creatorInfo: any;
    ResidentNames: string[];
    start: Date;
    end: Date;
    id: string;
    rrRule?: any;
    exDate: string[];
}

const convertToFacilityTimezone = (date: string | number | Date, facilityTimezone: string) => {
    return moment.tz(date, facilityTimezone).format('YYYY-MM-DDTHH:mm:ss');
};

const Announcements = () => {
    const [announcements, setAnnouncements] = useState<Announcement[]>([]);
    const [facilityTimezone, setFacilityTimezone] = useState('');
    const [selectedDateRange, setSelectedDateRange] = useState({
        startDateTimeString: moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
        endDateTimeString: moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
    });
    const [loadingAnnouncements, setLoadingAnnouncements] = useState(false);
    const [loadingFacilityInfo, setLoadingFacilityInfo] = useState(false);
    const [announcementsModalOpen, setAnnouncementsModalOpen] = useState<boolean>(false);
    const [selectedEvent, setSelectedEvent] = useState<AnnouncementEvent>();
    // Collect resident information that corresponds to the logged in neighbors web user from UserContext
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const correspondingSpeak2Resident = listOfResidents[activeRegistrantsIndexes[0]]; // get the first resident from the list of active residents because neighbors web only allows one resident to be linked to one account
    const correspondingSpeak2ResidentFullName =
        `${correspondingSpeak2Resident.FirstName} ${correspondingSpeak2Resident.LastName}`.trim();

    useEffect(() => {
        const fetchFacilityTimezone = async () => {
            setLoadingFacilityInfo(true);
            try {
                const facilityId = correspondingSpeak2Resident.Facility as string;
                const facilityDetails = await fetchFacilityDetails(facilityId); //// once this function is fixed to return only Facility obj, add type for this
                if (!facilityDetails.FacilityTimeZone) {
                    throw new Error('Unable to fetch timezone of the facility. Please contact support.');
                }
                setFacilityTimezone(facilityDetails.FacilityTimeZone as string);
            } catch (error) {
                sendToast('error', error instanceof Error ? error.message : 'Failed to fetch Facility Information');
            }
            setLoadingFacilityInfo(false);
        };
        fetchFacilityTimezone();
    }, []);

    useEffect(() => {
        const fetchAnnouncements = async () => {
            setLoadingAnnouncements(true);
            if (selectedDateRange.startDateTimeString && selectedDateRange.endDateTimeString && facilityTimezone) {
                const startDateTimeISO = moment
                    .tz(selectedDateRange.startDateTimeString, facilityTimezone)
                    .toISOString();
                const endDateTimeISO = moment.tz(selectedDateRange.endDateTimeString, facilityTimezone).toISOString();
                try {
                    const announcementsResp = await fetchAllCommunityAnnouncements(
                        correspondingSpeak2Resident.Facility,
                        {
                            startTimestampISO: startDateTimeISO,
                            endTimestampISO: endDateTimeISO,
                            residentId: correspondingSpeak2Resident._id,
                        },
                    );
                    setAnnouncements(announcementsResp);
                } catch (error) {
                    sendToast('error', error instanceof Error ? error.message : 'Failed to fetch Announcements');
                }
            }
            setLoadingAnnouncements(false);
        };
        fetchAnnouncements();
    }, [selectedDateRange.startDateTimeString, selectedDateRange.endDateTimeString, facilityTimezone]);

    if (!correspondingSpeak2Resident) {
        return <Message content="Invalid User. Please contact support" />;
    }

    const handleMonthChange = ({ start, end }) => {
        const startDateTimeString = moment(start).format('YYYY-MM-DDTHH:mm:ss');
        const endDateTimeString = moment(end).format('YYYY-MM-DDTHH:mm:ss');
        setSelectedDateRange({
            startDateTimeString,
            endDateTimeString,
        });
    };

    const handleEventClick = (info) => {
        setAnnouncementsModalOpen(true);
        setSelectedEvent((prevInfo) => ({
            ...prevInfo,
            title: info.event.title,
            creatorInfo: info.event.extendedProps.creatorInfo,
            ResidentNames: info.event.extendedProps.ResidentNames,
            start: info.event.start,
            end: info.event.end,
            id: info.event.id,
            rrRule: info.event.extendedProps.rrule,
            exDate: info.event.extendedProps.exdate,
        }));
    };

    const closeAnnouncementsModal = () => {
        setAnnouncementsModalOpen(false);
    };

    return (
        <>
            <Segment
                style={{
                    margin: '2px',
                    height: '60%',
                    width: '100%',
                    overflow: 'auto',
                }}
            >
                <div className="menu-calendar-view ">
                    <Dimmer active={loadingAnnouncements || loadingFacilityInfo} inverted>
                        <Loader content="Loading Announcements" />
                    </Dimmer>
                    {/**Heading */}
                    <p className="heading">Announcements</p>
                    <hr className="spacing" />
                    <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin, rrulePlugin]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                            right: 'today prev,next',
                        }}
                        events={[
                            ...announcements.map((event) => {
                                // start date of the event in the order of priority
                                // 1. triggerTimestampISO
                                // 2. startDate
                                // 3. DateAdded
                                const startDateObj = event.triggerTimestampISO
                                    ? new Date(convertToFacilityTimezone(event.triggerTimestampISO, facilityTimezone))
                                    : event.startDate
                                    ? new Date(event.startDate)
                                    : new Date(convertToFacilityTimezone(event.DateAdded, facilityTimezone));

                                // end date of the event in the order of priority
                                // 1. endDate
                                // 2. startDate
                                const endDateObj = event.endDate ? new Date(event.endDate) : startDateObj;

                                const pattern = /exdate/i;
                                let recurrence;
                                /* 
                                    below code handles full calendar bug https://github.com/fullcalendar/fullcalendar/issues/6105
                                    by removing exdate from recurrence
                                */
                                if (event.Recurrence && pattern.test(event.Recurrence.split('\n')[1])) {
                                    recurrence = event.Recurrence.split('\n');
                                    recurrence.splice(1, 1);
                                    recurrence = recurrence.join('\n');
                                } else {
                                    recurrence = event.Recurrence;
                                }
                                return event && event.Recurrence
                                    ? {
                                          title: event.Message,
                                          creatorInfo: event.creatorInfo,
                                          ResidentNames: event.ResidentNames,
                                          start: startDateObj,
                                          end: endDateObj,
                                          id: event._id,
                                          rrule: recurrence,
                                          exdate: pattern.test(event.Recurrence.split('\n')[1])
                                              ? event.Recurrence.split('\n')[1].split(':')[1].split(',')
                                              : [],
                                      }
                                    : {
                                          title: event.Message,
                                          creatorInfo: event.creatorInfo,
                                          ResidentNames: event.ResidentNames,
                                          start: startDateObj,
                                          end: endDateObj,
                                          id: event._id,
                                      };
                            }),
                        ]}
                        datesSet={handleMonthChange}
                        eventClick={handleEventClick}
                    />
                </div>
            </Segment>
            {/* Modal for displaying event details */}
            <Modal open={announcementsModalOpen} onClose={closeAnnouncementsModal}>
                <Modal.Header>Announcement details</Modal.Header>
                <Modal.Content image>
                    <Image
                        size="small"
                        wrapped
                        src={(selectedEvent && selectedEvent.creatorInfo.icon) || '/avatar_placeholder.png'}
                    />
                    <Modal.Description style={{ width: '100%' }}>
                        <Table>
                        <Table.Header>
                            {selectedEvent && selectedEvent.creatorInfo ? (
                                <Table.Row>
                                    <Table.Cell width={4}>Announcement by:  </Table.Cell>
                                    <Table.Cell width={6}>{`${selectedEvent.creatorInfo.firstName} ${selectedEvent && selectedEvent.creatorInfo.lastName}`.trim() || ''}</Table.Cell>
                                </Table.Row>
                            ) : (
                                ''
                            )}
                        </Table.Header>
                        <Table.Body>
                            {selectedEvent && selectedEvent.start ? (
                                <Table.Row>
                                    <Table.Cell>Announcement time:  </Table.Cell>
                                    <Table.Cell>
                                        {moment(selectedEvent.start).format('h:mm A on MMM D, YYYY')}
                                        {' ('}
                                        {moment(selectedEvent.start).fromNow()}
                                        {') '}
                                    </Table.Cell>
                                </Table.Row>
                            ) : (
                                ''
                            )}
                        </Table.Body>
                        <Table.Header>
                            <Table.Row>
                                <Table.Cell>Announcement message:  </Table.Cell>
                                <Table.Cell>{(selectedEvent && selectedEvent.title) || ''}</Table.Cell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            <Table.Row>
                                <Table.Cell>Recipients:  </Table.Cell>
                                <Table.Cell>
                                    {selectedEvent && selectedEvent.ResidentNames.length === 1
                                        ? correspondingSpeak2ResidentFullName
                                        : `${correspondingSpeak2ResidentFullName} and few others`}
                                </Table.Cell>
                            </Table.Row>
                        </Table.Body>
                        </Table>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color="red" onClick={closeAnnouncementsModal}>
                        Close
                    </Button>
                </Modal.Actions>
            </Modal>
        </>
    );
};

export default Announcements;
