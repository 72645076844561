import { RequestInstance } from "../neighbors/types/OrderStatus";
import { formatDateWithTZ } from "./timezone";

export const customDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss';

export const capitalizeFirstLetter = (string: string): string => {
    if (typeof string !== 'string') return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const cart = "cart";

export const carouselCart = "carouselCart"

export const menuPage = "menuPage";

export  const formatOrdersForTableView = (orders: RequestInstance[], facilityTimezone: string) => {
    return orders.map((order) => {
        return {
            ...order,
            OrderName: order.OrderName || '-',
            OrderId: order.OrderId || '-',
            DateTime: formatDateWithTZ(order.RequestedTime, 'MM/DD/YYYY hh:mm A', facilityTimezone),
            AcceptedByName: order.AcceptedByName || '-',
            Scheduled: order.ScheduledTime ? 'Yes' : 'No',
            _id: order._id,
        };
    });
};

