import React, { useContext } from 'react';
import UserContext from '../../../contexts/UserContext';
import './style.less';
import { Grid } from 'semantic-ui-react';
import { Registrant } from '../../types/Registrant';

const AllConnectedResidents = () => {
    const { userObject, setUser } = useContext(UserContext);

    const listOfResidents = (userObject && userObject.registrants && userObject.registrants.list) || [];
    const activeRegistrantsIndexes =
        (userObject && userObject.registrants && userObject.registrants.activeRegistrantsIndexes) || [];

    const activeResident = listOfResidents[activeRegistrantsIndexes[0]]; // get the first resident from the list of active residents for which the user is a relationship manager because neighbors web only supports the resident who is logged in and we can't deselect or change it

    const handleResidentClick = (registrant: Registrant) => {
        // set active resident to clicked one and update setUser with active resident index
        const activeRegistrantsIndexes = [userObject.registrants.list.indexOf(registrant)];
        setUser({ ...userObject, registrants: { ...userObject.registrants, activeRegistrantsIndexes } });
    };

    return (
        <>
            <Grid>
                <Grid.Row>
                    {userObject.registrants.list.map((registrant: Registrant, index: number) => {
                        if (registrant.manager) {
                            const residentSocialFullName = (registrant.SocialProfile?.FirstName || registrant.SocialProfile?.LastName) ? `${registrant.SocialProfile.FirstName} ${registrant.SocialProfile.LastName}` : null;
                            const residentFullName = `${registrant.FirstName} ${registrant.LastName}`;
                            return (
                                <Grid.Column key={index} width={2}>
                                    <div
                                        className="small-circle"
                                        onClick={() => handleResidentClick(registrant)}
                                        style={{
                                            cursor: 'pointer',
                                            margin: 'auto',
                                            border: `5px solid ${activeResident._id === registrant._id ? '#ffd201' : 'gray'
                                                }`,
                                        }}
                                    >
                                        <img src={registrant.SocialProfile?.Image || registrant.Image || `/avatar_placeholder.png`} />
                                    </div>
                                    <p className="center">
                                        {residentSocialFullName || residentFullName}
                                    </p>
                                </Grid.Column>
                            );
                        }
                    })}
                </Grid.Row>
            </Grid>
        </>
    );
};

export default AllConnectedResidents;