import * as Lambda from '../../services/LambdaApi';

const fetchFacilityDetails = async (facilityId: string) => {
    try {
        const apiPath = `/s2l/${facilityId}/fetchFacilityInfo`;
        const response = await Lambda.Post(apiPath, { _id: facilityId }, 'lambda');
        if (!response || !response.Result || !response.Result.Content || !response.Result.Success) {
            throw new Error("Error fetching facility details");
        }
        return response.Result.Content;
    } catch (err) {
        console.error(err);
        throw new Error("Error fetching facility details");
    }
};

export {
    fetchFacilityDetails
};