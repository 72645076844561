import React, { useContext, useEffect, useState } from 'react';
import UserContext from '../../../contexts/UserContext';
import { fetchAllServicesInCategory, fetchClassifiedCategories, fetchServiceImage } from '../../services/Service';
import { Button, Card, CardContent, Dimmer, Grid, Header, Icon, Image, Loader, Modal, Popup } from 'semantic-ui-react';
import { Service } from '../../types/Service';
import sendToast from '../../../utils/Toast';
import CreateServiceOrder from '../../components/OrderStatus/CreateServiceOrder';
import BlueBirdPromise from "bluebird";
import LoadMore from '../../../utils/LoadMore';
import { useHistory } from 'react-router-dom';
import { fetchFacilityDetails } from '../../services/Facilities';

export const categoryColorMapping = {
    "Concierge": "#2D2B87",
    "Maintenance": "#0E8FD7",
    "Wellness": "#6BAD4C",
    "Dining": "#0E8FD7",
    "Transportation": "#6BAD4C",
    "Housekeeping": "#E98530",
    "Spa": "#0E8FD7",
    "Administrative": "#E98530",
    "Technology": "#2D2B87",
    "Pets": "#2D2B87",
    "Valet": "#2D2B87",
    "Other": "#0E8FD7"
};

interface ClassifiedCategory {
    category: string;
    subCategories: string[];
    color: string;
}

const CreateOrder = () => {
    const [classifiedCategories, setClassifiedCategories] = useState<ClassifiedCategory[]>([]);
    const [isFetching, setIsFetching] = useState(true);
    const [services, setServices] = useState<Service[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<string>("");
    const [openCreateOrderModal, setOpenCreateOrderModal] = useState(false);
    const [selectedService, setSelectedService] = useState<Service | null>(null);
    const [selectedCategoriesList, setSelectedCategoriesList] = useState<string[]>([]);
    const [servicePageNo, setServicePageNo] = useState<number>(1);
    const [hasMoreServices, setHasMoreServices] = useState<boolean>(true);
    const history = useHistory();
    const {
        userObject: {
            registrants: { list: listOfResidents, activeRegistrantsIndexes },
        },
    } = useContext(UserContext);
    const activeResident = listOfResidents[activeRegistrantsIndexes[0]]; // get the first resident from the list of active residents because neighbors web only supports the resident who is logged in and we can't deselect or change it
    const servicePageSize = 20;

    useEffect(() => {
        (async () => {
            await fetchAllCategories();
        })();
    }, []);

    useEffect(() => {
        if (selectedCategory && selectedCategoriesList.length > 0) {
            fetchServicesForSelectedCategory(selectedCategoriesList, selectedCategory, true);
        }
    }, [servicePageNo]);

    const fetchFacilityDetail = async () => {
        try {
            setIsFetching(true);
            const facilityId = activeResident.Facility as string;
            const facilityDetails = await fetchFacilityDetails(facilityId);
            if (!facilityDetails.FacilityTimeZone) {
                throw new Error('Unable to fetch timezone of the facility. Please contact support.');
            }
            if (facilityDetails.featureConfigs && facilityDetails.featureConfigs.configurations && facilityDetails.featureConfigs.configurations.dining) {
                return true
            }
            return false
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : 'Failed to fetch Facility Information');
        } finally {
            setIsFetching(false);
        }
    };

    const fetchServicesForSelectedCategory = async (categories: string[] = [], clickedCategory: string, isPaginating: boolean = false) => {
        try {
            setIsFetching(true);
            setSelectedCategoriesList(categories);
            setServices([]);
            setSelectedCategory(clickedCategory);
            const response = await fetchAllServicesInCategory({ facilityId: activeResident.Facility, category: isPaginating ? selectedCategoriesList : categories, pageNo: servicePageNo, pageSize: servicePageSize }); // isPaginating key is used when we click Load More button - to fetch the next page of services based on the already selected categories list hence if isPaginating is true we pass the selectedCategoriesList else we pass the categories which were clicked directly because else there will be state change issue and the selectedCategoriesList will not be updated at the time of query
            const servicesWithImage = response.Result.Content.Result.filter((service: Service) => service.image);
            const servicesImageDict = {};

            if (servicesWithImage.length > 0) {
                await BlueBirdPromise.map(servicesWithImage, async (service: Service) => {
                    const imageResponse = await fetchServiceImage(
                        {
                            serviceId: service._id,
                            fileName: service.image,
                            Facility: (service && service.Facility)
                        }
                    );

                    const imageUrl = imageResponse;
                    servicesImageDict[service._id] = imageUrl;
                }, { concurrency: 5 });
            }

            const updatedServicesResponseWithImageURL = response.Result.Content.Result.map((service: Service) => {
                if (servicesImageDict[service._id]) {
                    service.imageURL = servicesImageDict[service._id];
                }
                return service;
            });
            if (response.Result.Content.TotRecords <= servicePageNo * servicePageSize) {
                setHasMoreServices(false);
            }
            setServices(updatedServicesResponseWithImageURL);
        } catch (error) {
            console.error("Error in fetching service categories", error);
            sendToast('error', "Something went wrong while fetching services");
        } finally {
            setIsFetching(false);
        }
    };

    const fetchAllCategories = async () => {
        //fetch all classified categories
        try {
            setIsFetching(true);
            const response = await fetchClassifiedCategories({ facilityId: activeResident.Facility });
            setClassifiedCategories(response.Result.Content);
        } catch (error) {
            console.log("error in fetching all classified categories", error);
            sendToast('error', "Something went wrong while fetching classified categories");
        } finally {
            setIsFetching(false);
        }
    };

    const handleServiceClick = (service: Service) => {
        setSelectedService(service);
        setOpenCreateOrderModal(true);
    };
     
    const handleRedirectToDining = async () => {
        try {
            const isDiningEnabled = await fetchFacilityDetail();
            if (isDiningEnabled) {
                history.push({
                    pathname: `/dining`
                });
            } else {
                sendToast('warn', 'Dining feature is not enabled for this facility. Please contact support to enable it.');
            }
        } catch (error) {
            sendToast('error', error instanceof Error ? error.message : "Something went wrong while opening dining feature");
        }
    };

    const renderClassifiedCategories = () => {
        // list of categories not the values of the categories
        const categories = classifiedCategories && classifiedCategories.length > 0  && classifiedCategories || [];
        return (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>
                <div style={{ width: "950px" }}>
                    <Grid columns={4}>
                        {categories.map((categoryItem, index) => (
                            <Grid.Column key={index} >
                                <Button
                                    fluid
                                    style={{ backgroundColor: categoryItem.color, color: "white", marginBottom: "10px", width: "171px", borderRadius: "70px", height: "60px" }}
                                    className="category-button"
                                    onClick={() => {
                                        if (categoryItem.category === "Dining") {
                                            handleRedirectToDining();
                                        } else {
                                            fetchServicesForSelectedCategory(categoryItem.subCategories, categoryItem.category)
                                        }
                                    }
                                    }
                                >
                                    {categoryItem.category}
                                </Button>
                            </Grid.Column>
                        ))}
                    </Grid>
                </div>
            </div>
        );
    };

    const renderServicesForSelectedCategory = () => {
        return (
            <div>
                <Dimmer active={isFetching} inverted>
                    <Loader>Loading</Loader>
                </Dimmer>
                <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                    {
                        services && services.length > 0 && <h1 style={{ textAlign: 'center' }}>Services</h1>
                    }
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                        <Grid columns={4} centered padded style={{ width: "100%" }}>
                            {services && services.length > 0 ? (
                                services.map((service, index) => (
                                    <Grid.Column width={4} key={index}>
                                        <div style={{ height: '120px', width: '300px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: 'auto' }}>
                                            <Card style={{ height: '100%', width: '100%', cursor: 'pointer' }} onClick={() => handleServiceClick(service)}>
                                                <CardContent>
                                                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", padding: '10px' }}>
                                                        <div style={{ flex: 1 }}>
                                                            <h4 style={{ margin: '0', color: "black" }}>{service.name}</h4>
                                                            <p style={{ color: 'black', fontSize: '1.2em' }}>{service.shortDescription}</p>
                                                        </div>
                                                        <Image
                                                            size='mini'
                                                            src={service.imageURL || `${process.env.PUBLIC_URL}/dummy-image.png`}
                                                            style={{ width: 'auto', height: 'auto', maxHeight: '50px' }}
                                                        />
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </div>
                                    </Grid.Column>
                                ))
                            ) : (
                                (selectedCategory && services.length === 0) && (
                                    <Grid.Column width="8" textAlign="center" style={{ marginTop: '20px' }}>
                                        <Header color='black' as="h4">No services for the selected category</Header>
                                    </Grid.Column>
                                )
                            )}
                        </Grid>
                    </div>
                    {(selectedCategory && services.length > 0) ? (
                        <LoadMore isLoading={isFetching} hasMore={hasMoreServices} next={() => { setServicePageNo((prev) => prev + 1); }} />) : <></>}
                </div>
            </div>
        );
    };
    //redirect to order status page
    const handleRedirectToOrderStatus = () => {
        history.push({
            pathname: `/order-status`,
        });
    };
    return (
        <div>
            <Dimmer active={isFetching} inverted>
                <Loader>Loading</Loader>
            </Dimmer>
            <div className='headingContainer'>
                <Popup content='Go back to order status' trigger={
                    <Icon
                        name='arrow left'
                        size='large'
                        className='backIcon'
                        onClick={handleRedirectToOrderStatus}
                    />
                }
                />
                <div className='mainHeadingContainer'>
                    <h1 className="heading" >Place an Order</h1>
                </div>
            </div>
            <hr className="spacing" />
            {
                renderClassifiedCategories()
            }
            <div>
                {
                    renderServicesForSelectedCategory()
                }
                <Modal open={openCreateOrderModal} onClose={() => setOpenCreateOrderModal(false)} size="tiny">
                    <Modal.Content>
                        <CreateServiceOrder service={selectedService || {} as Service} selectedCategory={selectedCategory} setOpenCreateOrderModal={setOpenCreateOrderModal} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setOpenCreateOrderModal(false)}>Cancel form</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        </div>
    );
};

export default CreateOrder; 