import React, { useEffect, useCallback, useState } from 'react';
import { Button, Modal, Icon, Input, Card } from 'semantic-ui-react';
import PayCard from './Card';
import { getPaymentIntentSecret, getPaymentMethod } from '../../../services/quotas';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';

const CardComponent = ({
    extSaveHandler,
    setExtElements,
    setExtName,
    setExtStripe,
    open,
    cards,
    setModalOpen,
    submitPaymentMethod,
    paymentManagerId,
}) => {
    const [name, setName] = useState('');

    const elements = useElements();
    const stripe = useStripe();
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    if (extSaveHandler) {
        setExtElements(elements);
        setExtStripe(stripe);
    }

    const handleSubmit = async () => {
        try {
            setIsSubmitting(true);
            setError('');
            const { clientSecret } = await getPaymentIntentSecret(paymentManagerId);
            console.log(clientSecret, 'clientSecret');
            const res = await stripe?.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name,
                    },
                },
            });

            console.log(res, 'res', stripe);

            if (res.error && res.error.message) {
                setError(res.message || res.error.message);
                // formikOptions.setSubmitting(false);
                setIsSubmitting(false);
            } else {
                const { paymentMethod } = await getPaymentMethod(res.setupIntent.payment_method, paymentManagerId);
                setIsSubmitting(false);

                // formikOptions.setSubmitting(false);
                // setFinishedSuccessFullyAddingCard(true);
                // await delay(1000);

                submitPaymentMethod(paymentMethod);
            }
        } catch (err) {
            console.log('somerthing went wrong', err);
            setIsSubmitting(false);

            console.log('errrr', err);
            if (err.message || (err.error && err.error.message)) {
                setError(err.message || err.error.message);
            } else {
                setError('Something Went Wrong, please try again');
            }
        }
    };

    return (
        <div>
            {open ? (
                <div className="checkout-card-inline">
                    <h4>Enter card details</h4>
                    <div className="card-details">
                        <Input
                            value={name}
                            onChange={(e) => {
                                setName(e.target.value);
                                if (extSaveHandler) {
                                    setExtName(e.target.value);
                                }
                            }}
                            placeholder="Name on Card"
                            className="cardName"
                        ></Input>
                        <div className="pay-card">
                            <PayCard />
                        </div>
                    </div>

                    {extSaveHandler ? (
                        <> </>
                    ) : (
                        <>
                            <div className="error">{error}</div>
                            <Button primary small onClick={handleSubmit} loading={isSubmitting}>
                                Save Card for Payment
                            </Button>

                            {cards && cards.length ? (
                                <Button
                                    onClick={() => {
                                        setModalOpen(false);
                                        setName('');
                                    }}
                                    small
                                >
                                    Cancel
                                </Button>
                            ) : (
                                <></>
                            )}
                        </>
                    )}
                </div>
            ) : (
                <Button
                    large
                    basic
                    onClick={() => {
                        setModalOpen(true);
                    }}
                    className="add-card-button"
                    color="blue"
                >
                    <Icon name="add" />
                    Add Card
                </Button>
            )}
        </div>
    );
};

export default CardComponent;
